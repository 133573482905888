import React from 'react';
import PropTypes from 'prop-types';
import PcCard from './pc';
import ProviderCard from './provider';
import LeagueCard from './league';

export const cardVariants = {
  PC: 'PC',
  LEAGUE: 'LEAGUE',
  PROVIDER: 'PROVIDER',
  USER: 'USER',
  MATCH: 'MATCH',
};

const Card = ({ variant, ...rest }) => {
  if (variant === cardVariants.PC) return <PcCard {...rest} />;
  if (variant === cardVariants.PROVIDER) return <ProviderCard {...rest} />;
  if (variant === cardVariants.LEAGUE) return <LeagueCard {...rest} />;

  return false;
};
export default Card;
Card.propTypes = {
  variant: PropTypes.oneOf(Object.values(cardVariants)).isRequired,
};
