import React from 'react';

import { Tooltip2 } from '@blueprintjs/popover2';
import { Classes, Position } from '@blueprintjs/core';
import { Icon } from 'components';
import { title } from 'utils/helpers';

const VodIcon = ({ providersListWithVod }) => {
  const getIconColor = (providers = []) => {
    if (!providers.length) return '#999999';
    const hasDefault = providers.some((provider) => provider.isDefault);
    if (hasDefault) return '#040482';
    return '#4ACBCE';
  };

  const getToolTipContent = (providers = []) => {
    if (!providers.length) return 'No VOD';
    return providers.map(({ order, provider }) => (
      <div key={provider.id}>
        <>
          <b>{`P${order}: `}</b>
          <span>{title(provider.type)}</span>
        </>
      </div>
    ));
  };

  const content = getToolTipContent(providersListWithVod);

  const color = getIconColor(providersListWithVod);

  return (
    <Tooltip2
      content={content}
      position={Position.TOP}
      className={Classes.TOOLTIP_INDICATOR}
      minimal
      usePortal
    >
      <Icon icon="video" color={color} size={20} />
    </Tooltip2>
  );
};

export default VodIcon;
