import React from 'react';
import { Button, RadioGroup, Label, Text } from 'components';
import { Dialog } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useMutation } from '@apollo/client';
import { DEACTIVATE_USER_MUTATION } from 'gql';
import { DEACTIVATION_REASONS } from 'utils/enums';
import { FormattedMessage, useIntl } from 'react-intl';

const DeactivateConfirm = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [deactivateUserMutation, { loading }] = useMutation(
    DEACTIVATE_USER_MUTATION,
    {
      onError: ({ message }) => showToast({ message, icon: 'error' }),
      onCompleted: (data) => {
        const modalType = data.deactivateUser?.deactivated
          ? 'deactivated'
          : 'reactivated';
        const message = formatMessage(
          { id: 'containers.users.modals.deactivate.success' },
          { modalType }
        );
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message });
      },
    }
  );

  const initialValues = {
    id: modalState?.user?.id,
    legacyId: modalState?.user?.legacyId,
    deactivated: !modalState?.user?.deactivated,
    deactivationReason: undefined,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) =>
      deactivateUserMutation({ variables: { input: values } }),
  });

  const deactivateTitleData = {
    title: 'Deactivate User',
    icon: 'bg-deactivate',
  };
  const reactivateTitleData = {
    title: 'Reactivate User',
    icon: 'bg-reactivate',
  };

  const dialogTitleData = ({ icon, title }) => (
    <div className="deactivate-title">
      <span className={icon} size={20} data-testid="dialogIcon" />
      <div data-testid="dialogTitle">{title}</div>
    </div>
  );

  const { firstName, middleName, lastName, deactivated } = modalState?.user;

  const title = deactivated
    ? dialogTitleData(reactivateTitleData)
    : dialogTitleData(deactivateTitleData);
  return (
    <Dialog
      isOpen={modalState?.open}
      title={title}
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="bp3-dialog-body deactive-body-container ">
        {deactivated ? (
          <Text variant="body1" data-testid="users-reactivate-description">
            <FormattedMessage id="containers.users.modals.reactivate.content" />
          </Text>
        ) : (
          <Text variant="body1" data-testid="users-deactivate-description">
            <FormattedMessage id="containers.users.modals.deactivate.content" />
          </Text>
        )}
        <Label
          variant="AvatarLabel"
          labelText={`${firstName} ${middleName || ''} ${lastName}`}
          avatarText={`${firstName} ${lastName}`}
          data-testid="users-deactivate-avatar"
        />
        {!deactivated && (
          <RadioGroup
            name="deactivate-reason"
            items={DEACTIVATION_REASONS}
            selectedValue={formik.values.deactivationReason}
            onRadioChange={(v) => formik.setFieldValue('deactivationReason', v)}
          />
        )}
      </div>
      <div className="bp3-dialog-footer" data-testid="deactivate-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            disabled={!deactivated && !formik.values.deactivationReason}
            intent="Primary"
            text={`${
              deactivated
                ? formatMessage({ id: 'containers.users.menu.reactivate' })
                : formatMessage({ id: 'containers.users.menu.deactivate' })
            }`}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeactivateConfirm;
