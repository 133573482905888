import React from 'react';
import { Button } from 'components';

const FullScreenErrorMsg = ({ action }) => {
  const cancel = () =>
    window.location.replace(`${window.location.origin}/logout`);
  return (
    <div className="full-screen-error">
      <span>Oops, looks like something went wrong. Please try again.</span>
      <div className="flex gap-30">
        {action && (
          <Button text="Try Again" intent="Primary" onClick={action} />
        )}
        <Button text="Cancel" onClick={cancel} />
      </div>
    </div>
  );
};

export default FullScreenErrorMsg;
