import { Intent } from '@blueprintjs/core';
import { Button, Icon } from 'components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { title } from 'utils/helpers';

const ImpersonationBanner = ({ type, exitImpersonation }) => {
  return (
    <header className="impersonation-banner">
      <div className="accent" />
      <div className="mgr-6 flex gap-10">
        <Icon customIcon="impersonation" size={18} />
        <FormattedMessage
          id="containers.all.impersonation.banner.content"
          values={{ type: title(type) }}
        />
      </div>
      <Button intent={Intent.PRIMARY} onClick={exitImpersonation}>
        Stop Viewing
      </Button>
    </header>
  );
};

export default ImpersonationBanner;
