import React from 'react';
import { Dropdown } from 'components';
import { RenderIf } from 'config';
import { PART, TEAM_SIDE } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';
import { includes, prop } from 'ramda';

const TaskDetailsRow = ({
  touched,
  error,
  setFormikField,
  task = {},
  rowIndex,
  loading,
  offlineTaskTypes,
  halfValues,
  disableInActiveItems,
}) => (
  <div
    data-testid="dashboard-assignTasks-taskDetailsRow"
    className="flex gap-20"
  >
    <Dropdown
      value={task?.type || ''}
      items={optionsFromArr(offlineTaskTypes)}
      onChange={({ value }) => {
        setFormikField(`tasks[${rowIndex}]`, {
          half: '',
          part: [],
          teamSide: '',
        });
        setFormikField(`tasks[${rowIndex}].type`, value);
      }}
      buttonText="Task"
      loading={loading}
      error={touched?.type && error?.type}
      data-testid="dashboard-assignTasks-taskType"
    />

    <RenderIf condition={includes(task.type, ['LOCATION', 'VALIDATION'])}>
      <Dropdown
        value={task.half || ''}
        items={optionsFromArr(halfValues)}
        onChange={({ value }) => {
          setFormikField(`tasks[${rowIndex}].half`, value);
        }}
        buttonText="Half"
        loading={loading}
        error={touched?.half && error?.half}
        data-testid="dashboard-assignTasks-half"
        name="half"
      />
      <Dropdown
        multiSelect
        disableInActiveItems={disableInActiveItems}
        values={task.part || []}
        items={optionsFromArr(PART)}
        onChange={(value) => {
          setFormikField(`tasks[${rowIndex}].part`, value);
        }}
        buttonText="Part"
        className="customize-dropdown-button"
        error={touched?.part && error?.part}
        name="part"
        data-testid="dashboard-assignTasks-part"
      />
    </RenderIf>
    <RenderIf condition={includes(task.type, ['TAGGING', 'FREEZE_FRAME'])}>
      <Dropdown
        disableInActiveItems={disableInActiveItems}
        value={task.teamSide || ''}
        items={optionsFromArr(TEAM_SIDE)}
        onChange={(value) => {
          setFormikField(`tasks[${rowIndex}].teamSide`, prop('value', value));
        }}
        name="teamSide"
        buttonText="Team Side"
        className="customize-dropdown-button"
        error={touched?.teamSide && error?.teamSide}
        data-testid="dashboard-assignTasks-teamSide"
      />
      <Dropdown
        multiSelect
        values={task.half || []}
        items={optionsFromArr(halfValues)}
        onChange={(value) => {
          setFormikField(`tasks[${rowIndex}].half`, value);
        }}
        buttonText="Half"
        loading={loading}
        error={touched?.half && error?.half}
        disableInActiveItems={disableInActiveItems}
        data-testid="dashboard-assignTasks-half-tagging"
        name="half"
      />
    </RenderIf>
  </div>
);

export default TaskDetailsRow;
