import { gql } from '@apollo/client';
/**
 {
    "input": {
      "id": null,
      "coordinatorId": null
    }
  }
 */
export default gql`
  mutation UpdateMatchCoordinator($input: AssignMatchCoordinatorInput!) {
    updateMatchCoordinator(input: $input) {
      id
      coordinator {
        id
      }
    }
  }
`;
