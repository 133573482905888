/* eslint-disable no-console */
import React from 'react';
import {
  ApolloProvider,
  split,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import 'dotenv/config';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const GRAPHQL_API_URL = process.env.REACT_APP_API_URL;
const GRAPHQL_SUBSCRIPTION_URL = process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL;
const ApolloAuthProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  // http link
  const httpLink = new HttpLink({ uri: `${GRAPHQL_API_URL}/graphql` });

  // retry link
  const retryLink = new RetryLink({
    delay: {
      initial: 2500,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error) =>
        !(
          process.env.NODE_ENV !== 'production' ||
          !error ||
          [401, 500].includes(error.statusCode)
        ),
    },
  });

  // error link
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (operation) {
      console.log(`[GQL action]:${operation.operationName}`, {
        variables: operation.variables,
      });
    }
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${GRAPHQL_SUBSCRIPTION_URL}/graphql`,
      connectionParams: async () => {
        const token = await getAccessTokenSilently();
        return {
          Authorization: token ? `Bearer ${token}` : '',
          reconnect: true,
        };
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const link = ApolloLink.from([retryLink, errorLink, splitLink]);

  const authLink = setContext(async (_, { headers }) => {
    // token
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: authLink.concat(link),
    resolvers: {},
    connectToDevTools: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAuthProvider;
