import { Spinner } from '@blueprintjs/core';
import ItemTypes from 'containers/MatchAssignment/itemTypes';
import { findByValue } from 'utils/helpers';
import { isEmpty, keys } from 'ramda';
import { Pagination } from 'components';
import VideoMatchAssignmentCard from '../VideoMatchAssignmentCard';
import { arrangeMatches } from '../../helpers';

const VideoExpandableCard = ({
  date,
  matchesSquads,
  matchesData,
  matchesLoading,
  page,
  setPage,
  pageSize,
  matchesDispatch,
  matchesState,
}) => {
  const renderMatches = () => {
    if (matchesLoading) return <Spinner size={25} />;
    const { totalCount, matches } = matchesData || {};
    const sortedMatches = arrangeMatches(matches);
    const selectedMatchesCount = keys(
      matchesState.selectedMatches[date]
    ).length;

    if (isEmpty(matches)) return <div className="mgb-17">No Matches</div>;
    return (
      <>
        <h4 className="video-expandable-matches-count">
          {`${selectedMatchesCount} / ${totalCount}`}
          Selected Matches
        </h4>
        {sortedMatches?.map((match, index) => (
          <VideoMatchAssignmentCard
            matchesDispatch={matchesDispatch}
            key={`matchCard-${match.id}`}
            index={index}
            match={match}
            className="mgb-17"
            type={match?.squadShifts?.squadShift?.id ? '' : ItemTypes.MATCH}
            expandDate={date}
            squadName={
              findByValue('matchId', match?.id, matchesSquads)?.squadShifts[0]
                .squadShift?.squad?.name
            }
            matchesState={matchesState}
          />
        ))}
        <Pagination
          totalCount={totalCount}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          screenTitle="Matches"
          className="width-100"
        />
      </>
    );
  };
  return <div className="flex gap-8 column">{renderMatches()}</div>;
};

export default VideoExpandableCard;
