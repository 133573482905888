import Dropdown from 'components/Dropdown';
import React from 'react';
import { useIntl } from 'react-intl';
import { ACCOUNT_STATUSES } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';

const AccountStatusFilter = ({ accountStatus, onAccountStatusChange }) => {
  const { formatMessage } = useIntl();
  return (
    <Dropdown
      multiSelect
      values={accountStatus || []}
      items={optionsFromArr(ACCOUNT_STATUSES)}
      onChange={onAccountStatusChange}
      buttonText={formatMessage({ id: 'containers.users.accountStatus' })}
      loading={!ACCOUNT_STATUSES}
      data-testid="main-filters-accountStatus"
    />
  );
};

export default AccountStatusFilter;
