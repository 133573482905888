import React from 'react';
import { RadioGroup, TextInput, Divider } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';

const InfoIssue = ({
  setFieldValue,
  missingPlayer,
  type,
  homeTeam = '',
  awayTeam = '',
  errors = {},
  touched = {},
  resetErrors,
  resetTouched,
  isEdit,
}) => {
  const homeAway = [
    { text: `Home-${homeTeam}`, value: 'HOME' },
    { text: `Away-${awayTeam}`, value: 'AWAY' },
  ];

  const infoVariants = [
    { text: `Missing Player`, value: 'MISSING_PLAYER' },
    { text: `Metadata Issue`, value: 'METADATA_ISSUE' },
  ];
  const { formatMessage } = useIntl();

  return (
    <div className="missing-player-container">
      <RadioGroup
        items={infoVariants}
        selectedValue={type}
        onRadioChange={(data) => {
          setFieldValue('type', data);
          resetErrors({});
          resetTouched({});
        }}
        name="type"
        className="missing-player-issue-group"
        error={touched?.type && errors?.type}
        disabled={isEdit}
      />
      {type === 'MISSING_PLAYER' ? (
        <>
          <Divider className="mgb-25 mgt-15" />
          <Grid className="p-0 mg-0">
            <Row className="p-0 mg-0">
              <Col sm={6}>
                <RadioGroup
                  items={homeAway}
                  selectedValue={missingPlayer.teamSide}
                  onRadioChange={(data) =>
                    setFieldValue('missingPlayer.teamSide', data)
                  }
                  name="teamSide"
                  className="missing-player-issue-group flex column"
                  error={
                    touched.missingPlayer?.teamSide &&
                    errors?.missingPlayer?.teamSide
                  }
                />
              </Col>
              <Col sm={6} className="flex gap-10">
                <TextInput
                  required
                  type="text"
                  labelText={formatMessage({ id: 'containers.all.playerName' })}
                  placeholder={formatMessage({
                    id: 'containers.all.playerName',
                  })}
                  value={missingPlayer.name}
                  onChange={(data) => setFieldValue('missingPlayer.name', data)}
                  error={
                    touched?.missingPlayer?.name && errors?.missingPlayer?.name
                  }
                  data-testid="dashboard-infoIssue-playerName"
                />
                <TextInput
                  required
                  type="number"
                  labelText={formatMessage({ id: 'containers.all.number' })}
                  placeholder={formatMessage({ id: 'containers.all.number' })}
                  value={missingPlayer.shirtNumber}
                  onChange={(data) =>
                    setFieldValue(
                      'missingPlayer.shirtNumber',
                      parseInt(data, 10)
                    )
                  }
                  className="width-30"
                  error={
                    touched?.missingPlayer?.shirtNumber &&
                    errors?.missingPlayer?.shirtNumber
                  }
                  data-testid="dashboard-infoIssue-shirtNumber"
                />
              </Col>
            </Row>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InfoIssue;
