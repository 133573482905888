import { MATCHES_QUERY, MATCHES_WITH_VIDEO_FILTERS_QUERY } from 'gql';
import moment from 'moment';
import {
  defaultTo,
  flatten,
  pipe,
  pluck,
  sum,
  values,
  groupBy,
  mergeAll,
  prop,
  last,
  drop,
  append,
  head,
  concat,
  dropLast,
} from 'ramda';
import { USER_LINE_NAMES } from 'utils/enums';

export const activePowersPerShift = (type, activeSquads = []) =>
  defaultTo(0, pipe(defaultTo([], pluck(type)), sum))(activeSquads)?.toFixed(2);

export const totalsPerDay = (type, squadsPerShift) =>
  defaultTo(
    0,
    pipe(values, flatten, pluck(type), sum)
  )(squadsPerShift)?.toFixed(2);

export const squadsPerShift = groupBy((squadShiftPowerAndCapacity = []) => {
  const shiftTypeFunc = pipe(pluck(['shift']), mergeAll, prop('id'));
  const shiftType = shiftTypeFunc(squadShiftPowerAndCapacity?.squadShifts);
  return shiftType;
});

export const updateListWithNextDay = (datesList) => {
  const day = last(datesList);
  const nextDate = moment(day).add(1, 'days').format('YYYY-MM-DD').toString();
  const updateDates = pipe(drop(1), append(nextDate));
  return updateDates(datesList);
};

export const updateListWithPrevDay = (datesList) => {
  const day = head(datesList);
  const nextDate = moment(day)
    .subtract(1, 'days')
    .format('YYYY-MM-DD')
    .toString();
  const updateDates = pipe(dropLast(1), concat([nextDate]));
  return updateDates(datesList);
};

export const getMatchesQuery = (userLineName) => {
  return {
    [USER_LINE_NAMES.VIDEO]: MATCHES_WITH_VIDEO_FILTERS_QUERY,
    [USER_LINE_NAMES.OFFLINE]: MATCHES_QUERY,
  }[userLineName];
};
