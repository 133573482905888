import { gql } from '@apollo/client';

export default gql`
  mutation UpdatePriorityCategoriesRanks(
    $input: [UpdatePriorityCategoryRankInput!]!
  ) {
    updatePriorityCategoriesRanks(input: $input) {
      id
      rank
    }
  }
`;
