import { gql } from '@apollo/client';

export default gql`
  query competitionSeasonsInfo(
    $queryParams: QueryParams
    $areaIds: [Int]
    $competitionSeasonIds: [Int]
    $name: String
    $minSeasonYear: Int
  ) {
    competitionSeasonsInfo(
      queryParams: $queryParams
      areaIds: $areaIds
      competitionSeasonIds: $competitionSeasonIds
      name: $name
      minSeasonYear: $minSeasonYear
    ) {
      id
      competitionName
      seasonName
      area {
        id
        name
        code
      }
    }
  }
`;
