import React, { useReducer, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { isEmpty, path, pluck, find, propEq, isNil, reject } from 'ramda';
import {
  Button,
  DateWithShiftFilterBar,
  Icon,
  Label,
  MatchesFilters,
  Pagination,
  SearchBox,
  ShiftMatchCard,
  SingleDatePicker,
  Text,
} from 'components';
import {
  MATCHES_DETAILS_BY_LINE_QUERY,
  MATCH_OFFLINE_TASKS_QUERY,
  SHIFT_MATCHES_QUERY,
  SWAP_MATCH_MUTATION,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useProfile } from 'providers/profileProvider';
import { filtersInitialState, filtersReducer } from 'state/filtersState';
import useDebouncedCallback from 'hooks/useDebounce';
import { RenderIf } from 'config';
import {
  dashboardActions,
  dashboardInitialState,
  dashboardReducer,
} from 'state/dashboardState';
import { isVideo } from 'utils/helpers';

const SwapMatch = () => {
  const { modalDispatch, modalState } = useModal();
  const {
    profile: { squadId, jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;

  const { id: lineId, name: userLineName } = userLine ?? {};

  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [activeShiftMatchToReplace, setActiveShiftMatchToReplace] = useState();
  const shiftMatch = path(['payload', 'shiftMatch'], modalState);
  const assignedSquadName = path(['payload', 'assignedSquadName'], modalState);
  const refetchShiftMatches = path(
    ['payload', 'refetchShiftMatches'],
    modalState
  );
  const { formatMessage } = useIntl();
  const [filtersState, filtersDispatch] = useReducer(
    filtersReducer,
    filtersInitialState
  );
  const [dashboardState, dashboardDispatch] = useReducer(
    dashboardReducer,
    dashboardInitialState
  );
  const { activeDate, shift, searchValue, openFilters, filtersData } =
    dashboardState;
  const [invalidInput, setInvalidInput] = useState(false);
  const dispatchSearch = (searchValue) => {
    dashboardDispatch({
      type: dashboardActions.SET_SEARCH_VALUE,
      payload: { searchValue },
    });
  };

  const onSearch = (searchValue = undefined) => {
    if (searchValue) {
      if (!Number.isNaN(searchValue)) {
        const matchId = Number(searchValue);
        dispatchSearch(matchId);
        setInvalidInput(false);
      } else {
        dispatchSearch(searchValue);
        setInvalidInput(true);
      }
    } else {
      dispatchSearch(undefined);
      setInvalidInput(false);
    }
  };

  const delay = 100;
  const debouncedValue = useDebouncedCallback(
    (value) => onSearch(value),
    delay
  );
  const onSearchInputChange = (value) => debouncedValue(value);
  const {
    competitionSeasonIds,
    squadIds,
    priorityIds,
    statuses,
    collectionStatuses,
  } = filtersData;
  const validSearchValue =
    !isNil(searchValue) &&
    !isEmpty(searchValue) &&
    typeof searchValue !== 'string';
  const { data: { shiftMatches = [] } = [], loading: shiftMatchesLoading } =
    useQuery(SHIFT_MATCHES_QUERY, {
      variables: {
        date: validSearchValue ? undefined : activeDate,
        shiftId: validSearchValue ? undefined : shift?.id,
        squadIds,
        collectionStatuses,
        statuses,
        priorityIds,
        competitionSeasonIds,
        matchId: validSearchValue ? searchValue : undefined,
        lineId,
        hasAssignedPC: isVideo(userLineName),
        queryParams: {
          pageSize,
          page,
        },
        fetchPolicy: 'network-only',
      },
    });

  const { data: { matchesDetailsByLine = [] } = {} } = useQuery(
    MATCHES_DETAILS_BY_LINE_QUERY,
    {
      variables: {
        matchesIds: pluck('id', shiftMatches),
        lineId,
      },
      skip: isEmpty(shiftMatches),
      fetchPolicy: 'network-only',
    }
  );

  const [swapMatchMutation, { loading }] = useMutation(SWAP_MATCH_MUTATION, {
    onError: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: 'Changes can not be saved try again ',
        icon: 'error',
      });
    },
    onCompleted: () => {
      refetchShiftMatches();
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({ message: 'Match has been swapped successfully' });
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MATCHES_DETAILS_BY_LINE_QUERY,
        variables: {
          matchesIds: [shiftMatch?.id, activeShiftMatchToReplace?.matchId],
          lineId,
        },
      },
      {
        query: MATCH_OFFLINE_TASKS_QUERY,
        variables: { matchId: shiftMatch?.id },
      },
      {
        query: MATCH_OFFLINE_TASKS_QUERY,
        variables: { matchId: activeShiftMatchToReplace?.matchId },
      },
    ],
  });

  const setMatchesFiltersState = (filtersData) =>
    dashboardDispatch({
      type: dashboardActions.SET_ACTIVE_FILTERS_DATA,
      payload: { filtersData },
    });
  const swapMatch = () =>
    swapMatchMutation({
      variables: {
        input: {
          destMatch: {
            id: activeShiftMatchToReplace?.matchId,
            eta: activeShiftMatchToReplace?.eta,
            squadShiftId: activeShiftMatchToReplace?.squadShiftId,
          },
          srcMatch: {
            eta: shiftMatch?.eta,
            id: shiftMatch?.id,
            squadShiftId: shiftMatch?.squadShifts[0].squadShift.id,
          },
          squadId,
        },
      },
    });
  const getMatchedSquad = (id) =>
    find(propEq('matchId', id), matchesDetailsByLine);
  const setShift = (shift) =>
    dashboardDispatch({
      type: dashboardActions.SET_SHIFT,
      payload: { shift },
    });
  const onChangeDate = (activeDate) => {
    dashboardDispatch({
      type: dashboardActions.SET_DATE,
      payload: { activeDate },
    });
    setActiveShiftMatchToReplace(undefined);
    setPage(1);
  };
  const setOpenFilters = (openFilters) =>
    dashboardDispatch({
      type: dashboardActions.SET_OPEN_FILTERS,
      payload: { openFilters },
    });

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon
            customIcon="replaceMatch"
            size={20}
            data-testid="modalHeader-icon"
          />
          <div data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.swap.title" />
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '1000px', maxWidth: 'unset' }}
    >
      <Label
        variant="Label"
        labelText={formatMessage(
          { id: 'containers.dashboards.swap.squad' },
          { assignedSquadName }
        )}
        className={classNames(['pl-32', 'pt-14'])}
      />
      <div className="shift-match">
        <ShiftMatchCard shiftMatch={shiftMatch} />
      </div>
      <div className="flex mgl-22 gap-15 items-flex-start ">
        <DateWithShiftFilterBar
          activeDate={activeDate}
          onChangeDate={onChangeDate}
          shift={shift}
          setShift={setShift}
        />
        <SingleDatePicker onChange={onChangeDate} activeDate={activeDate} />

        <MatchesFilters
          setMatchesFiltersState={setMatchesFiltersState}
          screenType="swap"
          filtersState={filtersState}
          filtersDispatch={filtersDispatch}
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
        />
        <div
          className={classNames(['flex', 'column'], {
            'error-search-box': invalidInput,
          })}
        >
          <SearchBox
            onChange={onSearchInputChange}
            value={searchValue}
            placeholder="Search by Match ID"
          />
          <RenderIf condition={invalidInput}>
            <span className="error-text">Please Search by valid match ID</span>
          </RenderIf>
        </div>
      </div>

      <div className={classNames(['replace-pagination'])}>
        <Pagination
          totalCount={page * pageSize + shiftMatches.length - 1 || pageSize}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          screenTitle="Matches"
          pageCountOnly
        />

        {shiftMatchesLoading ? (
          <Spinner />
        ) : (
          reject((match) => match.id === shiftMatch?.id, shiftMatches)?.map(
            (shiftMatch, index) => {
              const matchSquad = getMatchedSquad(shiftMatch?.id);
              return (
                <div
                  className="shift-match"
                  data-testid="dashboard-swap-matchRow"
                  key={shiftMatch.id}
                >
                  <ShiftMatchCard
                    shiftMatch={shiftMatch}
                    index={index}
                    onClick={() =>
                      setActiveShiftMatchToReplace({
                        matchId: shiftMatch.id,
                        squadShiftId: shiftMatch?.squadShifts[0].squadShift.id,
                        eta: shiftMatch?.eta,
                      })
                    }
                    key={shiftMatch.id}
                    className={classNames({
                      'active-shift-match':
                        activeShiftMatchToReplace?.matchId === shiftMatch?.id,
                    })}
                    squad={matchSquad?.squadShifts[0].squadShift.squad}
                  />
                </div>
              );
            }
          )
        )}

        {!shiftMatchesLoading && isEmpty(shiftMatches) && page !== 1 && (
          <div className="pagination-limitation-container">
            <Text variant="body1">
              <FormattedMessage id="containers.all.pagination.end.warning" />
            </Text>
            <Button minimal intent="Primary" onClick={() => setPage(1)}>
              Start Again ?
            </Button>
          </div>
        )}
      </div>
      <div className="bp3-dialog-footer-actions" data-testid="swapMatch-footer">
        <Button
          outlined
          text="Cancel"
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={<FormattedMessage id="containers.dashboards.swap.title" />}
          onClick={swapMatch}
          loading={loading}
          disabled={!activeShiftMatchToReplace}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default SwapMatch;
