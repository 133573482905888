import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const Scroll = ({ renderedItemsLength, onScroll, hasMore, children, name }) => (
  <div
    id={`scrollableDiv-${name}`}
    style={{ height: 300, overflow: 'auto' }}
    className="scrollableDiv"
  >
    <InfiniteScroll
      dataLength={renderedItemsLength}
      next={onScroll}
      hasMore={hasMore}
      scrollableTarget={`scrollableDiv-${name}`}
      endMessage={<></>}
    >
      {children}
    </InfiniteScroll>
  </div>
);

export default Scroll;
