import { useQuery } from '@apollo/client';
import { find, pipe, prop, propEq, sortBy } from 'ramda';
import React from 'react';
import { useIntl } from 'react-intl';
import { Divider, Dropdown, Text } from 'components';
import { PRIORITY_CATEGORY_VALUES_QUERY } from 'gql';
import { optionsFromArr, optionsFromArrSla } from 'utils/helpers';

const PriorityDetailsSection = ({
  screenType,
  setFormikField,
  priorityCategory,
  priorityCategoryName,
  priority,
  errorSla,
  errorCategory,
}) => {
  const { loading: PrioritiesLoading, data: priorityCategoryValues } = useQuery(
    PRIORITY_CATEGORY_VALUES_QUERY,
    {
      variables: {
        type: `${screenType}`,
      },
    }
  );
  const { formatMessage } = useIntl();

  const priorities =
    priorityCategory &&
    pipe(
      find(propEq('id', priorityCategory)),
      prop('priorities'),
      sortBy(prop('slaOffset'))
    )(priorityCategoryValues?.priorityCategories);

  return (
    <>
      <Divider className="mgt-15 mgb-15" />

      <Text variant="body-one">
        {formatMessage({ id: 'containers.priorities.priorityDetails' })}
      </Text>
      <Dropdown
        items={optionsFromArr(priorityCategoryValues?.priorityCategories)}
        value={priorityCategory}
        onChange={(data) => {
          setFormikField('priorityCategory', data.value);
          setFormikField('priorityCategoryName', data.label);
        }}
        buttonText={formatMessage({ id: 'containers.priorities.priority' })}
        loading={PrioritiesLoading}
        error={errorCategory}
        data-testid="edit-priority-category"
      />
      <Dropdown
        items={optionsFromArrSla(
          priorities || [],
          priorityCategoryName.charAt(0)
        )}
        value={priority}
        onChange={(data) => setFormikField('priority', data.value)}
        buttonText={formatMessage({ id: 'containers.priorities.sla' })}
        loading={PrioritiesLoading}
        error={errorSla}
        data-testid="edit-priority-sla"
      />
    </>
  );
};

export default PriorityDetailsSection;
