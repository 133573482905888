import { gql } from '@apollo/client';
import { PC_FRAGMENT } from 'gql';

export default gql`
  mutation UpdatePC($input: updatePcInput) {
    updatePC(input: $input) {
      ...PcFragment
    }
  }
  ${PC_FRAGMENT}
`;
