import { useQuery } from '@apollo/client';
import { TEAMS_QUERY } from 'gql';
import { isNil, includes, isEmpty } from 'ramda';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { optionsFromArr, toggleListItem } from 'utils/helpers';
import { Dropdown, Fab } from 'components';

export default function TeamsAssignDropDown({
  isFemale,
  area,
  setFormikField,
  teamIds,
  setErrPreAssigned,
  errPreAssigned,
  setValidateCountryAndType,
}) {
  const [searchTeam, setSearchTeam] = useState();
  const intl = useIntl();
  const [teamsPage, setTeamsPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: teamsData = [],
    loading: teamsLoading,
    fetchMore,
  } = useQuery(TEAMS_QUERY, {
    variables: {
      isFemale,
      areaIds: [area],
      name: searchTeam,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
    skip: !area || isNil(isFemale),
  });
  const loadMoreData = () => {
    setTeamsPage((prev) => prev + 1);
    fetchMore({
      variables: {
        queryParams: {
          page: teamsPage,
          pageSize: 10,
        },
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.teams)) {
          setHasMore(false);
          return prev;
        }
        return { ...prev, teams: [...prev.teams, ...fetchMoreResult.teams] };
      },
    });
  };

  const buttonAction = () => {
    if (!area || isNil(isFemale)) {
      setValidateCountryAndType(true);
    }
  };
  return (
    <>
      <Dropdown
        variant="multiselect_obj_list"
        values={teamIds || []}
        items={optionsFromArr(teamsData && teamsData.teams)}
        onChange={(data) => {
          setFormikField('teamIds', [...data]);
        }}
        multiSelect
        buttonText={intl.formatMessage({ id: 'containers.priorities.teams' })}
        loading={teamsLoading}
        filterable
        searchPath={['label']}
        popOverPosition="bottom-left"
        onSearch={setSearchTeam}
        fetchMore={loadMoreData}
        scrollLoading={teamsLoading}
        buttonAction={buttonAction}
        disabled={!area || isNil(isFemale)}
        hasMore={hasMore}
        data-testid="assign-priority-teams"
      />
      {!isEmpty(teamIds) && (
        <div className="add-teams-fab-container">
          <p>{intl.formatMessage({ id: 'containers.priorities.teams' })}</p>
          <div className="add-teams-fab-content">
            {teamIds.map((team) => (
              <Fab
                text={team.label || ''}
                icon="cross"
                iconSize={12}
                onClick={() => {
                  setFormikField('teamIds', toggleListItem(teamIds, team));
                  if (errPreAssigned && includes(team, errPreAssigned)) {
                    setErrPreAssigned(toggleListItem(errPreAssigned, team));
                  }
                }}
                key={team.value}
                data-testid="teams-info"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
