import { gql } from '@apollo/client';

export default gql`
  mutation UpdateTeamPriority($input: updateTeamInput!) {
    updateTeamPriority(input: $input) {
      priority {
        teams {
          id
        }
      }
    }
  }
`;
