import { useQuery } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';
import { Dropdown, Icon, TextInput } from 'components';
import { FEATURES_QUERY } from 'gql';
import { filter, propEq } from 'ramda';
import { useIntl } from 'react-intl';
import { FEATURE_TYPES } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';

const IPTVDetails = ({ IPTVDetails, setFormikField, touched, errors }) => {
  const { formatMessage } = useIntl();
  const { loading: featuresLoading, data: featuresData } =
    useQuery(FEATURES_QUERY);

  const iptvDeviceData =
    featuresData?.features &&
    filter(propEq('type', FEATURE_TYPES.IPTV_DEVICE), featuresData?.features);

  return (
    <div className={classNames(['flex', 'gap-30', 'items-flex-start'])}>
      <div className={classNames(['flex', 'column', 'gap-20'])}>
        <TextInput
          required
          type="text"
          labelText={formatMessage({
            id: 'containers.providers.form.iptvName',
          })}
          placeholder={formatMessage({
            id: 'containers.providers.form.iptvName',
          })}
          value={IPTVDetails.name}
          onChange={(name) => setFormikField('IPTVDetails.name', name)}
          error={touched?.name && errors?.name}
          className={classNames({
            'selected-input-highlight': IPTVDetails.name,
          })}
          data-testid="providers-form-iptvName"
          name="iptvName"
        />
        <Dropdown
          items={optionsFromArr(iptvDeviceData)}
          value={IPTVDetails.featureId}
          onChange={({ value }) =>
            setFormikField('IPTVDetails.featureId', value)
          }
          error={touched?.featureId && errors?.featureId}
          buttonText={formatMessage({
            id: 'containers.pcAssignment.iptvDevice',
          })}
          loading={featuresLoading}
          className={classNames({
            'selected-dropdown-highlight': !IPTVDetails.featureId,
          })}
          data-testid="pc-form-featureId"
          name="featureId"
        />
      </div>
      <Checkbox
        large
        checked={IPTVDetails?.videoOnDemand}
        onChange={() => {
          setFormikField(
            `IPTVDetails.videoOnDemand`,
            !IPTVDetails?.videoOnDemand
          );
        }}
      >
        <div className="flex center gap-5">
          <div>VOD</div>
          <Icon icon="video" size={20} color="#0044cc" />
        </div>
      </Checkbox>
    </div>
  );
};

export default IPTVDetails;
