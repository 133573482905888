import React from 'react';
import classNames from 'classnames';
import { Label, Text } from 'components';

export default function CardHeader({
  competationId,
  competitionName,
  seasonName,
  countryCode,
  countryName,
}) {
  return (
    <div className="card-header gap-10">
      <Label
        className="mg-0 p-0"
        inline
        labelHeader="ID: "
        labelText={competationId}
        variant="Baisc"
      />
      <div className="flex column">
        <Text
          variant="body1"
          className={classNames(['ellipsis', 'comp-name'])}
          title={competitionName}
        >
          {competitionName}
        </Text>
        <Text variant="body1">{seasonName}</Text>
      </div>

      <div className="flex gap-10 center ">
        <span
          className={classNames([
            'fflag',
            'ff-lg ',
            'ff-round',
            `fflag-${countryCode}`,
          ])}
        />
        <Text
          variant="body2"
          className={classNames(['ellipsis', 'country-name'])}
          title={countryName}
        >
          {countryName}
        </Text>
      </div>
    </div>
  );
}
