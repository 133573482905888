/* eslint-disable no-console */

import React from 'react';
import Dropdown from 'components/Dropdown';
import { MATCH_STATUS_FILTER, STATUS, VIDEO_STATUS_OPTIONS } from 'utils/enums';
import { optionsFromArr, pluckValue, isVideo } from 'utils/helpers';
import { useProfile } from 'providers/profileProvider';
import { isNil } from 'ramda';

const MatchStatusFilter = ({ status: matchStatus, setPropState }) => {
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { name: userLineName } = line || {};
  const statusesArr = isVideo(userLineName)
    ? [...pluckValue(VIDEO_STATUS_OPTIONS), ...STATUS]
    : MATCH_STATUS_FILTER;
  const items = optionsFromArr(statusesArr);
  return (
    <Dropdown
      multiSelect
      values={matchStatus || []}
      items={items}
      onChange={(matchStatus) => setPropState({ matchStatus })}
      buttonText="Status"
      loading={isNil(items)}
      disabled={isNil(items)}
      disableDropdownButton={isNil(items)}
      className="match-assignment-filters"
      name="status"
    />
  );
};

export default MatchStatusFilter;
