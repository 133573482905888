import React from 'react';
import { length } from 'ramda';
import { useDrop, useDrag } from 'react-dnd';
import { Label } from 'components';

const DraggableInfoCard = ({
  data: { type, id, rank, value, priorities, totalCount },
  index,
  moveCard,
  findCard,
}) => {
  const usedType = type === 'TEAM' ? 'teams' : 'competitionSeasons';
  const label = type === 'TEAM' ? 'Team' : 'Competition';

  const originalIndex = findCard(id)?.index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'priorityInfoCard',
      item: { id, rank, originalIndex },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) moveCard(droppedId, originalIndex);
      },
    }),
    [id, originalIndex, moveCard]
  );

  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop(
    () => ({
      accept: 'priorityInfoCard',
      hover({ id: draggedId }) {
        const overId = id;
        if (draggedId !== overId) {
          const { index: overIndex } = findCard(overId);
          moveCard({ draggedId, overIndex });
        }
      },
    }),
    [findCard, moveCard]
  );
  const cardStyle = {
    border: isDragging ? '0.5px solid #0044CC' : '0.5px solid #CCCCCC',
    background: isDragging ? '#F5F9FF' : '#FCFCFC',
  };
  return (
    <div className="card-rank-wrapper" data-testid="settings-arrangeRanks-row">
      <div className="list-number">{index + 1}</div>
      <div
        className="card_rank"
        ref={(node) => drag(drop(node))}
        style={cardStyle}
      >
        <div className="move-handle" />
        <Label
          variant="ColoredLabel"
          labelText={value}
          className="cell"
          index={index}
          screenType={usedType}
        />
        <div className="cell">{`Rank ${rank}`}</div>
        <div className="cell">
          <span className="colored-sla">{length(priorities) || 0}</span>
          &nbsp;
          <span>SLA</span>
        </div>
        <div className="cell">
          {totalCount?.[usedType] || 0}
          &nbsp;
          {label}
        </div>
      </div>
    </div>
  );
};

export default DraggableInfoCard;
