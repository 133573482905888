import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Button } from 'components';

const SearchBox = ({ onChange, value, className, ...props }) => {
  const searchInput = useRef(null);
  const [focused, setFocused] = useState(false);
  const { type, min, disabled, placeholder } = props;

  const onFocus = () => {
    if (document.activeElement === searchInput.current) {
      setFocused(true);
    }
  };

  const onBlur = () => {
    if (document.activeElement !== searchInput.current) {
      setFocused(false);
    }
  };
  return (
    <div
      className={classNames('search-input-container', className)}
      data-testid="main-searchBox-container"
    >
      <Icon
        icon="search"
        color={focused ? '#0044CC' : '#999999'}
        size={14}
        className={classNames({
          'search-icon focused': focused && !value,
        })}
      />
      <input
        placeholder={placeholder || 'Search...'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        ref={searchInput}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        min={min}
        disabled={disabled}
        data-testid="main-searchBox-input"
      />
      {value && (
        <Button
          icon={
            <Icon
              icon="cross"
              color={focused ? '#0044CC' : '#999999'}
              size={14}
            />
          }
          iconOnly
          minimal
          size={14}
          onClick={() => onChange('')}
          className="cross-button"
        />
      )}
    </div>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

SearchBox.defaultProps = {
  onChange: () => {},
  value: '',
};
