import { gql } from '@apollo/client';

export default gql`
  fragment ChannelFregment on ChannelDetails {
    id
    name
    satellite {
      id
      name
      type
    }
    type
    feature {
      id
      name
      type
    }
  }
`;
