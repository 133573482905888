import { gql } from '@apollo/client';

export default gql`
  fragment StreamingFregment on StreamingDetails {
    videoOnDemand
    vpn
    noOfConcurrency
    noOfAccounts
    name
    feature {
      name
      id
      type
    }
    downloadable
  }
`;
