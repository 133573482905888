/* eslint-disable react/forbid-prop-types */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Divider, Dropdown, Label, VodIcon } from 'components';
import {
  acronym,
  calcSlaLongFormat,
  formatKO,
  optionsFromArr,
  title,
  formatEtaLocal,
  formatEta,
  sortProviders,
} from 'utils/helpers';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Classes, Position } from '@blueprintjs/core';
import { path, head } from 'ramda';

const MatchAcquisitionCard = ({
  className,
  testid,
  isAssigned,
  match,
  acquisitionTypes,
  onAcquisitionTypeChange,
  index,
  acquisitionType,
  loading,
}) => {
  const {
    id,
    homeTeam,
    awayTeam,
    area,
    priority,
    competitionSeason,
    eta,
    kickOff,
    date,
    recommendedVideoETA,
    availableTime,
    videoETA,
  } = match;
  const providers = competitionSeason.providers || [];
  const providersList = sortProviders(providers);
  const firstProviderType =
    path(['provider', 'type'], head(providersList)) || 'N/A';

  const ProvidersListTooltipContent = providersList.length ? (
    <div>
      {providersList.map(({ order, provider }) => (
        <div key={provider.id}>
          <>
            <b>{`P${order}: `}</b>
            <span>{title(provider.type)}</span>
          </>
        </div>
      ))}
    </div>
  ) : null;

  const providersListWithVod = providersList.filter(
    ({ provider }) =>
      (provider.streaming && provider.streaming.videoOnDemand) ||
      (provider.iptv && provider.iptv.videoOnDemand)
  );

  const renderProp = (prop, renderFn = (i) => i) => {
    if (!prop) return 'N/A';
    if (Array.isArray(prop)) {
      const allTrue = prop.every((i) => i);

      if (allTrue && prop.length) {
        return renderFn(...prop);
      }

      return 'N/A';
    }

    return renderFn(prop);
  };

  return (
    <Grid
      fluid
      className={classNames([
        'width-100',
        'match-row-container',
        'p-0',
        'mg-0',
        className,
        testid,
        {
          unassigned: !isAssigned,
        },
      ])}
    >
      <Row className={classNames(['p-5'])}>
        <Col>
          <Label
            variant="ColoredLabel"
            labelText={`${acronym(priority.value)}:${priority.slaOffset}`}
            index={index}
            screenType="teams"
            className="mg-0 p-0"
          />
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="Match ID:&nbsp;"
            labelText={renderProp(id)}
            className="mg-0 p-0"
          />
        </Col>
        <Col>
          <span>{renderProp(competitionSeason?.competitionName)}</span>
          &nbsp;
          <span>{renderProp(competitionSeason?.seasonName)}</span>
        </Col>

        <Col>
          <Label
            variant="Baisc"
            labelHeader="Country: &nbsp;"
            labelText={
              <>
                <span
                  className={classNames([
                    'fflag',
                    'ff-lg ',
                    'ff-round',
                    `fflag-${renderProp(area?.code)}`,
                  ])}
                />
                &nbsp;
                <span>{renderProp(area?.name)}</span>
              </>
            }
            inline
            className="mg-0 p-0"
          />
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="KO: &nbsp;"
            labelText={renderProp([date, kickOff], formatKO)}
          />
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="SLA: &nbsp;"
            labelText={renderProp(
              [date, priority.slaOffset, kickOff],
              calcSlaLongFormat
            )}
          />
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="Offline ETA: &nbsp;"
            labelText={renderProp(eta, formatEta)}
          />
        </Col>
      </Row>
      <Divider className="width-100" />
      <Row className={classNames(['p-5'])}>
        <Col className="team-competition-content p-5">
          <Tooltip2
            content={`${homeTeam?.name} vs. ${awayTeam?.name}`}
            position={Position.TOP_LEFT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal
          >
            <div>
              <span className="styled-team-name">
                {renderProp(homeTeam?.name)}
              </span>
              <span className="vs-styled">&nbsp;vs.&nbsp;</span>
              <span className="styled-team-name">
                {renderProp(awayTeam?.name)}
              </span>
            </div>
          </Tooltip2>
        </Col>

        <Col>
          <Tooltip2
            content={ProvidersListTooltipContent}
            position={Position.TOP}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal
          >
            <Label
              variant="Baisc"
              inline
              labelHeader="Provider Type: &nbsp;"
              labelText={renderProp(firstProviderType, title)}
            />
          </Tooltip2>
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="Video ETA: &nbsp;"
            labelText={
              isAssigned
                ? renderProp(videoETA, formatEtaLocal)
                : renderProp(recommendedVideoETA, formatEtaLocal)
            }
          />
        </Col>
        <Col>
          <Label
            variant="Baisc"
            inline
            labelHeader="Video Available Time: &nbsp;"
            labelText={renderProp(availableTime)}
          />
        </Col>
        <Col>
          <VodIcon providersListWithVod={providersListWithVod} />
        </Col>
        <Col className="p-5">
          <Dropdown
            popOverPosition="bottom-left"
            items={optionsFromArr(acquisitionTypes)}
            value={renderProp(acquisitionType)}
            onChange={({ value }) => onAcquisitionTypeChange({ value, id })}
            loading={loading}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default MatchAcquisitionCard;

MatchAcquisitionCard.propTypes = {
  isAssigned: PropTypes.bool,
  match: PropTypes.object.isRequired,
  acquisitionTypes: PropTypes.array.isRequired,
  onAcquisitionTypeChange: PropTypes.func.isRequired,
};

MatchAcquisitionCard.defaultProps = {
  isAssigned: false,
};
