import React from 'react';
import { Dropdown } from 'components';
import { optionsFromArr } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { isNil, map, flatten, defaultTo, pipe, prop } from 'ramda';
import { useQuery } from '@apollo/client';
import { SHIFT_MEMBERS_QUERY } from 'gql';

const AssignedCoordinatorFilter = ({ values = [], onChange, variables }) => {
  const { loading: membersLoading, data: shiftMembersData = {} } = useQuery(
    SHIFT_MEMBERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables,
    }
  );
  const { shiftMembers = [] } = shiftMembersData;
  const { formatMessage } = useIntl();
  const mapper = ({ squadMembers }) => map(prop('user'), squadMembers);
  const flatter = (arr) => flatten(defaultTo([], arr));
  const users = pipe(map(mapper), flatter, optionsFromArr)(shiftMembers);
  const disabled = isNil(shiftMembers);
  const buttonText = formatMessage({
    id: 'containers.dashboard.assignedCoordinators',
  });
  return (
    <Dropdown
      items={users}
      values={values}
      onChange={onChange}
      buttonText={buttonText}
      loading={membersLoading}
      disabled={disabled}
      disableDropdownButton={disabled}
      name={buttonText}
      variant="users"
      multiSelect
      filterable
    />
  );
};

export default AssignedCoordinatorFilter;
