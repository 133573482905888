import React from 'react';
import InfoIssue from './InfoIssue';
import VideoIssue from './VideoIssue';
import QualityIssue from './QualityIssue';
import ConnectionAndMissingMinutes from './ConnectionAndMissingMinutes';

const mapIssueVariantToComponent = ({
  variant,
  setFieldValue,
  values,
  shiftMatch,
  errors,
  touched,
  resetErrors,
  resetTouched,
  isEdit,
}) => {
  const {
    homeTeam: { name: homeTeam = '' },
    awayTeam: { name: awayTeam = '' },
  } = shiftMatch;
  const components = {
    INFO_ISSUE: (
      <InfoIssue
        setFieldValue={setFieldValue}
        missingPlayer={values.missingPlayer}
        type={values.type}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        errors={errors}
        touched={touched}
        resetErrors={resetErrors}
        resetTouched={resetTouched}
        isEdit={isEdit}
      />
    ),
    VIDEO_ISSUE: (
      <VideoIssue
        setFieldValue={setFieldValue}
        videoIssue={values.videoIssue}
        errors={errors?.videoIssue}
        touched={touched?.videoIssue}
      />
    ),
    QUALITY_ISSUE: (
      <QualityIssue
        setFieldValue={setFieldValue}
        type={values.type}
        badShirt={values.badShirt}
        badVideo={values.badVideo}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        errors={errors}
        touched={touched}
        resetErrors={resetErrors}
        resetTouched={resetTouched}
        isEdit={isEdit}
      />
    ),
    CONNECTION_ISSUE: (
      <ConnectionAndMissingMinutes
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        details={values.details}
      />
    ),
    MISSING_MINUTES: (
      <ConnectionAndMissingMinutes
        errors={errors}
        touched={touched}
        details={values.details}
        setFieldValue={setFieldValue}
      />
    ),
  };
  return components[variant] || false;
};
export default mapIssueVariantToComponent;
