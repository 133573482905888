import { Dialog } from '@blueprintjs/core';
import React from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';
import { COMPETITION_SEASON, TEAM } from 'utils/enums';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { DELETE_PRIORITY_MUTATION, PRIORITY_CATEGORY_VALUES_QUERY } from 'gql';
import { Button, Icon } from 'components';

export default function DeleteSla() {
  const { modalDispatch, modalState } = useModal();
  const { id, type } = prop('payload', modalState);
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const modalType = type === 'teams' ? TEAM : COMPETITION_SEASON;

  const [deleteSlaMutation, { loading }] = useMutation(
    DELETE_PRIORITY_MUTATION,
    {
      onError: () =>
        showToast({
          message: formatMessage({
            id: 'containers.priorities.settings.deleteSla.error',
          }),
          icon: 'error',
        }),
      refetchQueries: [
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: modalType },
        },
      ],
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.priorities.settings.deleteSla.success',
          }),
        });
      },
    }
  );
  const initialValues = {
    id: id || '',
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      deleteSlaMutation({
        variables: {
          deletePriorityId: values.id,
        },
      });
    },
  });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon customIcon="delete" size={20} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({
              id: 'containers.priorities.settings.title.deleteSla',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
    >
      <div className="bp3-dialog-body delete-body-container">
        <p data-testid="settings-deleteSla-description">
          {formatMessage({
            id: 'containers.priorities.settings.description.deleteSla',
          })}
        </p>
      </div>
      <div className="bp3-dialog-footer" data-testid="deleteSla-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={formatMessage({ id: 'containers.all.btn.cancel' })}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={formatMessage({ id: 'containers.all.delete' })}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
}
