import React, { useMemo } from 'react';
import { find, propEq, toLower } from 'ramda';
import { camelize, title } from 'utils/helpers';
import BasicLabel from './BasicLabel';
import BorderLabel from './BorderLabel';
import AvatarLabel from './AvatarLabel';
import ColoredLabel from './ColoredLabel';
import SimpleLabel from './SimpleLabel';
import ShiftLabel from './ShiftLabel';
import MatchStatus from './MatchStatus';
import CopyableLabel from './CopyableLabel';

const Label = ({
  labelHeader,
  labelText,
  inline,
  variant,
  labelsList,
  className,
  index,
  screenType = 'teams',
  background,
  status = '',
  avatarText,
  collectionStatus = '',
  size = 16,
  ...rest
}) => {
  // label color is calculated with index so its removed from memo dependancy array
  // TODO: color shouldn't be index based
  const CL = useMemo(
    () => (
      <ColoredLabel
        labelText={labelText}
        className={className}
        index={index}
        screenType={screenType}
      />
    ),
    [screenType, labelText, className]
  );

  const LabelVariants = [
    {
      variant: 'Baisc',
      comp: (
        <BasicLabel
          labelHeader={labelHeader}
          labelText={labelText}
          inline={inline}
          className={className}
          {...rest}
        />
      ),
    },
    {
      variant: 'Copyable',
      comp: (
        <CopyableLabel
          labelHeader={labelHeader}
          labelText={labelText}
          inline={inline}
          className={className}
          {...rest}
        />
      ),
    },
    {
      variant: 'BorderLabel',
      comp: (
        <BorderLabel
          labelsList={labelsList}
          inline={inline}
          className={className}
          {...rest}
        />
      ),
    },
    {
      variant: 'AvatarLabel',
      comp: (
        <AvatarLabel
          labelText={labelText}
          className={className}
          avatarText={avatarText}
          size={size}
          {...rest}
        />
      ),
    },
    {
      variant: 'ColoredLabel',
      comp: CL,
    },
    {
      variant: 'Label',
      comp: (
        <SimpleLabel
          labelText={labelText}
          className={className}
          background={background}
          {...rest}
        />
      ),
    },
    {
      variant: 'ShiftLabel',
      comp: (
        <ShiftLabel labelText={labelText} className={className} {...rest} />
      ),
    },
    {
      variant: 'MatchStatusLabel',
      comp: (
        <MatchStatus
          status={toLower(status)}
          className={className}
          collectionStatus={camelize(title(toLower(collectionStatus)))}
          {...rest}
        />
      ),
    },
  ];

  return <>{find(propEq('variant', variant), LabelVariants)?.comp}</>;
};

export default Label;
