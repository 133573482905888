import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button, Icon, Label } from 'components';
import { SQUAD_SHIFTS_QUERY, UNASSIGN_SQUAD_SHIFTS_MUTATION } from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { title } from 'utils/helpers';

const UnassignShiftModal = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { squadId, dates, shift, squadName } = modalState?.payload;
  const onClose = () => modalDispatch({ type: 'ClOSE_ALL' });

  const [unassignSquadShift, { loading }] = useMutation(
    UNASSIGN_SQUAD_SHIFTS_MUTATION,
    {
      onError: ({ message }) => {
        showToast({
          message,
          icon: 'error',
        });
        onClose();
      },
      onCompleted: () => {
        showToast({
          message: formatMessage({
            id: 'containers.shiftSchedule.unassignShift.success',
          }),
        });
        onClose();
      },
      refetchQueries: [
        {
          query: SQUAD_SHIFTS_QUERY,
          variables: { dates, squadId },
        },
      ],
    }
  );
  const unAssign = () => {
    return unassignSquadShift({
      variables: {
        input: {
          dates,
          squadId,
        },
      },
    });
  };
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon icon="ban-circle" data-testid="modalHeader-icon" />
          <span data-testid="modalHeader-title">
            <FormattedMessage id="containers.shiftScheule.unassign.title" />
          </span>
        </div>
      }
      onClose={onClose}
      canOutsideClickClose={false}
      width="541px"
    >
      <div className={classNames(['pl-32', 'pt-14'])}>
        <span
          className="modal-text "
          data-testid="shiftscheule-unassign-description"
        >
          <FormattedMessage id="containers.shiftSchedule.unassign.description" />
        </span>
        <div
          className={classNames(['flex', 'gap-15', 'items-center', 'mgt-15'])}
        >
          <Label
            background
            variant="Label"
            labelText={title(squadName)}
            data-testid="shiftscheule-unassign-squadAssigned"
          />
          <Label
            labelText={shift}
            variant="ShiftLabel"
            className="shift-label-height"
          />
        </div>
      </div>

      <div
        className="bp3-dialog-footer-actions"
        data-testid="unassignMatch-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({
            id: 'containers.shiftSchedule.unassign.title',
          })}
          onClick={unAssign}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default UnassignShiftModal;
