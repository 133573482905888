import React, { useCallback, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { path, prop, defaultTo, filter } from 'ramda';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  TextAreaLimited,
  Button,
  Dropdown,
  Label,
  ShiftMatchCard,
  Icon,
  Radio,
  Divider,
} from 'components';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  MATCH_ISSUES_QUERY,
  BLOCK_MATCH_MUTATION,
  SHIFT_MATCHES_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { isVideo, title } from 'utils/helpers';
import { BLOCK_MATCH } from 'validations';
import * as Yup from 'yup';
import { RenderIf } from 'config';
import { useIntl, FormattedMessage } from 'react-intl';
import { useProfile } from 'providers/profileProvider';
import mapIssueVariantToComponent from '../ReportFlow/partials';
import {
  blockIssueInitialValues,
  getIssuesDropDownOptions,
  getReportValidationSchema,
  offlineDetailsFormSubmit,
  videoDetailsFormSubmit,
} from '../helpers';

const BlockMatch = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [reportedIssuesExpanded, setReportedIssuesExpanded] = useState(true);

  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId, name: userLineName } = userLine ?? {};
  const isVideoUser = isVideo(userLineName);

  const { shiftMatch, assignedSquadName, activeDate, shiftId } = path(
    ['payload'],
    modalState
  );
  const matchId = prop('id', shiftMatch);

  const { data: matchIssuesData, loading: matchIssuesLoading } = useQuery(
    MATCH_ISSUES_QUERY,
    {
      variables: { matchId, lineId },
      fetchPolicy: 'network-only',
    }
  );
  const closeModal = useCallback(
    () =>
      modalDispatch({
        type: 'ClOSE_ALL',
      }),
    []
  );
  const onError = useCallback(
    () =>
      showToast({
        message: formatMessage({ id: 'containers.all.error' }),
        icon: 'error',
      }),
    []
  );
  const onCompleted = useCallback(() => {
    showToast({
      message: formatMessage({
        id: 'containers.all.block.success',
      }),
    });
    closeModal();
  }, []);

  const [blockMatchMutation, { loading }] = useMutation(BLOCK_MATCH_MUTATION, {
    refetchQueries: [
      {
        query: SHIFT_MATCHES_QUERY,
        variables: { date: activeDate, lineId, shiftId },
      },
    ],
    awaitRefetchQueries: true,
    onError,
    onCompleted,
  });
  const reportValidationSchema = getReportValidationSchema(userLineName);

  const initialValues = blockIssueInitialValues[userLineName]({ matchId });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.lazy((v) =>
      v.issueId ? BLOCK_MATCH : reportValidationSchema
    ),
    onSubmit: (values) => {
      const { comment, matchId, issueId } = values;
      if (issueId) {
        blockMatchMutation({
          variables: {
            input: {
              matchId,
              issueId,
              lineId,
            },
          },
        });
      } else {
        blockMatchMutation({
          variables: {
            input: {
              lineId,
              matchId,
              comment,
              issueType: values.type || values.reason,
              details: isVideoUser
                ? videoDetailsFormSubmit(values)
                : offlineDetailsFormSubmit(values),
            },
          },
        });
      }
    },
  });
  const setFormikField = (field, value) => formik.setFieldValue(field, value);

  const nonResolvedIssues = filter((issue) => issue.status !== 'RESOLVED')(
    defaultTo([], prop('matchIssues', matchIssuesData))
  );

  const onChangeReason = ({ value }) => {
    formik.setFieldValue('reason', value);
    formik.setFieldValue('type', value);
  };

  const onChangeType = ({ value }) => {
    formik.setFieldValue('type', value);
    formik.setFieldValue('details', {});
  };

  const issuesDropDownOptions = getIssuesDropDownOptions({
    isVideoUser,
    formatMessage,
  });

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="modal-title">
          <Icon customIcon="blocked" size={16} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.blocking.title" />
          </div>
        </div>
      }
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
      className="report-issue"
    >
      <div className={classNames(['pl-32', 'pt-14', 'pr-32'])}>
        <Label
          variant="Label"
          labelText={formatMessage(
            { id: 'containers.dashboards.squadAssigned' },
            { assignedSquadName }
          )}
        />
        <ShiftMatchCard
          shiftMatch={shiftMatch}
          className={classNames(['mgt-13', 'mgb-13'])}
        />
        <RenderIf
          condition={matchIssuesLoading || nonResolvedIssues?.length > 0}
        >
          <Button
            minimal
            rightIcon={reportedIssuesExpanded ? 'chevron-up' : 'chevron-down'}
            onClick={() => setReportedIssuesExpanded(!reportedIssuesExpanded)}
            text="Reported Issues"
            className="pl-0"
            loading={matchIssuesLoading}
            data-testid="dashboard-reportedIssues-expand"
          />
        </RenderIf>
        <div className={classNames(['mgr-15'])}>
          {reportedIssuesExpanded &&
            nonResolvedIssues &&
            nonResolvedIssues.map((issue) => {
              const issueId = issue.id;
              return (
                <div
                  key={issueId}
                  className={classNames(['mgb-15'])}
                  data-testid="dashboard-block-issueRow"
                >
                  <Radio
                    minimal
                    name="issueId"
                    fieldId={`issueId-${issueId}`}
                    isSelected={formik.values.issueId === issueId}
                    value={formik.values.issueId}
                    error={formik.touched.issueId && formik.errors.issueId}
                    onRadioChange={() =>
                      formik.setFieldValue('issueId', issueId)
                    }
                    className="pl-0"
                  >
                    <Grid className="p-0 mg-0">
                      <Row className="p-0 mg-0 ">
                        <Col
                          lg={2}
                          className={classNames(['color-qs', 'fs-12'], {
                            'color-sb': formik?.values?.issueId === issueId,
                          })}
                        >
                          <FormattedMessage id="containers.all.reason" />
                        </Col>
                        <Col
                          lg={5}
                          className={classNames(['color-qs', 'fs-12'], {
                            'color-sb': formik?.values?.issueId === issueId,
                          })}
                        >
                          <FormattedMessage id="containers.all.comment" />
                        </Col>
                      </Row>
                      <Row className="p-0 mg-0 mgt-5">
                        <Col lg={2}>{title(issue.type)}</Col>
                        <Col lg={3}>{issue.comment}</Col>
                      </Row>
                    </Grid>
                  </Radio>
                </div>
              );
            })}
          <RenderIf
            condition={matchIssuesLoading || nonResolvedIssues?.length > 0}
          >
            <Divider />
          </RenderIf>
          <div className={classNames(['mgb-15'])}>
            <RenderIf condition={nonResolvedIssues?.length > 0}>
              <Radio
                minimal
                name="issueId"
                fieldId="issueId-custom"
                isSelected={formik.values.issueId == null}
                value={formik.values.issueId}
                error={formik.touched.issueId && formik.errors.issueId}
                onRadioChange={() => formik.setFieldValue('issueId', null)}
                className="pl-0"
              >
                <FormattedMessage id="containers.all.addNewReason" />
              </Radio>
            </RenderIf>

            <RenderIf condition={!isVideoUser}>
              <Dropdown
                value={formik.values.reason}
                onChange={onChangeReason}
                error={formik.touched.reason && formik.errors.reason}
                {...issuesDropDownOptions}
              />
            </RenderIf>
            <RenderIf condition={isVideoUser}>
              <Dropdown
                value={formik.values.type}
                onChange={onChangeType}
                error={formik.touched.type && formik.errors.type}
                {...issuesDropDownOptions}
              />
            </RenderIf>
            {mapIssueVariantToComponent({
              variant: isVideoUser ? formik.values.type : formik.values.reason,
              setFieldValue: setFormikField,
              values: formik.values,
              errors: formik.errors,
              touched: formik.touched,
              shiftMatch,
            })}

            <div>
              <TextAreaLimited
                onChange={(e) =>
                  formik.setFieldValue('comment', e.target.value)
                }
                value={formik.values.comment}
                placeholder={formatMessage({
                  id: 'containers.dashboard.blocking.explain',
                })}
                maxLength="160"
                showMaxLength
                className="mgt-15 width-40"
                error={formik.touched.comment && formik.errors.comment}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(['bp3-dialog-footer-actions', 'mgt-30'])}
        data-testid="block-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          disabled={loading}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.dashboards.blocking.title' })}
          onClick={formik.submitForm}
          loading={loading}
          disabled={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default BlockMatch;
