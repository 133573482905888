import { gql } from '@apollo/client';

export default gql`
  query competitionSeasons(
    $queryParams: QueryParams
    $name: String
    $areaIds: [Int]
  ) {
    competitionSeasons(
      queryParams: $queryParams
      name: $name
      areaIds: $areaIds
    ) {
      totalCount
      competitionSeasons {
        id
        competitionName
        seasonName
        live
        competitorAvailable
        area {
          id
          code
          name
        }
        providers {
          isDefault
          provider {
            id
            type
            ... on IPTV {
              iptv {
                name
              }
            }
            ... on Streaming {
              streaming {
                name
              }
            }
            ... on Channel {
              channel {
                name
                satellite {
                  id
                  name
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;
