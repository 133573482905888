import React, { useEffect } from 'react';
import { Card } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Button, Icon } from 'components';
import { anyPass, isEmpty, isNil, reject } from 'ramda';
import { filtersActions } from 'state/filtersState';
import { LINES_QUERY } from 'gql';
import { useQuery } from '@apollo/client';
import { useProfile } from 'providers/profileProvider';
import classNames from 'classnames';
import SportsFilter from './SportsFilter';
import LinesFilter from './LinesFilter';
import FunctionsFilter from './FunctionsFilter';
// Generic filters doesn't query they wither use an enum or get an array of options passed as props
export { SportsFilter, LinesFilter, FunctionsFilter };
const GenericFilters = ({
  filtersState,
  filtersDispatch,
  openFilters,
  setOpenFilters,
  resetPagination,
}) => {
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole, lineFunction: userLineFunction } =
    profile;
  const {
    role: { name: roleName, line: userLine },
  } = userJobTitleRole;

  const { data: { lines = [] } = {} } = useQuery(LINES_QUERY);

  const isSuperAdmin = roleName === 'super-admin';

  const initializeFilters = () => {
    const { sport, id: lineId } = userLine ?? {};
    const { id: lineFunctionId } = userLineFunction ?? {};
    const userData = {
      lineFunctionId,
      sport,
      lineId,
    };
    filtersDispatch({
      type: filtersActions.INITIALIZE_GENERIC,
      payload: { isSuperAdmin, userData },
    });
    resetPagination();
  };

  useEffect(() => initializeFilters(), []);
  const { lineFunctionId, lineId, sport } = filtersState?.selected || {};
  const ApplyButton = () => {
    return (
      <Button
        intent="Primary"
        text="Apply"
        onClick={() => {
          // There's an apply current that can also be used to avoid sending payload
          filtersDispatch({
            type: filtersActions.SET_APPLIED,
            payload: reject(anyPass([isEmpty, isNil]))(filtersState?.selected),
          });
          resetPagination();
        }}
        data-testid="main-filters-apply"
      />
    );
  };
  const onSportChange = (sport) =>
    filtersDispatch({
      type: filtersActions.SET_SELECTED,
      payload: { sport, lineFunctionId: undefined, lineId: undefined },
    });
  const onLineChange = (lineId) =>
    filtersDispatch({
      type: filtersActions.SET_SELECTED,
      payload: { lineId, lineFunctionId: undefined },
    });
  const onLineFunctionChanges = (lineFunctionId) =>
    filtersDispatch({
      type: filtersActions.SET_SELECTED,
      payload: { lineFunctionId },
    });
  return (
    <Popover2
      minimal
      interactionKind="click"
      isOpen={openFilters}
      placement="bottom-start"
      usePortal={false}
      enforceFocus={false}
      popoverClassName="filters-container"
      transitionDuration={100}
      content={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Card className="filters-card">
          <Button
            icon="cross"
            onClick={() => setOpenFilters(!openFilters)}
            className="close-filter"
          />
          <div className="filters-content">
            <SportsFilter onSportChange={onSportChange} sport={sport} />
            <LinesFilter
              sport={sport}
              lineId={lineId}
              onLineChange={onLineChange}
              lines={lines}
            />
            <FunctionsFilter
              sport={sport}
              lineId={lineId}
              lines={lines}
              lineFunctionId={lineFunctionId}
              onLineFunctionChanges={onLineFunctionChanges}
            />
          </div>
          <div className="filters-buttons">
            <Button text="Cancel" onClick={initializeFilters} />
            <ApplyButton />
          </div>
        </Card>
      }
    >
      <Button
        icon={<Icon icon="filter" />}
        onClick={() => setOpenFilters(!openFilters)}
        minimal
        outlined
        className={classNames(['filter-button'], {
          active: openFilters,
        })}
        data-testid="main-users-filters"
      />
    </Popover2>
  );
};

export default GenericFilters;
