import { gql } from '@apollo/client';

export default gql`
  mutation updateShift($input: UpdateShiftInput!) {
    updateShift(input: $input) {
      id
      shiftType
      startAt
      endAt
    }
  }
`;
