import { gql } from '@apollo/client';

export default gql`
  mutation CreatePC($input: createPcInput) {
    createPC(input: $input) {
      id
      number
      task
      location {
        id
        name
        HQ
      }
    }
  }
`;
