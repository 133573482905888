import { Classes, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon } from 'components';
import { RenderIf } from 'config';
import { isEmpty } from 'ramda';
import React from 'react';
import { streamingIconByType } from '../helpers/helpers';

const StreamingDetails = ({
  // name,
  vpn,
  downloadable,
  videoOnDemand,
  // noOfAccounts,
  // noOfConcurrency,
  feature,
}) => {
  const hasAppleTv = feature?.name === 'APPLETV';
  const vpnContent = vpn?.join('-') ?? '';
  return (
    <>
      <RenderIf condition={videoOnDemand}>
        <div>
          <Tooltip2
            content="VOD"
            position={Position.BOTTOM}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <Icon icon={streamingIconByType('vod')} size={20} color="#0044cc" />
          </Tooltip2>
        </div>
      </RenderIf>
      <RenderIf condition={!isEmpty(vpn)}>
        <div>
          <Tooltip2
            content={vpnContent}
            position={Position.BOTTOM}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <Icon icon={streamingIconByType('vpn')} size={20} color="#0044cc" />
          </Tooltip2>
        </div>
      </RenderIf>
      <RenderIf condition={downloadable}>
        <div>
          <Tooltip2
            content="Downloadable"
            position={Position.BOTTOM}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <Icon
              icon={streamingIconByType('downloadable')}
              size={20}
              color="#0044cc"
            />
          </Tooltip2>
        </div>
      </RenderIf>
      <RenderIf condition={hasAppleTv}>
        <div className="apple-tv" />
      </RenderIf>
    </>
  );
};

export default StreamingDetails;
