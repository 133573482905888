import { useQuery } from '@apollo/client';
import Dropdown from 'components/Dropdown';
import { COMPETITION_SEASONS_INFO_QUERY } from 'gql';
import { isEmpty, uniq } from 'ramda';
import React, { useState } from 'react';
import { optionsFromArrComp } from 'utils/helpers';
import { useIntl } from 'react-intl';

const CompetitionFilter = ({
  competitionSeason,
  onCompetitionSeasonChange,
  area,
  selectedCompetitionSeason,
  disabled,
  minSeasonYear,
}) => {
  const { formatMessage } = useIntl();
  const [compSearchWord, setCompSearchWord] = useState('');
  const pageSize = 10;
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: competitionSeasonsData = {},
    loading: competitionSeasonsLoading,
    fetchMore,
  } = useQuery(COMPETITION_SEASONS_INFO_QUERY, {
    skip: disabled,
    variables: {
      name: isEmpty(compSearchWord) ? undefined : compSearchWord,
      areaIds: area ? [area] : undefined,
      minSeasonYear,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
  });

  const loadMoreData = () => {
    setPage((prev) => prev + 1);
    fetchMore({
      variables: { minSeasonYear, queryParams: { page, pageSize } },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.competitionSeasons)) {
          setHasMore(false);
          return prev;
        }
        return {
          ...prev,
          competitionSeasonsInfo: [
            ...prev.competitionSeasonsInfo,
            ...fetchMoreResult.competitionSeasonsInfo,
          ],
        };
      },
    });
  };

  const items = selectedCompetitionSeason?.id
    ? uniq([
        ...optionsFromArrComp(
          competitionSeasonsData &&
            competitionSeasonsData.competitionSeasonsInfo
        ),
        ...optionsFromArrComp([selectedCompetitionSeason]),
      ])
    : optionsFromArrComp(
        competitionSeasonsData && competitionSeasonsData.competitionSeasonsInfo
      );
  const onSearch = (v) => {
    setCompSearchWord(v);
    if (v && isEmpty(compSearchWord)) {
      setPage(1);
      setHasMore(true);
    }
  };
  return (
    <Dropdown
      value={competitionSeason || ''}
      items={items}
      onChange={({ value }) => onCompetitionSeasonChange(value)}
      buttonText={formatMessage({
        id: 'containers.priorities.header.competition.league',
      })}
      loading={competitionSeasonsLoading}
      filterable
      searchPath={['label']}
      popOverPosition="bottom-right"
      onSearch={onSearch}
      fetchMore={loadMoreData}
      scrollLoading={competitionSeasonsLoading}
      hasMore={hasMore}
      data-testid="main-area-filter"
      clearable={!disabled}
      disabled={disabled}
      disableDropdownButton={disabled}
    />
  );
};

export default CompetitionFilter;
