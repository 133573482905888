import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@blueprintjs/core';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Label from 'components/Label';
import { useModal } from 'providers/modalProvider';
import { useProfile } from 'providers/profileProvider';
import React from 'react';

const SignOut = () => {
  const { modalState, modalDispatch } = useModal();
  const { profile: user } = useProfile();
  const { firstName, lastName } = user;
  const { logout } = useAuth0();
  const Logout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon customIcon="out" size={20} />
          <div>Sign Out</div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
    >
      <div className="sign-out-container">
        <span className="sign-out-confirmation-message">
          Are you sure you want to sign out ?
        </span>
        <Label
          variant="AvatarLabel"
          labelText={`${firstName} ${lastName}`}
          avatarText={`${firstName} ${lastName}`}
        />
      </div>
      <div className="bp3-dialog-footer-actions">
        <Button
          outlined
          text="Cancel"
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
        />
        <Button intent="Primary" text="Proceed" onClick={Logout} />
      </div>
    </Dialog>
  );
};

export default SignOut;
