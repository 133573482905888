import React from 'react';
import { isEmpty, uniq } from 'ramda';
import { Spinner } from '@blueprintjs/core';
import ItemTypes from 'containers/MatchAssignment/itemTypes';
import { Button } from 'components';
import { findByValue } from 'utils/helpers';
import MatchCard from '../MatchCard';
import { arrangeMatches } from '../../helpers';

const OfflineExpandableCard = ({
  pageSize,
  date,
  matchesSquads,
  matchesData,
  matchesLoading,
  fetchMore,
  page,
  setPage,
  disableNext,
}) => {
  const loadMoreData = () => {
    setPage(page + 1);
    fetchMore({
      variables: { queryParams: { page, pageSize } },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          matches: uniq([...prev?.matches, ...fetchMoreResult?.matches]),
        };
      },
    });
  };

  const renderMatches = () => {
    if (matchesLoading) return <Spinner size={25} />;
    const matches = arrangeMatches(matchesData?.matches);

    if (isEmpty(matches)) return <div className="mgb-17">No Matches</div>;
    return (
      <>
        {matches.map((match, index) => (
          <MatchCard
            key={`matchCard-${match.id}`}
            index={index}
            match={match}
            className="mgb-17"
            type={match?.squadShiftId ? '' : ItemTypes.MATCH}
            matchDate={date}
            squadName={
              findByValue('matchId', match?.id, matchesSquads)?.squadShifts[0]
                .squadShift?.squad?.name
            }
          />
        ))}
        {!(disableNext || matchesLoading) && !isEmpty(matches) && (
          <Button text="next" onClick={loadMoreData} minimal intent="Primary" />
        )}
      </>
    );
  };
  return <div className="flex gap-8 column">{renderMatches()}</div>;
};

export default OfflineExpandableCard;
