export const filtersInitialState = {
  teamIds: undefined,
  competitionSeasonIds: undefined,

  // Match status not collection or video status
  isAssigned: undefined,
  priorityIds: undefined,
  squadIds: undefined,
  matchStatus: undefined,

  // video
  videoIssueTypes: undefined,
  coordinatorIds: undefined,
  acquisitionTypes: undefined,
  videoStatuses: undefined,
  collectionStatuses: undefined,
  assignedCoordinators: undefined,
  locationIds: undefined,
};

export const genericFiltersInitialState = {
  selected: {
    lineFunctionId: undefined,
    lineId: undefined,
    sport: undefined,
  },
  applied: {
    lineFunctionId: undefined,
    lineId: undefined,
    sport: undefined,
  },
};

export const filtersActions = {
  SET_DATA: 'SET_DATA',
  RESET_FILTERS: 'RESET_FILTERS',
  INITIALIZE_GENERIC: 'INITIALIZE_GENERIC',
  SET_SELECTED: 'SET_SELECTED',
  SET_APPLIED: 'SET_APPLIED',
};

export const filtersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    // used in intialization to set both in one call
    case filtersActions.SET_DATA:
      return { ...state, ...payload };

    case filtersActions.INITIALIZE_GENERIC:
      return payload.userData && !payload.isSuperAdmin
        ? {
            ...genericFiltersInitialState,
            selected: { ...payload.userData },
            applied: { ...payload.userData },
          }
        : genericFiltersInitialState;

    case filtersActions.SET_SELECTED:
      return { ...state, selected: { ...payload } };

    case filtersActions.SET_APPLIED:
      return { ...state, applied: { ...payload } };

    case filtersActions.RESET_FILTERS:
      return filtersInitialState;

    default:
      return state;
  }
};
