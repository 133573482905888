import classNames from 'classnames';
import React from 'react';

const SimpleLabel = ({ labelText, className, background, ...rest }) => (
  <div
    className={classNames('simple-label', className, {
      'simple-label-with-background': background,
    })}
    {...rest}
  >
    <span>{labelText}</span>
  </div>
);

export default SimpleLabel;
