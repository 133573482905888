import { gql } from '@apollo/client';

export default gql`
  query SquadNames(
    $queryParams: QueryParams
    $searchWord: String
    $unassigned: Boolean
    $lineId: String
  ) {
    squadNames(
      searchWord: $searchWord
      queryParams: $queryParams
      unassigned: $unassigned
      lineId: $lineId
    ) {
      squads {
        id
        name
      }
    }
  }
`;
