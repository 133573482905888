import React, { useContext, createContext, useReducer } from 'react';
import { modalReducer, initialState } from '../components/Modal/modalReducer';
import Modal from '../components/Modal/index';

const ModalContext = createContext(null);
export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [modalState, modalDispatch] = useReducer(modalReducer, initialState);

  return (
    <ModalContext.Provider
      value={{
        modalDispatch,
        modalState,
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
