import React from 'react';
import { Button, Icon, Label } from 'components';
import { Dialog } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useFormik } from 'formik';
import { title } from 'utils/helpers';
import { pick, prop, toLower, toUpper, values } from 'ramda';
import {
  PRIORITY_QUERY,
  REMOVE_COMPETITION_SEASON_PRIORITY,
  REMOVE_TEAM_PRIORITY,
} from 'gql';
import { useIntl } from 'react-intl';

const PriorityRemoveModal = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { type: screenType, activePriorityData = {} } = prop(
    'payload',
    modalState
  );
  const { subId, activePriorityId, slaOffset, cat } = activePriorityData;
  const screenTypeId = screenType === 'team' ? `teamId` : `competitionSeasonId`;

  const refetchQueries = [
    {
      query: PRIORITY_QUERY,
      variables: {
        priorityId: activePriorityId,
      },
    },
  ];
  const onError = () => {
    showToast({
      message: formatMessage({ id: 'containers.all.error' }),
      icon: 'error',
    });
  };
  const onCompleted = () => {
    modalDispatch({ type: 'ClOSE_ALL' });
    showToast({
      message: formatMessage(
        { id: 'containers.priorities.removeTeam.success' },
        { screenType: title(screenType) }
      ),
    });
  };

  const [removeTeamPriority, { loading: removeTeamPriorityLoading }] =
    useMutation(REMOVE_TEAM_PRIORITY, {
      onError,
      refetchQueries,
      onCompleted,
    });
  const [removeCompetitionSeasonPriority, { loading: loadingComp }] =
    useMutation(REMOVE_COMPETITION_SEASON_PRIORITY, {
      onError,
      refetchQueries,
      onCompleted,
    });
  const removeTeam = (input) =>
    removeTeamPriority({
      variables: {
        ...input,
      },
    });
  const removeComp = (input) =>
    removeCompetitionSeasonPriority({
      variables: {
        ...input,
      },
    });
  const usedMutation = {
    team: removeTeam,
    competition: removeComp,
  };
  const initialValues = { subId };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const input = {
        [screenTypeId]: values.subId,
      };
      usedMutation[screenType](input);
    },
  });

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon customIcon="remove" size={20} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage(
              { id: 'containers.priorities.removeTeam.title' },
              { screenType: title(screenType) }
            )}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      style={{ width: '459px' }}
    >
      <div className="bp3-dialog-body priority-remove">
        <span data-testid="removePriority-description">
          {formatMessage(
            { id: 'containers.priorities.removeTeam.description' },
            { screenType: toLower(screenType) }
          )}
          <span className="color-sb">
            {toUpper(cat?.charAt(0))}
            <>:</>
            {slaOffset}
            Hours
          </span>
          &nbsp; ?
        </span>
        <Label
          variant="BorderLabel"
          labelsList={values(
            pick(['name', 'type', 'country'], activePriorityData)
          )}
          className="priority-remove-label"
          data-testid="priority-info-container"
        />
      </div>
      <div className="bp3-dialog-footer" data-testid="removePriority-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={formatMessage({ id: 'containers.all.btn.cancel' })}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={formatMessage({ id: 'containers.all.remove' })}
            onClick={formik.submitForm}
            loading={removeTeamPriorityLoading || loadingComp}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PriorityRemoveModal;
