import { gql } from '@apollo/client';
import ChannelFregment from '../fragments/channel';
import StreamingFregment from '../fragments/streaming';
import IptvFregment from '../fragments/iptv';

export default gql`
  mutation CreateProvider($input: createProviderInput) {
    createProvider(input: $input) {
      id
      type
      ... on IPTV {
        iptv {
          ...IptvFregment
        }
      }
      ... on Streaming {
        streaming {
          ...StreamingFregment
        }
      }
      ... on Channel {
        channel {
          ...ChannelFregment
        }
      }
    }
  }
  ${ChannelFregment}
  ${StreamingFregment}
  ${IptvFregment}
`;
