import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import React from 'react';

const PersonalAvatar = ({
  name,
  size,
  value,
  textSizeRatio,
  color,
  round,
  deactivated,
}) => (
  <Avatar
    name={name}
    size={`${size}px`}
    value={value}
    textSizeRatio={textSizeRatio}
    color={deactivated ? '#999999' : color}
    round={round}
  />
);

export default PersonalAvatar;

PersonalAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  value: PropTypes.string,
  textSizeRatio: PropTypes.number,
  color: PropTypes.oneOf(['red', 'green', 'blue', '#4992FC']),
  round: PropTypes.bool,
  deactivated: PropTypes.bool,
};

PersonalAvatar.defaultProps = {
  size: 24,
  value: '100%',
  textSizeRatio: 1.5,
  color: '#4992FC',
  round: true,
  deactivated: false,
};
