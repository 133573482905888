import classNames from 'classnames';
import React from 'react';
import { Grid } from 'react-flexbox-grid';
import { useProfile } from 'providers/profileProvider';
import { USER_LINE_NAMES } from 'utils/enums';
import OfflineShiftMatchCard from './OfflineShiftMatchCard';
import VideoShiftMatchCard from './VideoShiftMatchCard';

const ShiftMatchCard = ({
  shiftMatch,
  className,
  onClick,
  testid,
  matchAssignmentVersion,
  ...rest
}) => {
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole } = profile;
  const {
    role: { line: userLine },
  } = userJobTitleRole;

  const mapLineToCard = (lineName) => {
    const LineCard = {
      [USER_LINE_NAMES.VIDEO]: (
        <VideoShiftMatchCard
          shiftMatch={shiftMatch}
          matchAssignmentVersion={matchAssignmentVersion}
        />
      ),
      [USER_LINE_NAMES.OFFLINE]: (
        <OfflineShiftMatchCard shiftMatch={shiftMatch} {...rest} />
      ),
    };
    return LineCard[lineName];
  };
  return (
    <Grid
      fluid
      className={classNames(['width-100', 'match-row-container', className])}
      onClick={onClick}
      data-testid={testid}
    >
      {mapLineToCard(userLine.name)}
    </Grid>
  );
};

export default ShiftMatchCard;
