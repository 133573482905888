import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'components';
import { PRIORITIES_QUERY } from 'gql';
import { optionsFromArr } from 'utils/helpers';
import { isEmpty } from 'ramda';

const PrioritiesFilter = ({ priorityIds, setPropState }) => {
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;
  const {
    loading: prioritiesLoading,
    data: prioritiesData,
    fetchMore,
  } = useQuery(PRIORITIES_QUERY, {
    variables: { queryParams: { pageSize, page: 1 } },
  });

  const loadMoreData = () => {
    setPage((prev) => prev + 1);
    fetchMore({
      variables: { queryParams: { page, pageSize } },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.priorities)) {
          setHasMore(false);
          return prev;
        }
        return {
          ...prev,
          priorities: [...prev?.priorities, ...fetchMoreResult?.priorities],
        };
      },
    });
  };

  return (
    <Dropdown
      multiSelect
      variant="multiselect_obj_list"
      items={optionsFromArr(prioritiesData?.priorities)}
      values={priorityIds || []}
      onChange={(data) => setPropState({ priorityIds: data })}
      loading={prioritiesLoading}
      filterable
      searchPath={['label']}
      buttonText="Priority"
      className="match-assignment-filters"
      fetchMore={loadMoreData}
      scrollLoading={prioritiesLoading}
      hasMore={hasMore}
      name="priorities"
      data-testid="main-filters-priorities"
    />
  );
};

export default PrioritiesFilter;
