import { Classes, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Button, Icon, Label, Text } from 'components';
import { RenderIf, Acl } from 'config';
import { useModal } from 'providers/modalProvider';
import { prop, toLower } from 'ramda';
import React from 'react';
import { PROVIDER_TYPES, providerTypeIconMapper } from 'utils/enums';
import { title } from 'utils/helpers';
import StreamingDetails from './StreamingDetails';
import IPTVDetails from './IPTVDetails';
import ChannelDetails from './ChannelDetails';

const ProviderCard = (cardData) => {
  const { type, streaming, channel, iptv, screenInfo, id } = cardData;
  const name = cardData?.[toLower(type)]?.name;
  const { modalDispatch } = useModal();

  return (
    <div
      className="card pl-20 pr-20 "
      data-testid="video-providers-card-container"
    >
      <div className="card-header" data-testid={`${toLower(type)}-card-header`}>
        <div>
          <Tooltip2
            content="Provider Name"
            position={Position.BOTTOM_LEFT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <div className="flex column gap-7">
              <Text variant="body1">{title(name)}</Text>
              <RenderIf condition={type === PROVIDER_TYPES.CHANNEL}>
                <Text variant="body2">{title(channel?.satellite?.name)}</Text>
              </RenderIf>
            </div>
          </Tooltip2>
        </div>

        <div>
          <Tooltip2
            content="Provider Type"
            position={Position.BOTTOM_RIGHT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <div className="flex gap-5" data-testid="video-provider-type">
              <Icon icon={prop(type, providerTypeIconMapper)} />
              <Text variant="body2">{title(type)}</Text>
            </div>
          </Tooltip2>
        </div>
      </div>
      <div className="card-body " data-testid={`${toLower(type)}-card-body`}>
        <RenderIf condition={type === PROVIDER_TYPES.STREAMING}>
          <StreamingDetails {...streaming} />
        </RenderIf>

        <RenderIf condition={type === PROVIDER_TYPES.IPTV}>
          <IPTVDetails {...iptv} />
        </RenderIf>
        <RenderIf condition={type === PROVIDER_TYPES.CHANNEL}>
          <ChannelDetails {...channel} />
        </RenderIf>
      </div>

      <div className="card-footer" data-testid={`${toLower(type)}-card-footer`}>
        <RenderIf
          condition={type === PROVIDER_TYPES.IPTV && iptv?.feature?.name}
        >
          <Label
            className="mg-0 p-0"
            inline
            labelHeader="IPTV Device "
            labelText={title(iptv?.feature?.name)}
            variant="Baisc"
          />
        </RenderIf>
        <RenderIf condition={type === PROVIDER_TYPES.STREAMING}>
          <div>
            <Label
              className="mg-0 p-0"
              inline
              labelHeader="Number of Accounts: "
              labelText={streaming?.noOfAccounts}
              variant="Baisc"
            />
            <Label
              inline
              labelHeader="Account Concurrency: "
              labelText={streaming?.noOfConcurrency}
              variant="Baisc"
              className="mg-0 p-0"
            />
          </div>
        </RenderIf>
        <div className="automarginLeft">
          <Acl
            allowedPermissions={['delete:provider']}
            allowedRoleNames={['super-admin']}
          >
            <Button
              intent="none"
              text="Delete"
              icon="ban-circle"
              minimal
              onClick={() =>
                modalDispatch({
                  type: 'DELETE_PROVIDER',
                  payload: {
                    providerData: {
                      id,
                      type,
                      name,
                    },
                    screenInfo,
                  },
                })
              }
              className="mg-5 p-0"
            />
          </Acl>
          <Acl
            allowedPermissions={['update:provider']}
            allowedRoleNames={['super-admin']}
          >
            <Button
              text="Edit"
              intent="primary"
              icon="edit"
              minimal
              onClick={() =>
                modalDispatch({
                  type: 'CREATE_UPDATE_PROVIDER',
                  payload: {
                    providerData: {
                      type,
                      iptv,
                      streaming,
                      channel,
                      name,
                      id,
                    },
                    screenInfo,
                  },
                })
              }
              className="mg-5 p-0"
            />
          </Acl>
        </div>
      </div>
    </div>
  );
};

export default ProviderCard;
