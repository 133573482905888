import React, { useState } from 'react';
import { Button, Dropdown } from 'components';
import { useMutation } from '@apollo/client';
import { RenderIf } from 'config';
import { useFormik } from 'formik';
import { Popover2 } from '@blueprintjs/popover2';
import { Position } from '@blueprintjs/core';
import {
  MATCH_OFFLINE_TASKS_QUERY,
  UPDATE_OFFLINE_TASK_MUTATION,
  UNASSIGN_OFFLINE_TASK_MUTATION,
} from 'gql';
import { TEAM_SIDE } from 'utils/enums';
import { optionsFromArr, title } from 'utils/helpers';
import { includes, equals } from 'ramda';
import { useToast } from 'providers/toastProvider';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FormattedMessage, useIntl } from 'react-intl';

const CollectorTask = ({ task, squadId, matchId, halfValues, partValues }) => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { formatMessage } = useIntl();
  const {
    id: taskId,
    type,
    half,
    teamSide,
    part,
    member: {
      id: squadShiftMemberId,
      user: { id: userId },
    },
  } = task;
  const { showToast } = useToast();
  const initialValues = {
    teamSide,
    half,
    part,
  };

  const [updateOfflineTaskMutation, { loading: updateLoading }] = useMutation(
    UPDATE_OFFLINE_TASK_MUTATION,
    {
      refetchQueries: [
        {
          query: MATCH_OFFLINE_TASKS_QUERY,
          variables: { matchId },
        },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        showToast({
          message: 'Changes can not be saved try again ',
          icon: 'error',
        });
      },
      onCompleted: () => {
        showToast({ message: 'Match tasks have been edited successfully.' });
      },
    }
  );

  const [unAssignOfflineTasksMutation, { loading: unassignLoading }] =
    useMutation(UNASSIGN_OFFLINE_TASK_MUTATION, {
      refetchQueries: [
        {
          query: MATCH_OFFLINE_TASKS_QUERY,
          variables: {
            matchId,
          },
          fetchPolicy: 'cache-and-network',
          notifyOnNetworkStatusChange: true,
        },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        showToast({
          message: 'Changes can not be saved try again ',
          icon: 'error',
        });
      },
      onCompleted: () => {
        showToast({ message: 'Tasks has been unassigned successfully' });
      },
    });

  const unAssignTask = () => {
    setPopOverOpen(false);
    return unAssignOfflineTasksMutation({
      variables: {
        input: {
          squadId,
          taskId,
          matchId,
          squadShiftMemberId,
          userId,
        },
      },
    });
  };

  const updateTask = ({ teamSide, half, part }) => {
    const allowTeamSide = includes(type, ['TAGGING', 'FREEZE_FRAME']);
    const allowPart = includes(type, ['LOCATION', 'VALIDATION']);

    return updateOfflineTaskMutation({
      variables: {
        input: {
          squadId,
          task: {
            taskId,
            type,
            teamSide: allowTeamSide ? teamSide : undefined,
            half,
            part: allowPart ? part : undefined,
          },
        },
      },
    });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: updateTask,
  });

  return (
    <Grid className="pl-32 pt-36">
      <Row data-testid="dashboard-editTask-taskRow">
        <Col lg={1} md={1} sm={1} className="mg-0 p-0">
          <Popover2
            popoverClassName=""
            content={
              <div className="flex center items-center pr-8 pl-8">
                <span className="text-one">
                  <FormattedMessage id="containers.dashboards.editTask.confirm" />
                </span>
                <Button
                  onClick={unAssignTask}
                  iconSize={8}
                  minimal
                  intent="danger"
                  text={formatMessage({ id: 'containers.all.remove' })}
                  data-testid="dashboard-editTask-unassignConfirm"
                />
                <Button
                  onClick={() => setPopOverOpen(false)}
                  iconSize={8}
                  minimal
                  text={formatMessage({ id: 'containers.all.btn.cancel' })}
                  className="pl-0"
                />
              </div>
            }
            position={Position.TOP_LEFT}
            openOnTargetFocus={false}
            usePortal={false}
            isOpen={popOverOpen}
          >
            <Button
              iconsOnly
              minimal
              intent="danger"
              icon="remove"
              onClick={() => setPopOverOpen(!popOverOpen)}
              disabled={unassignLoading}
              loading={unassignLoading}
              data-testid="dashboard-editTask-unassignButton"
            />
          </Popover2>
        </Col>
        <Col lg={2} md={2} sm={2} className="flex column mg-0 p-0">
          <span className="">Task</span>
          <span
            className="body-three-txt"
            data-testid="dashboard-editTask-taskType"
          >
            {title(type)}
          </span>
        </Col>
        <Col lg={3} md={3} sm={3} className="flex column mg-0 p-0">
          <RenderIf condition={includes(type, ['LOCATION', 'VALIDATION'])}>
            <Dropdown
              items={optionsFromArr(halfValues)}
              value={formik.values.half}
              onChange={({ value }) => formik.setFieldValue('half', value)}
              buttonText="Half"
              labelText="Half"
              usePortal={false}
              data-testid="dashboard-editTask-halfLoc"
            />
          </RenderIf>
          <RenderIf condition={includes(type, ['TAGGING', 'FREEZE_FRAME'])}>
            <Dropdown
              items={optionsFromArr(TEAM_SIDE)}
              value={formik.values.teamSide}
              onChange={({ value }) => formik.setFieldValue('teamSide', value)}
              buttonText="Team Side"
              labelText="Team Side"
              usePortal={false}
              data-testid="dashboard-editTask-teamSide"
            />
          </RenderIf>
        </Col>
        <Col lg={3} md={3} sm={3} className="flex column mg-0 p-0">
          <RenderIf condition={includes(type, ['LOCATION', 'VALIDATION'])}>
            <Dropdown
              items={optionsFromArr(partValues)}
              value={formik.values.part}
              onChange={({ value }) => formik.setFieldValue('part', value)}
              buttonText="Part"
              labelText="Part"
              usePortal={false}
              data-testid="dashboard-editTask-part"
            />
          </RenderIf>
          <RenderIf condition={includes(type, ['TAGGING', 'FREEZE_FRAME'])}>
            <Dropdown
              items={optionsFromArr(halfValues)}
              value={formik.values.half}
              onChange={({ value }) => formik.setFieldValue('half', value)}
              buttonText="Half"
              labelText="Half"
              usePortal={false}
              data-testid="dashboard-editTask-halfFF"
            />
          </RenderIf>
        </Col>
        <Col lg={1} md={1} sm={1} className="flex column mg-0 p-0">
          <RenderIf condition={!equals(formik.values, initialValues)}>
            <Button
              text={formatMessage({ id: 'containers.all.btn.save' })}
              icon="tick-circle"
              intent="Primary"
              onClick={formik.submitForm}
              disabled={updateLoading}
              loading={updateLoading}
              data-testid="dashboard-editTask-save"
            />
          </RenderIf>
        </Col>
      </Row>
    </Grid>
  );
};

export default CollectorTask;
