import Dropdown from 'components/Dropdown';
import React from 'react';
import { MATCH_STATUS } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';

const IsAssignedFilter = ({ isAssigned, onChange }) => (
  <Dropdown
    multiSelect
    values={isAssigned || []}
    items={optionsFromArr(MATCH_STATUS)}
    onChange={onChange}
    buttonText="Status"
    loading={!MATCH_STATUS}
    className="match-assignment-filters"
    name="status"
  />
);

export default IsAssignedFilter;
