import classNames from 'classnames';
import { Icon } from 'components';
import React from 'react';
import SimpleLabel from './SimpleLabel';

const MatchStatus = ({
  status = '',
  className,
  collectionStatus = '',
  ...rest
}) => {
  const labelTexts = {
    completed: 'Completed',
    notStarted: 'Not Started',
    inProgress: 'In Progress',
  };
  return (
    <div
      className={classNames(
        'match-status-label',
        className,
        collectionStatus,
        status
      )}
      {...rest}
    >
      <Icon customIcon={status === 'blocked' ? status : collectionStatus} />
      <SimpleLabel labelText={labelTexts[collectionStatus]} />
    </div>
  );
};

export default MatchStatus;
