import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import { Icon } from 'components';

const ButtonLink = ({ link, text, icon, small, activeId, id, count }) => (
  <Link
    href={link}
    className={classNames('button-link', {
      active: activeId === id,
      small,
    })}
    data-testid="main-button-link"
  >
    {icon && <Icon {...icon} />}
    <span>{`${text} (${count})`}</span>
  </Link>
);

export default ButtonLink;
