import { gql } from '@apollo/client';

export default gql`
  query MatchIssues($matchId: Int!, $lineId: String!) {
    matchIssues(matchId: $matchId, lineId: $lineId) {
      id
      matchId
      type
      comment
      edited
      videoStatusIssue
      reporter {
        id
        firstName
        lastName
        middleName
        hrCode
      }
      resolver {
        id
        hrCode
        firstName
        lastName
        middleName
      }
      details {
        ... on PlayerIssue {
          name
          shirtNumber
          teamSide
        }
        ... on VideoIssue {
          half
          from
          to
          isWholeMatch
        }
        ... on MissingMinutesIssue {
          from
          to
        }
      }

      status
    }
  }
`;
