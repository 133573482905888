import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCompetitionSeasonProviders(
    $input: updateCompetitionSeasonProvidersInput!
  ) {
    updateCompetitionSeasonProviders(input: $input) {
      id
    }
  }
`;
