import React, { useCallback } from 'react';
import classNames from 'classnames';
import { DateObject } from 'react-multi-date-picker';
import { Button, Icon, Label } from 'components';
import ShiftTypeSelect from 'components/ShiftTypeSelect';

const DateWithShiftFilterBar = ({
  onChangeDate,
  activeDate,
  shift,
  setShift,
}) => {
  const handleShiftTypeChange = (shiftData) => setShift(shiftData);

  const formattedDate = (date) => date.format('YYYY-MM-DD');
  const { name: dateName } = new DateObject(activeDate).weekDay;

  const isToday =
    formattedDate(new DateObject(activeDate)) ===
    formattedDate(new DateObject());
  const dateNameLabelText = isToday ? `TODAY - ${dateName}` : dateName;

  const nextDay = useCallback(() => {
    const nextDate = new DateObject(activeDate);
    nextDate.add(1, 'day');
    onChangeDate(formattedDate(nextDate));
  }, [activeDate]);

  const prevDay = useCallback(() => {
    const prevDate = new DateObject(activeDate);
    prevDate.subtract(1, 'day');
    onChangeDate(formattedDate(prevDate));
  }, [activeDate]);

  return (
    <div className="date-with-filter-Bar flex items-center gap-10">
      <Button
        minimal
        icon={<Icon icon="chevron-left" size={18} color="#040482" />}
        onClick={prevDay}
        intent="Primary"
        data-testid="dashboard-header-prev"
      />
      <Label
        variant="Label"
        labelText={`${dateNameLabelText} ${activeDate}`}
        data-testid="dashboard-activeDate"
      />
      <ShiftTypeSelect
        onChange={handleShiftTypeChange}
        className={classNames(shift?.shiftType?.toLowerCase())}
        value={shift?.id}
        haveDefaultShift
      />
      <Button
        minimal
        icon={<Icon icon="chevron-right" size={18} color="#040482" />}
        onClick={nextDay}
        intent="Primary"
        data-testid="dashboard-header-next"
      />
    </div>
  );
};

export default DateWithShiftFilterBar;
