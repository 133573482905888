import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Menu, MenuItem, Spinner } from '@blueprintjs/core';
import update from 'immutability-helper';
import {
  defaultTo,
  filter,
  find,
  map,
  mergeAll,
  path,
  pipe,
  prop,
  propEq,
  reduce,
  sum,
  values,
  pluck,
} from 'ramda';
import { Icon, Button, Text, Avatar } from 'components';
import {
  UPDATE_SQUAD_SHIFT_MEMBER_MUTATION,
  SQUAD_SHIFTS_QUERY,
  SQUAD_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { POWERS, PRESENT_TYPES } from 'utils/enums';
import { findByValue, title } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { RenderIf } from 'config';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Popover2 } from '@blueprintjs/popover2';

const EditSquadPower = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const dates = path(['payload', 'dates'], modalState);
  const squadId = path(['payload', 'squadId'], modalState);
  const [activeId, setActiveId] = useState('');
  const [changesArr, setChangesArr] = useState([]);
  const { loading: squadLoading, data: squadData } = useQuery(SQUAD_QUERY, {
    variables: { squadId },
  });
  const intl = useIntl();

  const [squadMembers, setSquadMembers] = useState([]);
  const [squadShiftIds, setSquadShiftIds] = useState([]);
  const { loading: squadShiftsLoading, data: squadShiftsData } = useQuery(
    SQUAD_SHIFTS_QUERY,
    {
      skip: !squadId || !dates,
      variables: { dates, squadId },
    }
  );
  const getShiftByDate = (date) =>
    find(
      propEq('date', date),
      defaultTo([], squadShiftsData?.squadShifts?.squadShifts)
    );

  const getActiveMembers = (shift) =>
    filter(
      (x) => PRESENT_TYPES.includes(x.vacationType),
      defaultTo([], prop('squadMembers', shift))
    );
  const getActiveMembersPowersSum = (members) =>
    reduce((acc, next) => acc + prop('power', next), 0, defaultTo([], members));

  const getPowerByDate = pipe(
    getShiftByDate,
    getActiveMembers,
    getActiveMembersPowersSum
  );

  const getTotalPower = pipe(
    getShiftByDate,
    (shift) => prop('squadMembers', shift),
    getActiveMembersPowersSum
  );
  const weekTotalsVacations = sum(defaultTo([], map(getPowerByDate, dates)));

  const weekTotals = sum(defaultTo([], map(getTotalPower, dates)));

  const totalPower = `${weekTotalsVacations.toFixed(2)} / ${weekTotals.toFixed(
    2
  )}`;

  useEffect(() => {
    const squadShifts = squadShiftsData?.squadShifts?.squadShifts;
    setSquadShiftIds(pluck('id', squadShifts));
    const squadShiftMembers = mergeAll(pluck('squadMembers', squadShifts));
    setSquadMembers(values(squadShiftMembers));
  }, [squadShiftsData]);
  const [updateSquadShiftMember, { loading: updateLoading }] = useMutation(
    UPDATE_SQUAD_SHIFT_MEMBER_MUTATION,
    {
      refetchQueries: [
        { query: SQUAD_SHIFTS_QUERY, variables: { dates, squadId } },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        showToast({
          message: intl.formatMessage({ id: 'containers.all.errorCannotEdit' }),
          icon: 'error',
        });
        setChangesArr(
          update(changesArr, {
            $push: [{ memberId: activeId, status: 'error' }],
          })
        );
        setActiveId('');
      },
      onCompleted: () => {
        showToast({
          message: intl.formatMessage({ id: 'containers.all.success' }),
        });
        setChangesArr(
          update(changesArr, {
            $push: [{ memberId: activeId, status: 'success' }],
          })
        );
        setActiveId('');
      },
    }
  );
  const submitPower = (updatedPower, memberId) =>
    updateSquadShiftMember({
      variables: {
        input: {
          power: updatedPower,
          userId: memberId,
          squadId,
          squadShiftIds,
        },
      },
    });

  const { name = '' } = squadData?.squad;

  const members = filter(
    propEq('deactivated', false),
    defaultTo([], prop('members', squadData?.squad))
  );
  const getMemberPower = (memberId) => {
    const activeMemberByShift =
      findByValue('userId', memberId, squadMembers) || {};
    return activeMemberByShift.power;
  };
  const powersList = (memberId) => (
    <Menu>
      {POWERS?.map((power) => (
        <MenuItem
          key={`power-${power}`}
          text={power}
          onClick={() => {
            setActiveId(memberId);
            submitPower(power, memberId);
          }}
        />
      ))}
    </Menu>
  );
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="modal-title">
          <Icon icon="edit" size={15} />
          <div>
            {intl.formatMessage({
              id: 'containers.shiftSchedule.header.btn.editSquadPower',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      style={{ width: '507px' }}
    >
      <RenderIf
        condition={!squadLoading || !squadShiftsLoading}
        renderElse={<Spinner />}
      >
        <Grid className="edit-squad-power-container">
          <Row middle="xs sm md lg">
            <Col lg={3} md={3} sm={3} xs={3}>
              <Text variant="h2">{`Squad ${title(name)}`}</Text>
            </Col>
            <Col lg={5} md={5} sm={5} xs={5} className="flex">
              <Text variant="body1">Total Power:</Text>
              <span className="bg-bolt" />
              <Text variant="body1">{totalPower}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="flex gap-10" lg={11} md={11} sm={11} xs={11}>
              <span className="bg-warning2 pr-15" />
              <Text variant="body2">
                {intl.formatMessage(
                  { id: 'containers.shiftSchedule.editSquadPower.warning' },
                  { label: <b>selected Week only</b> }
                )}
              </Text>
            </Col>
          </Row>
        </Grid>
        <Grid className="edit-squad-power-table">
          <Row className="edit-squad-power-table-header">
            <Col lg={1} md={1} sm={1} xs={1} />
            <Col lg={2} md={2} sm={2} xs={2}>
              #
            </Col>
            <Col lg={5} md={5} sm={5} xs={5}>
              <Text variant="body2">
                {intl.formatMessage({ id: 'containers.all.employeeName' })}
              </Text>
            </Col>
            <Col lg={2} className="flex" md={2} xs={2} sm={2}>
              <span className="bg-bolt" />
              <Text variant="body2">
                {intl.formatMessage({ id: 'containers.all.power' })}
              </Text>
            </Col>
            <Col />
          </Row>
          {members?.map((member) => {
            const { hrCode, firstName, id: memberId, lastName } = member;
            return (
              <Row
                key={`edit-squad-power-${memberId}`}
                className="edit-squad-power-table-body"
              >
                <Col lg={1} md={1} xs={1} sm={1} />
                <Col lg={2} md={2} xs={2} sm={2}>
                  {hrCode}
                </Col>
                <Col lg={5} md={5} xs={5} sm={5} className="flex gap-6">
                  <Avatar
                    name={`${firstName} ${lastName}`}
                    size={18}
                    textSizeRatio="1"
                  />
                  <Text variant="body2">
                    {`${title(firstName)} ${title(lastName)}`}
                  </Text>
                </Col>
                <Col
                  lg={2}
                  md={2}
                  xs={2}
                  sm={2}
                  className="flex gap-7"
                  data-testid="shift-member-power"
                >
                  <span className="bg-bolt" />
                  <Text variant="body1">{getMemberPower(memberId)}</Text>
                </Col>
                <Col>
                  {activeId === memberId && updateLoading ? (
                    <Spinner size={16} />
                  ) : (
                    <Popover2
                      content={powersList(memberId)}
                      placement="bottom"
                      usePortal={false}
                    >
                      <Button
                        icon={<Icon icon="chevron-down" />}
                        minimal
                        data-testid="edit-shift-member-power"
                      />
                    </Popover2>
                  )}
                </Col>
              </Row>
            );
          })}
        </Grid>
      </RenderIf>
      <div className="bp3-dialog-footer-actions">
        <Button
          outlined
          intent="Primary"
          text={intl.formatMessage({
            id: 'containers.all.close',
          })}
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
        />
      </div>
    </Dialog>
  );
};

export default EditSquadPower;
