import { gql } from '@apollo/client';

export default gql`
  query ShiftMembers($shiftId: String!, $date: Date!) {
    shiftMembers(shiftId: $shiftId, date: $date) {
      squadMembers {
        user {
          id
          firstName
          lastName
          hrCode
          deactivated
        }
      }
    }
  }
`;
