import { gql } from '@apollo/client';
/**
 *
 {
  "input": {
    "id": null,
    "videoStatus": null,
  }
}
 */
export default gql`
  mutation UpdateMatchVideoStatus($input: UpdateMatchVideoStatusInput!) {
    updateMatchVideoStatus(input: $input) {
      id
      videoStatus
    }
  }
`;
