import React from 'react';
import TimeStampPicker from '../../../../../../components/TimeStampPicker';

const ConnectionAndMissingMinutes = ({
  details,
  setFieldValue,
  touched,
  errors,
}) => {
  const { from, to } = details;
  const handleFromChange = (val) => setFieldValue('details.from', val);
  const handleToChange = (val) => setFieldValue('details.to', val);

  return (
    <div className="pt-10">
      <TimeStampPicker
        fromValue={from}
        toValue={to}
        onFromChange={handleFromChange}
        onToChange={handleToChange}
        touched={touched}
        errors={errors}
      />
    </div>
  );
};

export default ConnectionAndMissingMinutes;
