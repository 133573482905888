import { gql } from '@apollo/client';

export default gql`
  fragment IptvFregment on IptvDetails {
    videoOnDemand
    name
    feature {
      id
      name
      type
    }
  }
`;
