import React from 'react';
import { find, isNil, propEq, reject } from 'ramda';
import { Icon, Text, Button } from 'components';
import { Checkbox, Dialog } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useFormik } from 'formik';
import AreaFilter from 'components/Filters/filtersComponents/AreaFilter';
import CompetitionFilter from 'components/Filters/filtersComponents/CompetitionFilter';
import ProviderFilter from 'components/Filters/filtersComponents/ProviderFilter';
import { useIntl, FormattedMessage } from 'react-intl';
import { allUniqueNoNils } from 'utils/helpers';
import {
  UPDATE_COMPETITION_SEASON_PROVIDERS_MUTATION,
  COMPETITION_SEASONS_QUERY,
} from 'gql';
import { RenderIf } from 'config';

const MIN_SEASON = 2023;
const createLeagueModal = () => {
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const {
    modalDispatch,
    modalState: {
      open,
      payload: { league },
    },
  } = useModal();
  const isEditModal = !isNil(league);
  const minSeasonYear = isEditModal ? undefined : MIN_SEASON;
  const {
    area: areaToEdit,
    competitionName,
    seasonName,
    id,
    live,
    competitorAvailable,
    providers = [],
  } = league || {};
  const selectedArea = { label: areaToEdit?.name, value: areaToEdit?.id };
  const selectedCompetitionSeason = { competitionName, seasonName, id };
  const defaultProvider = find(propEq('isDefault', true), providers);
  const otherProviders = reject(propEq('isDefault', true), providers);
  const selectedProvider2 = otherProviders[0];
  const selectedProvider3 = otherProviders[1];
  const [
    updateCompetitionSeasonProviders,
    { loading: updateCompetitionSeasonProvidersLoading },
  ] = useMutation(UPDATE_COMPETITION_SEASON_PROVIDERS_MUTATION, {
    refetchQueries: [COMPETITION_SEASONS_QUERY],
    onCompleted: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: formatMessage({
          id: 'containers.leagues.edit.success',
        }),
      });
    },
    onError: ({ message }) =>
      showToast({
        message,
      }),
  });
  const providerInitialValue = {
    providerId: undefined,
    isDefault: false,
  };
  const getProviderValues = (selectedProvider) => ({
    providerId: selectedProvider?.provider?.id,
    isDefault: selectedProvider?.isDefault,
  });

  const initialValues = {
    area: selectedArea?.value ?? undefined,
    competitionSeasonId: selectedCompetitionSeason?.id ?? undefined,
    live,
    competitorAvailable,
    provider1: isEditModal
      ? getProviderValues(defaultProvider)
      : {
          ...providerInitialValue,
          isDefault: true,
        },
    provider2: isEditModal
      ? getProviderValues(selectedProvider2)
      : providerInitialValue,

    provider3: isEditModal
      ? getProviderValues(selectedProvider3)
      : providerInitialValue,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: undefined,
    onSubmit: ({
      competitionSeasonId,
      live,
      competitorAvailable,
      provider1,
      provider2,
      provider3,
    }) => {
      const input = {
        competitionSeasonId,
        live,
        competitorAvailable,
        providers: [provider1, provider2, provider3].filter(
          (provider) => provider.providerId !== undefined
        ),
      };
      updateCompetitionSeasonProviders({
        variables: { input },
      });
    },
  });
  const uniqProviders = allUniqueNoNils([
    formik.values.provider1?.providerId,
    formik.values.provider2?.providerId,
    formik.values.provider3?.providerId,
  ]);
  const newWithNoProvider =
    !league &&
    isNil(formik.values.provider1) &&
    isNil(formik.values.provider2) &&
    isNil(formik.values.provider3);
  const onAreaChange = (area) => formik.setFieldValue('area', area);

  const onCompetitionSeasonChange = (competitionSeason) =>
    formik.setFieldValue('competitionSeasonId', competitionSeason);

  const onProviderChange = (key, provider) => {
    return formik.setFieldValue(key, {
      providerId: provider,
      isDefault: key === 'provider1',
    });
  };

  const toggleLive = () => formik.setFieldValue(`live`, !formik.values.live);

  const toggleCompetitor = () =>
    formik.setFieldValue(
      `competitorAvailable`,
      !formik.values.competitorAvailable
    );
  return (
    <Dialog
      isOpen={open}
      title={
        <div className="create-edit-header">
          <Icon
            icon="plus"
            color="#999999"
            size={14}
            data-testid="modalHeader-icon"
          />
          <p data-testid="modalHeader-title">
            {league ? (
              <FormattedMessage id="containers.leagues.edit" />
            ) : (
              <FormattedMessage id="containers.leagues.add" />
            )}
          </p>
        </div>
      }
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="edit-priorty-container">
        <div className="flex gap-40 items-center">
          <div className="flex column">
            <Text variant="body1" className="pb-10">
              {formatMessage({
                id: 'containers.all.dropdown.country',
              })}
            </Text>
            <AreaFilter
              area={formik.values.area}
              onAreaChange={onAreaChange}
              selectedArea={selectedArea}
              disabled={league}
            />
            {formik.errors.area && (
              <span className="error-text" data-testid="main-errors-country">
                {formatMessage({ id: 'containers.all.required' })}
              </span>
            )}
          </div>
          <div className="flex column">
            <Text variant="body1" className="pb-10">
              {formatMessage({
                id: 'containers.league.Competition',
              })}
            </Text>
            <CompetitionFilter
              area={formik.values.area}
              competitionSeason={formik.values.competitionSeasonId}
              onCompetitionSeasonChange={onCompetitionSeasonChange}
              selectedCompetitionSeason={selectedCompetitionSeason}
              disabled={league}
              minSeasonYear={minSeasonYear}
            />
            {formik.errors.competitionSeasonId && (
              <span className="error-text" data-testid="main-errors-country">
                {formatMessage({ id: 'containers.all.required' })}
              </span>
            )}
          </div>
        </div>
        <div className="mgt-20 flex gap-40">
          <div className="flex column">
            <Text variant="body1" className="pb-10">
              <FormattedMessage id="containers.leagues.defaultProvider" />
            </Text>
            <ProviderFilter
              provider={formik.values.provider1?.providerId}
              onProviderChange={(val) => onProviderChange('provider1', val)}
              selectedProvider={defaultProvider}
            />
            <Text variant="body1" className="pb-10 pt-15">
              <FormattedMessage id="containers.leagues.provider" />
            </Text>
            <ProviderFilter
              provider={formik.values.provider2.providerId}
              onProviderChange={(val) => onProviderChange('provider2', val)}
              selectedProvider={selectedProvider2}
            />
            <Text variant="body1" className="pb-10 pt-15">
              <FormattedMessage id="containers.leagues.provider" />
            </Text>
            <ProviderFilter
              provider={formik.values.provider3.providerId}
              onProviderChange={(val) => onProviderChange('provider3', val)}
              selectedProvider={selectedProvider3}
            />
          </div>
          <div className="flex column">
            <Checkbox
              label={
                <Text>
                  <FormattedMessage id="containers.leagues.live" />
                </Text>
              }
              checked={formik.values.live}
              onChange={toggleLive}
            />
            <Checkbox
              label={
                <Text>
                  <FormattedMessage id="containers.leagues.competitor" />
                </Text>
              }
              checked={formik.values.competitorAvailable}
              onChange={toggleCompetitor}
            />
          </div>
        </div>
        <RenderIf condition={!uniqProviders}>
          <Text className="error-text ">
            <FormattedMessage id="containers.leagues.uniq" />
          </Text>
        </RenderIf>
      </div>
      <div className="bp3-dialog-footer-actions" data-testid="create-footer">
        <Button
          outlined
          text={formatMessage({ id: 'containers.users.form.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.users.form.save' })}
          onClick={formik.submitForm}
          loading={updateCompetitionSeasonProvidersLoading}
          disabled={!formik.values || !uniqProviders || newWithNoProvider}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default createLeagueModal;
