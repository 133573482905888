/* eslint no-magic-numbers: "off" */
/* eslint no-useless-escape: "off" */
import {
  sort,
  find,
  symmetricDifference,
  curry,
  ifElse,
  contains,
  without,
  append,
  head,
  length,
  propEq,
  includes,
  groupBy,
  pipe,
  pluck,
  mergeAll,
  prop,
  isNil,
  isEmpty,
  either,
  filter,
  difference,
  reduce,
  assoc,
  keys,
  pathEq,
  defaultTo,
  sortBy,
  flatten,
  map,
  toLower,
  path,
  is,
  complement,
  compose,
  trim,
  equals,
  uniqWith,
} from 'ramda';
import moment from 'moment';
import titleize from 'titleize';
import { DateObject } from 'react-multi-date-picker';
import {
  INFO_ISSUE_LIST,
  QUALITY_ISSUE_LIST,
  SPORTS,
  STATUS,
  USER_LINE_NAMES,
  VOD_STATUS,
  providerTypeIconMapper,
} from './enums';

export const toBackEndDate = (date) => moment(date, 'YYYY-MM-DD');
export const toBackEndTime = (date = new Date(), time) =>
  moment.utc(`${date} ${time}`).format('HH:mm:ssZ');
export const formatTime = (time) => {
  const m = moment(time, 'HH:mm');
  const t = m.minutes() === 0 ? m.format('hh A') : m.format('hh:mm A');
  return t.replace(/\b0/g, '');
};
export const title = (str) => {
  // special case for certain cases
  if (['CS_MANAGER', 'HR_MANAGER'].includes(str)) {
    return `${str.split('_')[0].toUpperCase()} ${titleize(str.split('_')[1])}`;
  }
  return typeof str === 'string' ? titleize(str.replace(/_/g, ' ')) : str;
};

/**
 * TODO: @refactor
 * when refactor the dropdown comp need to update the optionsFromArr
 * so it can accept `value` and `label` field names
 * also update functions here src/components/Card/helpers/helpers.js
```
    const optionsFromArr = (arr = [], {
      value: 'valueFieldName',
      label: 'labelFieldName',
      allProps: false | true
    })
```
 */
export const optionsFromArr = (arr = [], allprops = false) =>
  // TODO Refactor cases for options in dropdowns
  arr.map((item) => {
    if (typeof item === 'string' && item !== null) {
      if (item?.toLowerCase() === 'pregame')
        return { value: item, label: 'Multi-sport' }; // easiest way to get it done!
      return { label: item, value: item };
    }

    if (typeof item === 'number' && item !== null) {
      return { label: String(item), value: item };
    }

    if (item?.firstName && item?.lastName) {
      return {
        label: `${item.firstName} ${item.middleName || ''} ${item.lastName}`,
        value: item.id,
        ...item,
      };
    }

    if (item.slaOffset && item.id) {
      return {
        label: `${item?.priorityCategory?.value} : ${item?.slaOffset}`,
        value: item.id,
      };
    }

    if (item.shiftType) {
      return {
        label: item.shiftType,
        value: item.id,
        icon: item?.shiftType?.toLowerCase(),
        ...item,
      };
    }
    if (item?.HQ) {
      return {
        label: `${item.HQ} - ${item?.name}`,
        value: item.id,
        icon: 'map-marker',
        HQ: item?.HQ,
      };
    }
    if (!item.name) {
      return { label: item.value, value: item.id };
    }
    if (allprops) {
      return { label: item.name, value: item.id, ...item };
    }
    return { label: item.name, value: item.id };
  });

export const optionsFromArrSla = (arr = [], cat) =>
  arr.map((item) => ({
    label: `${cat}:${item?.slaOffset} Hours`,
    value: item.id,
  }));
export const optionsFromArrComp = (arr = []) =>
  arr.map((item) => ({
    label: `${item.competitionName}  ${item.seasonName}`,
    value: item.id,
  }));

export const convertStatusToBool = (arr = []) => {
  // TODO : Refactor All convert [] to bool to use a single method
  if (equals(length(arr), 1) && equals(head(arr), 'ACTIVE')) return false;
  if (equals(length(arr), 1) && equals(head(arr), 'DEACTIVATED')) return true;
  return undefined;
};

export const convertTeamTypeToBool = (arr = []) => {
  // TODO : Refactor All convert [] to bool to use a single method
  if (equals(length(arr), 1) && equals(head(arr), 'MEN')) return false;
  if (equals(length(arr), 1) && equals(head(arr), 'WOMEN')) return true;
  return undefined;
};

export const getLabel = (val, items) => {
  const labeledItems = items;
  const x = labeledItems.filter((item) => item.value === val);
  const label = x[0]?.label === 'AMF' ? x[0]?.label : title(x[0]?.label);
  return label;
};

export const toggleListItem = (array, item) =>
  symmetricDifference(array, [item]);

export const toggleObjListItem = curry((array, item) =>
  ifElse(contains(item), without([item]), append(item))(array)
);

export const arrayDiff = (a, b) =>
  b.filter(
    (elm) => !a.map((elm) => JSON.stringify(elm)).includes(JSON.stringify(elm))
  );
export const setActiveRoute = (arr, currentRoute) =>
  arr.map((item) => {
    return {
      isActive: includes(currentRoute, [item.link, item.altLink]),
      ...item,
    };
  });

export const formatDate = (x) =>
  `${x.year}-${x.month.number.toString().padStart(2, '0')}-${x.day
    .toString()
    .padStart(2, '0')}`;
export const momentDate = (date) => moment(date).format('DD/MM/YYYY');
export const longFormatDate = (x) =>
  `${x?.day?.toString().padStart(2, '0')} ${x?.month?.name} ${x?.year}`;
export const makeDateObj = (x) => new DateObject(x);
export const findByValue = (compare = '', value = '', items = []) =>
  find(propEq(compare, value), items);
export const acronym = (str = '') => str.match(/\b(\w)/g)?.join('');
export const weekNumber = () => {
  const todayDate = new Date();
  const oneJan = new Date(todayDate.getFullYear(), 0, 1);
  const numberOfDays = Math.floor((todayDate - oneJan) / (24 * 60 * 60 * 1000));
  const result = Math.ceil((todayDate.getDay() + 1 + numberOfDays) / 7);
  return result;
};

export const convertMatchStatusToBool = (arr = []) => {
  // TODO : Refactor All convert [] to bool to use a single method
  if (equals(length(arr), 1) && equals(head(arr), 'Assigned')) return true;
  if (equals(length(arr), 1) && equals(head(arr), 'Unassigned')) return false;
  return undefined;
};

export const getChangedValues = (values = {}, initialValues = {}) => {
  const isDifferent = (value, initial) => !equals(value, initial);

  const changes = reduce(
    (acc, key) => {
      if (isDifferent(values[key], initialValues[key])) {
        acc[key] = values[key];
      }
      return acc;
    },
    {},
    keys(values)
  );

  return changes;
};
export const isBetweenDates = (dates = [], day) => {
  const today = new DateObject().format('YYYY-MM-DD');
  return includes(day || today, dates);
};
export const isPastDay = (day) => {
  const dateFromDay = new DateObject(day);
  const yesterday = new DateObject().subtract(1, 'day');
  return dateFromDay.unix < yesterday.unix;
};

// eslint-disable-next-line func-names
export const squadsPerShift = groupBy(function (
  squadShiftPowerAndCapacity = []
) {
  const shiftTypeFunc = pipe(pluck(['shift']), mergeAll, prop('id'));
  const shiftType = shiftTypeFunc(squadShiftPowerAndCapacity?.squadShifts);
  return shiftType;
});

export const removeDateFromString = (str = '') => {
  return str?.replace(/\d{2,4}[\-|\.|\/]\d{1,2}[\-|\.|\/]\d{1,2}/g, '');
};
export const calcSlaLongFormat = (date, sla, kickOff) => {
  const slaMarginTime = 1.75;
  return moment
    .utc(`${date} ${kickOff}`, 'YYYY-MM-DD HHmmss')
    .add(sla, 'hours')
    .add(slaMarginTime, 'hours')
    .local()
    .format('DD.MM.YYYY - HH:mm');
};
export const formatKO = (date, ko) => {
  return moment
    .utc(`${date} ${ko}`, 'YYYY-MM-DD HHmmss')
    .local()
    .format('DD.MM.YYYY - HH:mm');
};

export const pluckValue = (arr = []) =>
  (!isEmpty(arr) && !isNil(arr) && pluck('value', arr)) || undefined;
export const splitHomeAwayTasks = (arr = []) => {
  const home = filter(propEq('teamSide', 'HOME'), arr);
  const away = difference(arr, home);
  return [home, away];
};

export const splitMyTasksMatesTasks = (arr = [], myId) => {
  const myTasks = filter(pathEq(['member', 'user', 'id'], myId), arr);
  const matesTasks = difference(arr, myTasks);
  return [myTasks, matesTasks];
};
export const getChanges = (oldArray, newArray) => {
  let i;
  let item;
  let j;
  let len;
  const changes = [];
  if (JSON.stringify(oldArray) === JSON.stringify(newArray)) {
    return false;
  }
  // eslint-disable-next-line no-plusplus, no-multi-assign
  for (i = j = 0, len = newArray.length; j < len; i = ++j) {
    item = newArray[i];
    if (JSON.stringify(item) !== JSON.stringify(oldArray[i])) {
      changes.push(item);
    }
  }
  return changes;
};

// map(renameKeys({ id: 'taskId' }), updatedTasks)
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

export const camelize = (str = '') => {
  return str?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const getIssueReason = (type = '') => {
  if (QUALITY_ISSUE_LIST.includes(type)) {
    return 'QUALITY_ISSUE';
  }
  if (INFO_ISSUE_LIST.includes(type)) {
    return 'INFO_ISSUE';
  }
  return type;
};

export const formatTimeFromTimePicker = (x) =>
  `${x.hour.toString().padStart(2, '0')}:${x.minute
    .toString()
    .padStart(2, '0')}:${x.second.toString().padStart(2, '0')}`;

export const splitStatues = (arr = []) => {
  if (isEmpty(arr)) return [undefined, undefined];
  const statuses = defaultTo(
    undefined,
    filter((x) => STATUS.includes(x), arr)
  );
  const collectionStatus = defaultTo(undefined, difference(arr, statuses));
  return [statuses, collectionStatus];
};

export const divideCollectorTasks = (
  collectorTasks = false,
  offlineTasks = [],
  userId = ''
) => {
  if (collectorTasks) {
    const tasks = splitMyTasksMatesTasks(offlineTasks, userId);
    return tasks || [];
  }
  return splitHomeAwayTasks(offlineTasks);
};

export const getAvatarColor = () => {
  const colors = [
    '#08A764',
    '#CFD242',
    '#18A0FB',
    '#FBBB18',
    '#A7082E',
    '#42CAD2',
    '#FB4E18',
    '#FB186A',
    '#077648',
    '#1E1ECC',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const isSameDate = (date1, date2) => moment(date1).isSame(date2);

export const formatEta = (eta) => {
  return moment(eta, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY - HH:mm');
};
export const formatEtaLocal = (eta) => {
  return moment
    .utc(eta, 'YYYY-MM-DD HH:mm')
    .local()
    .format('DD.MM.YYYY - HH:mm');
};
export const isAfter = (
  date1,
  date2,
  date1Format = 'YYYY-MM-DD HH:mm',
  date2Format = 'DD.MM.YYYY - HH:mm'
) => {
  if (!date1 || !date2) return;
  const moment1 = moment(date1, date1Format);
  const moment2 = moment(date2, date2Format);
  /* eslint-disable consistent-return */
  return moment1.isAfter(moment2);
};

export const hasGraphQlErrors = (graphQLErrors, code) => {
  return equals(
    path(['extensions', 'exception', 'code'], head(graphQLErrors)),
    code
  );
};

export const getPrioritiesFromCategories = (categories = [], categoryId) => {
  if (!categories) return [];
  const priorityCategory = categories.find((c) =>
    equals(prop('id', c), categoryId)
  );
  const priorities = priorityCategory?.priorities;
  return (priorities && sortBy(prop('slaOffset'), priorities)) || [];
};

export const getLinesBySport = (lines = [], sport) =>
  sport ? filter(propEq('sport', sport))(lines) : [];

export const getFunctions = (lines = [], sport, lineId) => {
  if (sport && lineId) {
    const functions = pipe(
      filter(propEq('sport', sport)),
      filter(propEq('id', lineId)),
      head,
      prop('groups')
    )(lines);
    return functions;
  }
  return [];
};
export const getJobTitles = (lines = [], sport, lineId) => {
  if (!isEmpty(lines) && sport && lineId) {
    const jobTitles = pipe(
      filter(propEq('sport', sport)),
      filter(propEq('id', lineId)),
      head,
      prop('role'),
      pluck(['jobTitleRole']),
      flatten
    )(lines);
    return map(renameKeys({ jobTitle: 'name' }), jobTitles);
  }
  return [];
};

export const mapSportsValues = (sport = '') => prop(toLower(sport), SPORTS);

export const providerTypesItems = (providerTypes = [], currentValue) =>
  providerTypes.map((providerType) => {
    return {
      isActive: includes(currentValue, providerType),
      text: title(providerType),
      value: providerType,
      icon: prop(providerType, providerTypeIconMapper),
    };
  });

export const locationsItems = (locations = [], currentValue) =>
  locations.map((location) => {
    const { id, HQ, name } = location;
    return {
      isActive: equals(currentValue, id),
      text: `${title(HQ)} - ${title(name)}`,
      value: id,
      icon: 'map-marker',
    };
  });

export const allUniqueNoNils = (arr) => {
  const noNilArr = arr.filter(Boolean);
  return new Set(noNilArr).size === noNilArr.length;
};

export const getShiftEta = (squadsActiveDate, startAt) => {
  const shiftTime = 5;
  return moment(`${squadsActiveDate} ${startAt}`)
    .utc()
    .add(shiftTime, 'h')
    .format('DD/MM/YYYY HH:mm');
};
export const rankMapper = (shift) => {
  const shiftsRanks = {
    overnight2: 1,
    morning: 2,
    morning2: 3,
    night: 4,
    night2: 5,
    overnight: 6,
  };
  return {
    ...shift,
    rank: shiftsRanks[shift?.shiftType?.toLowerCase()] ?? 0,
  };
};
export const convertVODToBool = (arr = []) => {
  if (arr.length === 1 && arr[0] === VOD_STATUS[0]) return true;
  if (arr.length === 1 && arr[0] === VOD_STATUS[1]) return false;
  return undefined;
};

export const includesAllItems = (items = [], list = []) => {
  if (items.length && list.length) {
    return items.every((element) => list.includes(element));
  }

  return false;
};

export const textToBase64Img = (text) => {
  const canvas = document.createElement('canvas');
  canvas.height = 50;
  canvas.width = 100;
  const ctx = canvas.getContext('2d');
  ctx.font = '12px Arial';
  ctx.fillText(text, 10, 25);
  const dataUrl = canvas.toDataURL();
  return dataUrl;
};

export const isVideo = (userLineName) => {
  return equals(userLineName, USER_LINE_NAMES.VIDEO);
};

export const isMatchHasSquadShift = (match) => {
  return !!path(['squadShifts', '0', 'squadShift', 'id'], match);
};

export const getSquadShiftId = (match) => {
  return path(['squadShifts', '0', 'squadShift', 'id'], match);
};
export const sortDates = (dates = []) =>
  sort((a, b) => new Date(b) - new Date(a), dates);

export const compareMatchKoAndShiftEnd = (
  matchKo,
  { startAt, endAt, squadsActiveDate }
) => {
  const matchKoDate = moment(matchKo);
  const startTime = moment(startAt, 'hh:mm:ss');
  const endTime = moment(endAt, 'hh:mm:ss');
  const shiftEndDateTime = startTime.isAfter(endTime)
    ? moment(`${squadsActiveDate}T${endAt}`).add(1, 'd')
    : moment(`${squadsActiveDate}T${endAt}`);
  return matchKoDate.isBefore(shiftEndDateTime);
};

export const returnIf = (condition, arg) => (condition ? arg : undefined);
export const isNilOrEmpty = either(isNil, isEmpty);
export const isNotNilOrEmpty = complement(isNilOrEmpty);
export const isString = is(String);
export const isNotString = complement(isString);
export const isEmptyString = compose(isEmpty, trim);
export const uniqById = (list) => uniqWith((a, b) => a.id === b.id, list);
// sort the providers list so we have the default as the first one
export const sortProviders = (providers = []) => {
  return sort((p1, p2) => p2?.isDefault - p1?.isDefault, providers).map(
    (provider, index) => ({
      ...provider,
      order: !provider.isDefault && !index ? index + 2 : index + 1,
    })
  );
};
