import React, { useState, useCallback } from 'react';
import { Dialog, TextArea } from '@blueprintjs/core';
import { Button, Dropdown, Icon, Label, ShiftMatchCard } from 'components';
import { useFormik } from 'formik';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { isEmpty, path, isNil, trim, equals } from 'ramda';
import classNames from 'classnames';
import { isEmptyString, isVideo } from 'utils/helpers';
import {
  REPORT_ISSUE_MUTATION,
  SHIFT_MATCHES_QUERY,
  UPDATE_ISSUE_MUTATION,
} from 'gql';
import { useMutation } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { useProfile } from 'providers/profileProvider';
import { RenderIf } from 'config';
import mapIssueVariantToComponent from './partials';
import {
  getIssuesDropDownOptions,
  getReportValidationSchema,
  offlineDetailsFormSubmit,
  reportIssueInitialValues,
  videoDetailsFormSubmit,
} from '../helpers';

const IssuesManagement = () => {
  const { formatMessage } = useIntl();
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const [reset, setReset] = useState(false);

  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId, name: userLineName } = userLine ?? {};
  const isVideoUser = isVideo(userLineName);

  const { shiftMatch, squadId, activeDate, reason, shiftId } = path(
    ['payload'],
    modalState
  );
  const editedIssue = path(['payload', 'issue'], modalState) ?? {};
  const isEdit = !isEmpty(editedIssue) && !isNil(editedIssue);
  const initialValues = reportIssueInitialValues[userLineName]({
    ...editedIssue,
    reason,
  });
  const validationSchema = getReportValidationSchema(userLineName);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => (isEdit ? updateIssue(values) : reportIssue(values)),
  });

  const handleOnError = useCallback(
    () =>
      showToast({
        message: formatMessage({ id: 'containers.all.error' }),
        icon: 'error',
      }),
    []
  );
  const handleOnSuccess = useCallback(
    (message) =>
      showToast({
        message: formatMessage({
          id: message,
        }),
      }),
    []
  );
  const closeModal = useCallback(
    () =>
      modalDispatch({
        type: 'ClOSE_ALL',
      }),
    []
  );

  const [reportIssueMutation, { loading }] = useMutation(
    REPORT_ISSUE_MUTATION,
    {
      refetchQueries: [
        {
          query: SHIFT_MATCHES_QUERY,
          variables: {
            date: activeDate,
            lineId,
            hasAssignedPC: isVideoUser,
            shiftId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onError: handleOnError,
      onCompleted: () => {
        reset ? formik.resetForm() : closeModal();
        handleOnSuccess('containers.dashboards.report.success');
      },
    }
  );
  const [updateIssueMutation, { loading: updateLoading }] = useMutation(
    UPDATE_ISSUE_MUTATION,
    {
      refetchQueries: [
        {
          query: SHIFT_MATCHES_QUERY,
          variables: { date: activeDate, lineId, hasAssignedPC: isVideoUser },
        },
      ],
      awaitRefetchQueries: true,
      onError: handleOnError,
      onCompleted: () => {
        closeModal();
        handleOnSuccess('containers.dashboards.update.success');
      },
    }
  );
  const reportIssue = (values) =>
    reportIssueMutation({
      variables: {
        input: {
          matchId: shiftMatch?.id,
          type: values.type || values.reason,
          comment: !isEmptyString(values.comment)
            ? trim(values.comment)
            : undefined,
          details: isVideoUser
            ? videoDetailsFormSubmit(values)
            : offlineDetailsFormSubmit(values),
          squadId,
        },
      },
    });
  const updateIssue = (values) =>
    updateIssueMutation({
      variables: {
        input: {
          id: values.id,
          comment: !equals(
            trim(editedIssue?.comment ?? ''),
            trim(values?.comment)
          )
            ? trim(values.comment)
            : undefined,
          details: isVideoUser
            ? videoDetailsFormSubmit(values)
            : offlineDetailsFormSubmit(values),
          squadId,
          matchId: shiftMatch?.id,
        },
      },
    });
  const setFormikField = (field, value) => formik.setFieldValue(field, value);
  const issuesDropDownOptions = getIssuesDropDownOptions({
    isVideoUser,
    formatMessage,
    isEdit,
  });
  const onChangeReason = ({ value }) => {
    formik.setFieldValue('reason', value);
    formik.setFieldValue('type', value);
    formik.setErrors({});
    formik.setTouched({});
  };
  const onChangeType = ({ value }) => {
    formik.setFieldValue('type', value);
    formik.setFieldValue('details', {});
    formik.setErrors({});
    formik.setTouched({});
  };

  const isReportDisabled = isVideoUser
    ? !formik.values.type
    : !formik.values.reason;
  const isSubmitButtonDisabled = isEdit ? !formik.dirty : isReportDisabled;
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon
            customIcon={isEdit ? 'edit' : 'reported'}
            size={16}
            data-testid="modalHeader-icon"
          />
          <div data-testid="modalHeader-title">
            {isEdit ? (
              <FormattedMessage id="containers.dashboards.update.issue.title" />
            ) : (
              <FormattedMessage id="containers.dashboards.report.issue.title" />
            )}
          </div>
        </div>
      }
      onClose={closeModal}
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
      className="report-issue"
    >
      <div className="mgt-20 mgb-13 pl-32 pr-32">
        <ShiftMatchCard shiftMatch={shiftMatch} />
        <Label
          variant="Label"
          labelText="Report Issue"
          className="pt-30 pb-20"
        />
        <div className={classNames(['flex', 'column', 'gap-10'])}>
          <RenderIf condition={!isVideoUser}>
            <Dropdown
              value={formik.values.reason}
              onChange={onChangeReason}
              error={formik.touched.reason && formik.errors.reason}
              {...issuesDropDownOptions}
            />
          </RenderIf>
          <RenderIf condition={isVideoUser}>
            <Dropdown
              value={formik.values.type}
              onChange={onChangeType}
              error={formik.touched.type && formik.errors.type}
              {...issuesDropDownOptions}
            />
          </RenderIf>

          {mapIssueVariantToComponent({
            variant: isVideoUser ? formik.values.type : formik.values.reason,
            setFieldValue: setFormikField,
            values: formik.values,
            errors: formik.errors,
            touched: formik.touched,
            shiftMatch,
            resetErrors: formik.setErrors,
            resetTouched: formik.setTouched,
            isEdit,
          })}
          <>
            <TextArea
              growVertically={false}
              large
              onChange={(e) => {
                formik.setFieldValue('comment', e.target.value);
                formik.setFieldTouched('comment', true, false);
              }}
              value={formik.values.comment}
              style={{ resize: 'none', width: '300px' }}
              placeholder="Please explain the issue."
              cols={30}
              rows={4}
              data-testid="dashboard-reportIssue-comment"
            />
            <RenderIf
              condition={formik.touched.comment && formik.errors.comment}
            >
              <span
                className="error-text"
                data-testid="dashboard-reportIssue-commentError"
              >
                {formik.errors.comment}
              </span>
            </RenderIf>
          </>
        </div>
      </div>
      <div
        className={classNames('bp3-dialog-footer-actions', {
          report: !isEdit,
        })}
        data-testid="reportIssue-footer"
      >
        {!isEdit ? (
          <Button
            icon={<Icon customIcon="reported" />}
            disabled={isVideoUser ? !formik.values.type : !formik.values.reason}
            text="Report another Issue"
            onClick={() => {
              formik.submitForm();
              setReset(true);
            }}
            intent="Primary"
            minimal
            data-testid="dashboard-report-reportAnotherIssue"
          />
        ) : (
          <></>
        )}
        <div>
          <Button
            outlined
            text={formatMessage({ id: 'containers.all.btn.cancel' })}
            onClick={closeModal}
            data-testid="cancel-button-footer"
          />

          <Button
            intent="Primary"
            text={
              isEdit ? (
                <FormattedMessage id="containers.all.btn.saveChanges" />
              ) : (
                <FormattedMessage id="containers.dashboards.report.issue.title" />
              )
            }
            onClick={() => {
              formik.submitForm();
              setReset(false);
            }}
            loading={isEdit ? updateLoading : loading}
            disabled={isSubmitButtonDisabled}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default IssuesManagement;
