import React from 'react';
import classNames from 'classnames';
import { Divider, Label, RadioGroup, TimePicker } from 'components';
import { BAD_VIDEO, HALF, QUALITY_ISSUE } from 'utils/enums';
import { RenderIf } from 'config';
import { FormattedMessage, useIntl } from 'react-intl';

const QualityIssue = ({
  setFieldValue,
  badShirt,
  badVideo,
  homeTeam = '',
  awayTeam = '',
  errors = {},
  touched = {},
  type,
  resetErrors = () => {},
  resetTouched = () => {},
  isEdit = false,
}) => {
  const homeAway = [
    { text: `Home-${homeTeam}`, value: 'HOME' },
    { text: `Away-${awayTeam}`, value: 'AWAY' },
  ];
  const { formatMessage } = useIntl();
  return (
    <div className="quality-issue-container">
      <RadioGroup
        items={QUALITY_ISSUE}
        selectedValue={type}
        onRadioChange={(data) => {
          setFieldValue('type', data);
          setFieldValue('badShirt', { teamSide: '' });
          setFieldValue('badVideo', {
            isWholeMatch: undefined,
            half: undefined,
            to: undefined,
            from: undefined,
          });
          resetErrors({});
          resetTouched({});
        }}
        name="TeamType"
        className="quality-issue-group"
        error={touched?.type && errors?.type}
        disabled={isEdit}
      />
      <RenderIf condition={type === 'BAD_SHIRT'}>
        <div className="bad-shirt-container">
          <Divider />
          <RadioGroup
            items={homeAway}
            selectedValue={badShirt.teamSide}
            onRadioChange={(data) => setFieldValue('badShirt.teamSide', data)}
            name="team-side-bad-shirt"
            className="quality-issue-group"
            error={touched?.badShirt?.teamSide && errors?.badShirt?.teamSide}
          />
        </div>
      </RenderIf>
      <RenderIf condition={type === 'BAD_VIDEO'}>
        <div className="bad-shirt-container">
          <Divider />
          <RadioGroup
            items={BAD_VIDEO}
            selectedValue={badVideo.isWholeMatch}
            onRadioChange={(data) => {
              setFieldValue('badVideo.isWholeMatch', data);
            }}
            name="whole-match"
            className="quality-issue-group"
            error={
              touched?.badVideo?.isWholeMatch && errors?.badVideo?.isWholeMatch
            }
          />
          <RenderIf condition={badVideo.isWholeMatch === false}>
            <Divider />
            <RadioGroup
              items={HALF}
              selectedValue={badVideo.half}
              onRadioChange={(data) => {
                setFieldValue('badVideo.half', data);
              }}
              name="half"
              className="missing-player-issue-group flex column"
              error={touched?.badVideo?.half && errors?.badVideo?.half}
            />
          </RenderIf>

          <RenderIf condition={badVideo?.half}>
            <div className="time-stamp-container">
              <div className={classNames(['flex', 'gap-15', 'items-center'])}>
                <Label
                  labelText={`${formatMessage({
                    id: 'containers.all.timeStamp',
                  })}:`}
                  variant="Baisc"
                />
                <Label
                  labelText={`${formatMessage({ id: 'containers.all.from' })}:`}
                  variant="Baisc"
                  className="timeStamp"
                />
                <TimePicker
                  onChange={(data) => setFieldValue('badVideo.from', data)}
                  value={badVideo.from}
                  error={touched?.badVideo?.from && errors?.badVideo?.from}
                  name="from"
                />
                <Label
                  labelText={`${formatMessage({ id: 'containers.all.to' })}:`}
                  variant="Baisc"
                  className="timeStamp"
                />
                <TimePicker
                  onChange={(data) => setFieldValue('badVideo.to', data)}
                  value={badVideo.to}
                  error={touched?.badVideo?.to && errors?.badVideo?.to}
                  name="to"
                />
              </div>
              <span className="timestamp-info-container">
                <FormattedMessage id="containers.all.timeStamp.warning" />
              </span>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

export default QualityIssue;
