import { gql } from '@apollo/client';

export default gql`
  query MatchesDetailsByLine($matchesIds: [Int!]!, $lineId: String) {
    matchesDetailsByLine(matchesIds: $matchesIds, lineId: $lineId) {
      matchId: id
      squadShifts {
        squadShift {
          squad {
            id
            name
          }
        }
      }

      coordinator {
        id
        firstName
        email
        hrCode
      }

      pcs {
        pc {
          id
          number
          location {
            HQ
            id
            name
          }
        }
      }
    }
  }
`;
