import React from 'react';
import { RenderIf } from 'config';
import { Col, Row } from 'react-flexbox-grid';
import { Text, Label, Icon } from 'components';
import { FormattedMessage } from 'react-intl';
import {
  formatKO,
  acronym,
  removeDateFromString,
  formatEtaLocal,
  title,
} from 'utils/helpers';
import classNames from 'classnames';
import VideoStatusDropdown from 'containers/Dashboard/helpers/VideoStatusDropdown';
import { equals } from 'ramda';

const MatchAssignmentVariant = ({
  acquisitionType,
  plannedAcquisitionDate,
}) => {
  return (
    <>
      <RenderIf condition={acquisitionType}>
        <Col lg={3} md={4} sm={12} className="flex">
          <Text variant="body3" className="color-qs">
            <FormattedMessage id="containers.dashboards.videoCard.acq" />
          </Text>
          <Text variant="body3">{title(acquisitionType)}</Text>
        </Col>
      </RenderIf>
      <RenderIf condition={plannedAcquisitionDate}>
        <Col lg={4} md={4} sm={12} className="flex">
          <Text variant="body3" className="color-qs">
            <FormattedMessage id="containers.dashboards.videoCard.plannedAcqTime" />
          </Text>
          <Text variant="body3">{formatEtaLocal(plannedAcquisitionDate)}</Text>
        </Col>
      </RenderIf>
    </>
  );
};
const VideoEtaRender = ({ videoETA, matchAssignmentVersion }) => {
  return (
    <>
      <RenderIf condition={videoETA}>
        <Col
          lg={matchAssignmentVersion ? 3 : 5}
          md={3}
          sm={12}
          className="flex "
        >
          <Text variant="body3" className="color-qs">
            <FormattedMessage id="containers.video.Card.attributes.videoETA" />
          </Text>
          &nbsp;
          <Text variant="body3">{formatEtaLocal(videoETA)}</Text>
        </Col>
      </RenderIf>
    </>
  );
};
const VideoShiftMatchCard = ({
  shiftMatch,
  index,
  matchAssignmentVersion = false,
}) => {
  const {
    id,
    priority,
    competitionSeason,
    date,
    name,
    kickOff,
    acquisitionType,
    plannedAcquisitionDate,
    area,
    videoETA,
    videoStatus,
    videoIssueStatus,
  } = shiftMatch ?? {};
  const { code: countryCode } = area;
  const { competitionName = '', seasonName = '' } = competitionSeason ?? {};
  const { slaOffset, value = '' } = priority ?? {};
  const teamsNameWIthoutDate = removeDateFromString(name);
  const splittedTeams = teamsNameWIthoutDate.split('vs.');
  return (
    <Row center="lg">
      <Col lg={1} md={2} sm={12}>
        <RenderIf condition={value && slaOffset}>
          <Label
            variant="ColoredLabel"
            labelText={`${acronym(value)}:${slaOffset}`}
            index={index}
            screenType="teams"
            className="mg-0 p-0"
          />
        </RenderIf>
      </Col>
      <Col lg={matchAssignmentVersion ? 11 : 7} md={10} sm={12}>
        <Row className="pb-10 items-center">
          <Col
            lg={matchAssignmentVersion ? 2 : 3}
            md={2}
            sm={12}
            className="flex"
          >
            <Text variant="body3" className="color-qs">
              <FormattedMessage id="containers.video.Card.attributes.matchId" />
            </Text>
            <Text variant="body3">{id}</Text>
          </Col>
          <Col lg={matchAssignmentVersion ? 3 : 4} md={3} sm={12}>
            <Text
              variant="body3"
              className="ellipsis"
              title={`${competitionName} ${seasonName}`}
            >
              {`${competitionName} ${seasonName}`}
            </Text>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <span
              className={classNames([
                'fflag',
                'ff-lg ',
                'ff-round',
                `fflag-${countryCode}`,
              ])}
            />
          </Col>
          <Col
            lg={matchAssignmentVersion ? 3 : 4}
            md={3}
            sm={12}
            className="flex"
          >
            <Text variant="body3" className="color-qs">
              <FormattedMessage id="containers.video.Card.attributes.ko" />
            </Text>
            <Text variant="body3">{formatKO(date, kickOff)}</Text>
          </Col>
          <RenderIf condition={matchAssignmentVersion}>
            <VideoEtaRender
              videoETA={videoETA}
              matchAssignmentVersion={matchAssignmentVersion}
            />
          </RenderIf>
        </Row>
        {/* Second Row */}
        <Row>
          <Col lg={5} md={4} sm={12} className="flex">
            <Text variant="body1">{splittedTeams[0]}</Text>
            &nbsp;
            <Text className="color-qs">vs.</Text>
            &nbsp;
            <Text variant="body1">{splittedTeams[1]}</Text>
          </Col>
          <RenderIf condition={!matchAssignmentVersion}>
            <VideoEtaRender
              videoETA={videoETA}
              matchAssignmentVersion={matchAssignmentVersion}
            />
          </RenderIf>
          <RenderIf condition={matchAssignmentVersion}>
            <MatchAssignmentVariant
              plannedAcquisitionDate={plannedAcquisitionDate}
              acquisitionType={acquisitionType}
            />
          </RenderIf>
        </Row>
      </Col>
      <RenderIf
        condition={
          !matchAssignmentVersion && !!videoStatus && !!videoIssueStatus
        }
      >
        <Col
          lg={3}
          md={2}
          sm={12}
          className={classNames('flex gap-10 items-center')}
        >
          <VideoStatusDropdown
            videoIssueStatus={videoIssueStatus}
            videoStatus={videoStatus}
            disabled
            className="video-match-status-label"
          />
          <RenderIf condition={equals(videoIssueStatus, 'REPORTED')}>
            <Icon customIcon="reported" size={16} />
          </RenderIf>
        </Col>
      </RenderIf>
    </Row>
  );
};

export default VideoShiftMatchCard;
