/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import { Button, TextInput, Dropdown, Icon, Divider, Avatar } from 'components';
import {
  CREATE_USER_MUTATION,
  UPDATE_USER_MUTATION,
  USERS_QUERY,
  LINES_QUERY,
  JOB_TITLES_BY_ROLE_QUERY,
} from 'gql';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { JOB_TYPES, POWERS, SPORTS, SPORTS_LIST } from 'utils/enums';
import { USER_VALIDATION } from 'validations';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { Dialog } from '@blueprintjs/core';
import {
  getChangedValues,
  getFunctions,
  getJobTitles,
  getLinesBySport,
  optionsFromArr,
  title,
} from 'utils/helpers';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  prop,
  map,
  toLower,
  toUpper,
  isNil,
  propEq,
  filter,
  pluck,
  defaultTo,
  isEmpty,
  uniq,
  pipe,
} from 'ramda';
import { useProfile } from 'providers/profileProvider';

const CreateEditForm = () => {
  const {
    modalDispatch,
    modalState: { variables, user, open, refetch },
  } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const {
    profile: { jobTitleRole, lineFunction },
  } = useProfile();
  const { name: userRoleName, line } = jobTitleRole?.role;
  const { id: userlineId, sport: userSport } = line || {};

  const isSuperAdmin = userRoleName === 'super-admin';
  const formSportAndLine = isNil(user)
    ? {
        sport: !isSuperAdmin ? userSport : '',
        lineId: !isSuperAdmin ? userlineId : '',
        lineFunctionId: !isSuperAdmin ? lineFunction?.id : undefined,
      }
    : {
        sport: user?.jobTitleRole?.role?.line?.sport || '',
        lineId: user?.jobTitleRole?.role?.line?.id || '',
        lineFunctionId: user?.lineFunction?.id || undefined,
      };
  const initialValues = {
    id: user?.id || undefined,
    legacyId: user?.legacyId,
    hrCode: user?.hrCode || '',
    firstName: user?.firstName || '',
    middleName: user?.middleName || '',
    lastName: user?.lastName || '',
    jobTitleId: user?.jobTitleId || '',
    jobType: user?.jobType || '',
    power: user?.power || '',
    email: user?.email || '',
    mobileNumber: user?.mobileNumber || '',
    hiringDate: user?.hiringDate || '',
    ...formSportAndLine,
  };
  const [createUserMutation, { loading: createLoading }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onError: (err) => showToast({ message: err.message, icon: 'error' }),
      update(dataStore, { data: { createUser } }) {
        const usersData = dataStore.readQuery({
          query: USERS_QUERY,
          variables,
        });
        dataStore.writeQuery({
          query: USERS_QUERY,
          variables,
          data: {
            users: {
              users: [createUser, ...usersData.users.users],
              totalCount: usersData.users.totalCount + 1,
            },
          },
        });
      },
      onCompleted: () => {
        const message = formatMessage({
          id: 'containers.users.form.successCreate',
        });
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message });
      },
    }
  );
  const [updateUserMutation, { loading: updateLoading }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onError: (err) => showToast({ message: err.message, icon: 'error' }),
      onCompleted: () => {
        const message = formatMessage({
          id: 'containers.users.form.successEdit',
        });
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message });
        refetch();
      },
    }
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: USER_VALIDATION,
    onSubmit: (values) => {
      const { id, legacyId, lineId, sport, lineFunctionId, ...rest } = values;
      const input = {
        id,
        legacyId,
        lineFunctionId,
        ...getChangedValues(rest, initialValues),
      };
      id
        ? updateUserMutation({ variables: { input } })
        : createUserMutation({ variables: { input } });
    },
  });
  const { data: { jobTitlesByRole = [] } = {}, loading: jobTitlesLoading } =
    useQuery(JOB_TITLES_BY_ROLE_QUERY);
  // lines query is actually not used and can be removed, previously used for getting sports list
  const { data: { lines = [] } = {}, loading: linesLoading } =
    useQuery(LINES_QUERY);
  const loading = jobTitlesLoading || linesLoading;
  const sportsList = pipe(pluck('sport'), uniq)(jobTitlesByRole) || SPORTS_LIST;
  const createTitle = (
    <div className="create-edit-header">
      <Icon
        icon="plus"
        color="#999999"
        size={14}
        data-testid="modalHeader-icon"
      />
      <p data-testid="modalHeader-title">
        <FormattedMessage id="containers.users.add" />
      </p>
    </div>
  );
  const EditTitle = (
    <div className="create-edit-header">
      <Icon
        icon="edit"
        color="#999999"
        size={14}
        data-testid="modalHeader-icon"
      />
      <p data-testid="modalHeader-title">
        <FormattedMessage id="containers.users.edit" />
      </p>
    </div>
  );
  const renderSport = (sportsList = []) => {
    const mapSportsValues = map(
      (sport) => prop(toLower(sport), SPORTS),
      sportsList
    );
    return optionsFromArr(mapSportsValues);
  };
  return (
    <Dialog
      isOpen={open}
      title={user ? EditTitle : createTitle}
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="bp3-dialog-body">
        <div className="create-edit-form-container">
          <div className="create-edit-section-title">
            <FormattedMessage id="containers.users.form.empDetails" />
          </div>
          <div className="create-edit-sec-row">
            <TextInput
              required
              type="text"
              labelText={formatMessage({ id: 'containers.users.form.hr' })}
              placeholder={formatMessage({ id: 'containers.users.form.hr' })}
              value={formik.values.hrCode}
              onChange={formik.handleChange('hrCode')}
              error={formik.touched.hrCode && formik.errors.hrCode}
              className={classNames({
                'selected-input-highlight': formik.values.hrCode,
              })}
              data-testid="users-create_edit-hrCode"
              name="hrCode"
            />
          </div>
          <div className="create-edit-sec-row">
            <TextInput
              required
              type="text"
              labelText={formatMessage({
                id: 'containers.users.form.firstName',
              })}
              placeholder={formatMessage({
                id: 'containers.users.form.firstName',
              })}
              value={formik.values.firstName}
              onChange={(value) =>
                formik.setFieldValue('firstName', title(value))
              }
              error={formik.touched.firstName && formik.errors.firstName}
              className={classNames({
                'selected-input-highlight': formik.values.firstName,
              })}
              data-testid="users-create_edit-first_name"
              name="firstName"
            />
            <TextInput
              type="text"
              labelText={formatMessage({
                id: 'containers.users.form.middleName',
              })}
              placeholder={formatMessage({
                id: 'containers.users.form.middleName',
              })}
              value={formik.values.middleName}
              onChange={(value) =>
                formik.setFieldValue('middleName', title(value))
              }
              error={formik.touched.middleName && formik.errors.middleName}
              className={classNames({
                'selected-input-highlight': formik.values.middleName,
              })}
              data-testid="users-create_edit-middle_name"
              name="middleName"
            />

            <TextInput
              required
              type="text"
              labelText={formatMessage({
                id: 'containers.users.form.lastName',
              })}
              placeholder={formatMessage({
                id: 'containers.users.form.lastName',
              })}
              value={formik.values.lastName}
              onChange={(value) =>
                formik.setFieldValue('lastName', title(value))
              }
              error={formik.touched.lastName && formik.errors.lastName}
              className={classNames({
                'selected-input-highlight': formik.values.lastName,
              })}
              data-testid="users-create_edit-last_name"
              name="lastName"
            />
            {formik.values.firstName && formik.values.lastName && (
              <Avatar
                size={36}
                name={`${formik.values.firstName} ${formik.values.lastName}`}
              />
            )}
          </div>
          <Dropdown
            items={renderSport(sportsList)}
            value={prop(toLower(formik.values.sport), SPORTS) || ''}
            onChange={({ value }) => {
              formik.setValues({
                ...formik?.values,
                sport: toUpper(value),
                lineId: undefined,
                lineFunctionId: undefined,
                jobTitleId: undefined,
              });
            }}
            error={formik.touched.sport && formik.errors.sport}
            buttonText="Sport"
            name="sports"
            data-testid="users-create_edit-sport"
            disabled={!isSuperAdmin}
            disableDropdownButton={!isSuperAdmin}
            loading={loading}
          />

          <div className="create-edit-sec-row">
            <Dropdown
              items={optionsFromArr(
                getLinesBySport(jobTitlesByRole, formik.values.sport) || []
              )}
              value={formik.values.lineId || ''}
              onChange={({ value }) => {
                formik.setValues({
                  ...formik?.values,
                  lineId: value,
                  lineFunctionId: undefined,
                  jobTitleId: undefined,
                });
              }}
              error={formik.touched.lineId && formik.errors.lineId}
              buttonText={formatMessage({
                id: 'containers.users.form.businessLine',
              })}
              data-testid="users-create_edit-BL"
              name="line"
              loading={loading}
              disabled={!isSuperAdmin || !formik.values.sport}
              disableDropdownButton={!isSuperAdmin || !formik.values.sport}
            />
            <Dropdown
              items={optionsFromArr(
                getFunctions(
                  jobTitlesByRole,
                  formik.values.sport,
                  formik.values.lineId
                )
              )}
              value={formik.values.lineFunctionId || ''}
              onChange={({ value }) =>
                formik.setFieldValue('lineFunctionId', value)
              }
              error={
                formik.touched.lineFunctionId && formik.errors.lineFunctionId
              }
              buttonText={formatMessage({
                id: 'containers.users.form.team',
              })}
              data-testid="users-create_edit-function"
              name="lineFunction"
              disableDropdownButton={
                !isSuperAdmin ||
                !formik.values.sport ||
                !formik.values.lineId ||
                !isNil(lineFunction)
              }
              loading={loading}
            />

            <Dropdown
              items={optionsFromArr(
                getJobTitles(
                  jobTitlesByRole,
                  formik.values.sport,
                  formik.values.lineId
                )
              )}
              value={formik.values.jobTitleId || ''}
              onChange={({ value }) =>
                formik.setFieldValue('jobTitleId', value)
              }
              error={formik.touched.jobTitleId && formik.errors.jobTitleId}
              buttonText={formatMessage({ id: 'containers.users.jobTitle' })}
              data-testid="users-create_edit-job_title"
              name="jobTitle"
              disableDropdownButton={!formik.values.lineId}
              loading={loading}
            />
            <Dropdown
              items={optionsFromArr(JOB_TYPES)}
              value={formik.values.jobType || ''}
              onChange={({ value }) => formik.setFieldValue('jobType', value)}
              error={formik.touched.jobType && formik.errors.jobType}
              buttonText={formatMessage({
                id: 'containers.users.form.jobType',
              })}
              loading={!JOB_TYPES}
              className={classNames({
                'selected-dropdown-highlight': formik.values.jobType,
              })}
              data-testid="users-create_edit-job_type"
              name="jobType"
            />
          </div>
          <div className="create-edit-sec-row">
            <Dropdown
              items={optionsFromArr(POWERS)}
              onChange={({ value }) => formik.setFieldValue('power', value)}
              error={formik.touched.power && formik.errors.power}
              buttonText={formatMessage({ id: 'containers.users.form.power' })}
              value={formik.values.power}
              loading={!POWERS}
              className={classNames({
                'selected-dropdown-highlight': formik.values.power,
              })}
              data-testid="users-create_edit-power"
              name="powers"
            />
          </div>
          <Divider />
          <div className="create-edit-form-container">
            <div className="create-edit-contact-title">
              <FormattedMessage id="containers.users.form.createTitle" />
            </div>
            <TextInput
              required
              type="text"
              labelText={formatMessage({ id: 'containers.users.form.email' })}
              placeholder={formatMessage({ id: 'containers.users.form.email' })}
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              error={formik.touched.email && formik.errors.email}
              className={classNames('mgb-10', {
                'selected-input-highlight': formik.values.email,
              })}
              data-testid="users-create_edit-email"
              name="email"
            />
            <TextInput
              required
              type="text"
              labelText={formatMessage({ id: 'containers.users.form.mobile' })}
              placeholder={formatMessage({
                id: 'containers.users.form.mobile',
              })}
              value={formik.values.mobileNumber}
              onChange={formik.handleChange('mobileNumber')}
              error={formik.touched.mobileNumber && formik.errors.mobileNumber}
              maxLength="11"
              className={classNames('mgb-10', {
                'selected-input-highlight': formik.values.mobileNumber,
              })}
              data-testid="users-create_edit-phone"
              name="phone"
            />
            <TextInput
              required
              type="date"
              labelText={formatMessage({
                id: 'containers.users.form.hiringDate',
              })}
              placeholder={formatMessage({
                id: 'containers.users.form.hiringDate',
              })}
              value={formik.values.hiringDate}
              onChange={(value) => formik.setFieldValue('hiringDate', value)}
              error={formik.touched.hiringDate && formik.errors.hiringDate}
              htmlpattern="\y{4}-\m{2}-\d{2}"
              className={classNames('mgb-10', {
                'selected-input-highlight': formik.values.hiringDate,
              })}
              data-testid="users-create_edit-hiringDate"
              name="hiringDate"
            />
          </div>
        </div>
      </div>
      <div
        className="bp3-dialog-footer-actions"
        data-testid={user?.id ? `edit-footer` : 'create-footer'}
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.users.form.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.users.form.save' })}
          onClick={formik.submitForm}
          loading={createLoading || updateLoading}
          disabled={!formik.values}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default CreateEditForm;
