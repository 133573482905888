import React from 'react';
import { Dropdown } from 'components';
import { optionsFromArr } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import { useQuery } from '@apollo/client';
import { ACQUISITION_TYPES_QUERY } from 'gql';

const AcqTypeFilter = ({ values = [], onChange }) => {
  const { loading: acquisitionTypesLoading, data: acquisitionTypesData = {} } =
    useQuery(ACQUISITION_TYPES_QUERY, { fetchPolicy: 'cache-first' });
  const { acquisitionTypes = [] } = acquisitionTypesData;
  const { formatMessage } = useIntl();
  const buttonText = formatMessage({
    id: 'containers.acquisitionTypes.form.type',
  });
  return (
    <Dropdown
      items={optionsFromArr(acquisitionTypes)}
      values={values}
      onChange={onChange}
      multiSelect
      buttonText={buttonText}
      loading={acquisitionTypesLoading}
      disabled={isNil(acquisitionTypesData)}
      disableDropdownButton={isNil(acquisitionTypesData)}
      data-testid="providers-form-type"
      name="type"
    />
  );
};

export default AcqTypeFilter;
