import React, { useState } from 'react';
import { Dialog, Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';
import { path, prop, defaultTo, find, propEq, filter, length } from 'ramda';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Button, Label, ShiftMatchCard, Icon, Divider } from 'components';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { title } from 'utils/helpers';
import {
  MATCH_ISSUES_QUERY,
  SHIFT_MATCHES_QUERY,
  UNBLOCK_MATCH_MUTATION,
} from 'gql';
import { RenderIf } from 'config';
import { QUALITY_ISSUE_LIST } from 'utils/enums';
import { FormattedMessage, useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';

const BlockMatch = () => {
  const { formatMessage } = useIntl();
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;

  const { id: lineId } = userLine ?? {};

  const { shiftMatch, assignedSquadName, activeDate, shiftId } = path(
    ['payload'],
    modalState
  );
  const matchId = prop('id', shiftMatch);
  const [reportedIssuesExpanded, setReportedIssuesExpanded] = useState(true);
  const { data: matchIssuesData, loading: matchIssuesLoading } = useQuery(
    MATCH_ISSUES_QUERY,
    {
      variables: { matchId, lineId },
      fetchPolicy: 'network-only',
    }
  );
  const [unblockMatchMutation, { loading }] = useMutation(
    UNBLOCK_MATCH_MUTATION,
    {
      refetchQueries: [
        {
          query: SHIFT_MATCHES_QUERY,
          variables: { date: activeDate, lineId, shiftId },
        },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        showToast({
          message: formatMessage({ id: 'containers.all.error' }),
          icon: 'error',
        });
      },
      onCompleted: () => {
        showToast({
          message: formatMessage({
            id: 'containers.dashboards.unblock.success',
          }),
        });
        modalDispatch({ type: 'ClOSE_ALL' });
      },
    }
  );

  const isReported = (issue) =>
    issue.status !== 'RESOLVED' && issue.status !== 'BLOCKED';

  const blockingIssue = find(propEq('status', 'BLOCKED'))(
    defaultTo([], prop('matchIssues', matchIssuesData))
  );

  const reportedIssues = filter((issue) => isReported(issue))(
    defaultTo([], prop('matchIssues', matchIssuesData))
  );

  const qualityIssues = filter((issue) =>
    QUALITY_ISSUE_LIST.includes(issue.type)
  )(defaultTo([], reportedIssues));

  const hasQualityIssue = length(qualityIssues) > 0;

  const initialValues = {
    matchId,
    issueId: prop('id', blockingIssue),
    resolveAll: false,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (input) =>
      unblockMatchMutation({ variables: { input: { ...input, lineId } } }),
  });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="modal-title">
          <Icon data-testid="modalHeader-icon" customIcon="done2" size={16} />
          <div data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.unblock.title" />
          </div>
        </div>
      }
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
    >
      <div className={classNames(['pl-32', 'pt-14', 'pr-32'])}>
        <Label
          variant="Label"
          labelText={formatMessage(
            { id: 'containers.dashboards.squadAssigned' },
            { assignedSquadName }
          )}
        />
        <ShiftMatchCard shiftMatch={shiftMatch} className="mgt-15 mgb-15" />
        <RenderIf condition={matchIssuesLoading || reportedIssues.length > 0}>
          <Button
            minimal
            rightIcon={reportedIssuesExpanded ? 'chevron-up' : 'chevron-down'}
            onClick={() => setReportedIssuesExpanded(!reportedIssuesExpanded)}
            text="Reported Issues"
            loading={matchIssuesLoading}
            className="pl-0"
            data-testid="dashboard-reportedIssues-expand"
          />
        </RenderIf>
        <div className={classNames(['mgr-15'])}>
          {reportedIssuesExpanded &&
            reportedIssues &&
            reportedIssues.map((issue) => (
              <>
                <div
                  className={classNames(['mgb-15'])}
                  key={issue.id}
                  data-testid="dashboard-block-issueRow"
                >
                  <Grid className="p-0 mg-0 width-100">
                    <Row className="p-0 mg-0">
                      <Col sm={2} className="color-qs fs-12">
                        <FormattedMessage id="containers.all.reason" />
                      </Col>
                      <Col sm={2} className="color-qs fs-12">
                        <FormattedMessage id="containers.all.comment" />
                      </Col>
                    </Row>
                    <Row className="p-0 mg-0">
                      <Col sm={2}>{title(issue.type)}</Col>
                      <Col sm={7}>{title(issue.comment)}</Col>
                    </Row>
                  </Grid>
                </div>
                <Divider className="mgt-16 mgb-16" />
              </>
            ))}
          <div
            className="color-alizarin body-one-txt mgb-10"
            data-testid="dashboard-unblock-subTitle"
          >
            <FormattedMessage id="containers.dashboards.unblock.subTitle" />
          </div>
          <div
            className={classNames(['mgb-15'])}
            data-testid="dashboard-unblock-blockingIssueRow"
          >
            <Grid className="p-0 mg-0 width-100">
              <RenderIf condition={blockingIssue?.type}>
                <Row className="p-0 mg-0">
                  <Col sm={2}>
                    <FormattedMessage id="containers.all.reason" />
                  </Col>
                  <Col sm={2} className="body-two-txt color-qs">
                    {title(blockingIssue?.type)}
                  </Col>
                </Row>
              </RenderIf>
              <RenderIf condition={blockingIssue?.reporter}>
                <Row className="p-0 mg-0">
                  <Col sm={2}>
                    <FormattedMessage id="containers.all.reportedBy" />
                  </Col>
                  <Col sm={2} className="body-two-txt color-qs">
                    {blockingIssue?.reporter?.firstName}
                    &nbsp;
                    {blockingIssue?.reporter?.lastName}
                  </Col>
                </Row>
              </RenderIf>
              <RenderIf condition={blockingIssue?.comment}>
                <Row className="p-0 mg-0">
                  <Col sm={2}>
                    <FormattedMessage id="containers.all.comment" />
                  </Col>
                  <Col sm={2} className="body-two-txt color-qs">
                    {title(blockingIssue?.comment)}
                  </Col>
                </Row>
              </RenderIf>
            </Grid>
          </div>
          <RenderIf condition={reportedIssues.length > 0}>
            <div
              className="mgb-15 flex"
              data-testid="dashboard-unblock-resolveAllIssues"
            >
              <Checkbox
                checked={formik.values.resolveAll}
                label="Also resolve all issues"
                onChange={() =>
                  formik.setFieldValue('resolveAll', !formik.values.resolveAll)
                }
              />
              <RenderIf condition={hasQualityIssue && formik.values.resolveAll}>
                <div
                  className="flex mgl-12"
                  data-testid="dashboard-unblock-warning"
                >
                  <Icon customIcon="warning2" size={16} />
                  &nbsp;
                  <p>
                    <FormattedMessage id="containers.resolve.qualityIssue.warning" />
                  </p>
                </div>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
      </div>
      <div
        className={classNames(['bp3-dialog-footer-actions', 'mgt-30'])}
        data-testid="unblock-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          disabled={loading}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.all.unBlock' })}
          onClick={formik.submitForm}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default BlockMatch;
