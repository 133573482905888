import { gql } from '@apollo/client';
import SQUAD_FRAGMENT from '../fragments/squad';

export default gql`
  query squads(
    $queryParams: QueryParams
    $searchWord: String
    $lineFunctionId: String
    $lineId: String
    $sport: String
  ) {
    squads(
      queryParams: $queryParams
      searchWord: $searchWord
      lineFunctionId: $lineFunctionId
      lineId: $lineId
      sport: $sport
    ) {
      totalCount
      squads {
        ...SquadFragment
      }
    }
  }
  ${SQUAD_FRAGMENT}
`;
