import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { Button, Divider, Dropdown, Fab, Text, TextInput } from 'components';
import { optionsFromArr, toggleListItem } from 'utils/helpers';
import { path, pluck, isNil } from 'ramda';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { CREATE_SQUAD } from 'validations';
import {
  CREATE_SQUAD_MUTATION,
  USERS_QUERY,
  SQUADS_QUERY,
  LINES_QUERY,
} from 'gql';
import { useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';
import {
  SportsFilter,
  LinesFilter,
  FunctionsFilter,
} from 'components/Filters/filtersComponents/GenericFilters';

export default function CreateSquad() {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const intl = useIntl();
  const { data: { lines = [] } = {} } = useQuery(LINES_QUERY);
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole, lineFunction: userLineFunction } =
    profile;
  const { line: userLine = {} } = userJobTitleRole?.role;
  const { id: userLineId, sport: userSport } = userLine || {};
  const squadQueryVars = path(['payload', 'squadQueryVars'], modalState) || {};
  const [usersSearchWord, setSearchWord] = useState();
  const { loading: usersLoading, data: usersData } = useQuery(USERS_QUERY, {
    variables: {
      searchWord: usersSearchWord,
      unassigned: true,
    },
    fetchPolicy: 'network-only',
  });
  const membersData = optionsFromArr(usersData?.users?.users);

  const [CreateSquad, { loading }] = useMutation(CREATE_SQUAD_MUTATION, {
    onError: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: intl.formatMessage({
          id: 'containers.squads.createSquad.error',
        }),
        icon: 'error',
      });
    },
    update(dataStore, { data: { createSquad } }) {
      const squadsData = dataStore.readQuery({
        query: SQUADS_QUERY,
        variables: squadQueryVars,
      });
      dataStore.writeQuery({
        query: SQUADS_QUERY,
        variables: squadQueryVars,
        data: {
          squads: {
            __typename: 'SquadsList',
            squads: [createSquad, ...squadsData.squads.squads],
            totalCount: squadsData.squads.totalCount + 1,
          },
        },
      });
    },

    onCompleted: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: intl.formatMessage({
          id: 'containers.squads.createSquad.success',
        }),
      });
    },
  });

  const initialValues = {
    lineFunctionId: userLineFunction?.id || undefined,
    squadName: '',
    members: [],
    sport: userSport || undefined,
    lineId: userLineId || undefined,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CREATE_SQUAD,
    onSubmit: ({ lineFunctionId, members, squadName }) => {
      CreateSquad({
        variables: {
          input: {
            lineFunctionId,
            squad: {
              squadMembersIds: pluck('value', members),
              name: squadName,
            },
          },
        },
      });
    },
  });
  const disableSubmit =
    isNil(formik.values.sport) ||
    isNil(formik.values.lineId) ||
    isNil(formik.values.lineFunctionId);
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <Text variant="h2" data-testid="main-dialogTitle-addNewSquad">
          {intl.formatMessage({ id: 'containers.squads.addNewSquad' })}
        </Text>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
    >
      <div className="add-squad-form-container">
        <div
          className="add-squad-form-header"
          data-testid="squads-createSquad-squadDetails"
        >
          {intl.formatMessage({
            id: 'containers.squads.createSquad.squadDetails',
          })}
        </div>
        <SportsFilter
          sport={formik?.values?.sport}
          onSportChange={(data) =>
            formik.setValues({
              ...formik.values,
              sport: data,
              lineId: undefined,
              lineFunctionId: undefined,
            })
          }
        />
        <LinesFilter
          sport={formik.values.sport}
          lines={lines}
          lineId={formik.values.lineId}
          onLineChange={(data) =>
            formik.setValues({
              ...formik?.values,
              lineId: data,
              lineFunctionId: undefined,
            })
          }
        />
        <FunctionsFilter
          lineFunctionId={formik.values.lineFunctionId}
          onLineFunctionChanges={(data) =>
            formik.setFieldValue('lineFunctionId', data)
          }
          sport={formik.values.sport}
          lines={lines}
          lineId={formik.values.lineId}
        />
        <TextInput
          required
          type="text"
          labelText={intl.formatMessage({ id: 'containers.squads.squadName' })}
          placeholder={intl.formatMessage({
            id: 'containers.squads.squadName',
          })}
          value={formik.values.squadName}
          onChange={formik.handleChange('squadName')}
          error={formik.touched.squadName && formik.errors.squadName}
          name="squadName"
          data-testid="squads-create_squad-squadName"
        />
        <Divider />
        <Dropdown
          variant="users"
          searchPath={['hrCode', 'firstName', 'lastName', 'middleName']}
          onSearch={setSearchWord}
          filterable
          multiSelect
          values={formik.values.members}
          items={membersData}
          onChange={(data) => formik.setFieldValue('members', data)}
          loading={usersLoading}
          error={formik.touched.members && formik.errors.members}
          buttonText={intl.formatMessage({
            id: 'containers.squads.dropDown.members',
          })}
          name="members"
          data-testid="squads-create_squad-members"
        />
        <div className="add-members-fab-container">
          {formik.values.members?.map((member) => (
            <Fab
              text={member.label}
              icon="cross"
              iconSize={12}
              onClick={() =>
                formik.setFieldValue(
                  'members',
                  toggleListItem(formik.values.members, member)
                )
              }
              key={member.id}
              data-testid="squads-squadMembers-info"
            />
          ))}
        </div>
      </div>

      <div className="footer-action-btns" data-testid="addNewSquad-footer">
        <Button
          outlined
          intent="Primary"
          text={intl.formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={intl.formatMessage({
            id: 'containers.squads.createSquad.addSquad',
          })}
          onClick={formik.submitForm}
          loading={loading}
          disabled={disableSubmit}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
}
