import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { useNavigation } from 'react-navi';

const BreadCrumb = ({ mainPath, screenPath, className }) => {
  const navigation = useNavigation();
  const goBack = () => navigation.goBack();

  return (
    <div className={`bread-crumb-container ${className}`}>
      <Button small minimal icon="arrow-left" onClick={goBack} />
      <div>
        <span>{mainPath}</span>
        &nbsp; / &nbsp;
        <span>{screenPath}</span>
      </div>
    </div>
  );
};

export default BreadCrumb;

BreadCrumb.propTypes = {
  mainPath: PropTypes.string,
  screenPath: PropTypes.string,
  className: PropTypes.string,
};

BreadCrumb.defaultProps = {
  mainPath: '',
  screenPath: '',
  className: '',
};
