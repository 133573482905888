import { mount, route } from 'navi';
import ROUTE_ROLE_PERMISSIONS from 'config/routeRoles';

const routes = mount({
  '/': route({
    title: 'Dashboard',
    getView: () => import('../containers/Dashboard'),
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/dashboard'] || [],
    },
  }),

  '/logout': route({
    title: 'logout',
    getView: () => import('../containers/Logout'),
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/logout'] || [],
    },
  }),

  '/dashboard': route({
    title: 'Dashboard',
    getView: () => import('../containers/Dashboard'),
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/dashboard'] || [],
    },
  }),

  '/matches': route({
    title: 'Assignment',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/matches'] || [],
    },
    getView: () => import('../containers/MatchAssignment'),
  }),

  '/shift-schedule': route({
    title: 'Shift Schedule',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/shift-schedule'] || [],
    },
    getView: () => import('../containers/ShiftShedule'),
  }),

  '/squads': route({
    title: 'Squads',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/squads'] || [],
    },
    getView: () =>
      import('../containers/Squads/squadDetails/SquadsPerLineFunction'),
  }),
  // '/squads/:lineFunctionId': route({
  //   title: 'Squads',
  //   data: { allowedRoleNames: ROUTE_ROLES['/squads'] || [] },
  //   getView: () =>
  //     import('../containers/Squads/squadDetails/SquadsPerLineFunction'),
  // }),
  '/user-management': route({
    title: 'UserManagement',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/user-management'] || [],
    },
    getView: () => import('../containers/UserManagement'),
  }),

  '/priorities/teams': route({
    title: 'Teams',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    getView: () => import('../containers/Priorities/Teams'),
  }),
  '/priorities/competition_season': route({
    title: 'Competitions',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    getView: () => import('../containers/Priorities/Competitions'),
  }),

  '/priorities/teams/:cat/:id/': route({
    title: 'Teams',
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    getView: () => import('../containers/Priorities/Teams'),
  }),

  '/priorities/teams/:cat/:id/:priorityId': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    title: 'Teams',
    getView: () => import('../containers/Priorities/Teams'),
  }),

  '/priorities/competition_season/:cat/:id': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    title: 'Competitions',
    getView: () => import('../containers/Priorities/Competitions'),
  }),

  '/priorities/competition_season/:cat/:id/:priorityId': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/priorities'] || [],
    },
    title: 'Competitions',
    getView: () => import('../containers/Priorities/Competitions'),
  }),

  '/priorities/settings': route({
    data: {
      allowedRolePermissions:
        ROUTE_ROLE_PERMISSIONS['/priorities/settings'] || [],
    },
    title: 'Settings',
    getView: () => import('../containers/Priorities/SettingsView'),
  }),

  '/video/pcs': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/video/pcs'] || [],
    },
    title: 'Video PCs',
    getView: () => import('../containers/Video/Pcs'),
  }),
  '/video/providers': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/video/providers'] || [],
    },
    title: 'Video Providers',
    getView: () => import('../containers/Video/Providers'),
  }),
  '/video/leagues': route({
    data: {
      allowedRolePermissions: ROUTE_ROLE_PERMISSIONS['/video/leagues'] || [],
    },
    title: 'Video Leagues',
    getView: () => import('../containers/Video/Leagues'),
  }),
  '/video/acquisition/unassigned_matches': route({
    data: {
      allowedRolePermissions:
        ROUTE_ROLE_PERMISSIONS['/video/acquisition/unassigned_matches'] || [],
    },
    title: 'Unassigned Acquisition',
    getView: () =>
      import(
        '../containers/VideoAcquisitionTypes/unassigned/UnassignedMatches'
      ),
  }),
  '/video/acquisition/assigned_matches': route({
    data: {
      allowedRolePermissions:
        ROUTE_ROLE_PERMISSIONS['/video/acquisition/assigned_matches'] || [],
    },
    title: 'Assigned Acquisition',
    getView: () =>
      import('../containers/VideoAcquisitionTypes/assigned/AssignedMatches'),
  }),

  '/video/pc/unassigned_matches': route({
    data: {
      allowedRolePermissions:
        ROUTE_ROLE_PERMISSIONS['/video/pc/unassigned_matches'] || [],
    },
    title: 'Assign Pc',
    getView: () => import('../containers/PcAssignment/UnAssignedMatches'),
  }),

  '/video/pc/assigned_matches': route({
    data: {
      allowedRolePermissions:
        ROUTE_ROLE_PERMISSIONS['/video/pc/assigned_matches'] || [],
    },
    title: 'Assign Pc',
    getView: () => import('../containers/PcAssignment/AssignedMatches'),
  }),
});

export default routes;
