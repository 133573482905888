import React from 'react';
import { Button } from 'components';
import { RenderIf } from 'config';
import { length, remove } from 'ramda';
import { HALF_VALUES_QUERY, OFFLINE_TASK_TYPES_QUERY } from 'gql';
import { useQuery } from '@apollo/client';
import TaskDetailsRow from './TaskDetailsRow';

const TasksForm = ({
  tasks,
  touched = [],
  errors = [],
  setFormikField,
  taskCount = 0,
  maxCount,
}) => {
  const { data: { halfValues = [] } = {}, loading: halfValuesLoading } =
    useQuery(HALF_VALUES_QUERY);

  const {
    data: { offlineTaskTypes = [] } = {},
    loading: offlineTaskTypesLoading,
  } = useQuery(OFFLINE_TASK_TYPES_QUERY);

  return (
    <div className="dropdowns-sections">
      {tasks.map((task, index) => (
        <div key={`task-${task?.id}`} className="flex gap-20">
          <TaskDetailsRow
            touched={touched[index]}
            error={errors[index]}
            loading={offlineTaskTypesLoading || halfValuesLoading}
            setFormikField={setFormikField}
            task={task}
            rowIndex={index}
            offlineTaskTypes={offlineTaskTypes}
            halfValues={halfValues}
            disableInActiveItems={taskCount >= maxCount}
          />
          <RenderIf condition={length(tasks) > 1}>
            <Button
              icon="cross"
              iconOnly
              minimal
              onClick={() => {
                const t = remove(index, 1, tasks);
                setFormikField(`tasks`, t);
              }}
              data-testid="dashboard-assignTasks-removeTask"
            />
          </RenderIf>
        </div>
      ))}
    </div>
  );
};

export default TasksForm;
