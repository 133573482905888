import classNames from 'classnames';
import { Divider, Label, RadioGroup, TimePicker } from 'components';
import React from 'react';
import { BAD_VIDEO, HALF } from 'utils/enums';
import { RenderIf } from 'config';
import { FormattedMessage, useIntl } from 'react-intl';

const VideoIssue = ({ videoIssue, setFieldValue, errors, touched }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="missing-player-container">
      <RadioGroup
        items={BAD_VIDEO}
        selectedValue={videoIssue.isWholeMatch}
        onRadioChange={(data) => {
          setFieldValue('videoIssue.isWholeMatch', data);
        }}
        name="wholeMatch"
        className="missing-player-issue-group flex column mgl-15"
        error={touched?.isWholeMatch && errors?.isWholeMatch}
      />
      <RenderIf condition={videoIssue.isWholeMatch === false}>
        <div className="time-stamp-container">
          <Divider />
          <RadioGroup
            items={HALF}
            selectedValue={videoIssue.half}
            onRadioChange={(data) => {
              setFieldValue('videoIssue.half', data);
            }}
            name="half"
            className="missing-player-issue-group flex column"
            error={touched?.half && errors?.half}
          />
          <RenderIf condition={videoIssue?.half}>
            <div className={classNames(['flex', 'column'])}>
              <div className={classNames(['flex', 'items-center', 'gap-15'])}>
                <Label
                  labelText={`${formatMessage({
                    id: 'containers.all.timeStamp',
                  })}:`}
                  variant="Baisc"
                />
                <Label
                  labelText={`${formatMessage({ id: 'containers.all.from' })}:`}
                  variant="Baisc"
                  className="timeStamp"
                />
                <TimePicker
                  onChange={(data) => setFieldValue('videoIssue.from', data)}
                  value={videoIssue.from}
                  error={touched?.from && errors?.from}
                  name="from"
                />
                <Label
                  labelText={`${formatMessage({ id: 'containers.all.to' })}:`}
                  variant="Baisc"
                  className="timeStamp"
                />
                <TimePicker
                  onChange={(data) => setFieldValue('videoIssue.to', data)}
                  value={videoIssue.to}
                  error={touched?.to && errors?.to}
                  name="to"
                />
              </div>
              <span className="timestamp-info-container pb-15">
                <FormattedMessage id="containers.all.timeStamp.warning" />
              </span>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

export default VideoIssue;
