import { useMutation } from '@apollo/client';
import { Tag } from '@blueprintjs/core';
import { Button, Dialog, Icon, Text } from 'components';
import { useFormik } from 'formik';
import { DELETE_PC_MUTATION, PCS_QUERY } from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const DeletePc = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const {
    payload: { pcData = {}, screenInfo = {} },
  } = modalState;
  const [deletePCMutation, { loading }] = useMutation(DELETE_PC_MUTATION, {
    refetchQueries: [
      {
        query: PCS_QUERY,
        variables: screenInfo,
      },
    ],
    awaitRefetchQueries: true,
    onError: () =>
      showToast({
        message: formatMessage({ id: 'containers.all.error' }),
        icon: 'error',
      }),
    onCompleted: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: formatMessage({
          id: 'containers.pc.modals.delete.success',
        }),
      });
    },
  });

  const initialValues = {
    id: pcData?.id,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { id } = values;
      deletePCMutation({
        variables: { input: { id } },
      });
    },
  });

  return (
    <Dialog
      dialogTitle={<FormattedMessage id="containers.pc.modals.delete.title" />}
      icon={<Icon icon="ban-circle" size={15} data-testid="modalHeader-icon" />}
      width="541px"
    >
      <div className="bp3-dialog-body flex column gap-20">
        <Text variant="body1" data-testid="pc-deleteModal-text">
          <FormattedMessage id="containers.pc.modals.delete.content" />
        </Text>
        <Tag round className="pc-number-tag">
          <Text variant="body2">{`PC Number: #${pcData?.number}`}</Text>
        </Tag>
      </div>
      <div className="bp3-dialog-footer" data-testid="delete-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={<FormattedMessage id="containers.all.delete" />}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeletePc;
