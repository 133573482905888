import React, { useState, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { Button, Dialog } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { path, sortBy, prop, findIndex, propEq, find, concat } from 'ramda';
import update from 'immutability-helper';
import { useIntl } from 'react-intl';
import { useModal } from '../../../../providers/modalProvider';
import { useToast } from '../../../../providers/toastProvider';
import {
  UPDATE_PRIORITY_CATEGORIES_RANKS_MUTATION,
  PRIORITY_CATEGORY_VALUES_QUERY,
} from '../../../../gql';
import { Icon } from '../../../../components';
import DraggableInfoCard from '../helpers/DraggableInfoCard';

const ArrangeRanks = () => {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const closeModal = useCallback(
    () => modalDispatch({ type: 'ClOSE_ALL' }),
    []
  );
  const teamsCategoryValues = path(
    ['payload', 'teamsCategoryValues'],
    modalState
  );
  const competitionCategoryValues = path(
    ['payload', 'competitionCategoryValues'],
    modalState
  );
  const allRanks = sortBy(
    prop('rank'),
    concat(teamsCategoryValues, competitionCategoryValues)
  );
  const intl = useIntl();
  const [cards, setCards] = useState(allRanks);
  const [updatePriorityCategoriesRanks, { loading }] = useMutation(
    UPDATE_PRIORITY_CATEGORIES_RANKS_MUTATION,
    {
      refetchQueries: [
        { query: PRIORITY_CATEGORY_VALUES_QUERY, variables: { type: `TEAM` } },
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: `COMPETITION_SEASON` },
        },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        closeModal();
        showToast({
          message: intl.formatMessage({
            id: 'containers.priorities.settings.arrangeRanks.error',
          }),
          icon: 'error',
        });
      },
      onCompleted: () => {
        closeModal();
        showToast({
          message: intl.formatMessage({ id: 'containers.all.success' }),
        });
      },
    }
  );

  const input = useMemo(
    () => cards.map(({ id }, i) => ({ id, rank: i + 1 })),
    [cards]
  );
  const handleSubmit = () =>
    updatePriorityCategoriesRanks({ variables: { input } });
  const findCard = useCallback(
    (id) => {
      const card = find(propEq('id', id))(cards);
      const index = findIndex(propEq('id', id))(cards);
      return { card, index };
    },
    [cards]
  );

  const moveCard = useCallback(
    ({ draggedId, overIndex }) => {
      const { card, index } = findCard(draggedId);
      if (card && card.id) {
        setCards(
          update(cards, {
            $splice: [
              [index, 1],
              [overIndex, 0, card],
            ],
          })
        );
      }
    },
    [findCard, cards, setCards]
  );

  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop(() => ({ accept: 'priorityInfoCard' }));
  const title = useMemo(
    () => (
      <div className={classNames('flex', 'gap-7', 'items-center')}>
        <Icon
          customIcon="arrangeRanks"
          size={15}
          data-testid="modalHeader-icon"
        />
        <div data-testid="modalHeader-title">
          {intl.formatMessage({ id: 'containers.priorities.arrangeRanks' })}
        </div>
      </div>
    ),
    []
  );
  return (
    <Dialog
      isOpen={modalState?.open}
      title={title}
      onClose={closeModal}
      canOutsideClickClose={false}
      style={{ width: '600px' }}
    >
      <div className="priorities_rank_container" ref={drop}>
        <p>
          {intl.formatMessage({
            id: 'containers.priorities.settings.arrangeRanks.description',
          })}
        </p>
        {cards.map((card, index) => (
          <DraggableInfoCard
            data={card}
            key={card.id}
            index={index}
            moveCard={moveCard}
            findCard={findCard}
          />
        ))}
      </div>
      <div
        className="bp3-dialog-footer-actions"
        data-testid="arrangeRanks-footer"
      >
        <Button
          outlined
          text={intl.formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={closeModal}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={intl.formatMessage({
            id: 'containers.priorities.settings.btn.updateRanks',
          })}
          onClick={handleSubmit}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default ArrangeRanks;
