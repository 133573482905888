import React from 'react';
import classNames from 'classnames';
import { toLower } from 'ramda';
import SimpleLabel from './SimpleLabel';

const ShiftLabel = ({ labelText, className }) => {
  const shiftType = toLower(labelText);
  return (
    <div className={classNames('shift-type-label', className, shiftType)}>
      <span className={`bg-${shiftType}`} />
      <SimpleLabel
        labelText={shiftType.charAt(0).toUpperCase() + shiftType.slice(1)}
      />
    </div>
  );
};

export default ShiftLabel;
