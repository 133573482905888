import { gql } from '@apollo/client';

export default gql`
  query priorityCategories($type: PriorityCategoryType!) {
    priorityCategories(type: $type) {
      id
      type
      value
      rank
      totalCount
      priorities {
        id
        slaOffset
        totalCount
      }
    }
  }
`;
