import React from 'react';
import { Dropdown } from 'components';
import { useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';
import { getLinesBySport, optionsFromArr } from 'utils/helpers';
import { isNil } from 'ramda';

const LinesFilter = ({ sport, lines, lineId, onLineChange }) => {
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole } = profile;
  const {
    role: { name: roleName, line: userLine },
  } = userJobTitleRole;
  const isSuperAdmin = roleName === 'super-admin';
  const disableLine = !sport || (!isSuperAdmin && !isNil(userLine));
  const { formatMessage } = useIntl();
  const linesList = getLinesBySport(lines, sport);
  return (
    <Dropdown
      items={optionsFromArr(linesList)}
      value={lineId}
      onChange={({ value }) => onLineChange(value)}
      buttonText={formatMessage({ id: 'containers.filters.line' })}
      loading={!linesList}
      data-testid="main-filters-line"
      disableDropdownButton={disableLine}
      disabled={disableLine}
      name="line"
    />
  );
};

export default LinesFilter;
