import React from 'react';
import { Button, Dialog, Label, Text } from 'components';
import { useMutation } from '@apollo/client';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';

import { useFormik } from 'formik';
import { DELETE_USER_MUTATION } from 'gql';
import { FormattedMessage, useIntl } from 'react-intl';

const DeleteConfirm = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [deleteUserMutation, { loading }] = useMutation(DELETE_USER_MUTATION, {
    onError: () =>
      showToast({
        message: formatMessage({ id: 'containers.users.modals.delete.error' }),
        icon: 'error',
      }),
    onCompleted: () => {
      modalDispatch({ type: 'ClOSE_ALL' });
      showToast({
        message: formatMessage({
          id: 'containers.users.modals.delete.success',
        }),
      });
      modalState.refetch();
    },
  });

  const initialValues = {
    id: modalState?.user?.id,
    legacyId: modalState?.user?.legacyId,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      deleteUserMutation({
        variables: { input: values },
      });
    },
  });

  return (
    <Dialog
      dialogTitle={
        <FormattedMessage id="containers.users.modals.delete.title" />
      }
      icon={<span className="bg-delete" data-testid="bg-delete" />}
      width="541px"
    >
      <div className="bp3-dialog-body delete-body-container">
        <Text variant="body1" data-testid="users-deleteModal-text">
          <FormattedMessage id="containers.users.modals.delete.content" />
        </Text>
        <Label
          data-testid="users-deleteModal-avatar"
          variant="AvatarLabel"
          labelText={`${modalState?.user?.firstName} ${
            modalState?.user?.middleName || ''
          } ${modalState?.user?.lastName}`}
          avatarText={`${modalState?.user?.firstName} ${modalState?.user?.lastName}`}
        />
      </div>
      <div className="bp3-dialog-footer" data-testid="delete-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={<FormattedMessage id="containers.all.delete" />}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteConfirm;
