import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'components';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { prop, sortBy } from 'ramda';

const TabGroup = ({ items, isVertical, isCollapsed, small, children }) => {
  const sortedItems = sortBy(prop('order'), items);
  return (
    <div
      className={classNames(
        'tab-group',
        { vertical: isVertical },
        { collapsed: isCollapsed }
      )}
    >
      {sortedItems.map((item) => (
        <Tab
          {...item}
          isVertical={isVertical}
          key={`tab-${uuidv4()}`}
          small={small}
          isCollapsed={isCollapsed}
        />
      ))}
      {children}
    </div>
  );
};
export default TabGroup;

TabGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  isVertical: PropTypes.bool,
};

TabGroup.defaultProps = {
  isVertical: false,
};
