import { gql } from '@apollo/client';

export default gql`
  mutation createPriorityCategory($input: CreatePriorityCategoryInput!) {
    createPriorityCategory(input: $input) {
      id
      type
      value
      rank
    }
  }
`;
