import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, ShiftMatchCard } from 'components';
import { useToast } from 'providers/toastProvider';
import {
  MATCHES_QUERY,
  MATCHES_WITH_VIDEO_FILTERS_QUERY,
  UNASSIGN_MATCH_SQUAD_SHIFT_MUTATION,
} from 'gql';
import { Acl } from 'config';
import { useProfile } from 'providers/profileProvider';
import { isVideo } from 'utils/helpers';

const MatchRow = ({
  shiftMatch,
  index,
  squadId,
  squadShiftPowerAndCapacityRefetch,
  matchesActiveDay,
  refetchShiftMatches,
  shiftId,
  squadsActiveDate,
}) => {
  const { id = '' } = shiftMatch;
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { id: lineId, name: userLineName } = line || {};
  const { showToast } = useToast();
  const isVideoUser = isVideo(userLineName);

  const [unAssignSquadShiftMatchesMutation, { loading }] = useMutation(
    UNASSIGN_MATCH_SQUAD_SHIFT_MUTATION,
    {
      refetchQueries: [
        {
          query: isVideoUser ? MATCHES_WITH_VIDEO_FILTERS_QUERY : MATCHES_QUERY,
          variables: {
            date: matchesActiveDay,
            lineId,
          },
        },
      ],
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true,
      onError: () => {
        showToast({
          message: 'Changes can not be saved try again ',
          icon: 'error',
        });
      },
      onCompleted: () => {
        showToast({ message: 'Changes have been saved successfully.' });
        squadShiftPowerAndCapacityRefetch();
        refetchShiftMatches();
      },
      update(cache) {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'shiftMatches',
          args: {
            date: squadsActiveDate,
            shiftId,
            lineId,
            hasAssignedPC: isVideoUser,
          },
        });
      },
    }
  );
  const unAssign = () =>
    unAssignSquadShiftMatchesMutation({
      variables: {
        input: {
          matchId: id,
          squadId,
          squadShiftId: shiftMatch?.squadShifts[0].squadShift.id,
          lineId,
        },
      },
    });
  return (
    <div className="flex gap-30 mgt-15">
      <ShiftMatchCard
        shiftMatch={shiftMatch}
        index={index}
        matchAssignmentVersion
      />
      <Acl
        allowedPermissions={['delete:match-assignment']}
        allowedRoleNames={['super-admin']}
      >
        <Button
          text="Unassign"
          icon="ban-circle"
          minimal
          intent="primary"
          onClick={unAssign}
          loading={loading}
          data-testid="unassign-btn"
          data-matchid={id}
        />
      </Acl>
    </div>
  );
};

export default MatchRow;
