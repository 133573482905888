import React from 'react';
import { Dropdown } from 'components';
import { useQuery } from '@apollo/client';
import { optionsFromArr } from 'utils/helpers';
import { SHIFTS_QUERY } from 'gql';
import { toLower, find } from 'ramda';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useProfile } from 'providers/profileProvider';

const ShiftTypeSelect = ({
  value,
  onChange,
  error,
  className,
  haveDefaultShift = false,
}) => {
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { id: lineId } = line || {};
  const { loading: shiftsLoading, data: shiftsData } = useQuery(SHIFTS_QUERY, {
    skip: !lineId,
    variables: {
      lineId,
    },
    onCompleted: (data) => {
      if (haveDefaultShift) {
        onChange(
          find((shift) => toLower(shift.shiftType) === 'morning')(
            data?.shifts?.shifts
          ) || data?.shifts?.shifts[0]
        );
      }
    },
  });
  const intl = useIntl();
  return (
    <Dropdown
      items={optionsFromArr(shiftsData?.shifts.shifts)}
      loading={shiftsLoading}
      popOverPosition="bottom-left"
      buttonText={intl.formatMessage({ id: 'containers.all.shiftType' })}
      variant="shifts"
      onChange={onChange}
      value={value}
      className={className}
      error={error}
    />
  );
};

export default ShiftTypeSelect;
ShiftTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  haveDefaultShift: PropTypes.bool,
};

ShiftTypeSelect.defaultProps = {
  haveDefaultShift: false,
};
