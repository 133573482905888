import { gql } from '@apollo/client';

export default gql`
  query Servers($hq: String) {
    servers(HQ: $hq) {
      name
      id
      location {
        id
        name
      }
    }
  }
`;
