import { gql } from '@apollo/client';

export default gql`
  mutation UpdatePcMatch($input: updatePCMatchInput!) {
    updatePcMatch(input: $input) {
      match {
        id
      }

      pc {
        id
      }
    }
  }
`;
