import React from 'react';
import { Dialog, Spinner } from '@blueprintjs/core';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { Divider, Icon, Label, ShiftMatchCard } from 'components';
import IssuesBody from 'containers/Dashboard/helpers/IssuesBody';
import ManageMatchPopOver from 'containers/Dashboard/helpers/ManageMatchPopOver';
import { MATCH_ISSUES_QUERY } from 'gql';
import { useModal } from 'providers/modalProvider';
import {
  isEmpty,
  defaultTo,
  prop,
  path,
  propEq,
  find,
  filter,
  isNil,
} from 'ramda';
import { RenderIf } from 'config';
import { QUALITY_ISSUE_LIST } from 'utils/enums';
import { FormattedMessage, useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';
import { isVideo } from 'utils/helpers';
import { sortByStatus } from './helpers';

const ViewDetails = () => {
  const { formatMessage } = useIntl();
  const { modalDispatch, modalState } = useModal();
  const shiftMatch = path(['payload', 'shiftMatch'], modalState);
  const assignedSquadName = path(['payload', 'assignedSquadName'], modalState);
  const squadId = path(['payload', 'squadId'], modalState);
  const activeDate = path(['payload', 'activeDate'], modalState);
  const refetchShiftMatches = path(
    ['payload', 'refetchShiftMatches'],
    modalState
  );
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId, name: userLineName } = userLine ?? {};
  const isVideoUser = isVideo(userLineName);
  const shiftMatchIssueStatus = isVideoUser ? 'videoStatusIssue' : 'status';
  const { shiftId } = modalState?.payload;
  const modalPayload = {
    shiftMatch,
    squadId,
    assignedSquadName,
    activeDate,
    refetchShiftMatches,
    shiftId,
  };
  const { data: matchIssuesData, loading: matchIssuesLoading } = useQuery(
    MATCH_ISSUES_QUERY,
    {
      variables: { matchId: shiftMatch?.id, lineId },
      fetchPolicy:
        shiftMatch[shiftMatchIssueStatus] === 'REPORTED'
          ? 'network-only'
          : 'cache-and-network',
    }
  );

  const blockingIssue = find(propEq('status', 'BLOCKED'))(
    defaultTo([], prop('matchIssues', matchIssuesData))
  );

  const qualityIssues = filter(
    (issue) =>
      issue?.status !== 'BLOCKED' && QUALITY_ISSUE_LIST.includes(issue.type)
  )(defaultTo([], prop('matchIssues', matchIssuesData)));

  const otherIssues = filter(
    (issue) =>
      issue?.status !== 'BLOCKED' && !QUALITY_ISSUE_LIST.includes(issue.type)
  )(defaultTo([], prop('matchIssues', matchIssuesData)));

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon customIcon="details" size={16} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            <FormattedMessage id="containers.all.details" />
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
      className="view-details-modal"
    >
      <Label
        variant="Label"
        labelText={formatMessage(
          { id: 'containers.dashboards.squadAssigned' },
          { assignedSquadName }
        )}
        className={classNames([' pl-32', 'pt-14'])}
      />
      <div className="shift-match">
        <ShiftMatchCard shiftMatch={shiftMatch} />
      </div>
      <RenderIf condition={matchIssuesLoading}>
        <Spinner />
      </RenderIf>
      <RenderIf condition={!matchIssuesLoading}>
        <div
          className={classNames('issues-body-container', {
            'empty-issues-body-container':
              isNil(matchIssuesData) || isEmpty(matchIssuesData?.matchIssues),
          })}
        >
          <RenderIf
            condition={
              isNil(matchIssuesData) || isEmpty(matchIssuesData?.matchIssues)
            }
          >
            <b>
              <FormattedMessage id="containers.dashboards.emptyMatchDetails" />
            </b>
          </RenderIf>
          <RenderIf condition={!isEmpty(otherIssues)}>
            <IssuesBody
              screenTitle="Reported Issues"
              issues={sortByStatus(otherIssues)}
              modalPayload={modalPayload}
              shiftMatch={shiftMatch}
              testid="reported"
            />
          </RenderIf>
          <Divider className={classNames(['pb-10 ', 'mgr-60', 'mgl-32'])} />
          <RenderIf condition={!isEmpty(qualityIssues)}>
            <IssuesBody
              screenTitle="Reported Quality"
              issues={sortByStatus(qualityIssues)}
              modalPayload={modalPayload}
              shiftMatch={shiftMatch}
              testid="quality"
            />
          </RenderIf>
          <RenderIf condition={blockingIssue}>
            <IssuesBody
              screenTitle="Blocked Match"
              issues={[blockingIssue]}
              modalPayload={modalPayload}
              shiftMatch={shiftMatch}
              testid="blocked"
            />
          </RenderIf>
        </div>
      </RenderIf>
      <div className="mgl-15">
        <ManageMatchPopOver
          modalPayload={modalPayload}
          shiftMatch={shiftMatch}
          squad={{ id: squadId, name: assignedSquadName }}
          isVideo={isVideoUser}
        />
      </div>
    </Dialog>
  );
};

export default ViewDetails;
