import React from 'react';
import { Icon } from 'components';
import { Tooltip2 } from '@blueprintjs/popover2';
import { RenderIf } from 'config';
import { Classes, Position } from '@blueprintjs/core';
import { streamingIconByType } from '../helpers/helpers';

const IPTVDetails = ({ videoOnDemand }) => {
  return (
    <>
      <RenderIf condition={videoOnDemand}>
        <div>
          <Tooltip2
            content="VOD"
            position={Position.BOTTOM}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <Icon icon={streamingIconByType('vod')} size={20} color="#0044cc" />
          </Tooltip2>
        </div>
      </RenderIf>
    </>
  );
};

export default IPTVDetails;
