import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({ children, className, variant, ...rest }) => {
  const mapVariantTotag = (variantType, content, classNameProp) => {
    const variants = {
      h1: (
        <h1 className={classNames('txt-h-1', classNameProp)} {...rest}>
          {content}
        </h1>
      ),
      h2: (
        <h2 className={classNames('txt-h-2', classNameProp)} {...rest}>
          {content}
        </h2>
      ),
      h3: (
        <h3 className={classNames('txt-h-3', classNameProp)} {...rest}>
          {content}
        </h3>
      ),
      body1: (
        <p
          className={classNames('txt-body-1', 'mgb-0', classNameProp)}
          {...rest}
        >
          {content}
        </p>
      ),
      body2: (
        <p
          className={classNames('txt-body-2', 'mgb-0', classNameProp)}
          {...rest}
        >
          {content}
        </p>
      ),
      body3: (
        <p
          className={classNames('txt-body-3', 'mgb-0', classNameProp)}
          {...rest}
        >
          {content}
        </p>
      ),
      body4: (
        <p className={classNames('txt-body-4', 'mgb-0', className)} {...rest}>
          {content}
        </p>
      ),
    };
    return (
      variants[variantType] || (
        <span className={classNames('txt-other', className)} {...rest}>
          {content}
        </span>
      )
    );
  };
  return mapVariantTotag(variant, children, className);
};

export default Text;

Text.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'body1',
    'body2',
    'body3',
    'body4',
  ]),
};
