import React from 'react';
import classNames from 'classnames';
import { Dropdown, Text } from 'components';
import { pluck } from 'ramda';
import { optionsFromArr, isVideo } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { RenderIf } from 'config';
import { useProfile } from 'providers/profileProvider';

const AssignCollectors = ({
  squadShiftMembers,
  squadShiftMembersLoading,
  setFormikField,
  collector,
  error,
  hasLabel = true,
  changeIsLoading = false,
  clearable = false,
  disabled,
}) => {
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { name: userLineName } = line || {};
  const usersInShift = optionsFromArr(pluck('user', squadShiftMembers));
  const buttonText = isVideo(userLineName) ? (
    <FormattedMessage id="containers.dashboards.chooseCoordinator" />
  ) : (
    <FormattedMessage id="containers.dashboards.assignTask.collectorSelectTitle" />
  );
  const labelText = isVideo(userLineName) ? (
    <FormattedMessage id="containers.dashboards.matchRow.assignedCoordinator" />
  ) : (
    <FormattedMessage id="containers.dashboards.assignTask.collectorSelectTitle" />
  );
  return (
    <div className={classNames(['flex', ' gap-15', 'items-center'])}>
      <RenderIf condition={hasLabel}>
        <Text className={classNames(['mgb-25', 'mgt-25'])} variant="body1">
          {labelText}
        </Text>
      </RenderIf>
      <Dropdown
        variant="users"
        items={usersInShift}
        onChange={({ value }) => {
          setFormikField('collector', value);
        }}
        buttonText={buttonText}
        className="customize-dropdown-button"
        value={collector}
        error={error}
        loading={changeIsLoading || squadShiftMembersLoading}
        data-testid="dashboard-assignTasks-users"
        clearable={clearable}
        disabled={disabled}
        disableDropdownButton={disabled}
      />
    </div>
  );
};

export default AssignCollectors;
