import { useMutation, useQuery } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button, Divider, Dropdown, Icon, Label, Text } from 'components';
import { RenderIf } from 'config';
import { useFormik } from 'formik';
import {
  CREATE_PROVIDER_MUTATION,
  PROVIDERS_QUERY,
  PROVIDERS_TYPES_QUERY,
  UPDATE_PROVIDER_MUTATION,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  hasGraphQlErrors,
  optionsFromArr,
  pluckValue,
  title,
} from 'utils/helpers';
import { CREATE_UPDATE_PROVIDER } from 'validations';
import { isEmpty, omit } from 'ramda';
import StreamingDetails from './StreamingDetails';
import IPTVDetails from './IPTVDetails';
import ChannelDetails from './ChannelDetails';

const CreateUpdateProvider = () => {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { loading: providerTypesLoading, data: providerTypesData } = useQuery(
    PROVIDERS_TYPES_QUERY
  );

  const {
    payload: { providerData = {}, screenInfo = {} },
  } = modalState;

  const closeModal = useCallback(
    () => modalDispatch({ type: 'ClOSE_ALL' }),
    []
  );
  const onError = useCallback(({ graphQLErrors }) => {
    const hasGraphQlError = hasGraphQlErrors(graphQLErrors, 'P2002');
    const messageId = hasGraphQlError
      ? 'containers.providers.existing.provider'
      : 'containers.all.errors.saving.changes';
    showToast({
      message: formatMessage({ id: messageId }),
      icon: 'error',
    });
  }, []);
  const onCompleted = useCallback(() => {
    const message = formatMessage({
      id: isEmpty(providerData)
        ? 'containers.provider.form.successCreate'
        : 'containers.provider.form.successUpdate',
    });
    closeModal();
    showToast({ message });
  }, []);

  const initialValues = {
    id: providerData?.id || '',
    type: providerData?.type || '',
    streamingDetails: {
      name: providerData?.streaming?.name,
      noOfAccounts: providerData?.streaming?.noOfAccounts,
      noOfConcurrency: providerData?.streaming?.noOfConcurrency,
      vpn: optionsFromArr(providerData?.streaming?.vpn) || [],
      videoOnDemand: providerData?.streaming?.videoOnDemand || false,
      downloadable: providerData?.streaming?.downloadable || false,
      appleTv: Boolean(providerData?.streaming?.feature?.id) || false,
      featureId: providerData?.streaming?.feature?.id,
    },
    IPTVDetails: {
      name: providerData?.iptv?.name || '',
      videoOnDemand: providerData?.iptv?.videoOnDemand || false,
      featureId: providerData?.iptv?.feature?.id,
    },
    channelDetails: {
      name: providerData?.channel?.name || '',
      satelliteId: providerData?.channel?.satellite.id || '',
      bein: Boolean(providerData?.channel?.feature?.id) || false,
      featureId: providerData?.channel?.feature?.id,
    },
  };
  const refetchQueries = [
    {
      query: PROVIDERS_QUERY,
      variables: { ...screenInfo },
    },
  ];
  const [createProviderMutation, { loading: createProviderLoading }] =
    useMutation(CREATE_PROVIDER_MUTATION, {
      onError: (err) => onError(err),
      onCompleted,
      notifyOnNetworkStatusChange: true,
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const [updateProviderMutation, { loading: updateProviderLoading }] =
    useMutation(UPDATE_PROVIDER_MUTATION, {
      onError: (err) => onError(err),
      onCompleted,
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries,
    });

  const getDetailsByType = (values, type) => {
    const typeMapper = {
      IPTV: { IPTVDetails: values?.IPTVDetails },
      STREAMING: {
        streamingDetails: {
          ...omit(['appleTv'], values?.streamingDetails),
          vpn: pluckValue(values.streamingDetails.vpn),
          noOfAccounts: parseInt(values.streamingDetails.noOfAccounts, 10),
          noOfConcurrency: parseInt(
            values.streamingDetails.noOfConcurrency,
            10
          ),
        },
      },
      CHANNEL: { channelDetails: omit(['bein'], values?.channelDetails) },
    };
    return typeMapper[type];
  };
  const createProvider = (values) => {
    const { type } = values;
    return createProviderMutation({
      variables: {
        input: {
          type,
          ...getDetailsByType(values, type),
        },
      },
    });
  };
  const updateProvider = (values) => {
    const { type, id } = values;
    return updateProviderMutation({
      variables: {
        input: {
          id,
          ...getDetailsByType(values, type),
        },
      },
    });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CREATE_UPDATE_PROVIDER,
    onSubmit: (values) => {
      return isEmpty(providerData)
        ? createProvider(values)
        : updateProvider(values);
    },
  });
  const updateModalTitle = (
    <div className={classNames('flex', 'gap-3', 'items-center')}>
      <Icon icon="edit" size={15} data-testid="modalHeader-icon" />
      <div data-testid="modalHeader-title">
        {formatMessage({ id: 'containers.providers.UpdateProvider.title' })}
      </div>
    </div>
  );
  const createModalTitle = (
    <div className={classNames('flex', 'gap-3', 'items-center')}>
      <Icon icon="edit" size={15} data-testid="modalHeader-icon" />
      <div data-testid="modalHeader-title">
        {formatMessage({ id: 'containers.providers.createProvider.title' })}
      </div>
    </div>
  );
  const setFormikField = (field, value) => formik.setFieldValue(field, value);
  const modalTitle = isEmpty(providerData)
    ? createModalTitle
    : updateModalTitle;

  const ProviderTypeRender = () => {
    if (isEmpty(providerData)) {
      return (
        <Dropdown
          items={optionsFromArr(providerTypesData?.providerTypes)}
          value={formik.values.type}
          onChange={({ value }) => {
            formik.setFieldValue('type', value);
          }}
          error={formik.touched.type && formik.errors.type}
          buttonText={formatMessage({
            id: 'containers.providers.form.type',
          })}
          loading={providerTypesLoading}
          className={classNames('mgt-20', {
            'selected-dropdown-highlight': !formik.values.type,
          })}
          data-testid="providers-form-type"
          name="type"
        />
      );
    }
    return (
      <Label
        variant="Baisc"
        labelHeader="Provider Type"
        labelText={title(providerData?.type)}
        className="mgt-20"
      />
    );
  };
  return (
    <Dialog
      title={modalTitle}
      width="541px"
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      isOpen={modalState?.open}
    >
      <div className={classNames(['bp3-dialog-body', 'mgb-0', 'mgr-0'])}>
        <div>
          <Text variant="body1">
            <FormattedMessage id="containers.providers.form.providersDetails" />
          </Text>
          <ProviderTypeRender />
        </div>

        <RenderIf
          condition={
            !isEmpty(formik?.values.type) &&
            formik?.values.type !== 'CUSTOMER_DRIVE' &&
            formik?.values.type !== 'FEED_EXTENSION'
          }
        >
          <div className="mgt-20">
            <Divider className="mgb-20 mgr-20" />
            <Text variant="body1" className="mgb-20">
              {`${title(formik.values.type)} Details`}
            </Text>
          </div>
        </RenderIf>
        <RenderIf condition={formik?.values.type === 'IPTV'}>
          <IPTVDetails
            IPTVDetails={formik.values.IPTVDetails}
            setFormikField={setFormikField}
            errors={formik.errors.IPTVDetails}
            touched={formik.touched.IPTVDetails}
          />
        </RenderIf>

        <RenderIf condition={formik?.values.type === 'STREAMING'}>
          <StreamingDetails
            streamingDetails={formik.values.streamingDetails}
            setFormikField={setFormikField}
            errors={formik.errors.streamingDetails}
            touched={formik.touched.streamingDetails}
          />
        </RenderIf>
        <RenderIf condition={formik?.values.type === 'CHANNEL'}>
          <ChannelDetails
            channelDetails={formik.values.channelDetails}
            setFormikField={setFormikField}
            errors={formik.errors.channelDetails}
            touched={formik.touched.channelDetails}
          />
        </RenderIf>
        <div
          className="bp3-dialog-footer-actions"
          data-testid="create-provider"
        >
          <Button
            outlined
            text={formatMessage({ id: 'containers.users.form.cancel' })}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={formatMessage({ id: 'containers.users.form.save' })}
            onClick={formik.submitForm}
            loading={createProviderLoading || updateProviderLoading}
            disabled={!formik.values}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CreateUpdateProvider;
