import { gql } from '@apollo/client';
import MATCH_FRAGMENT from '../fragments/match';

export default gql`
  query ShiftMatches(
    $date: LocalDate
    $shiftId: String
    $squadShiftId: String
    $squadIds: [String!]
    $userId: String
    $competitionSeasonIds: [Int!]
    $teamIds: [Int!]
    $priorityIds: [String!]
    $statuses: [MatchStatus]
    $collectionStatuses: [CollectionStatus]
    $queryParams: QueryParams
    $matchId: Int
    $lineId: String!
    $videoIssueTypes: [IssueType!]
    $coordinatorIds: [String!]
    $acquisitionTypes: [AcquisitionType!]
    $videoStatuses: [VideoStatus]
    $hasAssignedPC: Boolean
    $locationIds: [String]
  ) {
    shiftMatches(
      date: $date
      shiftId: $shiftId
      squadShiftId: $squadShiftId
      squadIds: $squadIds
      userId: $userId
      competitionSeasonIds: $competitionSeasonIds
      teamIds: $teamIds
      priorityIds: $priorityIds
      statuses: $statuses
      collectionStatuses: $collectionStatuses
      queryParams: $queryParams
      matchId: $matchId
      lineId: $lineId
      videoIssueTypes: $videoIssueTypes
      coordinatorIds: $coordinatorIds
      acquisitionTypes: $acquisitionTypes
      videoStatuses: $videoStatuses
      hasAssignedPC: $hasAssignedPC
      locationIds: $locationIds
    ) {
      ...MatchFragmment
    }
  }
  ${MATCH_FRAGMENT}
`;
