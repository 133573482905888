const ROUTE_ROLE_PERMISSIONS = {
  '/logout': [
    'read:match',
    'read:priority',
    'read:shift-schedule',
    'read:user',
    'read:squad',
    'read:match-assignment',
  ],

  '/dashboard': ['read:match'],

  '/matches': ['read:match-assignment'],

  '/shift-schedule': ['read:shift-schedule'],

  '/squads': ['read:squad'],
  '/user-management': ['read:user'],

  '/priorities': ['read:priority'],
  '/priorities/settings': ['update:priority'],

  '/video/pcs': ['read:pc'],
  '/video/providers': ['read:provider'],
  '/video/leagues': ['read:league'],
  '/video/acquisition/unassigned_matches': ['read:acquisition'],
  '/video/acquisition/assigned_matches': ['read:acquisition'],
  // TODO: change permissions here to be PC ones
  '/video/pc/unassigned_matches': ['read:pc-assignment'],
  '/video/pc/assigned_matches': ['read:pc-assignment'],
};

export default ROUTE_ROLE_PERMISSIONS;
