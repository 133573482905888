import { gql } from '@apollo/client';

export default gql`
  query userHistory($userId: String!) {
    userHistory(userId: $userId) {
      jobTitle
      deactivated
      squadName
      deactivationReason
      startingDate
      endingDate
    }
  }
`;
