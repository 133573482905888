import classNames from 'classnames';
import React from 'react';
import { Label } from 'components';
import {
  calcSlaLongFormat,
  formatKO,
  removeDateFromString,
  acronym,
} from 'utils/helpers';
import { Col, Row } from 'react-flexbox-grid';

const OfflineShiftMatchCard = ({ shiftMatch, index, squad }) => {
  const {
    id,
    kickOff,
    priority = {},
    week,
    competitionSeason = {},
    name,
    date,
    collectionStatus = '',
    status = '',
  } = shiftMatch;
  const { competitionName = '', seasonName = '' } = competitionSeason;
  const { slaOffset, value = '' } = priority || {};
  const teamsNameWIthoutDate = removeDateFromString(name);
  const splittedTeams = teamsNameWIthoutDate.split('vs.');
  return (
    <Row>
      <Col
        lg={1}
        md={2}
        sm={1}
        className={classNames({
          'mgl-5': value && slaOffset,
          'pl-50': !(value && slaOffset),
        })}
      >
        {value && slaOffset && (
          <Label
            variant="ColoredLabel"
            labelText={`${acronym(value)}:${slaOffset}`}
            index={index}
            screenType="teams"
            className="mg-0 p-0"
          />
        )}
      </Col>
      <Col lg={2} md={2} sm={1}>
        <Label
          variant="Baisc"
          labelHeader="Match ID :"
          labelText={id}
          inline
          className="mg-0 p-0"
        />
        <Label
          variant="Baisc"
          labelHeader="Game  Week:"
          labelText={week}
          inline
          className="mg-0 p-0"
        />
      </Col>
      <Col lg={3} md={2} sm={1} className="mg-0 p-0 team-competition-content">
        <div>
          <span className="styled-team-name">{splittedTeams[0]}</span>
          <span className="vs-styled">vs.</span>
          <span className="styled-team-name">{splittedTeams[1]}</span>
        </div>
        <span>{`${competitionName} ${seasonName}`}</span>
      </Col>
      <Col>
        <Label
          variant="Baisc"
          labelHeader={'KO\xa0\xa0\xa0:'}
          labelText={formatKO(date, kickOff)}
          inline
          className="mg-0 p-0"
        />
        <Label
          variant="Baisc"
          labelHeader="SLA :"
          labelText={calcSlaLongFormat(date, slaOffset, kickOff)}
          inline
          className="mg-0 p-0"
        />
      </Col>

      <Col lg={2} md={2} sm={1}>
        {collectionStatus && status && (
          <Label
            variant="MatchStatusLabel"
            status={status}
            collectionStatus={collectionStatus}
            className="mg-0 p-0"
          />
        )}
      </Col>
      <Col>
        {squad && (
          <Label
            variant="Label"
            background
            labelText={`Assigned to Squad ${squad.name}`}
            className="mg-0 p-0"
          />
        )}
      </Col>
    </Row>
  );
};

export default OfflineShiftMatchCard;
