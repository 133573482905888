import { gql } from '@apollo/client';

export default gql`
  query GetPCByProvider($providerId: String!, $matchId: Int!) {
    getPCByProvider(providerId: $providerId, matchId: $matchId) {
      id
      location {
        id
        name
        HQ
      }
      number
    }
  }
`;
