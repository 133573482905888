import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Dropdown from 'components/Dropdown';
import { COMPETITION_SEASONS_INFO_QUERY } from 'gql';
import { isEmpty } from 'ramda';
import { optionsFromArrComp, uniqById } from 'utils/helpers';

const CompetitionsFilter = ({
  competitionSeasonIds: ids = [],
  setPropState,
}) => {
  const [compSearchWord, setCompSearchWord] = useState('');
  const pageSize = 10;
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: CompetitionSeasonsData = {},
    loading: CompetitionSeasonsLoading,
    fetchMore,
  } = useQuery(COMPETITION_SEASONS_INFO_QUERY, {
    variables: {
      name: isEmpty(compSearchWord) ? undefined : compSearchWord,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
  });

  const loadMoreData = () => {
    setPage((prev) => prev + 1);
    fetchMore({
      variables: { queryParams: { page, pageSize } },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.competitionSeasons)) {
          setHasMore(false);
          return prev;
        }
        const competitionSeasonsInfo = uniqById([
          ...prev.competitionSeasonsInfo,
          ...fetchMoreResult.competitionSeasonsInfo,
        ]);
        return {
          ...prev,
          competitionSeasonsInfo,
        };
      },
    });
  };
  const onSearch = (v) => {
    setCompSearchWord(v);
    if (v && isEmpty(compSearchWord)) {
      setPage(1);
      setHasMore(true);
    }
  };
  const items = optionsFromArrComp(
    CompetitionSeasonsData.competitionSeasonsInfo
  );
  const onChange = (competitionSeasonIds) =>
    setPropState({ competitionSeasonIds });
  return (
    <Dropdown
      multiSelect
      variant="multiselect_obj_list"
      items={items}
      values={ids}
      onChange={onChange}
      loading={CompetitionSeasonsLoading}
      onSearch={onSearch}
      filterable
      searchPath={['label']}
      buttonText="Competition"
      className="match-assignment-filters"
      scrollLoading={CompetitionSeasonsLoading}
      fetchMore={loadMoreData}
      hasMore={hasMore}
      name="competition"
      data-testid="main-filters-competition"
    />
  );
};

export default CompetitionsFilter;
