import { gql } from '@apollo/client';

export default gql`
  query areas($name: String, $queryParams: QueryParams) {
    areas(name: $name, queryParams: $queryParams) {
      id
      name
      code
    }
  }
`;
