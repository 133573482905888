import { gql } from '@apollo/client';
import MATCH_FRAGMENT from '../fragments/match';

export default gql`
  query matches(
    $date: LocalDate
    $competitionSeasonIds: [Int]
    $teamIds: [Int]
    $priorityIds: [String!]
    $queryParams: QueryParams
    $assigned: Boolean
    $matchId: Int
    $lineId: String!
  ) {
    matches(
      date: $date
      competitionSeasonIds: $competitionSeasonIds
      teamIds: $teamIds
      priorityIds: $priorityIds
      queryParams: $queryParams
      assigned: $assigned
      matchId: $matchId
      lineId: $lineId
    ) {
      ...MatchFragmment
    }
  }
  ${MATCH_FRAGMENT}
`;
