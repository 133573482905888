import { gql } from '@apollo/client';
import USER_FRAGMENT from '../fragments/user';

export default gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
