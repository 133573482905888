import React, { useContext, createContext, useRef } from 'react';
import { Toaster } from '@blueprintjs/core';

const ToastContext = createContext(null);
export const useToast = () => useContext(ToastContext);

const ToastProvider = ({ children }) => {
  const toasterRef = useRef(null);
  const mapIconToClassName = (icon) => {
    const ICONS_CLASSES = {
      success: 'bg-success',
      warning: 'bg-warning2',
      error: 'bg-warning',
      none: '',
    };
    return <span className={ICONS_CLASSES[icon] ?? 'bg-success'} />;
  };

  const showToast = ({ intent = undefined, icon, timeout = 4000, message }) => {
    toasterRef.current.show({
      intent,
      icon: mapIconToClassName(icon),
      timeout,
      message,
    });
  };
  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toaster ref={toasterRef} maxToasts={5} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
