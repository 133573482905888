import { gql } from '@apollo/client';

export default gql`
  fragment MatchFragmment on Match {
    id
    kickOff
    date
    name
    week
    playStatus
    eta
    videoStatus
    videoIssueStatus
    competitionSeason {
      id
      competitionName
      seasonName
      providers {
        isDefault
        provider {
          id
          type
          ... on IPTV {
            iptv {
              videoOnDemand
              name
            }
          }
          ... on Streaming {
            streaming {
              videoOnDemand
              name
            }
          }
          ... on Channel {
            channel {
              name
            }
          }
        }
      }
    }
    homeTeam {
      id
      name
    }
    awayTeam {
      id
      name
    }
    area {
      id
      name
      code
    }
    priority {
      value
      slaOffset
    }
    collectionStatus
    status
    acquisitionType
    availableTime
    recommendedVideoETA
    plannedAcquisitionDate
    squadShifts {
      squadShift {
        id
      }
    }
    videoETA
  }
`;
