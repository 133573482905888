import React from 'react';
import { Dropdown } from 'components';
import { useIntl } from 'react-intl';
import { concat, isNil, map, prop, head, sort } from 'ramda';
import { useToast } from 'providers/toastProvider';
import { useQuery } from '@apollo/client';
import { SHIFTS_QUERY } from 'gql';
import { useProfile } from 'providers/profileProvider';
import { title } from 'utils/helpers';
import moment from 'moment';

const AcqIntervalFilter = ({ value, onChange, loading }) => {
  const { formatMessage } = useIntl();
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { showToast } = useToast();
  const { line } = jobTitleRole?.role;
  const { id: lineId } = line || {};
  const { data: shiftsData, loading: shiftsLoading } = useQuery(SHIFTS_QUERY, {
    skip: !lineId,
    variables: { lineId },
    fetchPolicy: 'cache-and-network',
    onError: () => {
      showToast({
        message: formatMessage({ id: 'containers.all.error' }),
        icon: 'error',
      });
    },
  });
  const { shifts } = prop('shifts', shiftsData);
  // This code relates to Adding a later option
  // checks last shift end time if between 12 am and 12 pm most probably its a next day shift
  // also sets the start at for later option to endAt of last shift
  // later sends only a start at to BE but flag is used to set the date for tomorrow if later and is next day
  const today = moment().format('YYYY-MM-DD');
  const sorter = (a, b) => {
    const aEnd = moment(`${today} ${a.endAt}`);
    const bEnd = moment(`${today} ${b.endAt}`);
    return aEnd.diff(bEnd);
  };
  const sortedShifts = sort(sorter, shifts);
  const lastEndingShift = head(sortedShifts);
  const lastEndingShiftHour = moment
    .utc(`${today} ${lastEndingShift.endAt}`)
    .hour();
  const isNextDayShift = lastEndingShiftHour > 0 && lastEndingShiftHour < 12;
  const dropdownItemsMapper = ({ shiftType, startAt, endAt }) => {
    return {
      label: title(shiftType),
      value: {
        shiftType,
        startAt,
        endAt,
      },
    };
  };
  const laterOption = [
    {
      label: 'Later',
      value: {
        startAt: lastEndingShift.endAt,
        endAt: undefined,
        isNextDayShift,
      },
    },
  ];
  const items = concat(map(dropdownItemsMapper, shifts), laterOption);
  const buttonText =
    value?.label ??
    formatMessage({
      id: 'containers.acquisitionTypes.form.interval',
    });
  return (
    <Dropdown
      items={items}
      value={value}
      onChange={onChange}
      loading={loading || shiftsLoading}
      buttonText={buttonText}
      disabled={isNil(items)}
      disableDropdownButton={isNil(items)}
      clearable
      data-testid="acq-interval-filter"
      name="acq-interval"
    />
  );
};

export default AcqIntervalFilter;
