import React from 'react';
import PropTypes from 'prop-types';
import { Button as BPButton } from '@blueprintjs/core';
import classNames from 'classnames';

const Button = ({
  active,
  alignText,
  disabled,
  fill,
  icon,
  intent,
  large,
  loading,
  minimal,
  onClick,
  outlined,
  rightIcon,
  small,
  text,
  type,
  elementRef,
  children,
  className,
  light,
  ...rest
}) => (
  <BPButton
    active={active}
    alignText={alignText}
    disabled={disabled}
    fill={fill}
    icon={icon}
    intent={intent}
    large={large}
    small={small}
    loading={loading}
    minimal={minimal}
    onClick={onClick}
    outlined={outlined}
    rightIcon={rightIcon}
    type={type}
    elementRef={elementRef}
    className={classNames(className, { light })}
    {...rest}
  >
    {children || text}
  </BPButton>
);

export default Button;

Button.propTypes = {
  active: PropTypes.bool,
  alignText: PropTypes.oneOf(['left', 'center', 'right']),
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, undefined]),
  intent: PropTypes.oneOf(['Primary', 'Success', 'Warning', 'Danger']),
  large: PropTypes.bool,
  loading: PropTypes.bool,
  minimal: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, undefined]),
  small: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf(['reset', 'submit', 'button']),
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
    undefined,
  ]),
};

Button.defaultProps = {
  active: false,
  alignText: 'left',
  disabled: false,
  fill: false,
  icon: undefined,
  intent: undefined,
  large: false,
  loading: false,
  minimal: false,
  onClick: () => {},
  outlined: false,
  rightIcon: undefined,
  small: false,
  text: '',
  type: 'button',
  elementRef: undefined,
};
