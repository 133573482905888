import { gql } from '@apollo/client';

export default gql`
  query Locations {
    locations {
      locations {
        name
        type
        HQ
        id
      }
      totalCount
    }
  }
`;
