import { gql } from '@apollo/client';

export default gql`
  mutation SwapMatches($input: swapMatchesInput!) {
    swapMatches(input: $input) {
      match {
        id
      }
    }
  }
`;
