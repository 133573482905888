import { gql } from '@apollo/client';
import SHIFT_FRAGMENT from '../fragments/shift';

export default gql`
  query Shifts($lineId: String!, $queryParams: QueryParams) {
    shifts(lineId: $lineId, queryParams: $queryParams) {
      totalCount
      shifts {
        ...ShiftFragment
      }
    }
  }
  ${SHIFT_FRAGMENT}
`;
