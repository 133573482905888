import React, { useState } from 'react';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { range, prop } from 'ramda';
import {
  Divider,
  Dropdown,
  Icon,
  Label,
  TextInput,
  Button,
  Text,
} from 'components';
import {
  CREATE_PRIORITY_CATEGORY_MUTATION,
  PRIORITY_CATEGORY_VALUES_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { hasGraphQlErrors, optionsFromArr } from 'utils/helpers';
import { ADD_NEW_PRIORITY } from 'validations';
import { useIntl } from 'react-intl';
import { RenderIf } from 'config';
import { COMPETITION_SEASON, TEAM } from 'utils/enums';

export default function AddNewPriority() {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { type, maxRank } = prop('payload', modalState);
  const rankList = range(1, maxRank + 2);
  const [err, setErr] = useState(false);
  const modalType = type === 'teams' ? TEAM : COMPETITION_SEASON;

  const [CreatePriorityCategoryMutation, { loading }] = useMutation(
    CREATE_PRIORITY_CATEGORY_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: ({ graphQLErrors }) => {
        if (hasGraphQlErrors(graphQLErrors, 'P2002')) {
          setErr(true);
        } else {
          modalDispatch({ type: 'ClOSE_ALL' });
          showToast({
            message: formatMessage({
              id: 'containers.priorities.settings.addNewPriority.error',
            }),
            icon: 'error',
          });
        }
      },

      refetchQueries: [
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: TEAM },
        },
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: COMPETITION_SEASON },
        },
      ],
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({ id: 'containers.all.success' }),
        });
      },
    }
  );
  const initialValues = {
    name: '',
    rank: null,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ADD_NEW_PRIORITY,

    onSubmit: (values) => {
      CreatePriorityCategoryMutation({
        variables: {
          input: {
            type: modalType,
            value: values.name,
            rank: values.rank,
          },
        },
      });
    },
  });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className={classNames('flex', 'gap-3', 'items-center')}>
          <Icon icon="plus" size={15} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({
              id: 'containers.priorities.settings.title.addNewPriority',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '42%' }}
    >
      <div className="Add-priorty-category-container">
        <Label
          variant="Baisc"
          labelHeader="Category"
          labelText={`${type === 'teams' ? 'Teams' : 'Competition'} Priorities`}
        />
        <Divider className="mgt-21 mgb-15" />
        <div className="flex gap-15">
          <TextInput
            required
            type="text"
            labelText={formatMessage({ id: 'containers.all.name' })}
            placeholder={formatMessage({ id: 'containers.all.name' })}
            value={formik.values.name}
            onChange={formik.handleChange('name')}
            error={formik.touched.name && formik.errors.name}
            className={classNames({
              'selected-input-highlight': formik.values.name,
            })}
            name="name"
            data-testid="priorities-addNewPriority-name"
          />
          <RenderIf condition={err}>
            <div
              className={classNames(
                'flex',
                'gap-10',
                'Add-priorty-category-error-msg'
              )}
            >
              <Icon customIcon="warning" size={18} />
              <Text
                variant="body2"
                data-testid="priorities-addNewPriority-warning"
              >
                {formatMessage({
                  id: 'containers.priorities.settings.addNewPriority.warning',
                })}
              </Text>
            </div>
          </RenderIf>
        </div>
        <Dropdown
          value={formik.values.rank || ''}
          items={optionsFromArr(rankList)}
          onChange={(v) => formik.setFieldValue('rank', v.value)}
          buttonText={formatMessage({ id: 'containers.priorities.rank' })}
          loading={!rankList}
          popOverPosition="bottom-left"
          className={classNames({
            'selected-dropdown-highlight': formik.values.rank,
          })}
          error={formik.touched.rank && formik.errors.rank}
          name="rank"
          data-testid="priorities-addNewPriority-rank"
        />
      </div>
      <div
        className="bp3-dialog-footer-actions"
        data-testid="addNewPriority-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({
            id: 'containers.priorities.settings.title.addNewPriority',
          })}
          onClick={formik.submitForm}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
}
