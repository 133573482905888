import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Popover2 } from '@blueprintjs/popover2';
import copy from 'copy-to-clipboard';

const CopyableLabel = ({
  labelHeader,
  labelText,
  inline,
  className,
  timeOutIn = 1000,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const timeToClosePopOver = setTimeout(() => setIsOpen(false), timeOutIn);
    return () => clearTimeout(timeToClosePopOver);
  }, [isOpen]);

  return (
    <div
      className={classNames(className, {
        'bp3-inline': inline,
        'horizontal-label': !inline,
      })}
    >
      <span>{labelHeader}</span>
      <Popover2
        content="Copied!"
        interactionKind="click"
        placement="top"
        isOpen={isOpen}
        popoverClassName="copied-pop"
      >
        <button
          className="copy-btn"
          title="Click to copy"
          onClick={() => {
            copy(labelText);
            setIsOpen(true);
          }}
          type="button"
        >
          {labelText}
        </button>
      </Popover2>
    </div>
  );
};

export default CopyableLabel;
