import { ascend, equals, isEmpty, prop, sortWith } from 'ramda';
import { REPORT_ISSUES, VIDEO_ISSUES } from 'utils/enums';
import { camelize, isVideo, optionsFromArr, title } from 'utils/helpers';
import { REPORT_ISSUE, REPORT_ISSUE_VIDEO } from 'validations';

const offlineSharedInitialValues = ({ details, reason, type, comment }) => {
  return {
    reason: reason || '',
    type: type || '',
    comment: comment || '',
    badShirt: {
      teamSide: details?.teamSide || '',
    },
    badVideo: {
      isWholeMatch: details?.isWholeMatch,
      half: details?.half || undefined,
      from: details?.from || undefined,
      to: details?.to || undefined,
    },
    missingPlayer: {
      teamSide: details?.teamSide || 'HOME',
      name: details?.name || undefined,
      shirtNumber: details?.shirtNumber || undefined,
    },
    videoIssue: {
      half: details?.half || undefined,
      from: details?.from || undefined,
      to: details?.to || undefined,
      isWholeMatch: details?.isWholeMatch,
    },
  };
};
const offlineInitialValuesReport = ({
  id,
  type,
  comment,
  details,
  reason,
}) => ({
  ...offlineSharedInitialValues({ details, id, reason, type, comment }),
  id: id || '',
});

const offlineInitialValuesBlock = ({
  matchId,
  type,
  comment,
  details,
  reason,
}) => ({
  ...offlineSharedInitialValues({ details, reason, type, comment }),
  issueId: null,
  matchId,
});
const videoSharedInitialValues = ({ type, comment, details }) => ({
  type: type || '',
  comment: comment || '',
  details: {
    to: details?.to || undefined,
    from: details?.from || undefined,
  },
});
export const videoInitialValuesReport = ({ id, type, comment, details }) => ({
  ...videoSharedInitialValues({ type, comment, details }),
  id: id || '',
});
export const videoInitialValuesBlock = ({
  matchId,
  type,
  comment,
  details,
}) => ({
  ...videoSharedInitialValues({ type, comment, details }),
  matchId,
  issueId: null,
});

export const reportIssueInitialValues = {
  VIDEO: videoInitialValuesReport,
  OFFLINE: offlineInitialValuesReport,
};
export const blockIssueInitialValues = {
  VIDEO: videoInitialValuesBlock,
  OFFLINE: offlineInitialValuesBlock,
};

export const getIssuesList = (isVideoUser) =>
  isVideoUser ? VIDEO_ISSUES : REPORT_ISSUES;
export const getIssuesDropDownOptions = ({
  isVideoUser,
  formatMessage,
  isEdit,
}) => {
  const issuesList = getIssuesList(isVideoUser);
  return {
    buttonText: formatMessage({ id: 'containers.all.reason' }),
    disabled: isEdit,
    disableDropdownButton: isEdit,
    loading: !issuesList,
    items: optionsFromArr(issuesList),
    'data-testid': 'dashboard-reportIssue-type',
  };
};

export const getReportValidationSchema = (userLineName) =>
  isVideo(userLineName) ? REPORT_ISSUE_VIDEO : REPORT_ISSUE;

export const offlineDetailsFormSubmit = (values) =>
  !isEmpty(values[camelize(title(values.type))])
    ? values[camelize(title(values.type))]
    : undefined;
export const videoDetailsFormSubmit = ({ details }) => {
  return !isEmpty(details) &&
    !equals(details, { to: undefined, from: undefined })
    ? details
    : undefined;
};

export const sortByStatus = sortWith([ascend(prop('status'))]);
