import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';
import { RenderIf } from 'config';
import { Button } from 'components';
import Scrollable from '../Scrollable';

const ButtonGroup = ({ items, onClick, scrollable, loading, name }) => {
  if (loading) return <Spinner />;
  return (
    <div data-testid={`buttonGroup-container-${name}`}>
      <RenderIf condition={scrollable}>
        <Scrollable>
          {items.map((item) => (
            <Button
              onClick={() => onClick(item)}
              text={item.text}
              active={item?.isActive}
              icon={item?.icon}
              outlined
            />
          ))}
        </Scrollable>
      </RenderIf>
    </div>
  );
};

export default ButtonGroup;
ButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  scrollable: PropTypes.bool,
};
ButtonGroup.defaultProps = {
  loading: false,
  scrollable: true,
};
