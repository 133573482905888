import React from 'react';
import { Text } from 'components';
import { renderCardPropValue } from './helpers';

function CardAttribute({ name, value, renderFn }) {
  return (
    <div className="flex gap-5 center">
      <Text variant="body2" className="color-qs">
        {`${name}:`}
      </Text>
      <Text variant="body2">{renderCardPropValue(value, renderFn)}</Text>
    </div>
  );
}

export default CardAttribute;
