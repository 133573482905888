import { gql } from '@apollo/client';

export default gql`
  query MatchOfflineTasks($matchId: Int!) {
    matchOfflineTasks(matchId: $matchId) {
      id
      matchId
      member {
        id
        user {
          firstName
          lastName
          hrCode
          id
        }
      }
      half
      type
      teamSide
      part
    }
  }
`;
