import React from 'react';
import classNames from 'classnames';

const TextAreaLimited = ({
  maxLength,
  showMaxLength,
  onChange,
  placeholder,
  value,
  className,
  error,
}) => (
  <div className={classNames(['text-area-container', className])}>
    <textarea
      // growVertically={false}
      // large
      onChange={onChange}
      value={value}
      style={{ resize: 'none' }}
      placeholder={placeholder}
      cols={30}
      rows={4}
      maxLength={maxLength}
      data-testid="main-textArea"
    />
    {showMaxLength && (
      <div className="max-length-indicator">
        <span className={classNames({ error: value.length > maxLength })}>
          {value.length}
        </span>
        <span>/</span>
        <span>{maxLength}</span>
      </div>
    )}
    {error && <div className="error-text">{error}</div>}
  </div>
);

export default TextAreaLimited;
