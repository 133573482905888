import { gql } from '@apollo/client';

export default gql`
  mutation CreateCompetitionSeasonsPriority(
    $input: createCompetitionSeasonsPriorityInput!
  ) {
    createCompetitionSeasonsPriority(input: $input) {
      count
    }
  }
`;
