import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Dropdown from 'components/Dropdown';
import { PROVIDERS_QUERY } from 'gql';
import { isEmpty, map, uniq } from 'ramda';
import { useIntl } from 'react-intl';
import { useToast } from 'providers/toastProvider';

const ProviderFilter = ({ provider, onProviderChange, selectedProvider }) => {
  const { showToast } = useToast();
  const [page, setPage] = useState(2);
  const pageSize = 10;
  const [searchWord, setSearchWord] = useState();
  const [hasMore, setHasMore] = useState(true);
  const { formatMessage } = useIntl();

  const variables = {
    searchWord,
    queryParams: { pageSize, page: 1 },
    providerTypes: undefined,
  };
  const {
    loading: providersLoading,
    data: providersData,
    fetchMore,
  } = useQuery(PROVIDERS_QUERY, {
    variables,
    onError: () =>
      showToast({
        message: formatMessage({ id: 'containers.all.error' }),
        icon: 'error',
      }),
  });
  const nameFromProvider = (p) => p[p.type?.toLowerCase()]?.name;
  const labelMapper = (p) => ({
    label: `${p.type}${nameFromProvider(p) ? ` - ${nameFromProvider(p)}` : ''}`,
    value: p.id,
  });
  const providers = map(labelMapper, providersData?.providers?.providers || []);
  const items = selectedProvider
    ? uniq([...map(labelMapper, [selectedProvider]), ...providers])
    : providers;
  const loadMoreData = () => {
    setPage((prev) => prev + 1);
    fetchMore({
      variables: {
        searchWord,
        queryParams: { pageSize, page },
      },

      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        const noMore =
          !fetchMoreResult || isEmpty(fetchMoreResult?.providers?.providers);
        if (noMore) {
          setHasMore(false);
          return prev;
        }
        const data = {
          ...prev,
          providers: {
            providers: [
              ...prev?.providers?.providers,
              ...fetchMoreResult?.providers?.providers,
            ],
            totalCount: prev?.providers?.totalCount,
            __typename: 'ProvidersList',
          },
        };
        return data;
      },
    });
  };
  const onSearch = (v) => {
    setSearchWord(v);
    if (v && isEmpty(searchWord)) {
      setPage(1);
      setHasMore(true);
    }
  };
  return (
    <Dropdown
      value={provider || ''}
      items={items}
      onChange={({ value }) => onProviderChange(value)}
      buttonText={formatMessage({
        id: 'containers.leagues.provider',
      })}
      loading={providersLoading}
      filterable
      searchPath={['label']}
      popOverPosition="bottom-right"
      onSearch={onSearch}
      fetchMore={loadMoreData}
      scrollLoading={providersLoading}
      hasMore={hasMore}
      clearable
    />
  );
};

export default ProviderFilter;
