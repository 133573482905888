import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import { Label, TimePicker } from 'components';
import { RenderIf } from 'config';
import { is } from 'ramda';

const TimeStampPicker = ({
  fromValue,
  toValue,
  onFromChange,
  onToChange,
  touched,
  errors,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={classNames(['flex', 'column'])}>
      <div className={classNames(['flex', 'items-center', 'gap-15'])}>
        <Label
          labelText={`${formatMessage({
            id: 'containers.all.timeStamp',
          })}:`}
          variant="Baisc"
        />
        <Label
          labelText={`${formatMessage({ id: 'containers.all.from' })}:`}
          variant="Baisc"
          className="timeStamp"
        />
        <TimePicker
          onChange={onFromChange}
          value={fromValue}
          error={touched?.details?.from && errors?.details?.from}
          name="from"
        />
        <Label
          labelText={`${formatMessage({ id: 'containers.all.to' })}:`}
          variant="Baisc"
          className="timeStamp"
        />
        <TimePicker
          onChange={onToChange}
          value={toValue}
          error={touched?.details?.to && errors?.details?.to}
          name="to"
        />
      </div>
      <RenderIf condition={touched?.details && is(String)(errors?.details)}>
        <span className="error-text">{errors?.details}</span>
      </RenderIf>
      <span className="timestamp-info-container pb-15">
        <FormattedMessage id="containers.all.timeStamp.warning" />
      </span>
    </div>
  );
};

export default TimeStampPicker;
