import React from 'react';
import { Avatar } from 'components';
import classNames from 'classnames';

const AvatarLabel = ({ labelText, className, avatarText, size, ...rest }) => (
  <div className={classNames('avatar-label', className)} {...rest}>
    <Avatar size={size} name={avatarText || labelText || ''} />
    <span>{labelText}</span>
  </div>
);

export default AvatarLabel;
