import { gql } from '@apollo/client';
import { MATCH_FRAGMENT } from 'gql';

export default gql`
  query PCsMatches(
    $lineId: String
    $dates: [LocalDate]
    $queryParams: QueryParams
    $matchId: Int
    $priorityIds: [String!]
    $providerTypes: [ProviderType!]
    $acquisitionTypes: [AcquisitionType!]
    $featureIds: [String!]
    $assigned: Boolean
    $pcId: String
  ) {
    PCsMatches(
      lineId: $lineId
      dates: $dates
      queryParams: $queryParams
      matchId: $matchId
      priorityIds: $priorityIds
      providerTypes: $providerTypes
      acquisitionTypes: $acquisitionTypes
      featureIds: $featureIds
      assigned: $assigned
      pcId: $pcId
    ) {
      matches {
        ...MatchFragmment
        pcs {
          match {
            id
          }
          pc {
            id
            location {
              id
              name
              type
              HQ
            }
            number
          }
        }
      }
      totalCount
    }
  }
  ${MATCH_FRAGMENT}
`;
