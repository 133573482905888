import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Dropdown from 'components/Dropdown';
import { AREA_QUERY } from 'gql';
import { isEmpty, uniq } from 'ramda';
import { optionsFromArr } from 'utils/helpers';
import { useIntl } from 'react-intl';

const AreaFilter = ({ area, onAreaChange, selectedArea, disabled }) => {
  const [page, setPage] = useState(2);
  const [countrySearch, setCountrySearch] = useState();
  const [areaHasMore, setAreaHasMore] = useState(true);
  const { formatMessage } = useIntl();
  const {
    data: areasData,
    loading: areasLoading,
    fetchMore,
  } = useQuery(AREA_QUERY, {
    skip: disabled,
    variables: {
      name: countrySearch,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const items = selectedArea?.value
    ? uniq([...optionsFromArr(areasData && areasData.areas), selectedArea])
    : optionsFromArr(areasData && areasData.areas);
  const loadMoreData = () => {
    setPage(page + 1);
    fetchMore({
      variables: {
        name: countrySearch,
        queryParams: {
          pageSize: 10,
          page,
        },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      updateQuery: (prev = {}, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.areas)) {
          setAreaHasMore(false);
          return prev;
        }
        return {
          ...prev,
          areas: [...prev?.areas, ...fetchMoreResult?.areas],
        };
      },
    });
  };
  return (
    <Dropdown
      value={area || ''}
      items={items}
      onChange={({ value }) => onAreaChange(value)}
      buttonText={formatMessage({
        id: 'containers.all.dropdown.country',
      })}
      loading={areasLoading}
      filterable
      searchPath={['label']}
      popOverPosition="bottom-right"
      onSearch={setCountrySearch}
      fetchMore={loadMoreData}
      scrollLoading={areasLoading}
      hasMore={areaHasMore}
      data-testid="main-area-filter"
      clearable={!disabled}
      disabled={disabled}
      disableDropdownButton={disabled}
    />
  );
};

export default AreaFilter;
