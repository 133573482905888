import { gql } from '@apollo/client';

export default gql`
  query squadShifts(
    $squadId: String!
    $queryParams: QueryParams
    $dates: [Date!]
  ) {
    squadShifts(squadId: $squadId, queryParams: $queryParams, dates: $dates) {
      totalCount
      squadShifts {
        id
        date
        shiftId
        squadId
        shift {
          shiftType
        }
        squadMembers {
          id
          power
          userId
          user {
            firstName
            lastName
          }
          vacationType
        }
      }
    }
  }
`;
