/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextInput = ({
  labelText,
  placeholder,
  type,
  required,
  onChange,
  error,
  value,
  className,
  name,
  ...rest
}) => {
  const [empty, setEmpty] = useState(true);
  useEffect(() => {
    if (value && value?.length) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    return () => {};
  }, [value]);
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };
  return (
    <div
      className={classNames(
        'text-input-container',
        { 'not-empty': !empty, error },
        className
      )}
    >
      <input
        type={type}
        required={required}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        name={name || labelText}
        {...rest}
      />
      <label>{labelText}</label>
      {error && (
        <div className="error-text" data-testid={`main-errors-${name}`}>
          {error}
        </div>
      )}
    </div>
  );
};
export default TextInput;

TextInput.propTypes = {
  required: PropTypes.bool,
  labelText: PropTypes.string,
  htmlpattern: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  required: false,
  labelText: '',
  placeholder: '',
  htmlpattern: '',
  onChange: () => {},
};
