import React from 'react';
import { Button, Dialog, Icon, Text } from 'components';
import { useModal } from 'providers/modalProvider';
import { FormattedMessage } from 'react-intl';
import { isVideo as isVideoUser, title } from 'utils/helpers';
import { useProfile } from 'providers/profileProvider';
import { SPORTS } from 'utils/enums';
import { toUpper } from 'ramda';

const ConfirmInitImpersonation = () => {
  const { modalState, modalDispatch } = useModal();
  const { handleImpersonation } = useProfile();
  const { payload: type } = modalState;

  const isVideo = isVideoUser(type);
  const handleCancel = () => {
    modalDispatch({
      type: 'ClOSE_ALL',
    });
  };
  const handleConfirm = (type) => {
    handleImpersonation({
      lineName: type,
      sport: toUpper(isVideo ? SPORTS.pregame : SPORTS.football),
    });
    modalDispatch({
      type: 'ClOSE_ALL',
    });
  };
  const modalTitle = isVideo ? title(type) : `${title(type)} Soccer`;
  return (
    <Dialog
      dialogTitle={
        <FormattedMessage
          id="containers.impersonation.modal.title"
          values={{ type: modalTitle }}
        />
      }
      icon={<Icon customIcon="impersonation" size={18} />}
      width="541px"
    >
      <div className="bp3-dialog-body mgt-8">
        <Text variant="h3">
          <FormattedMessage
            id="containers.impersonation.modal.switch"
            values={{ type: modalTitle }}
          />
        </Text>
        <Text variant="body3" className="flex gap-10">
          <Icon customIcon="warning2" size={18} />
          <FormattedMessage id="containers.impersonation.modal.viewOnlyMode" />
        </Text>
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={handleCancel}
          />
          <Button
            intent="Primary"
            text={<FormattedMessage id="containers.all.continue" />}
            onClick={() => handleConfirm(type)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmInitImpersonation;
