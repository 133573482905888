import { RenderIf } from 'config';
import { toLower } from 'ramda';
import { title } from 'utils/helpers';

const DetailsRender = (type, details, shiftMatch) => {
  const videoIssue = (
    <>
      {details?.isWholeMatch === true ? (
        'Whole Match Video'
      ) : (
        <>
          in the
          {title(details?.half)}
          &nbsp; half from &nbsp;
          {details?.from}
          &nbsp; to&nbsp;
          {details?.to}
        </>
      )}
    </>
  );
  const missingPlayer = (
    <>
      <RenderIf condition={details?.name || details?.shirtNumber}>
        Missing Player &nbsp;
        {title(details?.name)}
        &nbsp;
        <>#</>
        {details?.shirtNumber}
      </RenderIf>
      &nbsp; from team :
      {shiftMatch[`${toLower(details?.teamSide || '')}Team`]?.name}
      <>-</>
      {title(details?.teamSide)}
    </>
  );
  const badShift = (
    <>
      team side: &nbsp;
      {title(details?.teamSide)}
      &nbsp;
      <>-</>
      {shiftMatch[`${toLower(details?.teamSide || '')}Team`]?.name}
    </>
  );

  const missingMinutesOrConnectionIssue = (
    <RenderIf condition={details?.from && details?.to}>
      from &nbsp;
      {details?.from}
      &nbsp; to&nbsp;
      {details?.to}
    </RenderIf>
  );
  const mapIssueTypeToDetails = {
    VIDEO_ISSUE: videoIssue,
    BAD_SHIRT: badShift,
    BAD_VIDEO: videoIssue,
    MISSING_PLAYER: missingPlayer,
    MISSING_MINUTES: missingMinutesOrConnectionIssue,
    CONNECTION_ISSUE: missingMinutesOrConnectionIssue,
  };
  return mapIssueTypeToDetails[type] || false;
};
export default DetailsRender;
