import React from 'react';
import { Dropdown } from 'components';
import { optionsFromArr, isVideo } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import { ISSUES_TYPES, VIDEO_ISSUES } from 'utils/enums';
import { useProfile } from 'providers/profileProvider';

const IssueTypeFilter = ({ values = [], onChange }) => {
  const { formatMessage } = useIntl();
  const buttonText = formatMessage({
    id: 'containers.dashboard.issueTypes',
  });
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { name: userLineName } = line || {};
  const typesArr = isVideo(userLineName) ? VIDEO_ISSUES : ISSUES_TYPES;
  const items = optionsFromArr(typesArr);
  return (
    <Dropdown
      items={items}
      values={values}
      onChange={onChange}
      multiSelect
      buttonText={buttonText}
      loading={isNil(items)}
      disabled={isNil(items)}
      disableDropdownButton={isNil(items)}
      name="issueType"
    />
  );
};

export default IssueTypeFilter;
