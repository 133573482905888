import { gql } from '@apollo/client';
import PRIORITY_FRAGMENT from '../fragments/priority';

export default gql`
  query priorities($queryParams: QueryParams) {
    priorities(queryParams: $queryParams) {
      ...PriorityFragment
    }
  }
  ${PRIORITY_FRAGMENT}
`;
