import React from 'react';
import { Dropdown } from 'components';
import { useIntl } from 'react-intl';
import { getFunctions, optionsFromArr } from 'utils/helpers';
import { useProfile } from 'providers/profileProvider';
import { isNil } from 'ramda';

const FunctionsFilter = ({
  lineId,
  lines = [],
  lineFunctionId,
  onLineFunctionChanges,
  sport,
}) => {
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole, lineFunction: userLineFunction } =
    profile;
  const {
    role: { name: roleName },
  } = userJobTitleRole;
  const isSuperAdmin = roleName === 'super-admin';
  const disableLineFunction = !isSuperAdmin && !isNil(userLineFunction);
  const { formatMessage } = useIntl();
  const functionsList = getFunctions(lines, sport, lineId);
  return (
    <Dropdown
      items={optionsFromArr(functionsList)}
      value={lineFunctionId}
      onChange={({ value }) => onLineFunctionChanges(value)}
      buttonText={formatMessage({ id: 'containers.users.form.lineFunction' })}
      loading={!functionsList}
      data-testid="main-filters-functions"
      disableDropdownButton={disableLineFunction}
      disabled={disableLineFunction}
      name="function"
    />
  );
};

export default FunctionsFilter;
