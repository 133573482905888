import { gql } from '@apollo/client';

export default gql`
  mutation updatePriorityCategory($input: UpdatePriorityCategoryInput!) {
    updatePriorityCategory(input: $input) {
      id
      value
    }
  }
`;
