import { gql } from '@apollo/client';

export default gql`
  mutation DeleteCompetitionSeasonProviders(
    $input: deleteCompetitionSeasonProvidersInput!
  ) {
    removeCompetitionSeasonProviders(input: $input) {
      id
    }
  }
`;
