import { gql } from '@apollo/client';
import ChannelFregment from '../fragments/channel';
import StreamingFregment from '../fragments/streaming';
import IptvFregment from '../fragments/iptv';

export default gql`
  query Providers(
    $providerTypes: [ProviderType]
    $queryParams: QueryParams
    $searchWord: String
  ) {
    providers(
      providerTypes: $providerTypes
      queryParams: $queryParams
      searchWord: $searchWord
    ) {
      totalCount
      providers {
        id
        type
        ... on IPTV {
          iptv {
            ...IptvFregment
          }
        }
        ... on Streaming {
          streaming {
            ...StreamingFregment
          }
        }
        ... on Channel {
          channel {
            ...ChannelFregment
          }
        }
      }
    }
  }
  ${ChannelFregment}
  ${StreamingFregment}
  ${IptvFregment}
`;
