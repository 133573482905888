import React, { Component } from 'react';
import { FullScreenErrorMsg } from 'components';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error: error.toString() };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.log(error.toString(), '\n', info.componentStack);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return <FullScreenErrorMsg />;
    }
    return children;
  }
}

export default ErrorBoundary;
