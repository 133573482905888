import { DateObject } from 'react-multi-date-picker';

const today = new DateObject().format('YYYY-MM-DD');
export const dashboardInitialState = {
  activeDate: today,
  searchValue: undefined,
  shift: undefined,
  openFilters: false,
  filtersData: {},
};

export const dashboardActions = {
  SET_DATE: 'SET_DATE',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_SHIFT: 'SET_SHIFT',
  SET_OPEN_FILTERS: 'SET_OPEN_FILTERS',
  SET_ACTIVE_FILTERS_DATA: 'SET_ACTIVE_FILTERS_DATA',
};

export const dashboardReducer = (state, action) => {
  const { type, payload } = action;
  if (type === dashboardActions.SET_SEARCH_VALUE) {
    return { ...state, filtersData: {}, ...payload };
  }

  if (type === dashboardActions.SET_SHIFT) {
    return { ...state, ...payload };
  }

  if (type === dashboardActions.SET_ACTIVE_FILTERS_DATA) {
    return { ...state, searchValue: undefined, ...payload };
  }

  if (type === dashboardActions.SET_OPEN_FILTERS) {
    return { ...state, ...payload };
  }

  if (type === dashboardActions.SET_DATE) {
    return { ...state, ...payload };
  }
  return state;
};
