import { gql } from '@apollo/client';

export default gql`
  query SquadShiftsPowerAndCapacity(
    $date: Date!
    $squadIds: [String]
    $shiftId: String
    $lineId: String!
  ) {
    squadsPerLine: squadShiftsPowerAndCapacity(
      date: $date
      squadIds: $squadIds
      shiftId: $shiftId
      lineId: $lineId
    ) {
      name
      power
      capacity
      squadShifts {
        squadId
        shift {
          shiftType
          id
        }
        id
      }
    }
  }
`;
