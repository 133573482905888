import { useQuery } from '@apollo/client';
import Dropdown from 'components/Dropdown';
import { TEAMS_QUERY } from 'gql';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { optionsFromArr } from 'utils/helpers';

const TeamsFilter = ({ teamIds, setPropState }) => {
  const [searchTeam, setSearchTeam] = useState();
  const [teamsPage, setTeamsPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const pageSize = 10;
  const {
    data: teamsData,
    loading: teamsLoading,
    fetchMore,
  } = useQuery(TEAMS_QUERY, {
    variables: {
      name: searchTeam,
      queryParams: {
        pageSize,
        page: 1,
      },
    },
  });

  const loadMoreData = () => {
    setTeamsPage((prev) => prev + 1);
    fetchMore({
      variables: {
        queryParams: {
          page: teamsPage,
          pageSize: 10,
        },
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.teams)) {
          setHasMore(false);
          return prev;
        }
        return { ...prev, teams: [...prev?.teams, ...fetchMoreResult?.teams] };
      },
    });
  };

  return (
    <Dropdown
      variant="multiselect_obj_list"
      values={teamIds || []}
      items={optionsFromArr(teamsData && teamsData.teams)}
      onChange={(ids) => setPropState({ teamIds: ids })}
      multiSelect
      buttonText="Teams"
      loading={teamsLoading}
      filterable
      searchPath={['label']}
      popOverPosition="bottom-left"
      onSearch={setSearchTeam}
      fetchMore={loadMoreData}
      scrollLoading={teamsLoading}
      className="match-assignment-filters"
      hasMore={hasMore}
      name="teams"
    />
  );
};

export default TeamsFilter;
