import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'components';
import { useModal } from 'providers/modalProvider';
import { useNavigation, useCurrentRoute } from 'react-navi';
import PropTypes from 'prop-types';

const Tab = ({
  link,
  localizedText,
  text,
  isCollapsed,
  isVertical,
  icon,
  small,
  onClick,
  isActive: isActiveItem,
}) => {
  const { modalDispatch } = useModal();
  const navigator = useNavigation();
  const { url: currentUrl } = useCurrentRoute();

  const isActive = currentUrl.pathname.includes(link) || isActiveItem;
  const children = (
    <>
      {icon?.icon && (
        <Icon
          data-testid={`side-nav-${icon.icon}`}
          {...icon}
          color={isActive ? '#0044cc' : undefined}
        />
      )}
      {icon?.customIcon && (
        <span
          data-testid={`side-nav-${icon.customIcon}`}
          className={
            isActive ? `bg-active_${icon.customIcon}` : `bg-${icon.customIcon}`
          }
        />
      )}

      {!isCollapsed && (
        <span className="mgl-10">
          {localizedText ? <FormattedMessage id={localizedText} /> : text}
        </span>
      )}
    </>
  );

  return (
    <Button
      onClick={() => {
        if (onClick) {
          modalDispatch({
            type: onClick,
          });
        } else {
          navigator.navigate(link);
        }
      }}
      minimal
      className={classNames('tab-btn', {
        active: isActive,
        vertical: isVertical,
        horizontal: !isVertical,
        collapsed: isCollapsed,
        small,
      })}
      data-testid={`side-nav${link.replaceAll('/', '-')}`}
    >
      {children}
    </Button>
  );
};
export default Tab;
Tab.propTypes = {
  link: PropTypes.string,
  localizedText: PropTypes.string,
  text: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isVertical: PropTypes.bool,
  icon: PropTypes.shape({}),
  onClick: PropTypes.func,
};

Tab.defaultProps = {
  link: '',
  onClick: undefined,
  localizedText: '',
  text: '',
  isCollapsed: false,
  isVertical: false,
  icon: {},
};
