import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { useFormik } from 'formik';
import {
  prop,
  pluck,
  filter,
  pipe,
  map,
  isEmpty,
  isNil,
  chain,
  identity,
} from 'ramda';
import { Dropdown, Icon, RadioGroup, Text } from 'components';
import {
  AREA_QUERY,
  CREATE_COMPETITION_SEASONS_PRIORITY_MUTATION,
  CREATE_TEAMS_PRIORITY_MUTATION,
  PRIORITY_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { PRIORITY_IS_FEMALE } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';
import { useCurrentRoute } from 'react-navi';
import { useIntl } from 'react-intl';
import { EDIT_PRIORITY } from 'validations';
import { RenderIf } from 'config';
import PriorityDetailsSection from './PriorityDetailsSection';
import CompetitionAssignPrDropDwon from './CompetitionAssignPrDropDwon';
import TeamsAssignDropDown from './TeamsAssignDropDown';

const AssignPriorityModal = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const [isFemale, setIsFemale] = useState();
  const [errPreAssigned, setErrPreAssigned] = useState();
  const [validateCountryAndType, setValidateCountryAndType] = useState(false);
  const [countrySearch, setCountrySearch] = useState();
  const [area, setArea] = useState();
  const screenType = prop('screenType', modalState);
  const screenTypeId =
    screenType === 'TEAM' ? 'teamIds' : 'competitionSeasonIds';
  const { formatMessage } = useIntl();
  const screenParams = useCurrentRoute().lastChunk.request.params;
  const [areaHasMore, setAreaHasMore] = useState(true);

  const initialValues = {
    priorityCategory: screenParams?.id,
    priority: screenParams?.priorityId,
    priorityCategoryName: screenParams?.cat,
    [screenTypeId]: [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EDIT_PRIORITY,
    onSubmit: (values) => {
      const assignPriorityInput = {
        priorityId: formik.values.priority,
        [screenTypeId]: pluck('value', values[screenTypeId]),
      };
      // eslint-disable-next-line no-use-before-define
      usedMutation[screenType](assignPriorityInput);
    },
  });

  const getPreAssignedLabels = (graphQLIds) => {
    const filterDiff = (id) =>
      filter(({ value }) => value === id, formik?.values?.[screenTypeId] ?? []);
    const labelsOfPreAssignedTeams = pipe(
      map(filterDiff),
      chain(identity)
    )(graphQLIds);

    return labelsOfPreAssignedTeams;
  };

  const setFormikField = (field, value) => formik.setFieldValue(field, value);
  const closeModal = useCallback(
    () => modalDispatch({ type: 'ClOSE_ALL' }),
    []
  );
  const refetchQueries = [
    {
      query: PRIORITY_QUERY,
      variables: { priorityId: formik.values.priority },
    },
  ];

  const onError = ({ graphQLErrors }) => {
    const { ids } = graphQLErrors[0]?.extensions;
    const preAssignedLabels = getPreAssignedLabels(ids);
    setErrPreAssigned(preAssignedLabels);
    showToast({
      message: graphQLErrors[0]?.message,
      icon: 'error',
    });
  };

  const onCompleted = () => {
    closeModal();
    showToast({ message: formatMessage({ id: 'containers.all.success' }) });
  };
  const [createTeamPriorityMutation, { loading }] = useMutation(
    CREATE_TEAMS_PRIORITY_MUTATION,
    {
      refetchQueries,
      onError,
      awaitRefetchQueries: true,
      onCompleted,
    }
  );
  const [
    createCompetitionSeasonPriorityMutation,
    { loading: loadingCompetition },
  ] = useMutation(CREATE_COMPETITION_SEASONS_PRIORITY_MUTATION, {
    refetchQueries,
    onError,
    awaitRefetchQueries: true,
    onCompleted,
  });

  const createTeam = (assignPriorityInput) =>
    createTeamPriorityMutation({
      variables: {
        input: assignPriorityInput,
      },
    });
  const createComp = (assignPriorityInput) =>
    createCompetitionSeasonPriorityMutation({
      variables: {
        input: assignPriorityInput,
      },
    });

  const [p, setP] = useState(2);
  const {
    data: areasData,
    loading: areasLoading,
    fetchMore,
  } = useQuery(AREA_QUERY, {
    variables: {
      name: countrySearch,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const loadMoreData = () => {
    setP((prev) => prev + 1);
    fetchMore({
      variables: {
        name: countrySearch,
        queryParams: {
          pageSize: 10,
          page: p,
        },
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.areas)) {
          setAreaHasMore(false);
          return prev;
        }
        return {
          ...prev,
          areas: [...prev?.areas, ...fetchMoreResult?.areas],
        };
      },
    });
  };
  const usedMutation = {
    // eslint-disable-next-line no-use-before-define
    TEAM: createTeam,
    // eslint-disable-next-line no-use-before-define
    COMPETITION_SEASON: createComp,
  };

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={modalState?.open}
      title={
        <div className={classNames('flex', 'gap-3', 'items-center')}>
          <Icon icon="plus" size={15} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({ id: 'containers.priorities.assignPriority' })}
          </div>
        </div>
      }
      onClose={closeModal}
      style={{ width: '505px' }}
    >
      <div className="edit-priorty-container">
        <div className="flex gap-40 items-center">
          <div className="flex column">
            <Text variant="body1" className="pb-15">
              {formatMessage({
                id: 'containers.priorities.assignPriority.teamDetails',
              })}
            </Text>
            <Dropdown
              value={area || ''}
              items={optionsFromArr(areasData && areasData.areas)}
              onChange={(data) => {
                setArea(data.value);
              }}
              buttonText={formatMessage({
                id: 'containers.all.dropdown.country',
              })}
              loading={areasLoading}
              filterable
              searchPath={['label']}
              popOverPosition="bottom-left"
              onSearch={setCountrySearch}
              fetchMore={loadMoreData}
              scrollLoading={areasLoading}
              hasMore={areaHasMore}
              error={validateCountryAndType && !area}
              data-testid="assign-priority-country"
            />
            {validateCountryAndType && !area && (
              <span className="error-text" data-testid="main-errors-country">
                {formatMessage({ id: 'containers.all.required' })}
              </span>
            )}
          </div>
          {screenType === 'TEAM' && (
            <div className="flex column priority-radio-group pt-25">
              <span>
                {formatMessage({ id: 'containers.priorities.teamType' })}
              </span>
              <RadioGroup
                items={PRIORITY_IS_FEMALE}
                selectedValue={isFemale}
                onRadioChange={(data) => setIsFemale(data)}
                name="TeamType"
                error={validateCountryAndType && isNil(isFemale)}
              />
            </div>
          )}
        </div>
        <div className="assign-priority-members-section">
          <RenderIf condition={screenType === 'TEAM'}>
            <TeamsAssignDropDown
              isFemale={isFemale}
              area={area}
              setFormikField={setFormikField}
              teamIds={formik.values.teamIds}
              setErrPreAssigned={setErrPreAssigned}
              errPreAssigned={errPreAssigned}
              setValidateCountryAndType={setValidateCountryAndType}
            />
          </RenderIf>
          <RenderIf condition={screenType !== 'TEAM'}>
            <CompetitionAssignPrDropDwon
              area={area}
              setFormikField={setFormikField}
              competitionSeasonIds={formik.values.competitionSeasonIds}
              setErrPreAssigned={setErrPreAssigned}
              errPreAssigned={errPreAssigned}
              setValidateCountryAndType={setValidateCountryAndType}
            />
          </RenderIf>
        </div>
        {!isEmpty(errPreAssigned) && !isNil(errPreAssigned) && (
          <Text
            variant="body2"
            className="color-alizarin"
            data-testid="preassigned-teams"
          >
            {formatMessage(
              { id: 'containers.priorities.assignPriority.warning' },
              { label: <b>{pluck('label', errPreAssigned).join(' , ')}</b> }
            )}
          </Text>
        )}
        <PriorityDetailsSection
          screenType={screenType}
          setFormikField={setFormikField}
          priorityCategory={formik.values.priorityCategory}
          priorityCategoryName={formik.values.priorityCategoryName}
          priority={formik.values.priority}
          errorSla={formik.touched.priority && formik.errors.priority}
        />
      </div>
      <div
        className="bp3-dialog-footer-actions"
        data-testid="editPriority-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={closeModal}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.priorities.assignPriority' })}
          onClick={formik.submitForm}
          loading={loading || loadingCompetition}
          disabled={isEmpty(formik.values[screenTypeId])}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default AssignPriorityModal;
