import React from 'react';
import { Dialog } from '@blueprintjs/core';
import Icon from 'components/Icon';
import { useModal } from 'providers/modalProvider';

const DialogComponent = ({
  children,
  dialogTitle,
  width = 950,
  className,
  icon,
  customIcon,
  size,
}) => {
  const { modalDispatch, modalState } = useModal();

  const iconProps = customIcon ? { customIcon, size } : { icon, size };
  const dialogTitleComponent = (
    <div className="dialog-title">
      <Icon {...iconProps} />
      <div data-testid="dialogTitle">{dialogTitle}</div>
    </div>
  );
  return (
    <Dialog
      isOpen={modalState?.open}
      title={dialogTitleComponent}
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width, maxWidth: 'unset' }}
      className={className}
    >
      {children}
    </Dialog>
  );
};

export default DialogComponent;
