import React from 'react';
import classNames from 'classnames';
import { Button, Dialog, Divider, Label, ShiftMatchCard } from 'components';
import { Acl, RenderIf } from 'config';
import { useModal } from 'providers/modalProvider';
import { useProfile } from 'providers/profileProvider';
import {
  groupBy,
  map,
  mergeWithKey,
  path,
  pick,
  pipe,
  values,
  prop,
  flatten,
  length,
  isEmpty,
  pluck,
  mergeAll,
  omit,
  head,
} from 'ramda';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { PARTS, HALVES } from 'utils/enums';
import { title } from 'utils/helpers';

const ViewTasks = () => {
  const { modalDispatch, modalState } = useModal();
  const { shiftMatch, assignedSquadName, squadId, matchOfflineTasks } = path(
    ['payload'],
    modalState
  );

  const {
    profile: { id: userId },
  } = useProfile();

  const concatValuesPart = (k, l, r) => (k === 'part' ? [l, r] : r);

  const mergeValuesWithKey = (objs) => {
    const halfValues = pluck('half', objs);
    const task = pipe(mergeAll, omit('half'))(objs);
    return {
      ...task,
      matchHalf: map((x) => prop(x, HALVES), halfValues).join(' + '),
    };
  };

  const mergeValuesWithKeyPart = (objs) =>
    objs.length <= 1 ? values(objs) : mergeWithKey(concatValuesPart, ...objs);

  const openTasksModals = (type) =>
    modalDispatch({
      type,
      payload: {
        shiftMatch,
        squadId,
        assignedSquadName,
        matchOfflineTasks,
      },
    });
  // TODO: JSX can be removed,match half can carry values and a render function can be called while rendering element keeping the array JSX free
  const convertHalfAndParts = (arr = []) =>
    arr.reduce(
      (acc, val) => {
        const { member, half, part, type } = val;
        const { matchHalf } = acc;
        const partArr = typeof part === 'string' ? [part] : part;
        const partVal = partArr.map((p) => prop(p, PARTS)).join(' ,  ');
        return {
          member,
          type,
          matchHalf: (
            <>
              <>{matchHalf}</>
              <>{matchHalf ? ' + ' : ''}</>
              <b>{prop(half, HALVES)}</b>
              &nbsp;
              <i>{partVal}</i>
            </>
          ),
        };
      },
      { matchHalf: '', member: {} }
    );

  // TODO: refactor those 2 methods and eliminate duplication
  const teamSideBasedTasks = (arr = []) => {
    const tasksPerTeamSide = map(groupBy(prop('teamSide')), arr);
    const out = [];
    if (tasksPerTeamSide.TAGGING) {
      const taggingTasks = flatten(
        values(map(mergeValuesWithKey, tasksPerTeamSide.TAGGING))
      );
      out.push(...taggingTasks);
    }
    if (tasksPerTeamSide.FREEZE_FRAME) {
      const FFtasks = flatten(
        values(map(mergeValuesWithKey, tasksPerTeamSide.FREEZE_FRAME))
      );
      out.push(...FFtasks);
    }
    return out;
  };

  const partBasedTasks = (arr = []) => {
    const tasksPerHalf = map(groupBy(prop('half')), arr);
    const out = [];
    if (tasksPerHalf.LOCATION) {
      const locationTasks = pipe(
        map(mergeValuesWithKeyPart),
        values,
        flatten,
        convertHalfAndParts
      )(values(tasksPerHalf.LOCATION));
      out.push(locationTasks);
    }
    if (tasksPerHalf.VALIDATION) {
      const validationTasks = pipe(
        map(mergeValuesWithKeyPart),
        values,
        flatten,
        convertHalfAndParts
      )(values(tasksPerHalf.VALIDATION));
      out.push(validationTasks);
    }
    return out;
  };

  const groupTeamSidePerUser = (arr = {}) => {
    const teamSideTasks = pick(['TAGGING', 'FREEZE_FRAME'], arr);
    const partTasks = pick(['LOCATION', 'VALIDATION'], arr);
    return [...teamSideBasedTasks(teamSideTasks), ...partBasedTasks(partTasks)];
  };

  const tasksByTypes = pipe(
    groupBy((task) => task.member.user.id),
    map(groupBy(prop('type'))),
    map(groupTeamSidePerUser),
    values
  );
  const tasksList = tasksByTypes(matchOfflineTasks);

  const index = tasksList.findIndex((x) => head(x)?.member.user?.id === userId);
  tasksList.unshift(tasksList.splice(index, 1)[0]);
  // TODO : might consider a rename to sorted tasks
  const tasks = tasksList.sort(
    (a, b) =>
      (head(b)?.member.user?.id.id === userId) -
      (head(a)?.member.user?.id.id === userId)
  );

  return (
    <Dialog dialogTitle="Assigned Tasks" customIcon="list">
      <Grid fluid className="view-tasks-container">
        <Row className="p-21">
          <Col lg={11} md={9}>
            <Label
              variant="Label"
              labelText={`Assigned to Squad ${assignedSquadName}`}
              className="pb-17"
            />
            <ShiftMatchCard shiftMatch={shiftMatch} />
          </Col>
        </Row>
        <Row className={classNames(['p-21', 'titles-container'])}>
          <Col lg={3} md={3}>
            Collector
          </Col>
          <Col lg={2} md={2}>
            Task
          </Col>
          <Col lg={2} md={2}>
            Team Side
          </Col>
          <Col lg={2} md={2}>
            Match Half
          </Col>
        </Row>
        {tasks.map((matchTask, index) => (
          <>
            {matchTask.map((task, key) => {
              const { member, type, teamSide, matchHalf } = task;
              const { hrCode, firstName, lastName, id } = member?.user;
              return (
                <Row
                  key={`mt-${member.id}`}
                  className={classNames(['pl-21', 'pb-20', 'details-body'], {
                    'bold-titles': id === userId,
                  })}
                >
                  <RenderIf
                    condition={key === 0}
                    renderElse={<Col lg={3} md={3} />}
                  >
                    <Col lg={3} md={3}>
                      {`${title(firstName)} ${title(lastName)} - ${hrCode}`}
                    </Col>
                  </RenderIf>
                  <Col lg={2} md={2}>
                    {title(type)}
                  </Col>
                  <Col lg={2} md={2}>
                    {title(teamSide) || (
                      <span className="tasks-dashes">--</span>
                    )}
                  </Col>
                  <Col lg={2} md={2} className="font-style-match-half">
                    {matchHalf || <span className="tasks-dashes">--</span>}
                  </Col>
                </Row>
              );
            })}
            <RenderIf condition={length(tasks) !== index + 1}>
              <Divider className="mgb-19" />
            </RenderIf>
          </>
        ))}
        <Row className="p-21">
          <Acl
            allowedPermissions={['update:squad', `update:squad:${squadId}`]}
            allowedRoleNames={['super-admin']}
          >
            <Button
              minimal
              icon="plus"
              text="Assign Tasks"
              intent="primary"
              onClick={() => openTasksModals('ASSIGN_TASK')}
              disabled={isEmpty(squadId)}
            />
          </Acl>
          <Acl
            allowedPermissions={['update:squad', `update:squad:${squadId}`]}
            allowedRoleNames={['super-admin']}
          >
            <Button
              minimal
              icon="edit"
              text="Edit"
              onClick={() => openTasksModals('EDIT_TASK')}
              disabled={isEmpty(squadId)}
              data-testid="dashboard_tasks_editTask"
            />
          </Acl>
        </Row>
      </Grid>
    </Dialog>
  );
};

export default ViewTasks;
