import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, Dialog, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button, Divider, Icon, Label, ShiftMatchCard } from 'components';
import DetailsRender from 'containers/Dashboard/helpers/DetailsRender';
import { useFormik } from 'formik';
import {
  MATCH_ISSUES_QUERY,
  RESOLVE_ISSUE_MUTATION,
  RESOLVE_MATCH_ISSUES_MUTATION,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import {
  defaultTo,
  isEmpty,
  length,
  all,
  pluck,
  propEq,
  filter,
  equals,
  path,
} from 'ramda';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { QUALITY_ISSUE_LIST } from 'utils/enums';
import { title } from 'utils/helpers';
import { useProfile } from 'providers/profileProvider';

const ResolveIssue = () => {
  const { modalDispatch, modalState } = useModal();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const shiftMatch = path(['payload', 'shiftMatch'], modalState);
  const assignedSquadName = path(['payload', 'assignedSquadName'], modalState);
  const squadId = path(['payload', 'squadId'], modalState);
  const refetchShiftMatches = path(
    ['payload', 'refetchShiftMatches'],
    modalState
  );
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId } = userLine ?? {};
  const [matchIssues, setMatchIssues] = useState([]);
  const [hasQualityIssue, setHasQualityIssue] = useState(false);

  const { loading: matchIssuesLoading } = useQuery(MATCH_ISSUES_QUERY, {
    variables: {
      matchId: shiftMatch?.id,
      lineId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const reportedIssues = filter(
        propEq('status', 'REPORTED'),
        data?.matchIssues
      );

      setMatchIssues(reportedIssues);
      setHasQualityIssue(
        defaultTo(
          [],
          filter(
            ({ type }) => QUALITY_ISSUE_LIST.includes(type),
            reportedIssues
          )
        )
      );
    },
  });
  const initialValues = {
    all: false,
    issues: new Array(length(matchIssues)).fill({
      id: '',
      checked: false,
    }),
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) =>
      // eslint-disable-next-line no-use-before-define
      values.all ? resolveAll(values) : resolveIssue(values?.issues),
  });
  useEffect(() => {
    formik.setFieldValue(
      'all',
      all(equals(true))(pluck('checked', formik.values.issues))
    );
  }, [formik.values.issues]);

  const [resolveIssueMutation, { loading }] = useMutation(
    RESOLVE_ISSUE_MUTATION,
    {
      onError: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({ id: 'containers.all.error' }),
          icon: 'error',
        });
      },
      onCompleted: () => {
        refetchShiftMatches();
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.dashboards.resolve.success',
          }),
        });
      },
    }
  );

  const [resolveMatchIssuesMutation, { loading: loadingResolveAll }] =
    useMutation(RESOLVE_MATCH_ISSUES_MUTATION, {
      onError: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({ id: 'containers.all.error' }),
          icon: 'error',
        });
      },
      onCompleted: () => {
        refetchShiftMatches();
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.dashboards.resolve.success',
          }),
        });
      },
    });
  const resolveIssue = (values) => {
    const resolvedIds = pluck('id', filter(propEq('checked', true), values));
    return resolveIssueMutation({
      variables: {
        input: {
          ids: resolvedIds,
          squadId,
          matchId: shiftMatch?.id,
        },
      },
    });
  };
  const resolveAll = () =>
    resolveMatchIssuesMutation({
      variables: {
        input: {
          squadId,
          matchId: shiftMatch?.id,
        },
      },
    });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon customIcon="success" size={16} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.resolve.title" />
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
      className="resolve-issue-container"
    >
      <Grid fluid className="mgt-13 mgb-13 pl-32">
        <Row>
          <Col sm={4} md={8} lg={12}>
            <Label
              variant="Label"
              labelText={formatMessage(
                { id: 'containers.dashboards.squadAssigned' },
                { assignedSquadName }
              )}
              className={classNames(['pb-14', 'pt-14'])}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} md={8} lg={12}>
            <ShiftMatchCard shiftMatch={shiftMatch} />
          </Col>
        </Row>
        {matchIssuesLoading ? (
          <Spinner className="mgt-35 mgb-35" />
        ) : (
          <>
            <Row>
              <Col sm={4} md={8} lg={12}>
                <Label
                  variant="Label"
                  labelText={formatMessage({
                    id: 'containers.dashboard.reportedIssues',
                  })}
                  className="pt-30 pb-20 "
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} md={6} lg={12} className="flex">
                <Checkbox
                  label={
                    <span
                      className="resolve-all-label-text"
                      data-testid="dashboard-resolve-allIssues-title"
                    >
                      <FormattedMessage id="containers.all.resolveAll" />
                    </span>
                  }
                  inline
                  large
                  checked={formik.values.all}
                  onChange={() => {
                    formik.setFieldValue(`all`, !formik.values.all);
                    formik.setFieldValue(
                      `issues`,
                      new Array(length(matchIssues)).fill({
                        id: '',
                        checked: !formik.values.all,
                      })
                    );
                  }}
                  className="resolve-all-checkbox"
                  data-testid="dashboard-resolve-allIssues"
                />
                {formik.values?.all && !isEmpty(hasQualityIssue) ? (
                  <div
                    className="flex gap-5 items-center mgb-16"
                    data-testid="dashboard-resolve-hasQAwarning"
                  >
                    <Icon customIcon="warning2" />
                    <span>
                      <FormattedMessage id="containers.resolve.qualityIssue.warning" />
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {matchIssues.map((issue, index) => {
              const { type, comment, details } = issue;
              return (
                <>
                  <Row>
                    <Col sm={1} md={1} lg={1} />
                    <Col sm={2} md={3} lg={3} className="header-text">
                      <FormattedMessage id="containers.all.reason" />
                    </Col>
                    <Col sm={3} md={4} lg={4} className="header-text">
                      <FormattedMessage id="containers.all.comment" />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={1} md={1} lg={1}>
                      <Checkbox
                        inline
                        large
                        checked={formik.values.issues[index]?.checked}
                        onChange={() =>
                          formik.setFieldValue(`issues[${index}]`, {
                            id: issue?.id,
                            checked: !formik.values.issues[index]?.checked,
                          })
                        }
                        data-testid="dashboard-resolve-issuesCheckbox"
                      />
                    </Col>
                    <Col
                      sm={2}
                      md={3}
                      lg={3}
                      className="content-text"
                      data-testid="dashboard-matches-issues"
                    >
                      {title(type)}
                    </Col>
                    <Col sm={3} md={4} lg={4} className="content-text">
                      <>{comment}</>
                      <div className="details-text">
                        {DetailsRender(type, details, shiftMatch)}
                      </div>
                    </Col>
                  </Row>
                  <Divider
                    className={classNames(['mgt-20 ', 'mgr-60', 'mgb-20'])}
                  />
                </>
              );
            })}
          </>
        )}
      </Grid>
      <div
        className={classNames(['bp3-dialog-footer-actions', 'mgt-30'])}
        data-testid="resolveIssue-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.all.resolve' })}
          onClick={formik.submitForm}
          loading={loading || loadingResolveAll}
          disabled={
            !formik?.values?.all &&
            isEmpty(filter(propEq('checked', true), formik.values.issues))
          }
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default ResolveIssue;
