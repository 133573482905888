/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button, Icon, Label, ShiftMatchCard } from 'components';
import { useFormik } from 'formik';
import {
  ASSIGN_OFFLINE_TASKS_MUTATION,
  MATCH_OFFLINE_TASKS_QUERY,
  SQUAD_SHIFT_MEMBERS_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import {
  find,
  length,
  pathEq,
  prop,
  keys,
  is,
  isEmpty,
  clone,
  path,
} from 'ramda';
import { ASSIGN_TASK } from 'validations';
import { RenderIf } from 'config';
import { FormattedMessage } from 'react-intl';
import AssignCollectors from './helpers/AssignCollectors';
import TasksForm from './helpers/TasksForm';

const AssignTasks = () => {
  // set maximum allowed count for tasks per mutation
  const MAX_TASK_COUNT = 4;

  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { squadId, assignedSquadName, shiftMatch } = prop(
    'payload',
    modalState
  );
  const matchId = prop('id', shiftMatch);
  const squadShiftId = path(
    ['squadShifts', '0', 'squadShift', 'id'],
    shiftMatch
  );

  const initialValues = {
    collector: '',
    tasksLength: 1,
    tasks: [
      {
        half: [],
        teamSide: '',
        type: '',
        part: [],
      },
    ],
  };
  const {
    data: { squadShiftMembers = [] } = {},
    loading: squadShiftMembersLoading,
  } = useQuery(SQUAD_SHIFT_MEMBERS_QUERY, {
    variables: { squadShiftId },
  });

  const [assignOfflineTasksMutation, { loading }] = useMutation(
    ASSIGN_OFFLINE_TASKS_MUTATION,
    {
      refetchQueries: [
        {
          query: MATCH_OFFLINE_TASKS_QUERY,
          variables: { matchId },
        },
      ],
      awaitRefetchQueries: true,
      onError: (err) => {
        if (err?.graphQLErrors?.[0].extensions?.exception?.code === 'P2002') {
          showToast({
            message: 'This task is already assigned to another collector.',
            icon: 'error',
          });
        } else {
          showToast({
            message: 'Changes can not be saved try again.',
            icon: 'error',
          });
        }
      },
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message: 'Match tasks have been assigned successfully.' });
      },
    }
  );

  const assignOfflineTask = () => {
    const squadShiftMember = find(
      pathEq(['user', 'id'], formik.values.collector),
      squadShiftMembers
    );

    return assignOfflineTasksMutation({
      variables: {
        input: {
          squadShiftMemberId: squadShiftMember?.id,
          matchId,
          tasks,
          userId: formik.values.collector,
          squadId,
        },
      },
    });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ASSIGN_TASK,
    onSubmit: assignOfflineTask,
  });

  const { values, touched, errors } = formik;
  const tasks = useMemo(() => {
    const tasksToReturn = [];
    const tempTasks = clone(values.tasks);
    // clean empty arr vals
    tempTasks.forEach((task) => {
      keys(task).forEach((k) => {
        if (isEmpty(task[k])) delete task[k];
      });
    });

    // add tasks
    tempTasks.forEach((task) => {
      keys(task).forEach((k) => {
        if (is(Array, task[k])) {
          task[k].forEach((v) => {
            tasksToReturn.push({
              ...task,
              [k]: v,
            });
          });
        }
      });
    });
    return tasksToReturn;
  }, [values]);

  const setFormikField = (field, value) => formik.setFieldValue(field, value);

  const AddEmptyTask = () => {
    setFormikField(`tasks[${length(values.tasks)}]`, {
      ...initialValues.tasks[0],
    });
  };

  const dialogTitle = (
    <div className="dialog-title">
      <Icon icon="plus" size={16} data-testid="modalHeader-icon" />
      <div data-testid="modalHeader-title">
        <FormattedMessage id="containers.dashboards.assign.title" />
      </div>
    </div>
  );
  const closeModal = () => modalDispatch({ type: 'ClOSE_ALL' });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={dialogTitle}
      onClose={closeModal}
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
    >
      <div className="assign-tasks-container">
        <Label
          variant="Label"
          labelText={`Assigned to Squad ${assignedSquadName}`}
          data-testid="dashboard-assignTasks-squadAssigned"
        />
        <div className={classNames(['mgt-13', 'mgb-13'])}>
          <ShiftMatchCard
            shiftMatch={shiftMatch}
            testid="dashboard-assignTasks-shiftMatchCard"
          />
        </div>
        <div className="flex items-center">
          <AssignCollectors
            squadShiftMembersLoading={squadShiftMembersLoading}
            squadShiftMembers={squadShiftMembers}
            error={touched.collector && errors.collector}
            setFormikField={setFormikField}
            collector={values.collector}
          />
          <div className="automarginLeft info-text flex items-center gap-27">
            <i>
              <FormattedMessage
                id="containers.dashboards.assignTask.countinfo"
                values={{ maxCount: MAX_TASK_COUNT }}
              />
            </i>
            <b
              className={classNames({
                'color-pigment-green': tasks.length <= MAX_TASK_COUNT,
                'color-alizarin':
                  tasks.length < 1 || tasks.length > MAX_TASK_COUNT,
              })}
            >
              {tasks.length}
              <> /</>
              {MAX_TASK_COUNT}
            </b>
          </div>
        </div>
        <RenderIf condition={values.collector}>
          <TasksForm
            tasks={values.tasks}
            touched={touched.tasks}
            errors={errors.tasks}
            setFormikField={setFormikField}
            mutationTasks={values.mutationTasks}
            taskCount={tasks.length}
            maxCount={MAX_TASK_COUNT}
          />
        </RenderIf>
      </div>

      <div
        className="bp3-dialog-footer-actions pt-50"
        data-testid="assignTasks-footer"
      >
        <RenderIf condition={values.collector}>
          <RenderIf
            condition={tasks.length < MAX_TASK_COUNT}
            renderElse={
              <div
                className="automarginRight pl-32 flex items-center gap-5 body-three-txt"
                data-testid="dashboard-assignTask-countWarning"
              >
                <Icon customIcon="warning2" />
                <FormattedMessage
                  id="containers.dashboards.assignTask.countWarning"
                  values={{ maxCount: MAX_TASK_COUNT }}
                />
              </div>
            }
          >
            <Button
              minimal
              text="Add another Task"
              onClick={AddEmptyTask}
              intent="primary"
              icon="plus"
              className="automarginRight"
              disabled={values.tasks.length >= MAX_TASK_COUNT}
              data-testid="dashboard-assignTasks-addAnotherTask"
            />
          </RenderIf>
        </RenderIf>
        <Button
          outlined
          text="Cancel"
          onClick={closeModal}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={<FormattedMessage id="containers.dashboards.assign" />}
          onClick={formik.submitForm}
          loading={loading}
          disabled={!values.collector}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default AssignTasks;
