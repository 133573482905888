import React from 'react';
import { Button, Dialog } from 'components';
import { useMutation } from '@apollo/client';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import {
  DELETE_COMPETITION_SEASON_PROVIDERS_MUTATION,
  COMPETITION_SEASONS_QUERY,
} from 'gql';
import { FormattedMessage, useIntl } from 'react-intl';
import CardHeader from 'components/Card/league/cardHeader';

const DeleteLeagueModal = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();

  const {
    payload: { league },
  } = modalState;
  const [deleteCompetitionSeaonProvidersMutation, { loading }] = useMutation(
    DELETE_COMPETITION_SEASON_PROVIDERS_MUTATION,
    {
      refetchQueries: [COMPETITION_SEASONS_QUERY],
      onError: ({ message }) =>
        showToast({
          message,
          icon: 'error',
        }),
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.leagues.delete.success',
          }),
        });
      },
    }
  );
  const onSubmit = () => {
    deleteCompetitionSeaonProvidersMutation({
      variables: {
        input: { id: league?.id },
      },
    });
  };
  return (
    <Dialog
      dialogTitle={<FormattedMessage id="containers.leagues.delete" />}
      icon={<span className="bg-delete" data-testid="bg-delete" />}
      width="541px"
    >
      <div className="bp3-dialog-body delete-body-container">
        <CardHeader
          competationId={league?.id}
          competitionName={league?.competitionName}
          seasonName={league?.seasonName}
          countryCode={league?.area?.code}
          countryName={league?.area?.name}
        />
      </div>
      <div className="bp3-dialog-footer" data-testid="delete-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={<FormattedMessage id="containers.all.delete" />}
            onClick={onSubmit}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteLeagueModal;
