/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from 'components';

const PasswordInput = ({
  labelText,
  placeholder,
  required,
  onChange,
  error,
  value,
  className,
  ...props
}) => {
  const [empty, setEmpty] = useState(true);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (value && value?.length) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    return () => {};
  }, [value]);
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };
  return (
    <div
      className={classNames(
        'text-input-container',
        { 'not-empty': !empty, error },
        className
      )}
    >
      <input
        required={required}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        {...props}
        type={visible ? 'text' : 'password'}
      />
      <button
        type="button"
        onClick={() => setVisible(!visible)}
        className="show-btn"
      >
        {visible ? <Icon icon="eye-off" /> : <Icon icon="eye-open" />}
      </button>
      <label>{labelText}</label>
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};
export default PasswordInput;
