/* eslint-disable no-use-before-define */
import React from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { prop } from 'ramda';
import { Icon, Button, Label } from 'components';
import {
  PRIORITY_QUERY,
  UPDATE_COMPETITION_SEASON_PRIORITY_MUTATION,
  UPDATE_TEAM_PRIORITY_MUTATION,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { EDIT_PRIORITY } from 'validations';
import { useIntl } from 'react-intl';
import { RenderIf } from 'config';
import PriorityDetailsSection from './PriorityDetailsSection';

export default function PriorityEditModal() {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();

  const { type: screenType, active = {} } = prop('payload', modalState);
  const {
    activePriorityCategoryId = '',
    subId = '',
    activePriorityId = '',
    activeCatName,
    country,
    type,
    name,
  } = active;
  const screenTypeId = screenType === 'TEAM' ? `teamId` : `competitionSeasonId`;
  const screenTitle = screenType === 'TEAM' ? `Team` : `Competition`;
  const updateTeam = (input) =>
    updateTeamPriority({
      variables: {
        input,
      },
    });
  const updateComp = (input) =>
    updateCompetitionSeasonPriority({
      variables: {
        input,
      },
    });
  const usedMutation = {
    TEAM: updateTeam,
    COMPETITION_SEASON: updateComp,
  };
  const initialValues = {
    priorityCategory: activePriorityCategoryId,
    priority: activePriorityId,
    priorityCategoryName: activeCatName || '',
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EDIT_PRIORITY,
    onSubmit: (values) => {
      const input = {
        priorityId: values.priority,
        [screenTypeId]: subId,
      };
      usedMutation[screenType](input);
    },
  });
  const setFormikField = (field, value) => formik.setFieldValue(field, value);

  const refetchQueries = [
    // {
    //   query: PRIORITY_QUERY,
    //   variables: { priorityId: activePriorityId },
    // },
    {
      query: PRIORITY_QUERY,
      variables: { priorityId: formik.values.priority },
    },
  ];

  const onError = () => {
    modalDispatch({ type: 'ClOSE_ALL' });
    showToast({
      message: formatMessage({ id: 'containers.all.cannot.edit' }),
      icon: 'error',
    });
  };
  const onCompleted = () => {
    modalDispatch({ type: 'ClOSE_ALL' });
    showToast({ message: formatMessage({ id: 'containers.all.success' }) });
  };

  const [updateTeamPriority, { loading }] = useMutation(
    UPDATE_TEAM_PRIORITY_MUTATION,
    {
      refetchQueries,
      onError,
      awaitRefetchQueries: true,
      onCompleted,
    }
  );
  const [updateCompetitionSeasonPriority, { loading: loadingComp }] =
    useMutation(UPDATE_COMPETITION_SEASON_PRIORITY_MUTATION, {
      refetchQueries,
      onError,
      awaitRefetchQueries: true,
      onCompleted,
    });
  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={modalState?.open}
      title={
        <div className="create-category-title">
          <Icon icon="edit" size={15} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({
              id: 'containers.priorities.editAssignedPriority',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      style={{ width: '461px' }}
    >
      <div className="edit-priorty-container">
        <span>{` ${screenTitle} Details`}</span>
        <div className="edit-add-priority-details mgt-15 mgb-15">
          <Label
            variant="Baisc"
            labelHeader="Country"
            labelText={country}
            data-testid="edit-priority-country"
          />
          <RenderIf condition={screenType === 'TEAM'}>
            <Label
              variant="Baisc"
              labelHeader="Team Type"
              labelText={type}
              data-testid="edit-priority-teamType"
            />
          </RenderIf>
        </div>
        <Label
          variant="Baisc"
          labelHeader={screenTitle}
          labelText={name}
          data-testid="edit-priority-teamName"
        />
        <PriorityDetailsSection
          setFormikField={setFormikField}
          priorityCategory={formik.values.priorityCategory}
          priorityCategoryName={formik.values.priorityCategoryName}
          priority={formik.values.priority}
          screenType={screenType}
          errorSla={formik.touched.priority && formik.errors.priority}
          errorCategory={formik.errors.priorityCategory}
        />
      </div>

      <div
        className="bp3-dialog-footer-actions"
        data-testid="editPriority-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.all.btn.saveChanges' })}
          onClick={formik.submitForm}
          loading={loading || loadingComp}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
}
