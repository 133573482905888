import React from 'react';
import { Radio } from 'components';
import classNames from 'classnames';

const RadioGroup = ({
  items,
  name,
  selectedValue,
  onRadioChange,
  error,
  className = '',
  disabled,
}) => (
  <div
    className={classNames(['radio-group-container', className])}
    data-testid={`main-radioGroup-${name}`}
  >
    {items.map(({ text: labelText, value }, i) => (
      <Radio
        labelText={labelText}
        fieldId={`${name}-name-${i}`}
        value={value}
        name={name}
        isSelected={value === selectedValue}
        onRadioChange={onRadioChange}
        error={error}
        key={`name-${name}`}
        disabled={disabled}
      />
    ))}
    {error ? (
      <span className="error-text" data-testid={`main-errors-${name}`}>
        Required
      </span>
    ) : (
      <></>
    )}
  </div>
);

export default RadioGroup;
