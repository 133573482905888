import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Button, Icon, Label } from 'components';
import { useFormik } from 'formik';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useMutation } from '@apollo/client';
import { UPDATE_SQUAD_MEMBERS_MUTATION, SQUADS_QUERY } from 'gql';
import { title } from 'utils/helpers';
import { useIntl } from 'react-intl';

const RemoveSquadMember = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { squad, user } = modalState;
  const { firstName, lastName, middleName, id: userId } = user;
  const { id: squadId, name: squadName, lineFunctionId } = squad;
  const [removeMemberMutation, { loading }] = useMutation(
    UPDATE_SQUAD_MEMBERS_MUTATION,
    {
      refetchQueries: [
        {
          query: SQUADS_QUERY,
          variables: { lineFunctionId },
        },
      ],
      awaitRefetchQueries: true,
      onError: ({ message }) =>
        showToast({
          message,
          icon: 'error',
        }),
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.squads.removeSquadMember.success',
          }),
        });
      },
    }
  );
  const initialValues = {
    id: squadId,
    squadMembersIds: [userId],
    remove: true,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (input) => removeMemberMutation({ variables: { input } }),
  });
  const dialogTitleData = (
    <div className="create-category-title">
      <Icon icon="remove" data-testid="remove-icon" />
      <div data-testid="main-dialogTitle-removeSquadMember">
        {formatMessage({ id: 'containers.squads.removeSquadMember.title' })}
      </div>
    </div>
  );
  return (
    <Dialog
      isOpen={modalState?.open}
      title={dialogTitleData}
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="bp3-dialog-body">
        <div data-testid="squads-removeSquad-description">
          {formatMessage({
            id: 'containers.squads.removeSquadMember.description.part1',
          })}
          &nbsp;
          <b>{title(squadName)}</b>
          &nbsp;? &nbsp;
          {formatMessage({
            id: 'containers.squads.removeSquadMember.description.part2',
          })}
        </div>
        <Label
          variant="AvatarLabel"
          labelText={`${firstName} ${middleName || ''} ${lastName}`}
          avatarText={`${firstName} ${lastName}`}
          data-testid="removeMember-avatarLabel"
        />
      </div>
      <div className="bp3-dialog-footer" data-testid="removeMember-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={formatMessage({ id: 'containers.all.btn.cancel' })}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            disabled={false}
            intent="Primary"
            text={formatMessage({ id: 'containers.all.remove' })}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveSquadMember;
