import React from 'react';
import { map } from 'ramda';
import { Button, Spinner } from '@blueprintjs/core';
import { useNavigation } from 'react-navi';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const SideList = ({
  items = [],
  cat,
  activeId,
  type,
  loading,
  setActiveSla,
  route,
}) => {
  const navigation = useNavigation();
  return (
    <div className="side-list">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {map(
            (item) => (
              <Button
                onClick={() => {
                  setActiveSla(item.slaOffset);
                  navigation.navigate(`${route}/${item.id}`);
                }}
                minimal
                className={classNames('side-list-item', {
                  active: activeId === item.id,
                })}
                data-testid="priorities-side-list"
              >
                <span key={item.id}>
                  {`${cat.charAt(0)}: ${item.slaOffset} Hours (${
                    item?.totalCount?.[type] || 0
                  })`}
                </span>
              </Button>
            ),
            items
          )}
        </>
      )}
    </div>
  );
};

export default SideList;

SideList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cat: PropTypes.string.isRequired,
  activeId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['teams', 'competitionSeasons']).isRequired,
  loading: PropTypes.bool,
  setActiveSla: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
};

SideList.defaultProps = {
  loading: false,
};
