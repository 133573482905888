import React, { Fragment } from 'react';

import classNames from 'classnames';
import { Button, Divider, Label, Text } from 'components';
import { useModal } from 'providers/modalProvider';
import { useProfile } from 'providers/profileProvider';
import { equals, isNil } from 'ramda';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import { getIssueReason, title } from 'utils/helpers';
import { RenderIf } from 'config';
import DetailsRender from './DetailsRender';

const IssuesBody = ({
  issues = [],
  screenTitle,
  modalPayload,
  shiftMatch,
  testid,
}) => {
  const {
    profile: { id },
  } = useProfile();
  const { modalDispatch } = useModal();
  return (
    <>
      <Grid
        className={classNames([' pl-32', 'pt-14'])}
        data-testid={`dashboard-issuesBodyContainer-${testid}`}
      >
        <Row data-testid={`dashboard-issuesBodyTitle-${testid}`}>
          <Col xs={4} sm={4} md={4} className="mgb-6">
            {screenTitle === 'Blocked Match' ? (
              <div className="color-alizarin body-one-txt mgb-6">
                {screenTitle}
              </div>
            ) : (
              <Label variant="Label" labelText={screenTitle} />
            )}
          </Col>
        </Row>

        {issues.map((issue) => {
          const {
            type,
            comment,
            details,
            reporter = {},
            resolver,
            edited,
          } = issue;
          const {
            id: reporterId,
            firstName: reporterFirstName,
            lastName: reporterLastName,
            hrCode: reporterHrCode,
          } = reporter;
          const {
            firstName: resolverFirstName,
            lastName: resolverLastName,
            hrCode: resolverHrCode,
          } = resolver || {};
          const displayEditReportButton =
            isNil(resolver) && equals(id, reporterId);
          const reason = getIssueReason(type);
          return (
            <Fragment key={issue.id}>
              <Row className="issues-table-title-row">
                <Col sm={2} md={2} lg={2} className="issues-table-title">
                  <FormattedMessage id="containers.all.reason" />
                </Col>
                <Col sm={4} md={4} lg={4} className="issues-table-title">
                  <FormattedMessage id="containers.all.Comments" />
                </Col>
                <Col sm={3} md={3} lg={3} className="issues-table-title">
                  <FormattedMessage id="containers.all.reportedBy" />
                  <>:</>
                </Col>
                {!isNil(resolver) ? (
                  <Col
                    sm={3}
                    md={3}
                    lg={3}
                    className="issues-table-title-resolved"
                  >
                    <FormattedMessage id="containers.all.resolvedBy" />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row data-testid={`dashboard-issuesBodyRow-${testid}`}>
                <Col sm={2} md={2} lg={2} className="issues-table-body">
                  {title(issue.type)}
                </Col>
                <Col sm={4} md={4} lg={4} className="issues-table-body">
                  <>{comment || '-'}</>
                  <div className="details-text">
                    {DetailsRender(type, details, shiftMatch)}
                  </div>

                  <div className="edited-text">
                    <RenderIf condition={edited}>
                      <Text variant="body3">
                        <>(</>
                        <FormattedMessage id="containers.all.edited" />
                        <>)</>
                      </Text>
                    </RenderIf>
                  </div>
                </Col>
                <Col sm={3} md={3} lg={3} className="issues-table-body">
                  {displayEditReportButton ? (
                    <Button
                      text={
                        <FormattedMessage id="containers.dashboard.editMyReport" />
                      }
                      minimal
                      onClick={() => {
                        modalDispatch({
                          type: 'REPORT_ISSUE',
                          payload: { ...modalPayload, issue, reason },
                        });
                      }}
                      className="p-0"
                      data-testid="dashboard-viewDetails-editMyReport"
                    />
                  ) : (
                    `${reporterFirstName}${reporterLastName}-${reporterHrCode}`
                  )}
                </Col>
                {!isNil(resolver) ? (
                  <Col sm={3} md={3} lg={3}>
                    {`${resolverFirstName}${resolverLastName}-${resolverHrCode}`}
                  </Col>
                ) : (
                  <></>
                )}
              </Row>

              <Divider className={classNames(['pb-21 ', 'mgr-60'])} />
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default IssuesBody;
