import {
  curry,
  equals,
  filter,
  map,
  pipe,
  prop,
  toLower,
  uniqWith,
} from 'ramda';
import { streamingDataIconMapper } from 'utils/enums';
import { title, uniqById } from 'utils/helpers';

export const streamingIconByType = (type) =>
  prop(type, streamingDataIconMapper);

export const renderCardPropValue = (val, renderFn = (i) => i) => {
  if (!val) return 'N/A';
  if (Array.isArray(val)) {
    const allTrue = val.every((i) => i);

    if (allTrue && prop.length) {
      return renderFn(...val);
    }

    return 'N/A';
  }

  return renderFn(val);
};

const comparePcsLocations = (pc1, pc2) =>
  equals(pc1.location.id, pc2.location.id);

const uniqLocationsById = uniqWith(comparePcsLocations);

const mapLocations = map(({ location }) => ({ ...location }));

export const getDistinctLocations = pipe(uniqLocationsById, mapLocations);

export const filterAvailablePcsToSelectedLocation = curry(
  (list, selectedLocationId) =>
    uniqById(filter((pc) => equals(pc.location.id, selectedLocationId), list))
);

/**
 * TODO: @refactor
 * when refactor the dropdown comp need to update the optionsFromArr
 * so it can accept `value` and `label` field names
 * then will be no need for the mappers below
 */
export const pcsDropdownMapper = ({ id, number }) => ({
  label: number,
  value: id,
});

export const locationsDropdownMapper = ({ id, HQ, name }) => ({
  label: `${title(HQ)} - ${title(name)}`,
  value: id,
});

export const getProviderLabel = (provider, type) =>
  provider?.[toLower(type)]?.name ?? type;
