import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty, includes } from 'ramda';
import { Dropdown, Fab } from 'components';
import { COMPETITION_SEASONS_INFO_QUERY } from 'gql';
import { optionsFromArrComp, toggleListItem } from 'utils/helpers';
import { useIntl } from 'react-intl';

const CompetitionAssignPrDropDwon = ({
  area,
  competitionSeasonIds,
  setFormikField,
  setErrPreAssigned,
  errPreAssigned,
  setValidateCountryAndType,
}) => {
  const [searchComp, setSearchComp] = useState(undefined);
  const intl = useIntl();
  const [compPage, setCompPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const {
    data: CompetitionSeasonsData = {},
    loading: CompetitionSeasonsLoading,
    fetchMore,
  } = useQuery(COMPETITION_SEASONS_INFO_QUERY, {
    variables: {
      areaIds: [area],
      name: isEmpty(searchComp) ? undefined : searchComp,
      queryParams: {
        pageSize: 10,
        page: 1,
      },
    },
    skip: !area,
  });
  const buttonAction = () => {
    if (!area) {
      setValidateCountryAndType(true);
    }
  };
  const loadMoreData = () => {
    setCompPage((prev) => prev + 1);
    fetchMore({
      variables: {
        queryParams: {
          page: compPage,
          pageSize: 10,
        },
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.competitionSeasonsInfo)) {
          setHasMore(false);
          return prev;
        }
        return {
          ...prev,
          competitionSeasonsInfo: [
            ...prev?.competitionSeasonsInfo,
            ...fetchMoreResult?.competitionSeasonsInfo,
          ],
        };
      },
    });
  };
  const onSearch = (v) => {
    setSearchComp(v);
    if (v && isEmpty(searchComp)) {
      setCompPage(1);
      setHasMore(true);
    }
  };
  return (
    <>
      <Dropdown
        variant="multiselect_obj_list"
        values={competitionSeasonIds || []}
        items={optionsFromArrComp(
          CompetitionSeasonsData &&
            CompetitionSeasonsData.competitionSeasonsInfo
        )}
        onChange={(data) => {
          setFormikField('competitionSeasonIds', data);
        }}
        multiSelect
        buttonText={intl.formatMessage({
          id: 'containers.priorities.CompetitionSeason',
        })}
        loading={CompetitionSeasonsLoading}
        filterable
        searchPath={['label']}
        popOverPosition="bottom-left"
        onSearch={onSearch}
        scrollLoading={CompetitionSeasonsLoading}
        fetchMore={loadMoreData}
        buttonAction={buttonAction}
        disabled={!area}
        hasMore={hasMore}
        data-testid="assign-priority-competition"
      />
      {!isEmpty(competitionSeasonIds) && (
        <div className="add-teams-fab-container">
          <p>
            {intl.formatMessage({ id: 'containers.priorities.Competition' })}
          </p>
          <div className="add-teams-fab-content">
            {competitionSeasonIds.map((comp) => (
              <Fab
                text={comp.label}
                icon="cross"
                iconSize={12}
                onClick={() => {
                  setFormikField(
                    'competitionSeasonIds',
                    toggleListItem(competitionSeasonIds, comp)
                  );
                  if (errPreAssigned && includes(comp, errPreAssigned)) {
                    setErrPreAssigned(toggleListItem(errPreAssigned, comp));
                  }
                }}
                key={comp?.value}
                data-testid="competition-info"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CompetitionAssignPrDropDwon;
