import { useQuery } from '@apollo/client';
import { Dropdown } from 'components';
import { LOCATIONS_QUERY } from 'gql';
import { isNil } from 'ramda';
import React from 'react';
import { useIntl } from 'react-intl';
import { optionsFromArr } from 'utils/helpers';

const RoomsFilter = ({ values = [], onChange }) => {
  const { formatMessage } = useIntl();
  const { loading: locationsLoading, data: locationsData } =
    useQuery(LOCATIONS_QUERY);

  return (
    <Dropdown
      items={optionsFromArr(locationsData?.locations?.locations)}
      values={values}
      onChange={onChange}
      multiSelect
      buttonText={formatMessage({ id: 'containers.dashboard.filters.room' })}
      loading={locationsLoading}
      disabled={isNil(locationsData)}
      disableDropdownButton={isNil(locationsData)}
      data-testid="rooms-filter"
      name="type"
    />
  );
};

export default RoomsFilter;
