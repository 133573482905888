import { anyPass, find, isEmpty, isNil, propEq, reject } from 'ramda';
import { getChangedValues, pluckValue } from 'utils/helpers';

export const getFeatures = (
  appleTv,
  bein,
  satellites = [],
  iptvDevice = '',
  featuresData = []
) => {
  const beinSportFeature = find(propEq('name', 'BEIN'), featuresData);
  const appleTvFeature = find(propEq('name', 'APPLETV'), featuresData);
  const satellitesValue = pluckValue(satellites) || [];
  const pcFeatures = [
    ...satellitesValue,
    appleTv ? appleTvFeature?.id : '',
    bein ? beinSportFeature?.id : '',
    iptvDevice,
  ];
  const features = reject(anyPass([isEmpty, isNil]))(pcFeatures);
  return features;
};
const getChangedPcData = (initialValues, rest) => {
  let changedPcData = getChangedValues(rest, initialValues);
  if (changedPcData?.number) {
    changedPcData = {
      ...changedPcData,
      number: parseInt(changedPcData?.number, 10),
    };
  }
  return {
    ...changedPcData,
    VPN: changedPcData.VPN === undefined ? [] : changedPcData.VPN,
  };
};

export const updatePcInput = (initialValues, values, featuresData) => {
  const { id, appleTv, bein, satellites, iptvDevice, ...rest } = values;
  const changedData = getChangedPcData(initialValues, rest);

  const features = getFeatures(
    appleTv,
    bein,
    satellites,
    iptvDevice,
    featuresData
  );

  const input = {
    id,
    ...changedData,
    features,
  };
  return input;
};

export const createPcInput = (values, featuresData) => {
  const {
    number,
    task,
    locationId,
    serverId,
    VPN,
    appleTv,
    bein,
    satellites,
    iptvDevice,
  } = values;
  const features = getFeatures(
    appleTv,
    bein,
    satellites,
    iptvDevice,
    featuresData
  );
  const input = {
    number: parseInt(number, 10),
    locationId,
    task,
    serverId: !isEmpty(serverId) ? serverId : null,
    VPN,
    features,
  };
  return input;
};
