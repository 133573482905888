import { gql } from '@apollo/client';

export default gql`
  fragment PcFragment on PC {
    VPN
    id
    location {
      id
      name
      type
      HQ
    }
    number
    server {
      name
      id
    }
    task
    features {
      feature {
        id
        name
        type
      }
    }
  }
`;
