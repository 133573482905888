import { RenderIf } from 'config';
import React from 'react';

const ChannelDetails = ({ feature }) => {
  const hasbein = feature?.name === 'BEIN';

  return (
    <>
      <RenderIf condition={hasbein}>
        <div className="bein-sport" />
      </RenderIf>
    </>
  );
};

export default ChannelDetails;
