import { gql } from '@apollo/client';

export default gql`
  fragment PriorityFragment on Priority {
    id
    slaOffset
    teams {
      id
      name
      isFemale
      area {
        id
        name
        code
      }
    }
    competitionSeasons {
      id
      competitionName
      seasonName
      area {
        id
        name
        code
      }
    }
    priorityCategory {
      id
      value
    }
  }
`;
