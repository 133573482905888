import { gql } from '@apollo/client';
import USER_FRAGMENT from '../fragments/user';

export default gql`
  query users(
    $queryParams: QueryParams
    $jobTitles: [String]
    $deactivated: Boolean
    $searchWord: String
    $unassigned: Boolean
    $groups: [Group]
    $businessLines: [BusinessLine]
    $lineIds: [String]
    $lineFunctionIds: [String]
  ) {
    users(
      queryParams: $queryParams
      jobTitles: $jobTitles
      deactivated: $deactivated
      searchWord: $searchWord
      unassigned: $unassigned
      groups: $groups
      businessLines: $businessLines
      lineIds: $lineIds
      lineFunctionIds: $lineFunctionIds
    ) {
      totalCount
      users {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
