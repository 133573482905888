import { gql } from '@apollo/client';

export default gql`
  query Lines {
    lines {
      sport
      name
      id
      groups {
        id
        name
      }
      role {
        name
        jobTitleRole {
          id
          jobTitle
        }
      }
    }
  }
`;
