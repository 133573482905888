import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { Checkbox, Collapse } from '@blueprintjs/core';
import { DateObject } from 'react-multi-date-picker';
import {
  difference,
  filter,
  isEmpty,
  isNil,
  pluck,
  prop,
  pipe,
  keys,
  equals,
  path,
} from 'ramda';
import { Icon, Button } from 'components';
import {
  convertMatchStatusToBool,
  includesAllItems,
  isMatchHasSquadShift,
  isSameDate,
  isVideo,
} from 'utils/helpers';
import { MATCHES_DETAILS_BY_LINE_QUERY } from 'gql';
import { useToast } from 'providers/toastProvider';
import { matchesActions } from 'state/matchesState';
import { RenderIf } from 'config';
import { USER_LINE_NAMES } from 'utils/enums';
import { useProfile } from 'providers/profileProvider';
import { getMatchesQuery } from 'containers/MatchAssignment/Squads/helpers/HelperFunctions';
import VideoExpandableCard from './VideoExpandableCard';
import OfflineExpandableCard from './OfflineExpandableCard';
import { getIntervalFromShift } from '../../helpers';

const ExpandableCard = ({
  matchesState,
  matchesDispatch,
  date,
  userLineName,
}) => {
  const { filtersData, activeDate } = matchesState;
  const [isOpen, setIsOpen] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (!equals(page, 1)) setPage(1);
  }, [matchesState.filtersData]);
  const [totalAssignedMatchesIds, setTotalAssignedMatchesIds] = useState([]);
  const [matchesSquads, setMatchesSquads] = useState([]);
  const { showToast } = useToast();
  const pageSize = 10;
  const {
    competitionSeasonIds = [],
    teamIds = [],
    priorityIds = [],
    assigned: assignedFilterStatus,
    acqInterval,
    acquisitionTypes,
  } = filtersData;

  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { id: lineId } = line || {};
  const plannedAcquisitionInterval = getIntervalFromShift(date, acqInterval);
  const matchesQuery = getMatchesQuery(userLineName);
  const assigned = convertMatchStatusToBool(assignedFilterStatus);
  const videoQueryVariables = {
    queryParams: { page, pageSize },
    date,
    assigned,
    acquisitionTypes,
    plannedAcquisitionInterval,
    lineId,
  };

  const offlineQueryVariables = {
    date,
    competitionSeasonIds,
    teamIds,
    priorityIds:
      !isEmpty(priorityIds) && !isNil(priorityIds) ? priorityIds : undefined,
    assigned,
    queryParams: { page, pageSize },
    lineId,
  };

  const queryVariables = {
    [USER_LINE_NAMES.VIDEO]: videoQueryVariables,
    [USER_LINE_NAMES.OFFLINE]: offlineQueryVariables,
  }[userLineName];

  const [
    getMatches,
    { loading: matchesLoading, data: matchesDataPerLine = [], fetchMore },
  ] = useLazyQuery(matchesQuery, {
    variables: queryVariables,
    skip: !date,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    onCompleted: ({ matches }) => {
      // TODO: change this condition
      if (
        matches &&
        matches.length % pageSize &&
        userLineName === USER_LINE_NAMES.OFFLINE
      )
        setDisableNext(true);
    },
  });
  const [getMatchesSquad] = useLazyQuery(MATCHES_DETAILS_BY_LINE_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMatchesSquads((prev) => [...prev, ...data?.matchesDetailsByLine]);
    },
    onError: () => {
      showToast({
        message: 'Oops, looks like something went wrong. Please try again.',
      });
    },
  });
  const matchesData = isVideo(userLineName)
    ? matchesDataPerLine?.matchesWithVideoFilters
    : matchesDataPerLine;
  useEffect(() => {
    const assignMatchesPipe = pipe(
      filter(
        prop('priority') && path(['squadShifts', '0', 'squadShift', 'id'])
      ),
      pluck('id')
    );
    const assignedMatchesIds = assignMatchesPipe(matchesData?.matches || []);
    if (!isEmpty(assignedMatchesIds)) {
      const newMatchesIds = difference(
        assignedMatchesIds,
        totalAssignedMatchesIds
      );
      setTotalAssignedMatchesIds(assignedMatchesIds);
      getMatchesSquad({
        variables: {
          matchesIds: newMatchesIds,
          lineId,
        },
        skip: isEmpty(assignedMatchesIds) && isNil(assignedMatchesIds),
      });
    }
  }, [matchesData?.matches]);

  const { name: dateName } = new DateObject(date).weekDay;
  const onChangeDate = (activeDate) =>
    matchesDispatch({
      type: matchesActions.SET_DATE,
      payload: { activeDate },
    });
  const icon = isOpen
    ? { icon: 'chevron-up', color: '#0044cc' }
    : { icon: 'chevron-down' };
  const handleClickDate = () => {
    setIsOpen(!isOpen);
    getMatches();
    if (!isSameDate(date, activeDate)) onChangeDate(date);
  };

  const handleDaySelectionChange = (e) => {
    const isDaySelected = e.target.checked;
    matchesDispatch({
      type: isDaySelected
        ? matchesActions.SELECT_DAY
        : matchesActions.DE_SELECT_DAY,
      payload: {
        date,
        matches: matchesData?.matches,
      },
    });
  };

  const unAssignedVideoMatchesList =
    matchesData?.matches?.filter((match) => !isMatchHasSquadShift(match)) || [];

  const isDaySelected = includesAllItems(
    // get all match ids with no video line id and convert to string to compare with keys ==> string...
    matchesData?.matches?.reduce((prev, current) => {
      if (isMatchHasSquadShift(current)) return prev;
      return [...prev, String(current.id)];
    }, []),
    keys(matchesState.selectedMatches?.[date])
  );

  const checkboxIsDisabled =
    !isOpen || !matchesData?.totalCount || !unAssignedVideoMatchesList?.length;

  return (
    <div className={classNames('expandable-card-container')}>
      <div className="expandable-card-header">
        <RenderIf condition={isVideo(userLineName)}>
          <Checkbox
            disabled={checkboxIsDisabled}
            onChange={handleDaySelectionChange}
            checked={isDaySelected}
            large
            className="with-check-mark-checkbox"
          />
        </RenderIf>
        <Button
          minimal
          small
          fill
          intent="Primary"
          className="expand-button width-100 flex"
          onClick={handleClickDate}
        >
          <div className="expand-header-style">
            <span>{`${dateName} ${date}`}</span>
            <Icon {...icon} />
          </div>
        </Button>
      </div>
      <Collapse isOpen={isOpen}>
        <RenderIf condition={isVideo(userLineName)}>
          <VideoExpandableCard
            matchesDispatch={matchesDispatch}
            page={page}
            pageSize={pageSize}
            date={date}
            matchesSquads={matchesSquads}
            matchesData={matchesData}
            matchesLoading={matchesLoading}
            setPage={setPage}
            matchesState={matchesState}
          />
        </RenderIf>
        <RenderIf condition={equals(userLineName, USER_LINE_NAMES.OFFLINE)}>
          <OfflineExpandableCard
            page={page}
            pageSize={pageSize}
            date={date}
            matchesSquads={matchesSquads}
            matchesData={matchesData}
            fetchMore={fetchMore}
            matchesLoading={matchesLoading}
            setPage={setPage}
            disableNext={disableNext}
          />
        </RenderIf>
      </Collapse>
    </div>
  );
};

export default ExpandableCard;
