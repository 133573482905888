import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Button, PasswordInput, Text } from 'components';
import { useModal } from 'providers/modalProvider';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useToast } from 'providers/toastProvider';
import { CHANGE_PASSWORD } from 'validations';
import { UPDATE_PROFILE_PASSWORD } from 'gql';
import { isEmpty } from 'ramda';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

const ChangePassword = () => {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [updatePasswordMutation, { loading: updateLoading }] = useMutation(
    UPDATE_PROFILE_PASSWORD,
    {
      onError: (err) => showToast({ message: err.message, icon: 'error' }),
      onCompleted: () => {
        const message = (
          <FormattedMessage id="containers.users.modals.changePassword.success" />
        );
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message });
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    enableReinitialize: true,
    validationSchema: CHANGE_PASSWORD,
    onSubmit: (values) => {
      const { password } = values;
      updatePasswordMutation({ variables: { password } });
    },
  });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="modal-title">
          <span className="bg-resetPassword" />
          <div className="mgl-6">
            <FormattedMessage id="containers.users.modals.changePassword.title" />
          </div>
        </div>
      }
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
      style={{ width: '459px', maxWidth: 'unset' }}
    >
      <div className="mgt-21 mgl-32 mgb-21">
        <Text variant="body1">
          <FormattedMessage id="containers.users.modals.changePassword.content" />
        </Text>
        <PasswordInput
          required
          type="password"
          labelText={formatMessage({
            id: 'containers.users.modals.changePassword.password',
          })}
          placeholder={formatMessage({
            id: 'containers.users.modals.changePassword.password',
          })}
          value={formik.values.password}
          onChange={formik.handleChange('password')}
          error={formik.touched.password && formik.errors.password}
          className={classNames('mgt-15', 'width-70', {
            'selected-input-highlight': formik.values.password,
          })}
        />
        <PasswordInput
          required
          type="password"
          labelText={formatMessage({
            id: 'containers.users.modals.changePassword.passwordConfirmation',
          })}
          placeholder={formatMessage({
            id: 'containers.users.modals.changePassword.passwordConfirmation',
          })}
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange('passwordConfirmation')}
          error={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
          className={classNames('mgt-15', 'width-70', {
            'selected-input-highlight': formik.values.passwordConfirmation,
          })}
        />
      </div>
      <div className="bp3-dialog-footer-actions">
        <Button
          outlined
          text={formatMessage({ id: 'containers.users.form.cancel' })}
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
        />
        <Button
          intent="Primary"
          text={formatMessage({
            id: 'containers.users.modals.changePassword.title',
          })}
          onClick={formik.submitForm}
          loading={updateLoading}
          disabled={
            isEmpty(formik.values.password) ||
            isEmpty(formik.values.passwordConfirmation)
          }
        />
      </div>
    </Dialog>
  );
};

export default ChangePassword;
