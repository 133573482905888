import { gql } from '@apollo/client';
// acquisitionType field needs to be added to match type
export default gql`
  mutation updateMatchAcquisitionType(
    $input: UpdateMatchAcquisitionTypeInput!
  ) {
    updateMatchAcquisitionType(input: $input) {
      id
      acquisitionType
    }
  }
`;
