import PropTypes, { oneOfType } from 'prop-types';
import { isEmpty, intersection, defaultTo } from 'ramda';
import { useProfile } from 'providers/profileProvider';

const Acl = ({
  allowedRoleNames,
  allowedPermissions,
  renderNoAccess,
  children,
}) => {
  const { rolePermissions, userPermissions, roleName } = useProfile();
  if (allowedRoleNames.includes(roleName)) return children;
  const foundRolePermissions = intersection(
    defaultTo([], rolePermissions),
    allowedPermissions
  );
  const foundUserPermissions = intersection(
    defaultTo([], userPermissions),
    allowedPermissions
  );
  const permitted =
    !isEmpty(foundRolePermissions) || !isEmpty(foundUserPermissions);
  return permitted ? children : renderNoAccess;
};

export const hasAccess = ({
  allowedRoleNames = [],
  allowedPermissions = [],
  profile,
}) => {
  const { rolePermissions, userPermissions, roleName } = profile;
  if (allowedRoleNames.includes(roleName)) return true;
  const foundRolePermissions = intersection(
    defaultTo([], rolePermissions),
    allowedPermissions
  );
  const foundUserPermissions = intersection(
    defaultTo([], userPermissions),
    allowedPermissions
  );
  return !isEmpty(foundRolePermissions) || !isEmpty(foundUserPermissions);
};

export default Acl;

Acl.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  allowedRoleNames: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  renderNoAccess: oneOfType([PropTypes.node, PropTypes.bool]),
};

Acl.defaultProps = {
  renderNoAccess: false,
  allowedPermissions: [],
  allowedRoleNames: [],
};
