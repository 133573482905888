import { gql } from '@apollo/client';
import SQUAD_FRAGMENT from '../fragments/squad';

export default gql`
  query squad($squadId: String!) {
    squad(id: $squadId) {
      ...SquadFragment
    }
  }
  ${SQUAD_FRAGMENT}
`;
