import { gql } from '@apollo/client';

export default gql`
  mutation unassignPcMatch($input: assignPCMatchInput!) {
    unassignPcMatch(input: $input) {
      matchId
      pcId
    }
  }
`;
