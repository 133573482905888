import PropTypes from 'prop-types';

const RenderIf = ({ condition, children, renderElse = false }) => {
  return condition ? children : renderElse;
};

export default RenderIf;

RenderIf.propTypes = {
  condition: PropTypes.bool.isRequired,
};
