import { gql } from '@apollo/client';
import USER_FRAGMENT from '../fragments/user';

export default gql`
  query user($userId: String!) {
    user(id: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
