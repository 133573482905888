import React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'components';
import { VIDEO_STATUS_OPTIONS } from 'utils/enums';

const VideoStatusDropdown = ({
  videoIssueStatus,
  videoStatus,
  onChange,
  disabled,
  loading,
  className,
}) => {
  return (
    <Dropdown
      variant="video_status"
      items={VIDEO_STATUS_OPTIONS}
      onChange={onChange}
      popOverPosition="bottom-left"
      value={videoStatus}
      className={classNames(
        {
          blocked: videoIssueStatus === 'BLOCKED',
          [`video-status-${videoStatus?.toLowerCase()}`]: !!videoStatus,
        },
        'fit-content',
        className
      )}
      disabled={disabled}
      loading={loading}
      disableDropdownButton={disabled}
    />
  );
};

export default VideoStatusDropdown;
