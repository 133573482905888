import React, { useState } from 'react';
import { flatten, isNil, isEmpty, map, prop } from 'ramda';
import { Button, Icon } from 'components';
import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import CSVFileValidator from 'csv-file-validator';
import { FileUploader } from 'react-drag-drop-files';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { convertMatchStatusToBool } from 'utils/helpers';
import {
  ASSIGN_SQUAD_SHIFTS_MATCHES_MUTATION,
  MATCHES_QUERY,
  SQUAD_SHIFTS_POWER_AND_CAPACITY_QUERY,
} from 'gql';
import { useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';

const AssignSquadShiftMatchesModal = () => {
  const { formatMessage } = useIntl();
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const [assignSquadShiftsMatchesData, setAssignSquadShiftsMatchesData] =
    useState();
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;

  const { id: lineId } = userLine ?? {};

  const { filtersState, matchesActiveDate, squadsActiveDate } =
    prop('payload', modalState) || {};
  const { competitionSeasonIds, teamIds, priorityIds, assigned } = filtersState;
  const [fileName, setFileName] = useState();

  const [AssignSquadShiftMatchesMutation, { loading }] = useMutation(
    ASSIGN_SQUAD_SHIFTS_MATCHES_MUTATION,
    {
      onError: ({ message: mutationErrorMessage }) => {
        const message = mutationErrorMessage
          ? `Upload failed: ${mutationErrorMessage} `
          : formatMessage({
              id: 'containers.all.errorSaveChanges',
            });
        showToast({
          message,

          icon: 'error',
        });
      },
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({ id: 'containers.all.success' }),
        });
      },
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'shiftMatches' });
      },
      refetchQueries: [
        {
          query: MATCHES_QUERY,
          variables: {
            date: matchesActiveDate,
            competitionSeasonIds,
            teamIds,
            priorityIds,
            assigned: convertMatchStatusToBool(assigned),
          },
        },
        {
          query: SQUAD_SHIFTS_POWER_AND_CAPACITY_QUERY,
          variables: {
            date: squadsActiveDate,
            lineId,
          },
        },
      ],
    }
  );

  const CSVConfig = {
    headers: [
      { name: 'matchId', inputName: 'matchId', required: true },
      { name: 'squadShiftId', inputName: 'squadShiftId', required: true },
    ],
  };
  const convertToInt = (row) => [{ ...row, matchId: Number(row.matchId) }];

  const startAssigning = () =>
    AssignSquadShiftMatchesMutation({
      variables: {
        input: {
          matches: assignSquadShiftsMatchesData,
          lineId,
        },
      },
    });
  const handleChange = (file) => {
    if (!file) return;
    CSVFileValidator(file, CSVConfig)
      .then((csvData) => {
        if (isEmpty(csvData.inValidMessages)) {
          const { data } = csvData;
          const dataWithFixMatchIdType = map(convertToInt, data);
          const flattenedDate = flatten(dataWithFixMatchIdType);
          setAssignSquadShiftsMatchesData(flattenedDate);
          setFileName(file.name);
        } else {
          showToast({
            message: 'The file uploaded is invalid. Please try again.',
            icon: 'error',
          });
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log('error', err));
  };
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <span className="remove" />
          <div>
            {formatMessage({
              id: 'containers.matchAssignment.title.autoAssigning',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '459px' }}
    >
      <div className="assign-matches-upload-container">
        <FileUploader handleChange={handleChange} name="file" types={['csv']}>
          <div>
            <Icon customIcon="cog" size={25} />
            <span>
              {formatMessage({
                id: 'containers.matchAssignment.assignMatches.description.part1',
              })}
            </span>
            <span>
              {formatMessage({
                id: 'containers.matchAssignment.assignMatches.description.part2',
              })}
            </span>
            <span>
              {formatMessage({
                id: 'containers.matchAssignment.assignMatches.description.part3',
              })}
            </span>
          </div>
        </FileUploader>
      </div>
      {assignSquadShiftsMatchesData && (
        <div className="assign-matches-file-name">
          <Icon customIcon="out" size={16} />
          <span>{fileName}</span>
          <Button
            icon={<Icon icon="cross" color="red" />}
            minimal
            className="automarginLeft"
            onClick={() => setAssignSquadShiftsMatchesData(undefined)}
          />
        </div>
      )}
      <div className="bp3-dialog-footer-actions">
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
        />
        <Button
          intent="Primary"
          text={formatMessage({
            id: 'containers.matchAssignment.assignMatches.btn.autoAssigning',
          })}
          onClick={startAssigning}
          loading={loading}
          disabled={
            isEmpty(assignSquadShiftsMatchesData) ||
            isNil(assignSquadShiftsMatchesData)
          }
          data-testid="start-upload-btn"
        />
      </div>
    </Dialog>
  );
};

export default AssignSquadShiftMatchesModal;
