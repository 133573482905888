import { gql } from '@apollo/client';

export default gql`
  mutation updateSquadShiftMember($input: UpdateSquadShiftMemberInput!) {
    updateSquadShiftMember(input: $input) {
      id
      vacationType
      userId
      squadShiftId
      power
      updatedAt
    }
  }
`;
