import React from 'react';
import { usePagination, DOTS } from 'hooks/usePagination';
import { Icon } from 'components';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  screenTitle,
  className,
  pageCountOnly = false,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange.length < 1) return null;
  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);
  const lastPage = paginationRange[paginationRange.length - 1];
  const { formatMessage } = useIntl();
  return (
    <div
      className={classNames('pagination-container', className)}
      data-testid="main-pagination-container"
    >
      <div className="total-count" data-testid="main-pagination-total_count">
        {formatMessage(
          { id: 'containers.all.pagination.showingTotalMsg' },
          { totalCount, screenTitle }
        )}
      </div>
      {!pageCountOnly && (
        <div className="per-page" data-testid="main-pagination-page_size">
          <span>
            {formatMessage(
              { id: 'containers.all.pagination.showOnPageCount' },
              { pageSize }
            )}
          </span>
        </div>
      )}
      <div className="pages" data-testid="main-pagination-pages_container">
        <button
          onClick={onPrevious}
          className={classNames({ disabled: currentPage === 1 })}
          disabled={currentPage === 1}
          data-testid="main-pagination-btn_prev"
          type="button"
        >
          <Icon icon="chevron-left" />
        </button>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS)
            return (
              <span
                className="pagination-item dots"
                key={`pagination-${pageNumber}`}
              >
                &#8230;
              </span>
            );
          return (
            <button
              onClick={() => onPageChange(pageNumber)}
              className={classNames({ selected: pageNumber === currentPage })}
              key={`pagination-${pageNumber}`}
              data-testid="main-pagination-page_number"
              type="button"
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          onClick={onNext}
          className={classNames({ disabled: currentPage === lastPage })}
          disabled={currentPage === lastPage}
          data-testid="main-pagination-btn_next"
          type="button"
        >
          <Icon icon="chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  screenTitle: PropTypes.string,
  className: PropTypes.string,
  pageCountOnly: PropTypes.bool,
};

Pagination.defaultProps = {
  pageCountOnly: false,
  siblingCount: 1,
  className: '',
  screenTitle: '',
  pageSize: 10,
};
