/* eslint-disable no-unused-vars */

import { useQuery } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';
import { Dropdown, Icon, TextInput } from 'components';
import { FEATURES_QUERY } from 'gql';
import { filter, find, prop, propEq } from 'ramda';
import { useIntl } from 'react-intl';
import { optionsFromArr } from 'utils/helpers';

const ChannelDetails = ({
  channelDetails,
  setFormikField,
  touched,
  errors,
}) => {
  const { formatMessage } = useIntl();
  const { loading: featuresLoading, data: featuresData } =
    useQuery(FEATURES_QUERY);
  const satelliteData =
    featuresData?.features &&
    filter(propEq('type', 'SATELLITE'), featuresData?.features);
  const beinSportId =
    featuresData?.features &&
    prop('id', find(propEq('name', 'BEIN'), featuresData?.features));
  return (
    <div className={classNames('flex', 'gap-15', 'column', 'mgt-20')}>
      <div className={classNames(['flex', 'gap-40'])}>
        <TextInput
          required
          type="text"
          labelText={formatMessage({
            id: 'containers.providers.form.channelDetails.name',
          })}
          placeholder={formatMessage({
            id: 'containers.providers.form.channelDetails.name',
          })}
          value={channelDetails.name}
          onChange={(name) => setFormikField('channelDetails.name', name)}
          error={touched?.name && errors?.name}
          className={classNames({
            'selected-input-highlight': channelDetails.name,
          })}
          data-testid="providers-form-channelDetails"
          name="channelDetails"
        />
        <Checkbox
          large
          checked={channelDetails?.bein}
          onChange={({ currentTarget: { checked } }) => {
            setFormikField(`channelDetails.bein`, checked);
            const featureId = checked ? beinSportId : null;
            setFormikField(`channelDetails.featureId`, featureId);
          }}
        >
          <div className="flex gap-5">
            <div>Bein Sport</div>
            <div className="bein-sport" />
          </div>
        </Checkbox>
      </div>
      <Dropdown
        items={optionsFromArr(satelliteData)}
        value={channelDetails.satelliteId}
        onChange={({ value }) =>
          setFormikField('channelDetails.satelliteId', value)
        }
        error={touched?.satelliteId && errors?.satelliteId}
        buttonText={formatMessage({
          id: 'containers.providers.form.satellite',
        })}
        loading={featuresLoading}
        className={classNames({
          'selected-dropdown-highlight': !channelDetails.satelliteId,
        })}
        data-testid="providers-form-satelliteId"
        name="satelliteId"
      />
    </div>
  );
};

export default ChannelDetails;
