import React, { useState, useCallback } from 'react';
import { Dialog, Spinner } from '@blueprintjs/core';
import { RenderIf, Acl } from 'config';
import { title } from 'utils/helpers';
import {
  Button,
  Icon,
  Text,
  Divider,
  ShiftMatchCard,
  Pagination,
} from 'components';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useIntl } from 'react-intl';
import { prop, length, map, equals, isNil, isEmpty } from 'ramda';
import { Grid, Col, Row } from 'react-flexbox-grid';
import { useMutation, useQuery } from '@apollo/client';
import { UNASSIGN_PC_MATCH_MUTATION, PCS_MATCHES_QUERY } from 'gql';
import CardAttribute from 'components/Card/helpers/cardAttribute';

const ViewPCMatches = () => {
  const { modalDispatch, modalState } = useModal();
  const closeModal = useCallback(
    () =>
      modalDispatch({
        type: 'ClOSE_ALL',
      }),
    []
  );

  const { formatMessage } = useIntl();
  const [page, setPage] = useState(1);
  const [selectedMatchId, setSelectedMatchId] = useState();
  const pageSize = 5;
  const onPageChange = (page) => setPage(page);
  const { showToast } = useToast();
  const {
    pc,
    refetchPcScreenQuery,
    variables: passedVars,
  } = prop(['payload'], modalState);
  const variables = {
    ...passedVars,
    pcId: prop('id', pc),
    queryParams: {
      page,
      pageSize,
    },
  };
  const assignedPcRoom = pc?.location
    ? `${title(pc.location.HQ)} - ${title(pc.location.name)}`
    : '-';
  const { data, loading } = useQuery(PCS_MATCHES_QUERY, {
    variables,
    skip: !prop('id', pc),
    fetchPolicy: 'network-only',
  });
  const { matches = [], totalCount } = data?.PCsMatches || {};

  const [unassignPcMatchMutation, { loading: unassignLoading }] = useMutation(
    UNASSIGN_PC_MATCH_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PCS_MATCHES_QUERY,
          variables,
        },
      ],
      onError: () => {
        const message = formatMessage({
          id: 'containers.all.errorSaveChanges',
        });
        setSelectedMatchId(undefined);
        showToast({ message, icon: 'error' });
      },
      onCompleted: () => {
        refetchPcScreenQuery();
        const message = formatMessage({
          id: 'containers.dashboards.unassign.success',
        });
        setSelectedMatchId(undefined);
        showToast({ message });
        if (isEmpty(matches)) closeModal();
      },
    }
  );

  const modalTitle = (
    <div className="dialog-title">
      <Icon icon="desktop" size={20} data-testid="modalHeader-icon" />
      <Text variant="h3">
        {formatMessage({ id: 'containers.pc.viewMatches' })}
      </Text>
    </div>
  );
  const unAssign = (matchId) => {
    setSelectedMatchId(matchId);
    unassignPcMatchMutation({
      variables: {
        input: {
          matchId,
          pcId: prop('id', pc),
        },
      },
    });
  };
  const isOpen = prop('open', modalState) && !isNil(pc);
  return (
    <Dialog
      isOpen={isOpen}
      title={modalTitle}
      onClose={closeModal}
      canOutsideClickClose={false}
      style={{ width: '1200px', maxWidth: 'unset' }}
    >
      <Grid>
        <Row middle="sm" className="pt-28">
          <Col sm={1}>
            <Text variant="body1" className="pc-name-card">
              {`PC ${prop('number', pc)}`}
            </Text>
          </Col>
          <Col sm={8} className="card-border p-14 flex gap-16">
            <CardAttribute name="Room" value={assignedPcRoom} />
            <div className="flex center gap-2">
              <div className="bg-football" />
              <Text variant="body1">{`${totalCount} matches`}</Text>
            </div>
          </Col>
        </Row>
        <Divider className="mgt-16" />
        <RenderIf condition={loading}>
          <Spinner />
        </RenderIf>
        <RenderIf condition={!!length(matches) && !loading}>
          <Row>
            <Col sm={12}>
              <Pagination
                totalCount={totalCount}
                currentPage={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                screenTitle="Matches"
                pageCountOnly
              />
            </Col>
          </Row>
        </RenderIf>
        <RenderIf condition={!!length(matches) && !loading}>
          {map(
            (match) => (
              <Row middle="sm" key={prop('id', match)} className="mgb-10">
                <Col sm={12} className="flex gap-10">
                  <ShiftMatchCard shiftMatch={match} />
                  {/* TODO: may be needed to change to delete:pc-assignment */}
                  <Acl allowedPermissions={['update:pc-assignment']}>
                    <Button
                      text="Unassign"
                      icon="ban-circle"
                      minimal
                      intent="Primary"
                      onClick={() => unAssign(prop('id', match))}
                      loading={
                        unassignLoading &&
                        equals(selectedMatchId, prop('id', match))
                      }
                    />
                  </Acl>
                </Col>
              </Row>
            ),
            matches
          )}
        </RenderIf>
        <Row className="mgt-28 pr-16">
          <Col sm={12} className="bp3-dialog-footer-actions mgl-0">
            <Button
              outlined
              intent="Primary"
              text={formatMessage({ id: 'containers.all.btn.cancel' })}
              onClick={closeModal}
              data-testid="cancel-button-footer"
            />
          </Col>
        </Row>
      </Grid>
    </Dialog>
  );
};

export default ViewPCMatches;
