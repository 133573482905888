import { gql } from '@apollo/client';

export default gql`
  mutation CreateCompetitionSeasonProviders(
    $input: createCompetitionSeasonProvidersInput!
  ) {
    createCompetitionSeasonProviders(input: $input) {
      count
    }
  }
`;
