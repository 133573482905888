import React from 'react';
import { prop, map } from 'ramda';
import { providerTypeIconMapper } from 'utils/enums';
import { title } from 'utils/helpers';
import { Classes, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon, Text } from 'components';
import classNames from 'classnames';

const ProviderList = ({ providers }) => {
  return (
    <div className="flex column gap-15">
      {map(({ provider, isDefault }) => {
        const { id: providerId, type: providerType } = provider;
        const providerName = provider[providerType?.toLowerCase()]?.name;
        return (
          <div
            className={classNames(['flex ', 'gap-10'])}
            key={`provider-${providerId}`}
          >
            <Tooltip2
              content="Recording Type"
              position={Position.TOP_LEFT}
              className={Classes.TOOLTIP_INDICATOR}
              minimal
              usePortal
            >
              <div className={classNames(['flex ', 'gap-10'])}>
                <Icon icon={prop(providerType, providerTypeIconMapper)} />
                <Text variant={isDefault ? 'body1' : 'body2'}>
                  {title(providerType)}
                </Text>
              </div>
            </Tooltip2>
            <Tooltip2
              content="Recording Name"
              position={Position.TOP_LEFT}
              className={Classes.TOOLTIP_INDICATOR}
              minimal
              usePortal
            >
              <Text variant={isDefault ? 'body1' : 'body2'}>
                {providerName}
              </Text>
            </Tooltip2>
          </div>
        );
      }, providers)}
    </div>
  );
};

export default ProviderList;
