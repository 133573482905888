import { gql } from '@apollo/client';

export default gql`
  mutation AssignPcMatch($input: assignPCMatchInput!) {
    assignPcMatch(input: $input) {
      matchId
      pcId
    }
  }
`;
