export const initialState = { open: false, type: undefined };

export const modalReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'CREATE_USER':
      return {
        open: true,
        type,
        refetch: payload.refetch,
        variables: payload.variables,
      };
    case 'DELETE_USER':
      return { open: true, type, user: payload.user, refetch: payload.refetch };
    case 'DEACTIVATE_USER':
      return { open: true, type, user: payload };
    case 'EDIT_USER':
      return { open: true, type, user: payload.user, refetch: payload.refetch };
    case 'RESET_MFA':
      return {
        open: true,
        type,
        user: payload.user,
        refetch: payload.refetch,
        squad: payload.squad,
      };
    case 'RESET_PASSWORD':
      return {
        open: true,
        type,
        user: payload.user,
        refetch: payload.refetch,
        squad: payload.squad,
      };

    case 'ADD_SQUAD_MEMBER':
      return { open: true, type, payload };
    case 'REMOVE_SQUAD_USER':
      return { open: true, type, user: payload.user, squad: payload.squad };
    case 'CLOSE_ALL':
      return { open: false, type: undefined };
    case 'ADD_SQUAD':
      return { open: true, type, payload };
    case 'ASSIGN_PRIORITY':
      return { open: true, type, screenType: payload };
    case 'EDIT_PRIORITY':
      return { open: true, type, payload };
    case 'REMOVE_PRIORITY':
      return { open: true, type, payload };
    case 'ADD_NEW_SLA':
      return { open: true, type, payload };
    case 'DELETE_SLA':
      return { open: true, type, payload };
    case 'ADD_NEW_PRIORITY':
      return { open: true, type, payload };
    case 'EDIT_EXISTING_PRIORITY':
      return { open: true, type, payload };
    case 'ARRANGE_RANKS':
      return { open: true, type, payload };

    case 'EDIT_SQUAD_POWER':
      return { open: true, type, payload };
    case 'ASSIGN_SHIFT_MODAL':
      return { open: true, type, payload };
    case 'UNASSIGN_SHIFT_MODAL':
      return { open: true, type, payload };
    case 'SQUAD_MATCHES_DETAILS':
      return { open: true, type, payload };

    case 'ASSIGN_SQUAD_SHIFTS_MATCHES':
      return { open: true, type, payload };
    case 'ASSIGN_TASK':
      return { open: true, type, payload };
    case 'EDIT_TASK':
      return { open: true, type, payload };
    case 'BLOCK_MATCH':
      return { open: true, type, payload };
    case 'UN_BLOCK_MATCH':
      return { open: true, type, payload };
    case 'REASSIGN_MATCH':
      return {
        open: true,
        type,
        payload,
      };
    case 'SWAP_MATCH':
      return { open: true, type, payload };
    case 'UNASSIGN_MATCH':
      return { open: true, type, payload };
    case 'REPORT_ISSUE':
      return { open: true, type, payload };
    case 'RESOLVE_ISSUE':
      return { open: true, type, payload };
    case 'VIEW_DETAILS':
      return { open: true, type, payload };
    case 'CHANGE_PASSWORD':
      return { open: true, type, payload };
    case 'SIGN_OUT':
      return { open: true, type, payload };
    case 'VIEW_TASKS':
      return { open: true, type, payload };
    case 'CREATE_UPDATE_PC':
      return { open: true, type, payload };
    case 'DELETE_PC':
      return { open: true, type, payload };
    case 'CREATE_UPDATE_PROVIDER':
      return { open: true, type, payload };
    case 'DELETE_PROVIDER':
    case 'CREATE_LEAGUE':
      return { open: true, type, payload };
    case 'EDIT_LEAGUE':
      return { open: true, type, payload };
    case 'DELETE_LEAGUE':
      return { open: true, type, payload };
    case 'VIEW_PC_MATCHES':
      return { open: true, type, payload };
    case 'ENTER_IMPERSONATION':
      return { open: true, type, payload };
    default:
      return initialState;
  }
};
