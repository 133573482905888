import { gql } from '@apollo/client';

export default gql`
  query MatchTasks($matchId: Int!) {
    matchTasks(matchId: $matchId) {
      id
      matchId
      member {
        id
        userId
        squadShiftId
        power
        user {
          id
          hrCode
        }
      }
    }
  }
`;
