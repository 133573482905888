import React from 'react';
import PropTypes from 'prop-types';
import { Icon as BPIcon } from '@blueprintjs/core';
import classNames from 'classnames';
import * as icons from 'assets/customIcons/';

const Icon = ({
  icon,
  size,
  title,
  intent,
  color,
  customIcon,
  className,
  ...rest
}) => {
  if (customIcon) {
    const iconName = customIcon.charAt(0).toUpperCase() + customIcon.slice(1);
    return (
      <object
        type="image/svg+xml"
        data={icons[`${iconName}Icon`]}
        width={size}
        height={size}
        aria-label="icon"
        className={className}
        icon={customIcon}
        {...rest}
      />
    );
  }
  return (
    <BPIcon
      icon={icon}
      size={size}
      title={title}
      intent={intent}
      color={color}
      className={classNames('text-center', className)}
      {...rest}
    />
  );
};
export default Icon;

Icon.propTypes = {
  icon: PropTypes.string,
  customIcon: PropTypes.string,
  size: PropTypes.number,
  title: PropTypes.string,
  intent: PropTypes.string,
  color: PropTypes.oneOf(['#999999', '#0044CC', '#1BB55C', '#DC2228']),
};

Icon.defaultProps = {
  icon: 'plus',
  size: 15,
  title: undefined,
  intent: 'undefined',
  color: '#999999',
  customIcon: undefined,
};
