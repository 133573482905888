import { gql } from '@apollo/client';
// we can return array of modified matches if needed
// can provide room for optimistic response instead of refetching matches query
export default gql`
  mutation assignMatchesAcquisitionTypes(
    $input: [AssignMatchAcquisitionTypeInput!]!
  ) {
    assignMatchesAcquisitionTypes(input: $input) {
      count
    }
  }
`;
