import React from 'react';
import { Icon, Button } from 'components';
import PropTypes from 'prop-types';
import { RenderIf } from 'config';
import classNames from 'classnames';

const Fab = ({
  icon,
  text,
  iconSize,
  onClick,
  removeButton,
  className,
  ...rest
}) => (
  <div className={classNames('fab-component', className)} {...rest}>
    <span className="fab-component-text" data-testid="fab-text">
      {text}
    </span>
    <RenderIf condition={!removeButton}>
      <div className="fab-icon">
        <Button
          onClick={onClick}
          minimal
          small
          type="button"
          text=""
          icon={<Icon icon={icon} size={iconSize} color="#fff" />}
          data-testid="main-fab-close"
        />
      </div>
    </RenderIf>
  </div>
);

export default Fab;
Fab.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  removeButton: PropTypes.bool,
};

Fab.defaultProps = {
  icon: 'cross',
  iconSize: 12,
  onClick: () => {},
  removeButton: false,
};
