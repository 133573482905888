/* eslint-disable no-unused-vars */

import { useQuery } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';
import { Dropdown, Icon, TextInput } from 'components';
import { FEATURES_QUERY, VPN_SERVICES_QUERY } from 'gql';
import { find, prop, propEq } from 'ramda';
import React from 'react';
import { useIntl } from 'react-intl';
import { optionsFromArr } from 'utils/helpers';

const StreamingDetails = ({
  streamingDetails,
  setFormikField,
  touched,
  errors,
}) => {
  const { formatMessage } = useIntl();
  const { loading: vpnLoading, data: vpnData } = useQuery(VPN_SERVICES_QUERY);
  const { loading: featuresLoading, data: featuresData } =
    useQuery(FEATURES_QUERY);
  const appleTvId =
    featuresData?.features &&
    prop('id', find(propEq('name', 'APPLETV'), featuresData?.features));

  return (
    <>
      <div className={classNames(['flex ', 'gap-40'])}>
        <TextInput
          required
          labelText={formatMessage({
            id: 'containers.providers.form.streamingname',
          })}
          placeholder={formatMessage({
            id: 'containers.providers.form.streamingname',
          })}
          value={streamingDetails?.name}
          onChange={(v) => setFormikField('streamingDetails.name', v)}
          error={touched?.name && errors?.name}
          className={classNames({
            'selected-input-highlight': streamingDetails?.name,
          })}
          data-testid="providers-form-name"
          name="name"
        />
        <Dropdown
          items={optionsFromArr(vpnData?.vpnService)}
          values={streamingDetails?.vpn || []}
          onChange={(value) =>
            setFormikField('streamingDetails.vpn', [...value])
          }
          error={touched?.vpn && errors?.vpn}
          buttonText={formatMessage({
            id: 'containers.pc.form.vpn',
          })}
          multiSelect
          loading={vpnLoading}
          className={classNames({
            'selected-dropdown-highlight': !streamingDetails?.vpn,
          })}
          data-testid="pc-form-vpn"
          name="vpn"
          variant="multiselect_obj_list"
        />
      </div>

      <div className={classNames(['flex', 'gap-40'])}>
        <TextInput
          required
          type="number"
          labelText={formatMessage({
            id: 'containers.providers.form.noOfAccounts',
          })}
          placeholder={formatMessage({
            id: 'containers.providers.form.noOfAccounts',
          })}
          value={streamingDetails?.noOfAccounts}
          onChange={(v) => setFormikField('streamingDetails.noOfAccounts', v)}
          error={touched?.noOfAccounts && errors?.noOfAccounts}
          className={classNames('mgb-20', 'mgt-20', {
            'selected-input-highlight': streamingDetails?.noOfAccounts,
          })}
          min="0"
          data-testid="providers-form-noOfAccounts"
          name="noOfAccounts"
        />
        <TextInput
          required
          type="number"
          labelText={formatMessage({
            id: 'containers.providers.form.accConcurrency',
          })}
          placeholder={formatMessage({
            id: 'containers.providers.form.accConcurrency',
          })}
          value={streamingDetails?.noOfConcurrency}
          onChange={(v) =>
            setFormikField('streamingDetails.noOfConcurrency', v)
          }
          error={touched?.noOfConcurrency && errors?.noOfConcurrency}
          className={classNames('mgb-20', 'mgt-20', {
            'selected-input-highlight': streamingDetails?.noOfConcurrency,
          })}
          min="0"
          data-testid="providers-form-noOfConcurrency"
          name="noOfConcurrency"
        />
      </div>

      <div className="flex items-flex-start gap-60">
        <div className="flex column">
          <Checkbox
            large
            checked={streamingDetails?.videoOnDemand}
            onChange={() => {
              setFormikField(
                `streamingDetails.videoOnDemand`,
                !streamingDetails?.videoOnDemand
              );
            }}
          >
            <div className="flex center gap-5">
              <div>VOD</div>
              <Icon icon="video" size={20} color="#0044cc" />
            </div>
          </Checkbox>
          <Checkbox
            large
            checked={streamingDetails?.downloadable}
            onChange={() => {
              setFormikField(
                `streamingDetails.downloadable`,
                !streamingDetails?.downloadable
              );
            }}
          >
            <div className="flex center gap-5">
              <div>Downloadable</div>
              <Icon icon="download" size={20} color="#0044cc" />
            </div>
          </Checkbox>
        </div>
        <Checkbox
          large
          checked={streamingDetails?.appleTv}
          onChange={({ currentTarget: { checked } }) => {
            setFormikField(`streamingDetails.appleTv`, checked);
            const featureId = checked ? appleTvId : null;
            setFormikField(`streamingDetails.featureId`, featureId);
          }}
        >
          <div className="flex gap-5">
            <div>Apple TV</div>
            <div className="apple-tv" />
          </div>
        </Checkbox>
      </div>
    </>
  );
};

export default StreamingDetails;
