/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import DatePicker, {
  DateObject,
  getAllDatesInRange,
} from 'react-multi-date-picker';
import { map, last, concat } from 'ramda';
import { arrayDiff, formatDate, longFormatDate } from 'utils/helpers';
import { Button } from 'components';
import { RenderIf } from 'config';
import useKeyPress from '../../hooks/useKeyPress';

const WeekPicker = ({
  onChange,
  datePikerOnly = false,
  preSelect,
  weekStartsDay,
  minDate,
  maxDate,
  // used to overwrite keyPress and force key on/off behavior
  forceCtrlOn,
  forceAltOn,
}) => {
  const [week, setWeek] = useState([]);
  const [value, setValue] = useState([]);

  const ctrlOn = forceCtrlOn ?? useKeyPress('Control');
  const AltOn = forceAltOn ?? useKeyPress('Alt');

  const getStartDayIndex = (day = 'sun') => {
    const daysIndex = {
      sun: 0,
      mon: 1,
      tues: 2,
      wed: 3,
      thurs: 4,
      fri: 5,
      sat: 6,
    };
    return daysIndex[day];
  };

  const getStartandEndOfWeek = (inputDate) => {
    const weekStartIndex = getStartDayIndex(weekStartsDay);
    const date = inputDate ? new Date(inputDate) : new Date();
    const dayOfWeek = date.getDay();
    const difference =
      dayOfWeek >= weekStartIndex
        ? dayOfWeek - weekStartIndex
        : 7 + dayOfWeek - weekStartIndex;
    const weekStartDate = new Date(date);
    weekStartDate.setDate(date.getDate() - difference);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekStartDate.getDate() + 6);
    return [new DateObject(weekStartDate), new DateObject(weekEndDate)];
  };

  const getMinDate = () => {
    const datesMap = {
      CURRENT_WEEK: getStartandEndOfWeek()[0],
    };
    return datesMap[minDate] ?? minDate;
  };
  const getMaxDate = () => {
    const datesMap = {
      CURRENT_WEEK: getStartandEndOfWeek()[1],
    };
    return datesMap[maxDate] ?? maxDate;
  };
  const selectDates = (val) => {
    setValue(val);
    const formatedDates = map(formatDate, val);
    onChange(formatedDates);
  };

  useEffect(() => {
    if (preSelect && preSelect === 'week') {
      const currentWeek = getStartandEndOfWeek();
      const allDates = getAllDatesInRange(currentWeek);
      selectDates(allDates);
      setWeek(allDates);
    }
    if (preSelect && preSelect === 'day') {
      selectDates([new DateObject()]);
    }
  }, [weekStartsDay]);

  const datePickerRef = useRef();
  const datePickerRender = (_, openPicker) => (
    <Button
      icon={<span className="bg-calender" />}
      onClick={openPicker}
      small
      className="calender-icon bg-calender"
    />
  );

  const decrementWeek = () => {
    const day = new DateObject(week[0]).subtract(1, 'day').toDate();
    const lastWeek = getStartandEndOfWeek(day);
    const allDates = getAllDatesInRange(lastWeek);
    setWeek(allDates);
    setValue(allDates);
    const formatedDates = map(formatDate, allDates);
    onChange(formatedDates);
  };
  const incrementWeek = () => {
    const day = new DateObject(week[week.length - 1]).add(1, 'day').toDate();
    const nextWeek = getStartandEndOfWeek(day);
    const allDates = getAllDatesInRange(nextWeek);
    setWeek(allDates);
    setValue(allDates);
    const formatedDates = map(formatDate, allDates);
    onChange(formatedDates);
  };

  const handlePickerChange = (dateObjects) => {
    const addedDate = last(arrayDiff(value, dateObjects));
    const removedDate = last(arrayDiff(dateObjects, value));
    if (ctrlOn && AltOn && addedDate) {
      const week = getStartandEndOfWeek(addedDate);
      const allDates = getAllDatesInRange(week);
      const uniqDates = Array.from(new Set(concat(value, allDates)));
      setValue(uniqDates);
      const formatedDates = map(formatDate, uniqDates);
      onChange(formatedDates);
      return;
    }
    if (ctrlOn && AltOn && removedDate) {
      const week = getStartandEndOfWeek(removedDate);
      const allDates = getAllDatesInRange(week);
      const uniqDates = arrayDiff(allDates, value);
      setValue(uniqDates);
      const formatedDates = map(formatDate, uniqDates);
      onChange(formatedDates);
      return;
    }
    if (ctrlOn && addedDate) {
      const week = getStartandEndOfWeek(addedDate);
      const allDates = getAllDatesInRange(week);
      setWeek(allDates);
      setValue(allDates);
      const formatedDates = map(formatDate, allDates);
      onChange(formatedDates);
      return;
    }
    if (ctrlOn && removedDate) {
      const week = getStartandEndOfWeek(removedDate);
      const allDates = getAllDatesInRange(week);
      setWeek(allDates);
      setValue(allDates);
      const formatedDates = map(formatDate, allDates);
      onChange(formatedDates);
    } else {
      setValue(dateObjects);
      const formatedDates = map(formatDate, dateObjects);
      onChange(formatedDates);
    }
  };

  const displayDate = useMemo(
    () => (
      <>
        <b>
          Week
          {week[0]?.weekOfYear}
          &nbsp;:&nbsp;
        </b>
        <span>{longFormatDate(week[0])}</span>
        <span>&nbsp;-&nbsp;</span>
        <span>{longFormatDate(week[week.length - 1])}</span>
      </>
    ),
    [week]
  );
  const disableRight =
    minDate &&
    new DateObject(week[0])?.subtract(1, 'day').valueOf() >=
      getMinDate().valueOf();
  const disableLeft =
    maxDate &&
    new DateObject(week[6])?.add(1, 'day').valueOf() >= getMaxDate().valueOf();

  return (
    <div className="flex items-center" data-testid="week-picker">
      <RenderIf condition={!datePikerOnly}>
        <Button
          icon="chevron-left"
          minimal
          intent="Primary"
          onClick={decrementWeek}
          key={`${week[6]}-left`}
          disabled={disableRight}
        />
        <span>{displayDate}</span>
        <Button
          icon="chevron-right"
          minimal
          intent="Primary"
          onClick={incrementWeek}
          key={`${week[6]}-right`}
          disabled={disableLeft}
        />
      </RenderIf>
      <DatePicker
        value={value}
        onChange={(dateObjects) => handlePickerChange(dateObjects)}
        format="YYYY-MM-dd"
        multiple
        sort
        displayWeekNumbers
        weekStartDayIndex={getStartDayIndex(weekStartsDay)}
        showOtherDays
        shadow
        arrow
        ref={datePickerRef}
        render={datePickerRender}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        // plugins={[<DatePanel sort="date" />]}
      />
    </div>
  );
};

export default WeekPicker;
