import { useMutation } from '@apollo/client';
import { Tag } from '@blueprintjs/core';
import { Button, Dialog, Icon, Text } from 'components';
import { RenderIf } from 'config';
import { useFormik } from 'formik';
import { DELETE_PROVIDER_MUTATION, PROVIDERS_QUERY } from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { title } from 'utils/helpers';

const DeleteProvider = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const {
    payload: { providerData = {}, screenInfo = {} },
  } = modalState;
  const { id, type, name } = providerData;
  const onError = useCallback((err) => {
    if (err?.graphQLErrors?.[0]?.extensions?.exception?.code === 'P2003') {
      showToast({
        message:
          'Unable to delete the provider. Please remove any competition assignments associated with it first.',
        icon: 'error',
      });
    } else {
      showToast({
        message: 'Oops, looks like something went wrong. Please try again.',
        icon: 'error',
      });
    }
  }, []);
  const [deleteProviderMutation, { loading }] = useMutation(
    DELETE_PROVIDER_MUTATION,
    {
      refetchQueries: [
        {
          query: PROVIDERS_QUERY,
          variables: { ...screenInfo },
        },
      ],
      awaitRefetchQueries: true,
      onError: (err) => onError(err),
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.provider.modals.delete.success',
          }),
        });
      },
    }
  );

  const initialValues = {
    id,
    type,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { id } = values;
      deleteProviderMutation({
        variables: { input: { id, type } },
      });
    },
  });

  return (
    <Dialog
      dialogTitle={
        <FormattedMessage id="containers.providers.form.delete.provider.title" />
      }
      icon={<Icon icon="ban-circle" size={15} data-testid="modalHeader-icon" />}
      width="541px"
    >
      <div className="bp3-dialog-body flex column gap-20">
        <Text variant="body1" data-testid="provider-deleteModal-text">
          <FormattedMessage id="containers.providers.modals.delete.content" />
        </Text>
        <div className="flex gap-10">
          <RenderIf condition={name}>
            <Tag round className="pc-number-tag ">
              <Text variant="body2">{`${title(name)}`}</Text>
            </Tag>
          </RenderIf>
          <Tag round className="pc-number-tag">
            <Text variant="body2">{`${title(type)}`}</Text>
          </Tag>
        </div>
      </div>
      <div className="bp3-dialog-footer" data-testid="delete-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={<FormattedMessage id="containers.users.form.cancel" />}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={<FormattedMessage id="containers.all.delete" />}
            onClick={formik.submitForm}
            loading={loading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteProvider;
