import React from 'react';
import { map } from 'ramda';

export default function BorderLabel({ labelsList = [], className, ...rest }) {
  return (
    <div className={`bordered-label ${className} `} {...rest}>
      {map(
        (label) => (
          <span className="mgl-12 mgr-12 mgb-6 mgt-6">{label}</span>
        ),
        labelsList
      )}
    </div>
  );
}
