import React from 'react';
import { Button, Fab, Icon, Label, Text } from 'components';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Classes, Position } from '@blueprintjs/core';
import { optionsFromArr, title } from 'utils/helpers';
import { RenderIf, Acl } from 'config';
import { any, filter, find, isEmpty, map, prop, propEq } from 'ramda';
import { useModal } from 'providers/modalProvider';
import { FEATURE_TYPES } from 'utils/enums';

const PcCard = ({
  number,
  VPN,
  server,
  features = [],
  task,
  location,
  id,
  screenInfo,
}) => {
  const flattenFeatures = map(prop('feature'), features) || [];
  const satelliteFeature = filter(
    propEq('type', FEATURE_TYPES.SATELLITE),
    flattenFeatures
  );
  const iptvDeviceFeature = find(
    propEq('type', FEATURE_TYPES.IPTV_DEVICE),
    flattenFeatures
  );
  const hasAppleTv = any(propEq('name', 'APPLETV'))(flattenFeatures);
  const hasbein = any(propEq('name', 'BEIN'))(flattenFeatures);
  const { modalDispatch } = useModal();
  return (
    <div className="card" data-testid="video-pcs-card-container">
      <div className="card-header">
        <div>
          <Tooltip2
            content="PC Number"
            position={Position.BOTTOM_LEFT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <Text variant="body1">{`#${number}`}</Text>
          </Tooltip2>
        </div>
        <div className="flex gap-10 center">
          <RenderIf condition={!isEmpty(VPN)}>
            <div>
              <Tooltip2
                content={VPN.join('-')}
                position={Position.BOTTOM_RIGHT}
                className={Classes.TOOLTIP_INDICATOR}
                minimal
                usePortal={false}
              >
                <Icon icon="shield" size={22} color="#0044cc" />
              </Tooltip2>
            </div>
          </RenderIf>
          <RenderIf condition={hasAppleTv}>
            <div>
              <Tooltip2
                content="AppleTv"
                position={Position.BOTTOM_RIGHT}
                className={Classes.TOOLTIP_INDICATOR}
                minimal
                usePortal={false}
              >
                <div className="apple-tv" />
              </Tooltip2>
            </div>
          </RenderIf>
          <RenderIf condition={hasbein}>
            <div>
              <Tooltip2
                content="Bein Sport"
                position={Position.BOTTOM_RIGHT}
                className={Classes.TOOLTIP_INDICATOR}
                minimal
                usePortal={false}
              >
                <div className="bein-sport" />
              </Tooltip2>
            </div>
          </RenderIf>
        </div>
        <div>
          <Tooltip2
            content="Task"
            position={Position.BOTTOM_RIGHT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <div className="flex gap-5">
              <Text variant="body1">{title(task)}</Text>
            </div>
          </Tooltip2>
        </div>
      </div>
      <div className="card-body pc-card-body">
        <div className="flex column gap-10">
          <Tooltip2
            content="Location"
            position={Position.BOTTOM_LEFT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal={false}
          >
            <div className="flex gap-5" data-testid="video-pc-location">
              <Icon icon="map-marker" />
              <Text variant="body2">
                {`${title(location?.HQ)} - ${title(location?.name)}`}
              </Text>
            </div>
          </Tooltip2>
          <RenderIf condition={iptvDeviceFeature}>
            <Tooltip2
              content="IPTV Device"
              position={Position.BOTTOM_LEFT}
              className={Classes.TOOLTIP_INDICATOR}
              minimal
              usePortal={false}
            >
              <Fab text={iptvDeviceFeature?.name} removeButton />
            </Tooltip2>
          </RenderIf>
        </div>
        <div className="flex">
          <RenderIf condition={!isEmpty(satelliteFeature)}>
            <Tooltip2
              content="Satellites"
              position={Position.BOTTOM_LEFT}
              className={Classes.TOOLTIP_INDICATOR}
              minimal
              usePortal={false}
            >
              <div className="satellites-container">
                <div className="satellites-content">
                  {map(
                    (feature) => (
                      <Text variant="body2">{title(feature?.name)}</Text>
                    ),

                    satelliteFeature
                  )}
                </div>
              </div>
            </Tooltip2>
          </RenderIf>
        </div>
      </div>
      <div className="card-footer">
        <RenderIf condition={server}>
          <Label
            className="mg-0 p-0"
            inline
            labelHeader="Live Server: "
            labelText={server?.name}
            variant="Baisc"
          />
        </RenderIf>
        <div className="automarginLeft">
          <Acl
            allowedPermissions={['delete:pc']}
            allowedRoleNames={['super-admin']}
          >
            <Button
              text="Delete"
              icon="ban-circle"
              minimal
              intent="none"
              onClick={() =>
                modalDispatch({
                  type: 'DELETE_PC',
                  payload: {
                    pcData: {
                      number,
                      id,
                    },
                    screenInfo,
                  },
                })
              }
              className="mg-5 p-0"
            />
          </Acl>
          <Acl
            allowedPermissions={['update:pc']}
            allowedRoleNames={['super-admin']}
          >
            <Button
              intent="primary"
              text="Edit"
              icon="edit"
              minimal
              onClick={() =>
                modalDispatch({
                  type: 'CREATE_UPDATE_PC',
                  payload: {
                    pcData: {
                      number,
                      VPN: optionsFromArr(VPN),
                      server,
                      features,
                      task,
                      location,
                      hasbein,
                      hasAppleTv,
                      satelliteFeature: optionsFromArr(satelliteFeature),
                      iptvDeviceFeature: iptvDeviceFeature?.id,
                      id,
                    },
                    screenInfo,
                  },
                })
              }
              className="mg-5 p-0"
            />
          </Acl>
        </div>
      </div>
    </div>
  );
};

export default PcCard;
