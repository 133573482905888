import { gql } from '@apollo/client';

export default gql`
  query profile($impersonationType: ImpersonationVars) {
    profile(
      includeRole: true
      includeSquad: true
      impersonationType: $impersonationType
    ) {
      id
      firstName
      email
      lastName
      mobileNumber
      hrCode
      imageUrl
      jobType
      squadId
      squad {
        name
      }
      jobTitleRole {
        role {
          name
          permissions {
            permission {
              scope
            }
          }
          line {
            id
            name
            sport
          }
        }
      }
      power
      qualityScore
      deactivated
      createdAt
      updatedAt
      permissions {
        permission {
          scope
        }
      }
      lineFunction {
        name
        id
      }
    }
  }
`;
