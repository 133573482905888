import React from 'react';
import { useDrop } from 'react-dnd';
import ItemTypes from 'containers/MatchAssignment/itemTypes';
import classNames from 'classnames';
import { RenderIf } from 'config';
import { Spinner } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { map, pipe, uniq } from 'ramda';

const SquadBlock = ({
  title,
  power,
  action,
  onDrop,
  squadShiftId,
  loading,
  capacity,
  shiftId,
  isVideoUser,
}) => {
  const accept = ItemTypes.MATCH;

  const handleDrop = (droppedData) => {
    if (Array.isArray(droppedData)) {
      const matches = droppedData.map((match) => ({
        matchId: match?.matchId,
        squadShiftId,
      }));
      const getRefetchDatesList = pipe(
        map((match) => match.kickOffDateTime?.replace(/T.*/, '')),
        uniq
      );
      const refetchDatesList = getRefetchDatesList(droppedData);
      return onDrop(matches, shiftId, refetchDatesList);
    }
    return onDrop([{ matchId: droppedData, squadShiftId }], shiftId);
  };
  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop(
    () => ({
      accept,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: ({ id }) => handleDrop(id),
    }),
    [accept]
  );
  return (
    <div ref={drop}>
      <RenderIf condition={loading}>
        <Spinner size={25} />
      </RenderIf>
      <RenderIf condition={!loading}>
        <button
          onClick={action}
          className={classNames('block-link', {
            'video-alignment': isVideoUser,
          })}
          title={title}
          type="button"
        >
          <div className="block-link-title">{title}</div>
          <RenderIf condition={!isVideoUser}>
            <div className="block-link__description ">
              <span
                className={classNames({
                  'calender-colored-power-incomplete': power < capacity,
                  'calender-colored-power-complete': power >= capacity,
                })}
              >
                {power?.toFixed(1)}
              </span>
              <>/</>
              <span>{capacity?.toFixed(1)}</span>
            </div>
          </RenderIf>
        </button>
      </RenderIf>
    </div>
  );
};
export default SquadBlock;

SquadBlock.propTypes = {
  title: PropTypes.string.isRequired,
  power: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  squadShiftId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  capacity: PropTypes.number.isRequired,
  shiftId: PropTypes.string.isRequired,
};
