import { gql } from '@apollo/client';

export default gql`
  subscription MatchStatus {
    matchStatus {
      id
      status
    }
  }
`;
