import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import classNames from 'classnames';

const Radio = ({
  labelText,
  fieldId,
  value = '',
  name = '',
  isSelected,
  onRadioChange,
  error,
  minimal,
  children,
  className,
  disabled,
}) => (
  <label
    htmlFor={fieldId}
    className={classNames(
      'radio-btn-container',
      { selected: isSelected },
      { minimal },
      className
    )}
  >
    <input
      type="radio"
      value={value}
      name={name}
      id={fieldId}
      onChange={() => onRadioChange(value)}
      checked={isSelected}
      className={classNames({ error })}
      disabled={disabled}
      data-testid={`radio-${name}-${value}`}
    />
    {children || labelText}
  </label>
);

export default Radio;

Radio.propTypes = {
  labelText: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRadioChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

Radio.defaultProps = {
  onRadioChange: () => {},
  isSelected: false,
};
