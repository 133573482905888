import classNames from 'classnames';
import TextInput from 'components/TextInput';
import React from 'react';

const TimePicker = ({ onChange, value, error, name }) => {
  const onTimeChange = (v) => onChange(v);
  return (
    <div className={classNames('time-picker-container')}>
      <TextInput
        onChange={onTimeChange}
        value={value}
        maxLength="9"
        error={error}
        placeholder="MM:SS.sss"
        data-testid={`main-time-picker-${name}`}
        name={name}
      />
    </div>
  );
};

export default TimePicker;
