import { gql } from '@apollo/client';

export default gql`
  query JobTitlesByRole {
    jobTitlesByRole {
      role {
        jobTitleRole {
          jobTitle
          id
        }
      }
      sport
      id
      name
      groups {
        id
        name
      }
    }
  }
`;
