import React, { useState } from 'react';
import { pluck } from 'ramda';
import { Button, TextInput, Dropdown, Icon, Fab } from 'components';
import { USERS_QUERY, UPDATE_SQUAD_MEMBERS_MUTATION, SQUADS_QUERY } from 'gql';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { Dialog } from '@blueprintjs/core';

import {
  optionsFromArr,
  toggleObjListItem,
  arrayDiff,
  title,
} from 'utils/helpers';
import { useIntl } from 'react-intl';

const AddMemberForm = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const [searchWord, setSearchword] = useState();
  const intl = useIntl();
  const { squad, squadQueryVars } = modalState?.payload;
  const { loading: usersLoading, data: usersData } = useQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { searchWord, unassigned: true },
  });
  const [addMemberMutation, { loading: addMemberLoading }] = useMutation(
    UPDATE_SQUAD_MEMBERS_MUTATION,
    {
      refetchQueries: [
        {
          query: SQUADS_QUERY,
          variables: squadQueryVars,
        },
      ],
      awaitRefetchQueries: true,
      onError: (err) => showToast({ message: err.message, icon: 'error' }),
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: intl.formatMessage({
            id: 'containers.squads.addSquadMember.success',
          }),
        });
      },
    }
  );
  const initialValues = {
    squadMembers: optionsFromArr(squad?.members),
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newArr = pluck('value', values.squadMembers);
      const initialArr = pluck('value', optionsFromArr(squad?.members));
      const added = arrayDiff(initialArr, newArr);
      const removed = arrayDiff(newArr, initialArr);

      if (added && added.length > 0) {
        addMemberMutation({
          variables: {
            input: {
              id: squad?.id,
              remove: false,
              squadMembersIds: added,
            },
          },
        });
      }

      if (removed && removed.length > 0) {
        addMemberMutation({
          variables: {
            input: {
              id: squad?.id,
              remove: true,
              squadMembersIds: removed,
            },
          },
        });
      }
    },
  });

  const createTitle = (
    <div className="create-edit-header">
      <Icon icon="plus" color="#999999" size={14} data-testid="plus-icon" />
      <p data-testid="main-dialogTitle-addNewMember">
        {intl.formatMessage({ id: 'containers.squads.addNewMember' })}
      </p>
    </div>
  );
  return (
    <Dialog
      isOpen={modalState?.open}
      title={createTitle}
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="bp3-dialog-body">
        <TextInput
          required
          type="text"
          labelText={intl.formatMessage({
            id: 'containers.squads.lineFunctionName',
          })}
          placeholder={intl.formatMessage({
            id: 'containers.squads.lineFunctionName',
          })}
          value={title(squad?.lineFunction?.name)}
          disabled
          data-testid="squads-addNewMember-squadCategoryTextBox"
        />
        <TextInput
          required
          type="text"
          labelText={intl.formatMessage({ id: 'containers.squads.squadName' })}
          placeholder={intl.formatMessage({
            id: 'containers.squads.squadName',
          })}
          value={squad?.name}
          disabled
          className="mgt-15 mgb-15"
          data-testid="squads-addNewMember-squadNameTextBox"
        />
        <Dropdown
          variant="users"
          searchPath={['hrCode', 'firstName', 'lastName', 'middleName']}
          filterable
          multiSelect
          onSearch={setSearchword}
          items={optionsFromArr(usersData?.users?.users)}
          values={formik.values.squadMembers}
          loading={usersLoading}
          buttonText={intl.formatMessage({
            id: 'containers.squads.dropDown.members',
          })}
          onChange={(data) => formik.setFieldValue('squadMembers', [...data])}
          error={formik.touched.squadMembers && formik.errors.squadMembers}
          name="members"
          data-testid="squads-addNewMember-members"
        />
        <div
          className="add-members-fab-container"
          data-testid="squads-addNewMember-fabContainer"
        >
          {formik.values.squadMembers.map((user) => (
            <Fab
              text={user.label}
              icon="cross"
              iconSize={12}
              onClick={() =>
                formik.setFieldValue(
                  'squadMembers',
                  toggleObjListItem(formik.values.squadMembers, user)
                )
              }
              key={user.id}
              data-testid="squads-addNewMember-info"
            />
          ))}
        </div>
      </div>
      <div className="bp3-dialog-footer" data-testid="addNewMember-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            outlined
            text={intl.formatMessage({ id: 'containers.all.btn.cancel' })}
            onClick={() =>
              modalDispatch({
                type: 'ClOSE_ALL',
              })
            }
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={intl.formatMessage({ id: 'containers.all.btn.save' })}
            onClick={formik.submitForm}
            loading={addMemberLoading}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AddMemberForm;
