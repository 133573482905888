import React, { useMemo } from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Button, Icon } from 'components';
import { Acl, RenderIf } from 'config';
import { hasAccess } from 'config/acl';
import { useModal } from 'providers/modalProvider';
import { useProfile } from 'providers/profileProvider';
import { useIntl } from 'react-intl';
import { isEmpty, equals } from 'ramda';
import classNames from 'classnames';

const ManageMatchPopOver = ({
  modalPayload,
  shiftMatch,
  squad,
  isVideo = false,
}) => {
  const { modalDispatch } = useModal();
  const profile = useProfile();
  const { formatMessage } = useIntl();
  const openModal = (type) => {
    modalDispatch({
      type,
      payload: modalPayload,
    });
  };
  const matchStatus = isVideo ? shiftMatch.videoIssueStatus : shiftMatch.status;
  const isSquadlessOffline = !isVideo && isEmpty(squad);
  const quickActions = useMemo(() => {
    const btnReassign = (
      <Acl
        allowedPermissions={['update:match', `update:squad:${squad?.id}`]}
        allowedRoleNames={['super-admin']}
      >
        <MenuItem
          icon={<Icon customIcon="reassign" />}
          onClick={() => openModal('REASSIGN_MATCH')}
          text={formatMessage({ id: 'containers.dashboards.reassign' })}
          data-testid="dashboard-reassign-menuButton"
        />
      </Acl>
    );

    const btnUnassign = (
      <Acl
        allowedPermissions={['delete:match-assignment']}
        allowedRoleNames={['super-admin']}
      >
        <MenuItem
          icon={<Icon customIcon="unassignMatch" />}
          onClick={() => openModal('UNASSIGN_MATCH')}
          text={formatMessage({ id: 'containers.dashboards.unassign.title' })}
          data-testid="dashboard-unassign-menuButton"
        />
      </Acl>
    );

    const btnReplace = (
      <RenderIf condition={!isVideo}>
        <Acl
          allowedPermissions={['update:match', `update:squad:${squad?.id}`]}
          allowedRoleNames={['super-admin']}
        >
          <MenuItem
            icon={<Icon customIcon="replaceMatch" />}
            onClick={() => openModal('SWAP_MATCH')}
            text={formatMessage({ id: 'containers.dashboards.swap.title' })}
            data-testid="dashboard-swap-menuButton"
          />
        </Acl>
      </RenderIf>
    );

    const btnBlock = (
      <RenderIf condition={!equals(matchStatus, 'BLOCKED')}>
        <Acl
          allowedPermissions={['block:match']}
          allowedRoleNames={['super-admin']}
        >
          <MenuItem
            icon={<Icon customIcon="blocked" />}
            onClick={() => openModal('BLOCK_MATCH')}
            text={formatMessage({ id: 'containers.dashboards.blocking.title' })}
            data-testid="dashboard-block-menuButton"
          />
        </Acl>
      </RenderIf>
    );

    const btnUnblock = (
      <RenderIf condition={equals(matchStatus, 'BLOCKED')}>
        <Acl
          allowedPermissions={['unblock:match']}
          allowedRoleNames={['super-admin']}
        >
          <MenuItem
            icon={<Icon customIcon="edit" />}
            onClick={() => openModal('UN_BLOCK_MATCH')}
            text={formatMessage({ id: 'containers.dashboards.unblock.title' })}
            data-testid="dashboard-unblock-menuButton"
          />
        </Acl>
      </RenderIf>
    );

    const btnReport = (
      <RenderIf condition={!equals(matchStatus, 'BLOCKED')}>
        <Acl allowedPermissions={['report:match']}>
          <MenuItem
            icon={<Icon customIcon="reported" />}
            onClick={() => openModal('REPORT_ISSUE')}
            text={formatMessage({
              id: 'containers.dashboards.report.issue.title',
            })}
            data-testid="dashboard-reportIssue-menuButton"
          />
        </Acl>
      </RenderIf>
    );

    const btnResolve = (
      <RenderIf condition={equals(matchStatus, 'REPORTED')}>
        <Acl
          allowedPermissions={['resolve:match', `update:squad:${squad?.id}`]}
          allowedRoleNames={['super-admin']}
        >
          <MenuItem
            icon={<Icon customIcon="success" />}
            onClick={() => openModal('RESOLVE_ISSUE')}
            text={formatMessage({ id: 'containers.dashboards.resolve.title' })}
            data-testid="dashboard-resolveIssue-menuButton"
          />
        </Acl>
      </RenderIf>
    );

    const btnAssignTasks = (
      <RenderIf condition={!isVideo}>
        <Acl
          allowedPermissions={[
            'update:match',
            'update:squad',
            `update:squad:${squad?.id}`,
          ]}
          allowedRoleNames={['super-admin']}
        >
          <MenuItem
            icon={<Icon customIcon="plus" />}
            onClick={() => openModal('ASSIGN_TASK')}
            text={formatMessage({
              id: 'containers.dashboards.assignTasks.title',
            })}
            data-testid="dashboard-assignTasks-menuButton"
          />
        </Acl>
      </RenderIf>
    );
    return (
      <Menu>
        {[
          btnAssignTasks,
          btnReassign,
          btnUnassign,
          btnReplace,
          btnBlock,
          btnUnblock,
          btnReport,
          btnResolve,
        ]}
      </Menu>
    );
  }, [shiftMatch, squad]);
  const isManageDisabled =
    (equals(matchStatus, 'BLOCKED') &&
      !hasAccess({
        profile,
        allowedPermissions: ['update:match', 'unblock:match'],
        allowedRoleNames: ['super-admin'],
      })) ||
    isSquadlessOffline;
  return (
    <Acl
      allowedPermissions={[
        'update:match',
        `update:match:${shiftMatch.id}`,
        'update:squad',
        `update:squad:${squad?.id}`,
        'report:match',
        'resolve:match',
        'block:match',
        'unblock:match',
      ]}
      allowedRoleNames={['super-admin', 'admin']}
    >
      <Popover2
        content={quickActions}
        position="top-left"
        disabled={isManageDisabled}
        minimal
      >
        <Button
          minimal
          text="Manage Match"
          icon={<span className="bg-cog-qs" />}
          disabled={isManageDisabled}
          className={classNames([
            'btn-tertiary',
            'btn-p-0',
            'btn-no-active-bg',
          ])}
          data-testid="dashboard-manage-match-button"
        />
      </Popover2>
    </Acl>
  );
};

export default ManageMatchPopOver;
