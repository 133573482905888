import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { SQUAD_NAMES_QUERY } from 'gql';
import { isEmpty } from 'ramda';
import { optionsFromArr } from 'utils/helpers';
import { Dropdown } from 'components';
import { useProfile } from 'providers/profileProvider';

const SquadsFilter = ({ squadIds, setPropState }) => {
  const [searchWord, setSetSearchWord] = useState();
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;
  const { lineId, isSuperAdmin } = useProfile();
  const {
    loading: squadsLoadingFilter,
    data: squadsDataFilter,
    fetchMore,
  } = useQuery(SQUAD_NAMES_QUERY, {
    variables: {
      searchWord,
      queryParams: { page: 1, pageSize },
      lineId: isSuperAdmin ? undefined : lineId,
    },
  });
  const loadMoreData = () => {
    setPage((prev) => prev + 1);
    fetchMore({
      variables: { queryParams: { page, pageSize } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult?.squadNames?.squads)) {
          setHasMore(false);
          return prev;
        }
        const squads = [
          ...prev.squadNames?.squads,
          ...fetchMoreResult.squadNames?.squads,
        ];
        return {
          ...prev,
          squadNames: {
            ...prev.squadNames,
            squads,
          },
        };
      },
    });
  };

  return (
    <Dropdown
      multiSelect
      variant="multiselect_obj_list"
      items={optionsFromArr(squadsDataFilter?.squadNames?.squads)}
      values={squadIds || []}
      onChange={(ids) => setPropState({ squadIds: ids })}
      filterable
      loading={squadsLoadingFilter}
      buttonText="Squads"
      className="match-assignment-filters"
      searchPath={['label']}
      onSearch={setSetSearchWord}
      scrollLoading={squadsLoadingFilter}
      fetchMore={loadMoreData}
      hasMore={hasMore}
      name="squads"
      data-testid="main-filters-squads"
    />
  );
};

export default SquadsFilter;
