import { useQuery } from '@apollo/client';
import { Dialog, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { RenderIf } from 'config';
import { useToast } from 'providers/toastProvider';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Label, Pagination, Text } from 'components';
import { useModal } from 'providers/modalProvider';
import { SHIFT_MATCHES_QUERY } from 'gql';
import { useProfile } from 'providers/profileProvider';
import { isVideo } from 'utils/helpers';
import MatchRow from './MatchRow';

const SquadMatchesDetails = () => {
  const { modalDispatch, modalState } = useModal();
  const intl = useIntl();
  const { showToast } = useToast();
  const {
    power,
    capacity,
    shiftType,
    squadsActiveDate,
    squadName,
    squadId,
    squadShiftPowerAndCapacityRefetch,
    matchesActiveDay,
    shiftId,
    squadShiftId,
  } = modalState?.payload;
  const {
    profile: {
      jobTitleRole: {
        role: { line: userLine },
      },
    },
  } = useProfile();

  const { id: lineId, name: userLineName } = userLine ?? {};

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [totalCount, setTotalCount] = useState(0);
  const [matchesLength, setMatchesLength] = useState(0);
  const {
    data: shiftMatches,
    loading,
    refetch: refetchShiftMatches,
  } = useQuery(SHIFT_MATCHES_QUERY, {
    variables: {
      squadShiftId,
      queryParams: {
        pageSize,
        page,
      },
      lineId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMatchesLength(data?.shiftMatches.length);
      setTotalCount(
        data?.shiftMatches.length < 5
          ? totalCount
          : page * pageSize + data?.shiftMatches.length - 1
      );
    },
    onError: () => {
      showToast({
        message: 'Oops, looks like something went wrong. Please try again.',
        icon: 'error',
      });
    },
  });
  const day = new Date(squadsActiveDate).toLocaleDateString('en-us', {
    weekday: 'long',
  });
  return (
    <div>
      <Dialog
        isOpen={modalState?.open}
        title={
          <div
            className={classNames('flex', 'gap-5')}
            style={{ alignItems: 'end' }}
          >
            <Icon customIcon="squads" size={20} />
            <div>{squadName}</div>
          </div>
        }
        onClose={() =>
          modalDispatch({
            type: 'ClOSE_ALL',
          })
        }
        style={{ maxWidth: 'unset', width: '1050px' }}
        canOutsideClickClose={false}
      >
        <div className="shift-matches-container flex column  mgl-32 mgr-39 mgt-21 mgb-25">
          <div className="flex items-center justify-center gap-20 mgb-15">
            <Label variant="Label" labelText={`${day} ${squadsActiveDate}`} />
            <Label variant="ShiftLabel" labelText={shiftType} />
            <RenderIf condition={!isVideo(userLineName)}>
              <Label
                variant="Baisc"
                labelHeader={
                  <div className="flex items-center justify-center gap-4">
                    <span className="bg-bolt" />
                    <span>Power:</span>
                  </div>
                }
                labelText={
                  <span>
                    <span className="unassign-matches-colored-power">
                      {power?.toFixed(2)}
                    </span>
                    <>/</>
                    <span>{capacity}</span>
                  </span>
                }
                inline
              />
            </RenderIf>
          </div>
          <div className="unassign-matches-pagination-container">
            <span>
              {intl.formatMessage(
                { id: 'containers.all.pagination.showingTotalMsg' },
                { totalCount: matchesLength, screenTitle: 'Matches' }
              )}
            </span>
            <Pagination
              totalCount={totalCount}
              currentPage={page}
              pageSize={pageSize}
              onPageChange={(page) => setPage(page)}
              screenTitle="Matches"
              pageCountOnly
            />
          </div>

          {loading ? (
            <Spinner />
          ) : (
            shiftMatches?.shiftMatches.map((shiftMatch, index) => (
              <MatchRow
                shiftMatch={shiftMatch}
                index={index}
                key={shiftMatch.id}
                squadId={squadId}
                squadShiftPowerAndCapacityRefetch={
                  squadShiftPowerAndCapacityRefetch
                }
                matchesActiveDay={matchesActiveDay}
                refetchShiftMatches={refetchShiftMatches}
                shiftId={shiftId}
                squadsActiveDate={squadsActiveDate}
              />
            ))
          )}
          <RenderIf
            condition={
              !loading && isEmpty(shiftMatches?.shiftMatches) && page !== 1
            }
          >
            <div className="pagination-limitation-container">
              <Text variant="body1">
                <FormattedMessage id="containers.all.pagination.end.warning" />
              </Text>
              <Button
                minimal
                intent="Primary"
                className="p-0"
                onClick={() => {
                  setPage(1);
                  setTotalCount(0);
                }}
              >
                Start again?
              </Button>
            </div>
          </RenderIf>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button
              outlined
              intent="Primary"
              text={intl.formatMessage({ id: 'containers.all.close' })}
              onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SquadMatchesDetails;
