import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Spinner } from '@blueprintjs/core';
import { path, equals } from 'ramda';
import { DateObject } from 'react-multi-date-picker';
import { useProfile } from 'providers/profileProvider';
import {
  Button,
  DateWithShiftFilterBar,
  Icon,
  ShiftMatchCard,
  SingleDatePicker,
  Text,
} from 'components';
import {
  REASSIGN_MATCH_MUTATION,
  SQUAD_SHIFTS_POWER_AND_CAPACITY_QUERY,
  MATCHES_DETAILS_BY_LINE_QUERY,
} from 'gql';

import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import classNames from 'classnames';
import { RenderIf } from 'config';
import { isNilOrEmpty, isVideo, title } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import SquadInfoCard from '../../helpers/SquadInfoCard';

const ReassignMatch = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const { profile, roleName } = useProfile();
  const { squadId: currentUserSquadId } = profile;
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId, name: userLineName } = userLine ?? {};

  const isVideoUser = isVideo(userLineName);
  const {
    shiftMatch,
    assignedSquadName,
    squadId,
    activeDate: MainScreenActiveDate,
    activeShiftType,
    refetchShiftMatches,
  } = path(['payload'], modalState);

  const assignedSquadShiftId = shiftMatch?.squadShifts[0].squadShift.id;
  const isSquadManager =
    equals(roleName, 'team-leader') && equals(currentUserSquadId, squadId);

  const [activeSquadShiftId, setActiveSquadShiftId] = useState();

  const [reassignActiveDate, setReassignActiveDate] = useState(
    new DateObject(MainScreenActiveDate).format('YYYY-MM-DD')
  );
  const [reassignShift, setReassignShift] = useState();
  const closeModal = () => modalDispatch({ type: 'ClOSE_ALL' });

  const onChangeDate = (date) => {
    setReassignActiveDate(new DateObject(date).format('YYYY-MM-DD'));
    setActiveSquadShiftId(undefined);
  };

  const onChangeShift = (shift) => {
    setReassignShift(shift);
    setActiveSquadShiftId(undefined);
  };

  const {
    loading: squadsPerLineLoading,
    data: { squadsPerLine: squadsPerLineData } = {},
  } = useQuery(SQUAD_SHIFTS_POWER_AND_CAPACITY_QUERY, {
    variables: {
      date: reassignActiveDate,
      shiftId: reassignShift?.id,
      squadId: isSquadManager ? squadId : undefined,
      lineId,
    },
    skip: !reassignActiveDate || !reassignShift,
  });
  const [reassignMatchMutation, { loading: reassignLoading }] = useMutation(
    REASSIGN_MATCH_MUTATION,
    {
      onError: () => {
        const message = formatMessage({
          id: 'containers.all.errorSaveChanges',
        });
        closeModal();
        showToast({ message, icon: 'error' });
      },
      onCompleted: () => {
        refetchShiftMatches();
        closeModal();
        const message = formatMessage({
          id: 'containers.dashboards.reassign.success',
        });
        showToast({ message });
      },
      awaitRefetchQueries: true,
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'shiftMatches' });
      },
      refetchQueries: [
        {
          query: MATCHES_DETAILS_BY_LINE_QUERY,
          variables: {
            matchesIds: [shiftMatch?.id],
            lineId,
          },
        },
      ],
    }
  );
  const reassignMatch = () => {
    return reassignMatchMutation({
      variables: {
        input: {
          matchId: shiftMatch?.id,
          squadShiftId: activeSquadShiftId,
          squadId,
          oldSquadShiftId: assignedSquadShiftId,
          lineId,
        },
      },
    });
  };
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon
            customIcon="reassign"
            size={20}
            data-testid="modalHeader-icon"
          />
          <span data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.reassign.title" />
          </span>
        </div>
      }
      onClose={closeModal}
      canOutsideClickClose={false}
      style={{ width: '900px', maxWidth: 'unset' }}
    >
      <div
        className={classNames([
          ' pl-32',
          'mgt-8',
          'flex',
          'items-center',
          'gap-12',
        ])}
      >
        <Text variant="h2" data-testid="dashboard-reassign-squadAssigned">
          <FormattedMessage
            id="containers.dashboards.squadAssigned"
            values={{ assignedSquadName }}
          />
        </Text>
        <div className="flex gap-5">
          <span className={`bg-${activeShiftType?.toLowerCase()}`} />
          <Text variant="body1" className="pt-2">
            {title(activeShiftType || '')}
          </Text>
        </div>
      </div>
      <div className="shift-match">
        <ShiftMatchCard
          shiftMatch={shiftMatch}
          testid="dashboard-reassign-shiftMatchCard"
        />
      </div>
      <div className="flex mgl-22 mgt-19">
        <DateWithShiftFilterBar
          activeDate={reassignActiveDate}
          onChangeDate={onChangeDate}
          shift={reassignShift}
          setShift={onChangeShift}
        />
        <SingleDatePicker
          onChange={onChangeDate}
          activeDate={reassignActiveDate}
        />
      </div>
      <div
        className={classNames([
          'flex',
          'flex-wrap',
          'pl-32',
          'gap-10',
          'pt-18',
        ])}
      >
        <RenderIf
          condition={!squadsPerLineLoading && isNilOrEmpty(squadsPerLineData)}
        >
          <FormattedMessage id="containers.dashboard.reassign.no.squads" />
        </RenderIf>
        <RenderIf condition={squadsPerLineLoading}>
          <Spinner size={35} />
        </RenderIf>
        {squadsPerLineData?.map((squadShiftPowers) => {
          return (
            <SquadInfoCard
              squadShiftPowers={squadShiftPowers}
              activeCard={activeSquadShiftId}
              setActiveCard={setActiveSquadShiftId}
              key={`squad-${uuidv4()}`}
              isVideoUser={isVideoUser}
              className={classNames({
                'width-20': isVideoUser,
              })}
            />
          );
        })}
      </div>
      <div
        className="bp3-dialog-footer-actions mgt-30"
        data-testid="reassign-footer"
      >
        <RenderIf condition={assignedSquadShiftId === activeSquadShiftId}>
          <div className="automarginRight flex gap-10">
            <span className="bg-warning2" />
            <Text
              variant="body3"
              className="color-qs"
              data-testid="dashboard-reassign-warning"
            >
              <FormattedMessage id="containers.dashboards.reassign.sameShiftError" />
            </Text>
          </div>
        </RenderIf>
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={closeModal}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.dashboards.reassign' })}
          onClick={reassignMatch}
          loading={reassignLoading}
          disabled={
            !activeSquadShiftId || assignedSquadShiftId === activeSquadShiftId
          }
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default ReassignMatch;
