import React from 'react';
import classNames from 'classnames';

const BasicLabel = ({ labelHeader, labelText, inline, className, ...rest }) => (
  <div
    className={classNames(className, {
      'bp3-inline': inline,
      'horizontal-label': !inline,
    })}
    {...rest}
  >
    <span>{labelHeader}</span>
    <span>{labelText}</span>
  </div>
);

export default BasicLabel;
