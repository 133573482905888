import { gql } from '@apollo/client';

export default gql`
  fragment ShiftFragment on Shift {
    id
    shiftType
    startAt
    endAt
    squadShifts {
      id
    }
  }
`;
