import { useMutation } from '@apollo/client';
// import { Dialog } from '@blueprintjs/core'
import classNames from 'classnames';
import { Button, Dialog, Icon, Label, Text } from 'components';
import { RESET_PASSWORD_MUTATION } from 'gql';
import { useModal } from 'providers/modalProvider';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ResetPassword = () => {
  const { modalDispatch, modalState } = useModal();
  const [screenContent, setScreenContent] = useState('MAIN');
  const modalBody = {
    MAIN: (
      <Text variant="body1" data-testid="resetPassword-main-content">
        <FormattedMessage id="containers.users.modals.resetPassword.main" />
      </Text>
    ),
    SUCCESS: (
      <div
        className={classNames(['flex', 'gap-8'])}
        data-testid="resetPassword-success-content"
      >
        <Icon customIcon="vector" />
        <Text variant="body1">
          <FormattedMessage id="containers.users.modals.resetPassword.success" />
        </Text>
      </div>
    ),
    ERROR: (
      <div
        className={classNames(['flex', 'gap-8'])}
        data-testid="resetPassword-error-content"
      >
        <Icon customIcon="warning" />
        <Text variant="body1">
          <FormattedMessage id="containers.users.error" />
        </Text>
      </div>
    ),
  };
  const [resetPasswordMutation, { loading }] = useMutation(
    RESET_PASSWORD_MUTATION,
    {
      onError: () => setScreenContent('ERROR'),
      onCompleted: () => setScreenContent('SUCCESS'),
    }
  );
  const resetPassword = () => {
    const { user = {} } = modalState;
    const { email, squad } = user;
    return resetPasswordMutation({
      variables: {
        input: {
          email,
          squadId: squad?.id || modalState?.squad?.id || undefined,
        },
      },
    });
  };
  const modalFooter = {
    MAIN: (
      <div>
        <Button
          outlined
          text={<FormattedMessage id="containers.users.form.cancel" />}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={<FormattedMessage id="containers.all.btn.reset" />}
          onClick={resetPassword}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    ),
    SUCCESS: (
      <Button
        text={<FormattedMessage id="containers.all.close" />}
        intent="Primary"
        onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
        data-testid="resetPassword-success-footer"
      />
    ),
    ERROR: (
      <Button
        text={<FormattedMessage id="containers.all.btn.tryAgain" />}
        intent="Primary"
        onClick={resetPassword}
        loading={loading}
        data-testid="resetPassword-error-footer"
      />
    ),
  };

  return (
    <Dialog
      isOpen={modalState?.open}
      dialogTitle={
        <FormattedMessage id="containers.users.menu.resetPassword" />
      }
      icon={
        <span className="bg-resetPassword" data-testid="bg-resetPassword" />
      }
      width="459px"
    >
      <div className="reset-container" data-testid="resetPassword-body">
        {modalBody[screenContent]}
        <Label
          variant="AvatarLabel"
          labelText={`${modalState?.user?.firstName} ${
            modalState?.user?.middleName || ''
          } ${modalState?.user?.lastName}`}
          avatarText={`${modalState?.user?.firstName} ${modalState?.user?.lastName}`}
          data-testid="resetPassword-avatarLabel"
        />
      </div>
      <div className="bp3-dialog-footer" data-testid="resetPassword-footer">
        <div className="bp3-dialog-footer-actions">
          {modalFooter[screenContent]}
        </div>
      </div>
    </Dialog>
  );
};

export default ResetPassword;
