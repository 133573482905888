import React, { useState } from 'react';
import classNames from 'classnames';
import { pluck, isEmpty, pipe, prop, filter } from 'ramda';
import { Dialog } from '@blueprintjs/core';
import { Button, Icon, ShiftTypeSelect } from 'components';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useFormik } from 'formik';
import { arrayDiff } from 'utils/helpers';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  CREATE_SQUADS_SHIFTS_MUTATION,
  UPDATE_SQUAD_SHIFTS_MUTATION,
  CREATE_SQUAD_SHIFTS_MEMBERS_MUTATION,
  SQUAD_SHIFTS_QUERY,
} from 'gql';
import { useIntl } from 'react-intl';

const AssignShiftModal = () => {
  const { modalDispatch, modalState } = useModal();
  const intl = useIntl();
  const { squadId, dates, squadMembers } = modalState?.payload;
  const { showToast } = useToast();
  const closeModal = () => modalDispatch({ type: 'ClOSE_ALL' });
  const [loading, setLoading] = useState();

  const [createSquadShiftMembersMutation] = useMutation(
    CREATE_SQUAD_SHIFTS_MEMBERS_MUTATION,
    {
      onError: ({ message }) =>
        showToast({
          message,
          icon: 'error',
        }),
      onCompleted: () => {
        // eslint-disable-next-line no-console
        console.log({
          message: intl.formatMessage({
            id: 'containers.shiftSchedule.assignShift.membersAdded',
          }),
        });
      },
    }
  );
  const [getSquadShifts] = useLazyQuery(SQUAD_SHIFTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { dates, squadId },
  });

  const [createSquadShiftsMutation] = useMutation(
    CREATE_SQUADS_SHIFTS_MUTATION,
    {
      onError: ({ message }) =>
        showToast({
          message,
          icon: 'error',
        }),
      onCompleted: () =>
        showToast({
          message: intl.formatMessage({
            id: 'containers.shiftSchedule.assignShift.shiftsCreated',
          }),
        }),
    }
  );

  const [updateSquadShiftsMutation] = useMutation(
    UPDATE_SQUAD_SHIFTS_MUTATION,
    {
      onError: ({ message }) =>
        showToast({
          message,
          icon: 'error',
        }),
      onCompleted: () =>
        showToast({
          message: intl.formatMessage({
            id: 'containers.shiftSchedule.assignShift.shiftsUpdated',
          }),
        }),
    }
  );

  const formik = useFormik({
    initialValues: { squadId },
    enableReinitialize: true,
    onSubmit: async ({ shift: { id: shiftId }, squadId }) => {
      setLoading(true);
      const {
        data: { squadShifts: squadShiftsData },
      } = await getSquadShifts();
      const squadShifts = prop('squadShifts', squadShiftsData);
      const datesWithSquadShifts = pluck('date', squadShifts);
      const datesWithoutSquadShifts = arrayDiff(datesWithSquadShifts, dates);

      if (!isEmpty(datesWithoutSquadShifts)) {
        await createSquadShiftsMutation({
          variables: {
            input: {
              shiftId,
              squadIds: [squadId],
              dates: datesWithoutSquadShifts,
            },
          },
        });
        const {
          data: { squadShifts: updatedSquadShiftsData },
        } = await getSquadShifts({
          variables: { squadId, dates: datesWithoutSquadShifts },
        });
        const squadShiftIds = pipe(
          prop('squadShifts'),
          pluck('id')
        )(updatedSquadShiftsData);
        await createSquadShiftMembersMutation({
          variables: { input: { squadId, squadShiftIds, squadMembers } },
        });
      }

      if (!isEmpty(datesWithSquadShifts)) {
        const shiftsWithNoMembers = filter(
          (s) => isEmpty(prop('squadMembers', s)),
          squadShifts
        );
        if (!isEmpty(shiftsWithNoMembers)) {
          await createSquadShiftMembersMutation({
            variables: {
              input: {
                squadId,
                squadShiftIds: pluck('id', shiftsWithNoMembers),
                squadMembers,
              },
            },
          });
        }
        await updateSquadShiftsMutation({
          variables: {
            input: { shiftId, squadId, dates: datesWithSquadShifts },
          },
        });
      }
      await getSquadShifts();
      setLoading(false);
      closeModal();
    },
  });

  const handleShiftTypeChange = (data) => formik.setFieldValue('shift', data);

  return (
    <Dialog
      isOpen={modalState?.open}
      canOutsideClickClose={false}
      isCloseButtonShown={!loading}
      onClose={closeModal}
      title={
        <div className="modal-title">
          <Icon icon="plus" size={20} />
          <div>
            {intl.formatMessage({
              id: 'containers.shiftSchedule.header.btn.assignShift',
            })}
          </div>
        </div>
      }
      style={{ width: '292px' }}
    >
      <div className="assign-shift-container">
        <span>
          {intl.formatMessage({
            id: 'containers.shiftSchedule.header.btn.assignShift',
          })}
          :
        </span>
        <ShiftTypeSelect
          onChange={handleShiftTypeChange}
          className={classNames(
            formik.values.shift?.shiftType?.toLowerCase(),
            'shift-type-select-container',
            {
              'selected-shifts-dropdown-highlight': formik.values.shift?.id,
            }
          )}
          value={formik.values.shift?.id}
        />
      </div>
      <div className="bp3-dialog-footer-actions">
        <Button
          outlined
          intent="Primary"
          text={intl.formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={closeModal}
          disabled={loading}
        />
        <Button
          intent="Primary"
          text={intl.formatMessage({
            id: 'containers.shiftSchedule.header.btn.assignShift',
          })}
          onClick={formik.submitForm}
          loading={loading}
          disabled={!formik.values.shift}
        />
      </div>
    </Dialog>
  );
};

export default AssignShiftModal;
