import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Icon } from 'components/';
import { Popover2 } from '@blueprintjs/popover2';
import { Menu, MenuItem, Spinner } from '@blueprintjs/core';
import { title } from 'utils/helpers';
import { UPDATE_SQUAD_SHIFT_MEMBER_MUTATION, VACATION_TYPES_QUERY } from 'gql';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from 'providers/toastProvider';
import { Acl } from 'config';
import { PRESENT_TYPES } from 'utils/enums';
import { useIntl } from 'react-intl';
import { useProfile } from 'providers/profileProvider';

const ShiftAttendanceCard = ({
  leaveType,
  shiftType,
  userId,
  squadShiftId,
  squadId,
  deactivated,
  memberHasShift,
}) => {
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { id: lineId } = line || {};
  const intl = useIntl();
  const { showToast } = useToast();
  const disabled =
    !leaveType ||
    (leaveType && !PRESENT_TYPES.includes(leaveType)) ||
    deactivated;

  const [updateSquadShiftMemberMutation, { loading: updateMembersLoading }] =
    useMutation(UPDATE_SQUAD_SHIFT_MEMBER_MUTATION, {
      refetchQueries: [],
      onError: () =>
        showToast({
          message: intl.formatMessage({
            id: 'containers.shiftSchedule.updateShiftMember.error',
          }),
        }),
    });

  const assignVactation = useCallback(
    (vacationType) => {
      updateSquadShiftMemberMutation({
        variables: {
          input: {
            userId,
            squadShiftIds: [squadShiftId],
            squadId,
            vacationType,
          },
        },
      });
    },
    [userId, squadShiftId, squadId]
  );

  const mapSlugToTitle = (slug) => {
    const presentTypesTitles = {
      PRESENT_TA: 'Present TA',
      PRESENT_ES: 'Present ES',
      PRESENT_DS: 'Present DS',
    };
    return presentTypesTitles[slug] || title(slug);
  };
  const { data: { vacationTypes: vacationTypeData = [] } = {} } = useQuery(
    VACATION_TYPES_QUERY,
    {
      skip: !lineId,
      variables: {
        lineId,
      },
    }
  );

  const actionList = (
    <Menu className="vacation-types-menu">
      {vacationTypeData.map((vt) => (
        <MenuItem
          disabled={leaveType === vt}
          key={`action-${vt}`}
          onClick={() => assignVactation(vt)}
          text={mapSlugToTitle(vt)}
        />
      ))}
    </Menu>
  );

  const renderLeaveTypeButton = (type) => (
    <button data-testid="leave-type-btn" type="button">
      <span className="ellipsis">{mapSlugToTitle(type)}</span>
      {updateMembersLoading ? (
        <Spinner size={14} />
      ) : (
        <Icon icon="chevron-down" />
      )}
    </button>
  );

  if (squadShiftId) {
    return (
      <div
        className={classNames('shift-attendance-card', shiftType, { disabled })}
      >
        <div className="accent" />
        {shiftType && memberHasShift && (
          <div className="flex" style={{ alignItems: 'center' }}>
            <span className={`bg-${shiftType}${disabled ? '-disabled' : ''}`} />
            <span>{title(shiftType)}</span>
          </div>
        )}
        {shiftType && leaveType && (
          <Acl
            allowedRoleNames={['super-admin']}
            allowedPermissions={['update:squad', `update:squad:${squadId}`]}
            renderNoAccess={(type) => renderLeaveTypeButton(type)}
          >
            {!deactivated && (
              <Popover2
                content={actionList}
                position="bottom-left"
                disabled={deactivated}
              >
                {renderLeaveTypeButton(leaveType)}
              </Popover2>
            )}
          </Acl>
        )}
      </div>
    );
  }
  return false;
};

export default ShiftAttendanceCard;
