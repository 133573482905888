import React, { useRef } from 'react';
import DatePicker from 'react-multi-date-picker';
import PropTypes from 'prop-types';
import Button from '../Button';

const SingleDatePicker = ({ onChange, activeDate }) => {
  const datePickerRef = useRef();
  const datePickerRender = (val, openPicker) => (
    <Button
      icon={<span className="bg-calender" />}
      onClick={openPicker}
      small
      className="calender-icon"
      data-testid="date-picker"
    />
  );
  return (
    <div className="flex items-center">
      <DatePicker
        value={activeDate}
        onChange={(val) => onChange(val.format('YYYY-MM-DD'))}
        ref={datePickerRef}
        render={datePickerRender}
        format="YYYY-MM-DD"
      />
    </div>
  );
};

export default SingleDatePicker;

SingleDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeDate: PropTypes.string.isRequired,
};
