import React, { useContext, createContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { map, defaultTo, path } from 'ramda';
import { FullScreenErrorMsg } from 'components';
import { isNilOrEmpty } from 'utils/helpers';
import { useNavigation } from 'react-navi';
import { PROFILE_QUERY } from '../gql';

export const ProfileContext = createContext(null);
export const useProfile = () => useContext(ProfileContext);

const ProfileProvider = ({ children }) => {
  // Track impersonation type (e.g., { lineName: 'OFFLINE', sport: 'FOOTBALL' }, { lineName: 'VIDEO' ,sport:'PREGAME'}, or {})
  const [impersonationType, setImpersonationType] = useState({});
  const isImpersonating = !isNilOrEmpty(impersonationType);
  const { navigate } = useNavigation();

  const {
    isLoading: authLoading,
    error: authError,
    isAuthenticated,
    user: authUser,
  } = useAuth0();
  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
    refetch: refetchProfile,
  } = useQuery(PROFILE_QUERY, {
    variables: {
      includeRole: true,
      includeSquad: true,
      impersonationType: isImpersonating ? impersonationType : undefined,
    },
    skip: !isAuthenticated || authLoading || authError,
    fetchPolicy: 'cache-and-network',
  });
  const handleImpersonation = (type) => {
    setImpersonationType(type);
    navigate('/');
  };
  const profile = profileData?.profile;
  const roleName = profile?.jobTitleRole?.role?.name;
  const rolePermissions = map(
    path(['permission', 'scope']),
    defaultTo([], profile?.jobTitleRole?.role?.permissions)
  );
  const userPermissions = map(
    path(['permission', 'scope']),
    defaultTo([], profile?.permissions)
  );
  const isSuperAdmin = roleName === 'super-admin';
  const lineId = profile?.jobTitleRole?.role?.line?.id;
  // eslint-disable-next-line no-console
  if (authError) console.log({ authError });
  if (profileError) return <FullScreenErrorMsg action={refetchProfile} />;
  return (
    <ProfileContext.Provider
      value={{
        isAuthenticated,
        authLoading,
        authError,
        authUser,
        profile,
        refetchProfile,
        profileLoading,
        profileError,
        roleName,
        rolePermissions,
        userPermissions,
        isImpersonating,
        impersonationType,
        handleImpersonation,
        isSuperAdmin,
        lineId,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
