import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { difference, uniq, isEmpty, prop } from 'ramda';
import {
  Divider,
  Icon,
  Label,
  Button,
  Dropdown,
  Fab,
  TextInput,
} from 'components';
import {
  CREATE_PRIORITIES_MUTATION,
  PRIORITY_CATEGORY_VALUES_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { COMPETITION_SEASON, SLAS as SLAS_ENUM, TEAM } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { RenderIf } from 'config';

export default function AddNewSla() {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [hideDropDown, setHideDropDown] = useState(false);
  const { type, priorityName, slas, priorityCategoryId } = prop(
    'payload',
    modalState
  );
  const slaSet = uniq([...SLAS_ENUM, ...slas]);
  const modalType = type === 'teams' ? TEAM : COMPETITION_SEASON;

  const [CreatePriorityMutation, { loading }] = useMutation(
    CREATE_PRIORITIES_MUTATION,
    {
      onError: () => {
        showToast({
          message: formatMessage({ id: 'containers.all.error' }),
          icon: 'error',
        });
      },
      refetchQueries: [
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: modalType },
        },
      ],
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: formatMessage({
            id: 'containers.priorities.settings.addNewSla.success',
          }),
        });
      },
    }
  );
  const initialValues = {
    priorityCategoryId,
    slaOffsets: slas || [],
    newSla: null,
  };
  const newAssignedSlaOffset = (slaOffsets = [], newSla) => {
    const slaMutationArray = uniq([...slaOffsets, newSla]);
    const newSlas = difference(slaMutationArray, slas).filter((v) => v);
    return newSlas;
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,

    onSubmit: (values) => {
      const slaOffsets = newAssignedSlaOffset(
        values?.slaOffsets,
        values.newSla
      );
      CreatePriorityMutation({
        variables: {
          input: {
            priorityCategoryId: values.priorityCategoryId,
            slaOffsets,
          },
        },
      });
    },
  });

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className={classNames('flex', 'gap-3', 'items-center')}>
          <Icon icon="plus" size={15} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({
              id: 'containers.priorities.settings.title.addNewSla',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '42%' }}
    >
      <div className="Add-priorty-category-container">
        <Label
          variant="Baisc"
          labelHeader="Category"
          labelText={`${type === 'teams' ? 'Teams' : 'Competition'} Priorities`}
        />
        <Divider className="mgt-21 mgb-15" />

        <Label
          variant="Baisc"
          labelHeader="Priority"
          labelText={priorityName}
        />
        <Divider className="mgt-21 mgb-15" />

        <div className={classNames('flex', 'gap-25')}>
          <RenderIf condition={!hideDropDown}>
            <Dropdown
              multiSelect
              values={formik.values.slaOffsets}
              items={optionsFromArr(slaSet)}
              onChange={(v) => formik.setFieldValue('slaOffsets', v)}
              buttonText={formatMessage({ id: 'containers.priorities.sla' })}
              loading={!slaSet}
              MenuButton={() => (
                <Button
                  minimal
                  text={formatMessage({
                    id: 'containers.priorities.settings.title.addNewSla',
                  })}
                  onClick={() => setHideDropDown(true)}
                  intent="Primary"
                  icon="plus"
                  data-testid="settings-add-custom-sla"
                />
              )}
              data-testid="settings-sla-dropdown"
            />
          </RenderIf>
          <RenderIf condition={hideDropDown}>
            <div>
              <TextInput
                required
                type="number"
                labelText={formatMessage({
                  id: 'containers.priorities.settings.newSla',
                })}
                placeholder={formatMessage({
                  id: 'containers.priorities.settings.newSla',
                })}
                value={formik.values.newSla}
                onChange={(v) =>
                  formik.setFieldValue('newSla', parseInt(v, 10))
                }
                error={formik.touched.newSla && formik.errors.newSla}
                className={classNames({
                  'selected-input-highlight': formik.values.newSla,
                })}
                data-testid="settings-customSla-textInput"
              />
              <p className="mgt-10">
                {formatMessage(
                  { id: 'containers.priorities.addCustomSla' },
                  {
                    priorityName: priorityName.charAt(0),
                    newSla: formik.values.newSla || '',
                  }
                )}
              </p>
            </div>
          </RenderIf>

          <RenderIf condition={!isEmpty(formik.values.slaOffsets)}>
            <div>
              <p>{formatMessage({ id: 'containers.priorities.slas' })}</p>
              <div
                className={classNames('flex', 'gap-10', 'flex-wrap')}
                data-testid="settings-slas-info"
              >
                {formik.values.slaOffsets.map((sla) => (
                  <Fab
                    key={`fab-${sla}`}
                    text={`${sla} Hrs` || ''}
                    removeButton
                  />
                ))}
              </div>
            </div>
          </RenderIf>
        </div>
      </div>
      <div className="bp3-dialog-footer-actions" data-testid="addNewSla-footer">
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({
            id: 'containers.priorities.settings.title.addNewSla',
          })}
          onClick={formik.submitForm}
          loading={loading}
          disabled={isEmpty(
            newAssignedSlaOffset(
              formik.values?.slaOffsets,
              formik.values.newSla
            )
          )}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
}
