import { gql } from '@apollo/client';

export default gql`
  fragment UserFragment on User {
    id
    firstName
    middleName
    email
    lastName
    mobileNumber
    hrCode
    imageUrl
    jobType
    power
    qualityScore
    deactivated
    hiringDate
    legacyId
    deactivationReason
    jobTitleId
    squad {
      id
      name
    }
    lineFunction {
      id
      name
    }
    jobTitleRole {
      jobTitle
      role {
        name
        line {
          id
          sport
          name
        }
      }
    }
  }
`;
