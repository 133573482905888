import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button, Divider, Dropdown, Icon, Text, TextInput } from 'components';
import { useFormik } from 'formik';
import {
  COMPUTER_TASK_QUERY,
  CREATE_PC_MUTATION,
  FEATURES_QUERY,
  LOCATIONS_QUERY,
  PCS_QUERY,
  SERVERS_QUERY,
  UPDATE_PC_MUTATION,
  VPN_SERVICES_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { filter, isEmpty, propEq } from 'ramda';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { optionsFromArr, pluckValue } from 'utils/helpers';
import { CREATE_UPDATE_PC } from 'validations';
import { FEATURE_TYPES } from 'utils/enums';
import { createPcInput, updatePcInput } from '../helpers';

const MAX_SATELLITE_COUNT = 4;
const CreatePc = () => {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const {
    payload: { pcData = {}, screenInfo = {} },
  } = modalState;
  const { loading: locationsLoading, data: locationsData } =
    useQuery(LOCATIONS_QUERY);
  const { loading: computerTasksLoading, data: computerTasksData } =
    useQuery(COMPUTER_TASK_QUERY);
  const { loading: featuresLoading, data: featuresData } =
    useQuery(FEATURES_QUERY);

  const { loading: vpnLoading, data: vpnData } = useQuery(VPN_SERVICES_QUERY);
  const satelliteData =
    featuresData?.features &&
    filter(propEq('type', FEATURE_TYPES.SATELLITE), featuresData?.features);
  const iptvDeviceData =
    featuresData?.features &&
    filter(propEq('type', FEATURE_TYPES.IPTV_DEVICE), featuresData?.features);

  const closeModal = useCallback(
    () => modalDispatch({ type: 'ClOSE_ALL' }),
    []
  );
  const onError = useCallback((err) => {
    const errorObject = err?.graphQLErrors?.[0]?.extensions;
    if (errorObject?.exception?.code === 'P2002') {
      showToast({
        message: 'This PC number is already taken in this location',
        icon: 'error',
      });
    } else if (errorObject?.code === 'GRAPHQL_VALIDATION_FAILED') {
      showToast({
        message: err?.graphQLErrors?.[0]?.message,
        icon: 'error',
      });
    } else {
      showToast({
        message: 'Changes can not be saved try again.',
        icon: 'error',
      });
    }
  }, []);
  const onCompleted = useCallback(() => {
    const message = formatMessage({
      id: isEmpty(pcData)
        ? 'containers.pc.form.successCreate'
        : 'containers.pc.modals.update.success',
    });
    closeModal();
    showToast({ message });
  }, []);
  const refetchQueries = [
    {
      query: PCS_QUERY,
      variables: screenInfo,
    },
  ];
  const [createPCMutation, { loading: createPCLoading }] = useMutation(
    CREATE_PC_MUTATION,
    {
      onError: (err) => onError(err),
      onCompleted: () => onCompleted(),
      refetchQueries,
      notifyOnNetworkStatusChange: true,
    }
  );
  const [updatePCMutation, { loading: updatePCLoading }] = useMutation(
    UPDATE_PC_MUTATION,
    {
      onError: (err) => onError(err),
      onCompleted: () => onCompleted(),
      refetchQueries,
      notifyOnNetworkStatusChange: true,
    }
  );
  const initialValues = {
    id: pcData?.id || undefined,
    number: pcData?.number || '',
    task: pcData?.task || '',
    locationId: pcData?.location?.id || '',
    serverId: pcData?.server?.id || '',
    satellites: pcData?.satelliteFeature || [],
    VPN: pcData?.VPN || [],
    bein: pcData?.hasbein || false,
    appleTv: pcData?.hasAppleTv || false,
    HQ: pcData?.location?.HQ || '',
    iptvDevice: pcData?.iptvDeviceFeature || '',
  };

  const createPc = (values) => {
    const input = createPcInput(values, featuresData?.features);
    return createPCMutation({
      variables: {
        input,
      },
    });
  };
  const updatePc = (values) => {
    const input = updatePcInput(initialValues, values, featuresData?.features);
    return updatePCMutation({
      variables: {
        input,
      },
    });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CREATE_UPDATE_PC,
    onSubmit: (values) => {
      const VPN = pluckValue(values?.VPN);
      return !isEmpty(pcData)
        ? updatePc({ ...values, VPN })
        : createPc({ ...values, VPN });
    },
  });
  const { loading: serversLoading, data: serversData } = useQuery(
    SERVERS_QUERY,
    {
      variables: {
        hq: formik.values.HQ,
      },
      skip: !formik.values.HQ,
    }
  );
  const createPcTitle = (
    <div className={classNames('flex', 'gap-3', 'items-center')}>
      <Icon icon="plus" size={15} data-testid="modalHeader-icon" />
      <div data-testid="modalHeader-title">
        {formatMessage({ id: 'containers.pcs.createPc.title' })}
      </div>
    </div>
  );
  const updatePcTitle = (
    <div className={classNames('flex', 'gap-3', 'items-center')}>
      <Icon icon="edit" size={15} data-testid="modalHeader-icon" />
      <div data-testid="modalHeader-title">
        {formatMessage({ id: 'containers.pcs.updatePc.title' })}
      </div>
    </div>
  );
  const modalTitle = isEmpty(pcData) ? createPcTitle : updatePcTitle;

  const onTaskChange = ({ value }) => {
    formik.setFieldValue('task', value);
    if (value === 'SUPPORT') {
      formik.setFieldValue('serverId', '');
    }
  };

  const disableServersSelect =
    !formik.values.locationId ||
    isEmpty(formik.values.task) ||
    formik.values.task === 'SUPPORT';
  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={modalState?.open}
      title={modalTitle}
      onClose={closeModal}
    >
      <div className={classNames(['bp3-dialog-body', 'mgb-0', 'mgr-0'])}>
        <div>
          <Text variant="body1">
            <FormattedMessage id="containers.pc.form.pcDetails" />
          </Text>
          <TextInput
            required
            type="number"
            labelText={formatMessage({ id: 'containers.pc.form.pcNumber' })}
            placeholder={formatMessage({
              id: 'containers.pc.form.pcNumber',
            })}
            value={formik.values.number}
            onChange={formik.handleChange('number')}
            error={formik.touched.number && formik.errors.number}
            maxLength="11"
            className={classNames('mgb-20', 'mgt-20', {
              'selected-input-highlight': formik.values.number,
            })}
            min="0"
            data-testid="pc-form-pcNumber"
            name="PcNumber"
          />
        </div>
        <div className={classNames(['flex', 'gap-40', 'mgb-20'])}>
          <Dropdown
            items={optionsFromArr(locationsData?.locations?.locations)}
            value={formik.values.locationId}
            onChange={({ value, HQ }) => {
              formik.setFieldValue('locationId', value);
              formik.setFieldValue('HQ', HQ);
            }}
            error={formik.touched.locationId && formik.errors.locationId}
            buttonText={formatMessage({
              id: 'containers.pc.form.location',
            })}
            loading={locationsLoading}
            className={classNames({
              'selected-dropdown-highlight': !formik.values.locationId,
            })}
            data-testid="pc-form-location"
            name="location"
          />
          <Dropdown
            items={optionsFromArr(computerTasksData?.computerTask)}
            value={formik.values.task}
            onChange={onTaskChange}
            error={formik.touched.task && formik.errors.task}
            buttonText={formatMessage({
              id: 'containers.pc.form.computerTask',
            })}
            loading={computerTasksLoading}
            className={classNames({
              'selected-dropdown-highlight': !formik.values.task,
            })}
            data-testid="pc-form-computerTask"
            name="computerTask"
          />
        </div>
        <Divider className="mgr-25" />
        <div className="mgt-20 mgb-20">
          <Text variant="body1">
            <FormattedMessage id="containers.pc.form.pcFeatures" />
          </Text>
        </div>
        <div className={classNames(['flex', 'gap-40', 'mgb-20'])}>
          <Dropdown
            items={optionsFromArr(vpnData?.vpnService)}
            values={formik.values.VPN}
            onChange={(value) => formik.setFieldValue('VPN', [...value])}
            error={formik.touched.VPN && formik.errors.VPN}
            buttonText={formatMessage({
              id: 'containers.pc.form.vpn',
            })}
            multiSelect
            loading={vpnLoading}
            className={classNames({
              'selected-dropdown-highlight': !formik.values.VPN,
            })}
            data-testid="pc-form-vpn"
            name="vpn"
            variant="multiselect_obj_list"
          />

          <Dropdown
            items={optionsFromArr(serversData?.servers)}
            value={formik.values.serverId}
            onChange={({ value }) => {
              formik.setFieldValue('serverId', value);
            }}
            error={formik.touched.serverId && formik.errors.serverId}
            buttonText={formatMessage({
              id: 'containers.pc.form.liveServer',
            })}
            loading={serversLoading}
            disableDropdownButton={disableServersSelect}
            disabled={disableServersSelect}
            data-testid="pc-form-liveServer"
            name="liveServer"
            clearable
            onClear={() => formik.setFieldValue('serverId', null)}
          />
        </div>
        <div className="flex gap-40">
          <div
            className={classNames([
              'flex ',
              'column',
              'flex-wrap',
              'width-40',
              'gap-20',
            ])}
          >
            <Dropdown
              items={optionsFromArr(satelliteData)}
              values={formik.values.satellites}
              onChange={(value) =>
                formik.setFieldValue('satellites', [...value])
              }
              error={formik.touched.satellites && formik.errors.satellites}
              buttonText={formatMessage({
                id: 'containers.pc.form.satellites',
              })}
              multiSelect
              loading={featuresLoading}
              className={classNames({
                'selected-dropdown-highlight': !formik.values.satellites,
              })}
              disableInActiveItems={
                formik.values.satellites.length >= MAX_SATELLITE_COUNT
              }
              variant="multiselect_obj_list"
              data-testid="pc-form-satellites"
              name="satellites"
            />
            <Dropdown
              items={optionsFromArr(iptvDeviceData)}
              value={formik.values.iptvDevice}
              onChange={({ value }) =>
                formik.setFieldValue('iptvDevice', value)
              }
              error={formik.touched.iptvDevice && formik.errors.iptvDevice}
              buttonText={formatMessage({
                id: 'containers.pcAssignment.iptvDevice',
              })}
              loading={featuresLoading}
              className={classNames({
                'selected-dropdown-highlight': !formik.values.iptvDevice,
              })}
              data-testid="pc-form-iptvDevice"
              name="iptvDevice"
              clearable
            />
          </div>
          <div className="flex column">
            <Checkbox
              large
              checked={formik.values.appleTv}
              onChange={() => {
                formik.setFieldValue(`appleTv`, !formik.values.appleTv);
              }}
            >
              <div className="flex gap-5">
                <div>Apple TV</div>
                <div className="apple-tv" />
              </div>
            </Checkbox>
            <Checkbox
              large
              checked={formik.values.bein}
              onChange={() => {
                formik.setFieldValue(`bein`, !formik.values.bein);
              }}
            >
              <div className="flex gap-5">
                <div>Bein Sport</div>
                <div className="bein-sport" />
              </div>
            </Checkbox>
          </div>
        </div>

        <div className="bp3-dialog-footer-actions" data-testid="create-pc">
          <Button
            outlined
            text={formatMessage({ id: 'containers.users.form.cancel' })}
            intent="Primary"
            onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
            data-testid="cancel-button-footer"
          />
          <Button
            intent="Primary"
            text={formatMessage({ id: 'containers.users.form.save' })}
            onClick={formik.submitForm}
            loading={createPCLoading || updatePCLoading}
            disabled={!formik.values}
            data-testid="submit-button-footer"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CreatePc;
