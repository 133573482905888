import React from 'react';
import { prop } from 'ramda';
import classNames from 'classnames';

const ColoredLabel = ({ labelText, className, index = 0, screenType }) => {
  const Teamscolors = [
    '#96040B',
    '#22C7FD',
    '#999999',
    '#218E8A',
    '#3B054F',
    '#E12A62',
    '#FF7200',
    '#FEB538',
  ];

  const CompColors = [
    '#218E8A',
    '#3B054F',
    '#E12A62',
    '#FF7200',
    '#FEB538',
    '#96040B',
    '#22C7FD',
    '#999999',
  ];

  const colors = { teams: Teamscolors, competitionSeasons: CompColors };
  return (
    <div
      id="coloredLabel"
      className={classNames('colored-label-container', className)}
      style={{
        backgroundColor: prop(screenType, colors)[index % CompColors.length],
      }}
    >
      <p>{labelText}</p>
    </div>
  );
};

export default ColoredLabel;
