import { gql } from '@apollo/client';

export default gql`
  query SquadShiftMembers($squadShiftId: String!) {
    squadShiftMembers(squadShiftId: $squadShiftId) {
      id
      user {
        id
        firstName
        lastName
        hrCode
        deactivated
      }
    }
  }
`;
