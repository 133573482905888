import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { Button, Icon, Label, ShiftMatchCard } from 'components';
import { UNASSIGN_MATCH_SQUAD_SHIFT_MUTATION } from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { path } from 'ramda';
import { useIntl, FormattedMessage } from 'react-intl';
import { useProfile } from 'providers/profileProvider';

const UnassignMatch = () => {
  const { modalDispatch, modalState } = useModal();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const shiftMatch = path(['payload', 'shiftMatch'], modalState);
  const assignedSquadName = path(['payload', 'assignedSquadName'], modalState);
  const squadId = path(['payload', 'squadId'], modalState);
  const refetchShiftMatches = path(
    ['payload', 'refetchShiftMatches'],
    modalState
  );
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const {
    role: { line: userLine },
  } = jobTitleRole;
  const { id: lineId } = userLine ?? {};

  const [unAssignSquadShiftMatchesMutation, { loading }] = useMutation(
    UNASSIGN_MATCH_SQUAD_SHIFT_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: () => {
        const message = formatMessage({
          id: 'containers.all.errorSaveChanges',
        });
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message, icon: 'error' });
      },
      onCompleted: () => {
        refetchShiftMatches();
        modalDispatch({ type: 'ClOSE_ALL' });
        const message = formatMessage({
          id: 'containers.dashboards.unassign.success',
        });
        showToast({ message });
      },
    }
  );
  const unAssign = () =>
    unAssignSquadShiftMatchesMutation({
      variables: {
        input: {
          matchId: shiftMatch?.id,
          squadShiftId: shiftMatch?.squadShifts[0].squadShift.id,
          squadId,
          lineId,
        },
      },
    });

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title">
          <Icon icon="ban-circle" size={20} data-testid="modalHeader-icon" />
          <span data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.unassign.title" />
          </span>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
    >
      <div className={classNames(['pl-32', 'pt-14'])}>
        <span
          className="modal-text"
          data-testid="dashboard-unassign-description"
        >
          <FormattedMessage id="containers.dashboards.unassign.description" />
        </span>
        <Label
          variant="Label"
          background
          labelText={formatMessage(
            { id: 'containers.dashboards.squadAssigned' },
            { assignedSquadName }
          )}
          className="mgt-15"
          data-testid="dashboard-unassign-squadAssigned"
        />
      </div>

      <div className={classNames(['shift-match'])}>
        <ShiftMatchCard
          shiftMatch={shiftMatch}
          testid="dashboard-unassign-shiftMatchCard"
        />
      </div>

      <div
        className="bp3-dialog-footer-actions"
        data-testid="unassignMatch-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.dashboards.unassign' })}
          onClick={unAssign}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default UnassignMatch;
