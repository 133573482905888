import { gql } from '@apollo/client';
import PC_FRAGMENT from '../fragments/pc';

export default gql`
  query pcs($queryParams: QueryParams, $locationIds: [String]) {
    pcs(queryParams: $queryParams, locationIds: $locationIds) {
      pcs {
        ...PcFragment
      }
      totalCount
    }
  }
  ${PC_FRAGMENT}
`;
