import { gql } from '@apollo/client';
import SHIFT_FRAGMENT from '../fragments/shift';

export default gql`
  query shift($shiftId: String!) {
    shift(id: $shiftId) {
      ...ShiftFragment
    }
  }
  ${SHIFT_FRAGMENT}
`;
