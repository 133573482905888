import { gql } from '@apollo/client';

export default gql`
  mutation DeactivateUser($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      id
      legacyId
      deactivated
    }
  }
`;
