import React from 'react';
import { Dropdown } from 'components';
import { useIntl } from 'react-intl';
import { SPORTS_LIST } from 'utils/enums';
import { optionsFromArr } from 'utils/helpers';
import { useProfile } from 'providers/profileProvider';
import { isNil } from 'ramda';

const SportsFilter = ({ sport, onSportChange }) => {
  const { formatMessage } = useIntl();
  const { profile } = useProfile();
  const { jobTitleRole: userJobTitleRole } = profile;
  const {
    role: { name: roleName, line: userLine },
  } = userJobTitleRole;
  const isSuperAdmin = roleName === 'super-admin';
  const disableSport = !isSuperAdmin && !isNil(userLine);
  return (
    <Dropdown
      items={optionsFromArr(SPORTS_LIST) || []}
      value={sport || ''}
      onChange={({ value }) => onSportChange(value)}
      buttonText={formatMessage({ id: 'containers.users.header.sport' })}
      loading={!SPORTS_LIST}
      data-testid="main-filters-sport"
      disableDropdownButton={disableSport}
      disabled={disableSport}
      name="sport"
    />
  );
};

export default SportsFilter;
