import { defaultTo, difference, filter, prop } from 'ramda';
import { getSquadShiftId } from 'utils/helpers';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const arrangeMatches = (matches = []) => {
  const assignedMatches = filter(getSquadShiftId, matches || []);
  const unassignedMatches =
    difference(defaultTo([], matches), assignedMatches) || [];
  const unassignedWithPriority =
    filter(prop('priority'), unassignedMatches) || [];
  const others = difference(unassignedMatches, unassignedWithPriority) || [];
  const sortedMatches = [
    ...unassignedWithPriority,
    ...assignedMatches,
    ...others,
  ];
  return sortedMatches;
};

export const getIntervalFromShift = (date, acqInterval) => {
  const { startAt, endAt, isNextDayShift } = acqInterval?.value || {};
  const today = moment(date).format('YYYY-MM-DD');
  const tomorrow = moment(date).add(1, 'day').format('YYYY-MM-DD');
  const isEndBeforeStart = moment(`${today}T${endAt}`).isBefore(
    moment(`${today}T${startAt}`)
  );
  // this means the date provided is a local time when so the actual moment in UTC is sent to BE
  const intervalStart = moment(
    `${isNextDayShift ? tomorrow : today}T${startAt}`,
    'YYYY-MM-DD HH:mm:ss'
  ).toISOString();
  if (!startAt && !endAt) return undefined;
  if (startAt && !endAt) return { intervalStart };
  const intervalEnd = moment(
    `${isEndBeforeStart ? tomorrow : today}T${endAt}`,
    'YYYY-MM-DD HH:mm:ss'
  ).toISOString();
  if (isEndBeforeStart) {
    // concat dayBefore interval
    const yesterdayIntervalStart = moment(
      `${today}T${startAt}`,
      'YYYY-MM-DD HH:mm:ss'
    )
      .subtract(1, 'day')
      .toISOString();
    const yesterdayIntervalEnd = moment(
      `${today}T${endAt}`,
      'YYYY-MM-DD HH:mm:ss'
    ).toISOString();
    return [
      {
        intervalStart: yesterdayIntervalStart,
        intervalEnd: yesterdayIntervalEnd,
      },
      { intervalStart, intervalEnd },
    ];
  }
  return [{ intervalStart, intervalEnd }];
};
