import React from 'react';
import classNames from 'classnames';
import { isEmpty, isNil, path } from 'ramda';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { Divider, Label, Text } from 'components';
import {
  acronym,
  calcSlaLongFormat,
  formatEta,
  formatKO,
  isAfter,
} from 'utils/helpers';
import { Spinner } from '@blueprintjs/core';
import { Acl, RenderIf } from 'config';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import loading from './loading.png';

const MatchCard = ({
  match = {},
  className,
  type,
  index,
  matchDate,
  squadName,
}) => {
  const {
    id,
    priority = {},
    kickOff,
    week,
    competitionSeason: { competitionName = '', seasonName = '' } = {},
    squadShifts = [],
    eta,
    homeTeam: { name: homeTeam } = {},
    awayTeam: { name: awayTeam } = {},
  } = match;
  const { value = '', slaOffset = '' } = priority || {};

  const [{ opacity }, drag, preview] = useDrag({
    type,
    item: { id },
    isDragging(monitor) {
      const item = monitor.getItem();
      return id === item.id;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        // remove match dropped from matches list
        // query refetching is taking care of that but manual optimistic response can be added here so card can fade out and manual update cache can be used instead of refetch
      }
    },
  });

  const localTimeKO = formatKO(matchDate, kickOff);
  const calcSla = calcSlaLongFormat(matchDate, slaOffset, kickOff);
  const etaFormatted = formatEta(eta);
  const isAssigned = !!path(['0', 'squadShift', 'id'], squadShifts);
  const renderCardContent = () => (
    <Grid className="p-0 mg-0">
      <Row className="match-card-ops-info " middle="lg">
        <Col lg={!isNil(priority) ? 2 : 0}>
          <RenderIf condition={!isNil(priority)}>
            <Label
              variant="ColoredLabel"
              labelText={`${acronym(value)}:${slaOffset}`}
              index={index}
              screenType="teams"
              className="customize_colored_label"
            />
          </RenderIf>
        </Col>
        <Col lg={2}>
          <Label variant="Copyable" labelHeader="ID:" labelText={id} inline />
        </Col>
        <Col lg={2} md={2}>
          <div className="flex">
            <Text variant="body3" className="color-qs">
              Week:
            </Text>
            &nbsp;
            <Text variant="body3">{week}</Text>
          </div>
        </Col>
        <Col lg={4} md={3}>
          <Text variant="body2">{`${competitionName} ${seasonName}`}</Text>
        </Col>
        <Col lg={2} md={2}>
          <RenderIf
            condition={isAssigned}
            renderElse={
              <Text variant="body1" className="unassigned-label">
                <FormattedMessage id="containers.all.unassigned" />
              </Text>
            }
          >
            <RenderIf condition={squadName} renderElse={<Spinner size={5} />}>
              <div className="flex justify-end squad-custom-label-text">
                <Text variant="body3" className="color-qs">
                  Squad:
                </Text>
                &nbsp;
                <Text variant="body3">{squadName}</Text>
              </div>
            </RenderIf>
          </RenderIf>
        </Col>
      </Row>
      <Divider />
      <Row className="match-card-match-info" between="lg" middle="lg">
        <Col lg={4} md={10}>
          <Text variant="body1" className="styled-team-name">
            {homeTeam}
          </Text>
          <Text className="vs-styled">&nbsp;vs.&nbsp;</Text>
          <Text variant="body1" className="styled-team-name">
            {awayTeam}
          </Text>
        </Col>
        <Col lg={5} className="sla-ko-block" md={9}>
          <div className="preview-mode">
            <RenderIf condition={!isNil(eta)}>
              <div className="flex justify-end">
                <Text variant="body3" className="color-qs">
                  ETA:
                </Text>
                &nbsp;
                <Text
                  variant="body1"
                  className={classNames({
                    'color-alizarin': isAfter(eta, calcSla),
                    'color-pig-green': !isAfter(eta, calcSla),
                  })}
                >
                  {etaFormatted}
                </Text>
              </div>
            </RenderIf>
            <div className="flex justify-end">
              <Text variant="body3" className="color-qs">
                SLA:
              </Text>
              &nbsp;
              <Text variant="body3">{calcSla}</Text>
            </div>
            <div className="flex justify-end">
              <Text variant="body3" className="color-qs">
                KO:
              </Text>
              &nbsp;
              <Text variant="body3">{localTimeKO}</Text>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );

  const NonDraggableCard = () => (
    <div
      className={classNames('match-card-container', 'no-access', className, {
        unassigned: !isAssigned,
        'disabled-match': isNil(priority),
      })}
    >
      {renderCardContent()}
    </div>
  );
  if (isEmpty(match))
    return <FormattedMessage id="containers.all.emptyMatches" />;
  return (
    <Acl
      allowedPermissions={[
        'create:match-assignment',
        'update:match-assignment',
      ]}
      allowedRoleNames={['super-admin']}
      renderNoAccess={<NonDraggableCard />}
    >
      <>
        <DragPreviewImage connect={preview} src={loading} />
        <div
          className={classNames('match-card-container', className, {
            unassigned: !isAssigned,
            'disabled-match': isNil(priority),
          })}
          ref={!isNil(priority) && !isAssigned ? drag : null}
          style={{ opacity }}
          id={`dragPreviewDiv${id}`}
        >
          {renderCardContent()}
        </div>
      </>
    </Acl>
  );
};

export default MatchCard;
