import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { RenderIf } from 'config';
import { useFormik } from 'formik';
import {
  PRIORITY_CATEGORY_VALUES_QUERY,
  UPDATE_PRIORITY_CATEGORY_MUTATION,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { prop } from 'ramda';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { UPDATE_PRIORITY } from 'validations';
import { Divider, Icon, Label, TextInput, Button, Text } from 'components';
import { hasGraphQlErrors } from 'utils/helpers';
import { COMPETITION_SEASON, TEAM } from 'utils/enums';

export const EditExistingPriority = () => {
  const { modalDispatch, modalState } = useModal();
  const { showToast } = useToast();
  const { type, id, name } = prop('payload', modalState);
  const { formatMessage } = useIntl();
  const [err, setErr] = useState(false);
  const modalType = type === 'teams' ? TEAM : COMPETITION_SEASON;

  const [UpdatePriorityCategoryMutation, { loading }] = useMutation(
    UPDATE_PRIORITY_CATEGORY_MUTATION,
    {
      onError: ({ graphQLErrors }) => {
        if (hasGraphQlErrors(graphQLErrors, 'P2002')) {
          setErr(true);
        } else {
          modalDispatch({ type: 'ClOSE_ALL' });
          showToast({
            message: formatMessage({ id: 'containers.all.error' }),
            icon: 'error',
          });
        }
      },
      refetchQueries: [
        {
          query: PRIORITY_CATEGORY_VALUES_QUERY,
          variables: { type: modalType },
        },
      ],
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({ message: formatMessage({ id: 'containers.all.success' }) });
      },
    }
  );
  const initialValues = {
    name: name || '',
    id,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UPDATE_PRIORITY,
    onSubmit: (values) => {
      UpdatePriorityCategoryMutation({
        variables: {
          input: {
            id,
            value: values.name,
          },
        },
      });
    },
  });
  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="create-category-title">
          <Icon icon="edit" size={15} data-testid="modalHeader-icon" />
          <div data-testid="modalHeader-title">
            {formatMessage({
              id: 'containers.priorities.settings.editPriority.title',
            })}
          </div>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      style={{ width: '42%' }}
      canOutsideClickClose={false}
    >
      <div className="Add-priorty-category-container">
        <Label
          variant="Baisc"
          labelHeader="Category"
          labelText={`${type === 'teams' ? 'Teams' : 'Competition'} Priorities`}
        />
        <Divider className="mgt-21 mgb-15" />
        <div className="flex gap-15">
          <TextInput
            required
            type="text"
            labelText={formatMessage({ id: 'containers.all.name' })}
            placeholder={formatMessage({ id: 'containers.all.name' })}
            value={formik.values.name}
            onChange={formik.handleChange('name')}
            error={formik.touched.name && formik.errors.name}
            className={classNames({
              'selected-input-highlight': formik.values.name,
            })}
            data-testid="settings-edit-textInput"
            name="priority-name"
          />
          <RenderIf condition={err}>
            <div className="flex gap-10 Add-priorty-category-error-msg">
              <Icon customIcon="warning" size={18} />
              <Text
                variant="body2"
                data-testid="priorities-addNewPriority-warning"
              >
                {formatMessage({
                  id: 'containers.priorities.settings.addNewPriority.warning',
                })}
              </Text>
            </div>
          </RenderIf>
        </div>
      </div>
      <div
        className="bp3-dialog-footer-actions"
        data-testid="editPriority-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.btn.cancel' })}
          intent="Primary"
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={formatMessage({ id: 'containers.all.btn.saveChanges' })}
          onClick={formik.submitForm}
          loading={loading}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
};
export default EditExistingPriority;
