import { gql } from '@apollo/client';

export default gql`
  query priority(
    $priorityId: String!
    $queryParams: QueryParams
    $areaIds: [Int]
    $isFemale: Boolean
    $name: String
  ) {
    priority(
      id: $priorityId
      queryParams: $queryParams
      areaIds: $areaIds
      isFemale: $isFemale
      name: $name
    ) {
      id
      slaOffset
      teams {
        id
        name
        isFemale
        area {
          id
          name
          code
        }
      }
      totalCount
      competitionSeasons {
        id
        competitionName
        seasonName
        area {
          id
          name
          code
        }
      }
    }
  }
`;
