import { gql } from '@apollo/client';

export default gql`
  query SquadShiftsWithAvailableCoordinators(
    $date: Date!
    $lineId: String!
    $squadIds: [String]
    $shiftId: String
  ) {
    squadsPerLine: squadShiftsWithAvailableCoordinators(
      date: $date
      lineId: $lineId
      squadIds: $squadIds
      shiftId: $shiftId
    ) {
      name
      squadShifts {
        squadId
        shift {
          shiftType
          id
        }
        id
      }
    }
  }
`;
