import React from 'react';
import Dropdown from 'components/Dropdown';
import { useIntl } from 'react-intl';
import { getJobTitles, optionsFromArr } from 'utils/helpers';
import { isNil } from 'ramda';

const JobTitlesFilter = ({
  jobTitles,
  onJobTitlesChange,
  lines,
  linesLoading,
  sport,
  lineId,
}) => {
  const { formatMessage } = useIntl();
  const jobTitlesList = getJobTitles(lines, sport, lineId);

  return (
    <Dropdown
      multiSelect
      values={jobTitles || []}
      items={optionsFromArr(jobTitlesList)}
      onChange={onJobTitlesChange}
      loading={linesLoading}
      buttonText={formatMessage({ id: 'containers.users.jobTitle' })}
      data-testid="main-filters-jobTitles"
      disableDropdownButton={isNil(lineId) || isNil(sport)}
      disabled={isNil(lineId) || isNil(sport)}
    />
  );
};

export default JobTitlesFilter;
