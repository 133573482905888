import React from 'react';
import classNames from 'classnames';
import { Text } from 'components';
import { RenderIf } from 'config';

const SquadInfoCard = ({
  squadShiftPowers,
  setActiveCard,
  activeCard,
  isVideoUser,
  className,
}) => {
  const { name, capacity, power, squadShifts = [] } = squadShiftPowers;
  const { id } = squadShifts[0];
  return (
    <div
      className={classNames(['squad-power-and-capacity-info-card', className], {
        'active-card': activeCard === id,
      })}
      role="button"
      onKeyDown={(e) => e.key === 'Enter' && setActiveCard(id)}
      onClick={() => setActiveCard(id)}
      data-testid="main-card-powerAndCapacity"
      tabIndex="0"
    >
      <Text variant="body1" className="mgb-0">
        {`Squad ${name}`}
      </Text>
      <RenderIf condition={!isVideoUser}>
        <div className="flex items-center">
          <span className="bg-bolt" />
          <Text variant="body1" className="mgb-0">
            Power:&nbsp;
            <span
              className={classNames({
                'calender-colored-power-incomplete': power <= capacity,
                'calender-colored-power-complete': power >= capacity,
              })}
            >
              {power.toFixed(2)}
            </span>
            <RenderIf condition={capacity > 0}>
              <>/</>
              <span>{capacity.toFixed(2)}</span>
            </RenderIf>
          </Text>
        </div>
      </RenderIf>
    </div>
  );
};

export default SquadInfoCard;
