import React, { useRef, useState, useEffect } from 'react';
import { Icon, Button } from 'components';
import classNames from 'classnames';

const Scrollable = ({ children, className }) => {
  const ref = useRef(null);
  const [showLeftBtn, setShowLeftBtn] = useState(false);
  const [showRightBtn, setShowRightBtn] = useState(false);
  const offsets = {
    forward: 150,
    backward: -150,
  };

  useEffect(() => {
    const container = ref.current;
    if (container) {
      setShowRightBtn(container.scrollWidth > container.clientWidth);
    }
  }, []);

  const scroll = (offset) => {
    ref.current.scrollLeft += offset;
  };

  const onScroll = () => {
    const container = ref.current;
    if (container) {
      setShowLeftBtn(container.scrollLeft > 0);
      setShowRightBtn(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  return (
    <div className={classNames('scrollable-container', className)}>
      {showLeftBtn && (
        <Button
          minimal
          className="scroll-button left"
          onClick={() => scroll(offsets.backward)}
        >
          <Icon icon="chevron-left" color="#040482" size={20} />
        </Button>
      )}
      <div className="children ellipsis" ref={ref} onScroll={onScroll}>
        {children}
      </div>
      {showRightBtn && (
        <Button
          minimal
          className="scroll-button right"
          onClick={() => scroll(offsets.forward)}
        >
          <Icon icon="chevron-right" color="#040482" size={20} />
        </Button>
      )}
    </div>
  );
};

export default Scrollable;
