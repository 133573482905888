import { gql } from '@apollo/client';

export default gql`
  query teams(
    $queryParams: QueryParams
    $areaIds: [Int]
    $isFemale: Boolean
    $name: String
  ) {
    teams(
      queryParams: $queryParams
      areaIds: $areaIds
      isFemale: $isFemale
      name: $name
    ) {
      id
      name
      isFemale
      area {
        id
        name
        code
      }
    }
  }
`;
