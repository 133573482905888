import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import { Button, Icon, Label, ShiftMatchCard, Dropdown } from 'components';
import { optionsFromArr } from 'utils/helpers';
import {
  MATCH_OFFLINE_TASKS_QUERY,
  SQUAD_SHIFT_MEMBERS_QUERY,
  HALF_VALUES_QUERY,
  PART_VALUES_QUERY,
} from 'gql';
import { useModal } from 'providers/modalProvider';
import { path, filter, pluck, sortBy, prop } from 'ramda';
import { FormattedMessage, useIntl } from 'react-intl';
import CollectorTask from './collectorTask';

const EditTask = () => {
  const { modalDispatch, modalState } = useModal();
  const { formatMessage } = useIntl();
  const { assignedSquadName, matchOfflineTasks, squadId, shiftMatch } = path(
    ['payload'],
    modalState
  );
  const [collector, setCollector] = useState();
  const sortById = sortBy(prop('id'));
  const squadShiftId = path(
    ['squadShifts', '0', 'squadShift', 'id'],
    shiftMatch
  );

  // TODO stop using this extra query
  const {
    data: { squadShiftMembers = [] } = {},
    loading: squadShiftMembersLoading,
  } = useQuery(SQUAD_SHIFT_MEMBERS_QUERY, {
    variables: {
      squadShiftId,
    },
  });

  const { data: { halfValues = [] } = {} } = useQuery(HALF_VALUES_QUERY);

  const { data: { partValues = [] } = {} } = useQuery(PART_VALUES_QUERY);

  const { data: offlineTasksData } = useQuery(MATCH_OFFLINE_TASKS_QUERY, {
    variables: { matchId: shiftMatch?.id },
  });

  const collectorTasks = sortById(
    filter(
      (task) => path(['member', 'user', 'id'], task) === collector,
      offlineTasksData.matchOfflineTasks || matchOfflineTasks
    )
  );

  return (
    <Dialog
      isOpen={modalState?.open}
      title={
        <div className="dialog-title gap-5">
          <Icon customIcon="edit" size={20} data-testid="modalHeader-icon" />
          <span data-testid="modalHeader-title">
            <FormattedMessage id="containers.dashboards.editTasks.title" />
          </span>
        </div>
      }
      onClose={() =>
        modalDispatch({
          type: 'ClOSE_ALL',
        })
      }
      canOutsideClickClose={false}
      style={{ width: '950px', maxWidth: 'unset' }}
    >
      <Label
        variant="Label"
        labelText={`Assigned to Squad ${assignedSquadName}`}
        className=" pl-32 pt-14"
      />
      <div className="mgt-12 mgb-12 pl-30 pr-30">
        <ShiftMatchCard shiftMatch={shiftMatch} />
      </div>
      <div className="mgt-12 pl-32 body-four-txt">Edit Assigned Tasks</div>
      <div className="pl-32 pt-24 flex items-center">
        <div className="body-one-txt mgr-12">
          Choose Collector to edit their tasks
        </div>
        <Dropdown
          variant="users"
          items={optionsFromArr(pluck('user', squadShiftMembers))}
          onChange={({ value }) => setCollector(value)}
          buttonText="Collector"
          labelText="collector"
          value={collector}
          loading={squadShiftMembersLoading}
          data-testid="dashboard_viewTasks_collectorDropDown"
        />
      </div>
      {collectorTasks.map((task) => (
        <CollectorTask
          key={task.id}
          task={task}
          squadId={squadId}
          matchId={shiftMatch.id}
          halfValues={halfValues}
          partValues={partValues}
        />
      ))}
      <div
        className="bp3-dialog-footer-actions pt-50"
        data-testid="editTasks-footer"
      >
        <Button
          outlined
          text={formatMessage({ id: 'containers.all.close' })}
          onClick={() =>
            modalDispatch({
              type: 'ClOSE_ALL',
            })
          }
          intent="Primary"
          data-testid="cancel-button-footer"
        />
      </div>
    </Dialog>
  );
};

export default EditTask;
