import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Divider = ({ className, isVertical }) => (
  <div
    className={classNames('divider', className, {
      'vertical-divider': isVertical,
    })}
  />
);

export default Divider;

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};
