import { gql } from '@apollo/client';

export default gql`
  mutation createShift($input: CreateShiftInput!) {
    createShift(input: $input) {
      id
      shiftType
      startAt
      endAt
    }
  }
`;
