import { gql } from '@apollo/client';
import SQUAD_FRAGMENT from '../fragments/squad';

export default gql`
  mutation createSquad($input: CreateSquadInput!) {
    createSquad(input: $input) {
      ...SquadFragment
    }
  }
  ${SQUAD_FRAGMENT}
`;
