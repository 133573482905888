import React from 'react';
import { equals, isEmpty } from 'ramda';
import { Button, Icon } from 'components';
import { Acl, RenderIf } from 'config';
import { useProfile } from 'providers/profileProvider';
import { isVideo as isVideoFn, pluckValue, splitStatues } from 'utils/helpers';
import { Card } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { filtersActions, filtersInitialState } from 'state/filtersState';
import MatchStatusFilter from './filtersComponents/MatchStatusFilter';
import SquadsFilter from './filtersComponents/SquadsFilter';
import PrioritiesFilter from './filtersComponents/PrioritiesFilter';
import TeamsFilter from './filtersComponents/TeamsFilter';
import CompetitionsFilter from './filtersComponents/CompetitionsFilter';
import AcqIntervalFilter from './filtersComponents/AcqIntervalFilter';
import AcqTypeFilter from './filtersComponents/AcqTypeFilter';
import IssueTypeFilter from './filtersComponents/IssueTypeFilter';
import AssignedCoordinatorFilter from './filtersComponents/AssignedCoordinatorFilter';
import IsAssignedFilter from './filtersComponents/IsAssignedFilter';
import RoomsFilter from './filtersComponents/RoomsFilter';

const MatchesFilters = ({
  screenType,
  openFilters,
  setOpenFilters,
  setMatchesFiltersState,
  filtersState,
  filtersDispatch,
  date,
  shiftId,
}) => {
  const {
    profile: { jobTitleRole },
  } = useProfile();
  const { line } = jobTitleRole?.role;
  const { name: userLineName } = line || {};
  const isVideo = isVideoFn(userLineName);
  const {
    competitionSeasonIds,
    teamIds,
    priorityIds,
    matchStatus,
    squadIds,
    acqInterval,
    acquisitionTypes,
    issueTypes,
    assignedCoordinators,
    isAssigned,
    locationIds,
  } = filtersState;
  const setPropState = (payload) => {
    filtersDispatch({
      type: filtersActions.SET_DATA,
      payload,
    });
  };

  const ApplyButton = () => {
    const splitStatuses = splitStatues(matchStatus) || [];
    const statuses = isEmpty(splitStatuses[0]) ? undefined : splitStatuses[0];
    const collectionStatuses =
      isVideo || isEmpty(splitStatuses[1]) ? undefined : splitStatuses[1];
    const videoStatuses =
      !isVideo || isEmpty(splitStatuses[1]) ? undefined : splitStatuses[1];

    const applyFilters = () => {
      setMatchesFiltersState({
        teamIds: pluckValue(teamIds),
        competitionSeasonIds: pluckValue(competitionSeasonIds),
        priorityIds: pluckValue(priorityIds),
        squadIds: pluckValue(squadIds),
        assigned: isAssigned,
        statuses,
        collectionStatuses,
        videoStatuses,
        acqInterval,
        acquisitionTypes,
        videoIssueTypes: issueTypes,
        coordinatorIds: pluckValue(assignedCoordinators),
        locationIds,
      });
    };
    return (
      <Button
        intent="primary"
        text="Apply"
        onClick={applyFilters}
        data-testid="main-filters-applyBtn"
      />
    );
  };

  const resetFilters = () => {
    setMatchesFiltersState(filtersInitialState);
    filtersDispatch({ type: filtersActions.RESET_FILTERS });
  };
  return (
    <>
      {/** popupFilters * */}
      <RenderIf condition={!equals(screenType, 'dashboard') || isVideo}>
        <Popover2
          minimal
          interactionKind="click"
          isOpen={openFilters}
          placement="bottom"
          usePortal={false}
          popoverClassName="filters-container"
          transitionDuration={100}
          content={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Card className="filters-card">
              <Button
                icon="cross"
                onClick={() => setOpenFilters(!openFilters)}
                className="close-filter"
              />
              <div className="filters-content">
                <div className="match-assignment-filters-container">
                  <RenderIf condition={equals(screenType, 'matchAssignment')}>
                    <RenderIf condition={!isVideo}>
                      <CompetitionsFilter
                        competitionSeasonIds={competitionSeasonIds}
                        setPropState={setPropState}
                      />
                      <TeamsFilter
                        teamIds={teamIds}
                        setPropState={setPropState}
                      />
                    </RenderIf>
                    <RenderIf condition={isVideo}>
                      <AcqTypeFilter
                        values={acquisitionTypes}
                        onChange={(acquisitionTypes) =>
                          setPropState({ acquisitionTypes })
                        }
                      />
                    </RenderIf>
                    <IsAssignedFilter
                      isAssigned={isAssigned}
                      onChange={(isAssigned) => {
                        setPropState({ isAssigned });
                      }}
                    />
                    <RenderIf condition={!isVideo}>
                      <PrioritiesFilter
                        priorityIds={priorityIds}
                        setPropState={setPropState}
                      />
                    </RenderIf>
                    <RenderIf condition={isVideo}>
                      <AcqIntervalFilter
                        value={acqInterval}
                        onChange={(acqInterval) =>
                          setPropState({ acqInterval })
                        }
                      />
                    </RenderIf>
                  </RenderIf>
                  <RenderIf condition={equals(screenType, 'swap')}>
                    <MatchStatusFilter
                      status={matchStatus}
                      setPropState={setPropState}
                    />
                    <PrioritiesFilter
                      priorityIds={priorityIds}
                      setPropState={setPropState}
                    />
                    <CompetitionsFilter
                      competitionSeasonIds={competitionSeasonIds}
                      setPropState={setPropState}
                    />
                    <Acl
                      allowedPermissions={['update:squad', 'update:match']}
                      allowedRoleNames={['super-admin', 'admin', 'viewer']}
                    >
                      <SquadsFilter
                        squadIds={squadIds}
                        setPropState={setPropState}
                      />
                    </Acl>
                  </RenderIf>
                  <RenderIf
                    condition={equals(screenType, 'dashboard') && isVideo}
                  >
                    <MatchStatusFilter
                      status={matchStatus}
                      setPropState={setPropState}
                    />
                    <SquadsFilter
                      squadIds={squadIds}
                      setPropState={setPropState}
                    />
                    <IssueTypeFilter
                      values={issueTypes}
                      onChange={(issueTypes) => setPropState({ issueTypes })}
                    />
                    <CompetitionsFilter
                      competitionSeasonIds={competitionSeasonIds}
                      setPropState={setPropState}
                    />
                    <AcqTypeFilter
                      values={acquisitionTypes}
                      onChange={(acquisitionTypes) =>
                        setPropState({ acquisitionTypes })
                      }
                    />
                    <AssignedCoordinatorFilter
                      values={assignedCoordinators}
                      onChange={(assignedCoordinators) =>
                        setPropState({ assignedCoordinators })
                      }
                      variables={{
                        shiftId,
                        date,
                      }}
                    />
                    <RoomsFilter
                      values={locationIds}
                      onChange={(locationIds) => setPropState({ locationIds })}
                    />
                  </RenderIf>
                </div>
              </div>
              <div className="filters-buttons">
                <Button text="Cancel" onClick={resetFilters} />
                <ApplyButton />
              </div>
            </Card>
          }
        >
          <Button
            icon={<Icon icon="filter" />}
            onClick={() => setOpenFilters(!openFilters)}
            minimal
            outlined
            className={classNames(['filter-button'], {
              active: openFilters,
            })}
            data-testid="main-openFilters-Button"
          />
        </Popover2>
      </RenderIf>
      {/** Normal Filters * */}
      <RenderIf condition={equals(screenType, 'dashboard') && !isVideo}>
        <Button
          icon={<Icon icon="filter" />}
          onClick={() => setOpenFilters(!openFilters)}
          minimal
          outlined
          className={classNames(['filter-button'], {
            active: openFilters,
          })}
          data-testid="main-openFilters-Button"
        />
        <div className="match-assignment-filters-container">
          <RenderIf condition={openFilters}>
            <PrioritiesFilter
              priorityIds={priorityIds}
              setPropState={setPropState}
            />
            <CompetitionsFilter
              competitionSeasonIds={competitionSeasonIds}
              setPropState={setPropState}
            />
            <Acl
              allowedPermissions={['update:squad', 'update:match']}
              allowedRoleNames={['super-admin', 'admin', 'viewer']}
            >
              <SquadsFilter squadIds={squadIds} setPropState={setPropState} />
            </Acl>
            <MatchStatusFilter
              status={matchStatus}
              setPropState={setPropState}
            />
            <div className="filters-buttons">
              <ApplyButton />
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </>
  );
};

export default MatchesFilters;
