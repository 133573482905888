/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow strict

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FuzzySearch from 'fuzzy-search';
import { MenuItem, Button, Menu, Checkbox } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import {
  toggleObjListItem,
  toggleListItem,
  findByValue,
  getLabel,
  title,
} from 'utils/helpers';
import Avatar from 'react-avatar';
import { pluck, includes, defaultTo } from 'ramda';
import { Scroll, Icon } from 'components';
import classNames from 'classnames';

const Dropdown = ({
  values,
  disabled,
  value: activeValue,
  multiSelect,
  items,
  searchPath,
  filterable,
  resetOnClose,
  onChange,
  popOverPosition,
  labelText,
  onSearch,
  error,
  variant,
  loading,
  buttonText,
  className,
  MenuButton,
  fetchMore,
  buttonAction,
  hasMore,
  name,
  disableInActiveItems,
  disableDropdownButton,
  usePortal = true,
  clearable = false,
  onClear,
  ...rest
}) => {
  const popOverProps = {
    position: popOverPosition,
    canEscapeKeyClose: true,
    hoverOpenDelay: 100,
    minimal: true,
    usePortal,
  };
  const inputProps = {};
  const querySearch = useMemo(() => {
    return new FuzzySearch(items, searchPath, {
      caseSensitive: false,
      sort: true,
    });
  }, [items, searchPath]);

  const itemRenderer = (item, { handleClick }) => {
    // TODO extract item renderes outside
    const {
      label,
      value,
      id,
      hrCode,
      jobTitle,
      firstName,
      lastName,
      deactivated,
    } = item;
    if (variant === 'shifts') {
      const shiftItemText = (
        <div className="flex gap-10">
          <span className={`bg-${item?.shiftType?.toLowerCase()}`} />
          <span>{title(label)}</span>
        </div>
      );
      return (
        <MenuItem
          onClick={handleClick}
          key={id}
          text={shiftItemText}
          value={id}
        />
      );
    }
    if (variant === 'users') {
      const userItemText = (
        <div className="flex gap-10">
          {!multiSelect ? <Icon icon="plus" /> : <></>}
          <Avatar round size={20} name={`${firstName} ${lastName}`} />
          <span>{title(label)}</span>
          <span>{hrCode}</span>
          <span>{title(jobTitle)}</span>
        </div>
      );
      return !multiSelect ? (
        <MenuItem
          onClick={() =>
            multiSelect ? onChange(toggleListItem(values, item)) : handleClick()
          }
          key={id}
          text={userItemText}
          value={item}
          disabled={deactivated}
          data-testid={id}
        />
      ) : (
        <div className={classNames('users-variant-multi-select')}>
          <Checkbox
            label={<>{userItemText}</>}
            inline
            large
            checked={includes(value, pluck('value', values))}
            onChange={() => {
              const newItems = toggleObjListItem(values, item);
              onChange(newItems);
            }}
            data-testid={`dropdown-checkbox-${name}`}
          />
        </div>
      );
    }

    if (variant === 'add') {
      const addText = (
        <div className="flex gap-10">
          <Icon icon="plus" />
          <span>{title(label)}</span>
        </div>
      );
      return (
        <MenuItem
          onClick={() => {
            const newItems = toggleObjListItem(values, item);
            onChange(newItems);
          }}
          key={id}
          text={addText}
          value={item}
        />
      );
    }

    if (variant === 'multiselect_obj_list') {
      return (
        <div className="flex gap-10">
          <Checkbox
            label={title(label) || 'no name'}
            inline
            large
            checked={includes(value, pluck('value', values))}
            onChange={() => {
              const newItems = toggleObjListItem(values, item);
              onChange(newItems);
            }}
            disabled={
              !includes(value, defaultTo([], pluck('value', values))) &&
              disableInActiveItems
            }
            data-testid={`dropdown-checkbox-${name}`}
          />
        </div>
      );
    }

    if (variant === 'video_status') {
      const shiftItemText = (
        <div className="flex gap-10">
          <span className={`bg-video-status-${item?.value?.toLowerCase()}`} />
          <span>{title(label)}</span>
        </div>
      );
      return (
        <MenuItem
          onClick={handleClick}
          key={id}
          text={shiftItemText}
          value={id}
        />
      );
    }

    if (multiSelect) {
      return (
        <div className="mgt-8">
          <Checkbox
            label={title(label) || 'no name'}
            inline
            large
            checked={includes(value, defaultTo([], values))}
            onChange={() => onChange(toggleListItem(values, value))}
            disabled={
              !includes(value, defaultTo([], values)) && disableInActiveItems
            }
            data-testid={`dropdown-checkbox-${name}`}
          />
        </div>
      );
    }
    const labelText = name === 'sports' ? label : title(label);
    return (
      <>
        <MenuItem
          key={value}
          text={labelText || 'N/A'}
          value={value}
          onClick={handleClick}
          icon={item?.icon}
        />
      </>
    );
  };
  const handleScroll = useCallback(() => fetchMore(), [fetchMore]);
  const itemListRenderer = ({ filteredItems, renderItem, itemsParentRef }) => {
    const renderedItems = filteredItems.map(renderItem);
    if (fetchMore) {
      return (
        <Scroll
          hasMore={hasMore}
          onScroll={handleScroll}
          renderedItemsLength={renderedItems.length}
          name={name}
        >
          <Menu ulRef={itemsParentRef}>
            {renderedItems}
            {MenuButton && <MenuButton />}
          </Menu>
        </Scroll>
      );
    }
    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}
        {MenuButton && <MenuButton />}
      </Menu>
    );
  };

  const createNewItemRenderer = () => <Button>Create new</Button>;
  const activeValueIcon = findByValue('value', activeValue, items)?.icon || '';
  return (
    <>
      <Select
        activeItem={activeValue}
        inputProps={inputProps}
        disabled={disabled}
        items={items}
        filterable={filterable}
        resetOnClose={resetOnClose}
        itemRenderer={itemRenderer}
        itemListRenderer={itemListRenderer}
        createNewItemPosition={() => 'bottom-left'}
        createNewItemRenderer={createNewItemRenderer}
        popoverProps={popOverProps}
        itemListPredicate={(query) => {
          if (onSearch) onSearch(query);
          return querySearch.search(query);
        }}
        noResults={<MenuItem disabled text="No results." />}
        onItemSelect={(item) => onChange(item)}
        className={classNames('dropdown-select', { error })}
        shouldReturnFocusOnClose
      >
        <>
          {labelText && <label>{labelText}</label>}
          <Button
            text={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="flex dropdown-button-content items-center">
                {activeValueIcon && (
                  <>
                    <span className={`bg-${activeValueIcon}`} />
                    &nbsp;
                  </>
                )}
                <span data-testid={`${activeValue || buttonText}`}>
                  {getLabel(activeValue, items) || buttonText}
                </span>
              </div>
            }
            rightIcon={
              clearable && activeValue ? (
                <Icon
                  icon="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear ? onClear() : onChange('');
                  }}
                />
              ) : (
                'chevron-down'
              )
            }
            className={classNames('dropdown-button', className)}
            alignText="left"
            loading={loading}
            onClick={buttonAction}
            disabled={disableDropdownButton}
            {...rest}
            name={name}
          />
        </>
        {error && (
          <div className="error-text" data-testid={`main-errors-${name}`}>
            {error}
          </div>
        )}
      </Select>
    </>
  );
};

export default Dropdown;
Dropdown.propTypes = {
  disabled: PropTypes.bool,
  activeItem: PropTypes.object,
  items: PropTypes.array.isRequired,
  searchPath: PropTypes.array,
  filterable: PropTypes.bool,
  resetOnClose: PropTypes.bool,
  loading: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onItemSelect: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  popOverPosition: PropTypes.oneOf([
    'bottom-left',
    'right-top',
    'bottom-right',
  ]),
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.string,
  variant: PropTypes.string,
  labelText: PropTypes.string,
  clearable: PropTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
  // value: undefined,
  searchPath: [],
  filterable: false,
  resetOnClose: false,
  popOverPosition: 'bottom-left',
  // onChange: () => {},
  // buttonAction: () => {},
};
