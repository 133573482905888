import React from 'react';
import { SearchBox } from 'components';
import classNames from 'classnames';

const FiltersBar = ({ children, onSearch, searchValue, className }) => (
  <div
    className={classNames('filters-bar-container', className)}
    data-testid="main-filters-container"
  >
    <div className="filters-bar-search-box">
      <SearchBox onChange={onSearch} value={searchValue} />
    </div>
    <div className="filters-bar-children">{children}</div>
  </div>
);

export default FiltersBar;
