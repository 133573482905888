import React from 'react';
import classNames from 'classnames';
import { Button, Icon, Label, Text } from 'components';
import { RenderIf, Acl } from 'config';
import { isEmpty } from 'ramda';
import { useModal } from 'providers/modalProvider';
import { useIntl } from 'react-intl';
import ProviderList from './ProviderList';
import CardHeader from './cardHeader';

const LeagueCard = (cardData) => {
  const { modalDispatch } = useModal();
  const { formatMessage } = useIntl();
  const {
    id: competationId,
    competitionName,
    seasonName,
    live,
    competitorAvailable,
    area: { code: countryCode, name: countryName },
    providers = [],
  } = cardData;
  return (
    <div className={classNames(['card', 'pl-20', 'pr-20'])}>
      <CardHeader
        competationId={competationId}
        competitionName={competitionName}
        seasonName={seasonName}
        countryCode={countryCode}
        countryName={countryName}
      />

      <div className="card-body league-card-body">
        <RenderIf
          renderElse={
            <div className="flex gap-5">
              <Icon icon="disable" />
              <Text variant="body2">No Providers</Text>
            </div>
          }
          condition={providers && !isEmpty(providers)}
        >
          <ProviderList providers={providers} />
        </RenderIf>
        <RenderIf condition={live}>
          <Label
            className="mg-0 p-0"
            labelText={competationId}
            variant="BorderLabel"
            labelsList={[
              <div className="flex gap-5">
                <Icon icon="symbol-circle" color="red" />
                <Text variant="body1">Live</Text>
              </div>,
            ]}
          />
        </RenderIf>
      </div>
      <div className="card-footer">
        <RenderIf condition={competitorAvailable}>
          <div className="flex gap-5">
            <Icon icon="third-party" />
            <Text variant="body2">3rd party</Text>
          </div>
        </RenderIf>
        <div className="automarginLeft">
          <Acl
            allowedRoleNames={['super-admin']}
            allowedPermissions={['delete:league']}
          >
            <Button
              text={formatMessage({ id: 'containers.all.delete' })}
              icon="ban-circle"
              minimal
              onClick={() =>
                modalDispatch({
                  type: 'DELETE_LEAGUE',
                  payload: { league: cardData },
                })
              }
              className="mg-5 p-0"
              intent="none"
            />
          </Acl>
          <Acl
            allowedRoleNames={['super-admin']}
            allowedPermissions={['update:league']}
          >
            <Button
              text="Edit"
              intent="Primary"
              icon="edit"
              minimal
              onClick={() =>
                modalDispatch({
                  type: 'EDIT_LEAGUE',
                  payload: { league: cardData },
                })
              }
              className="mg-5 p-0"
            />
          </Acl>
        </div>
      </div>
    </div>
  );
};

export default LeagueCard;
