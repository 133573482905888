import { gql } from '@apollo/client';

export default gql`
  query LineFunctions($lineId: String) {
    lineFunctions(lineId: $lineId) {
      id
      name
      _count {
        squads
      }
      line {
        id
        name
        sport
      }
    }
  }
`;
