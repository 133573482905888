import React from 'react';
import { sortBy, prop, filter, isEmpty, includes } from 'ramda';
import { useCurrentRoute } from 'react-navi';
import { TabGroup, Button } from 'components';
import { RenderIf } from 'config';
import { title } from 'utils/helpers';
import { hasAccess } from 'config/acl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ROUTE_ROLE_PERMISSIONS from 'config/routeRoles';

const SideNav = ({ collapsed, setCollapsed, profile }) => {
  const currentRoute = useCurrentRoute().url.pathname;
  const SIDE_NAV_ITEMS = [
    {
      order: 1,
      text: 'General',
      links: [
        {
          order: 1,
          text: 'Dashboard',
          icon: { customIcon: 'dashboard' },
          link: '/dashboard',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/dashboard'],
          isActive: includes(currentRoute, ['/dashboard', '/']),
        },
      ],
    },
    {
      order: 2,
      text: 'Manage Matches',
      links: [
        {
          order: 1,
          text: 'Assign Matches',
          icon: { customIcon: 'check' },
          link: '/matches',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/matches'],
          isActive: includes(currentRoute, ['/matches']),
        },
        {
          order: 2,
          text: 'Priorities',
          icon: { customIcon: 'bang' },
          link: '/priorities/teams',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/priorities'],
          isActive: includes(currentRoute, [
            '/priorities/competition_season',
            '/priorities/teams',
          ]),
        },
        {
          order: 3,
          text: 'Video Acquisition',
          icon: { customIcon: 'acquisition' },
          link: '/video/acquisition/unassigned_matches',
          newTab: false,
          allowedPermissions:
            ROUTE_ROLE_PERMISSIONS['/video/acquisition/unassigned_matches'],
          isActive: includes(currentRoute, [
            '/video/acquisition/unassigned_matches',
            '/video/acquisition/assigned_matches',
          ]),
        },
        {
          order: 4,
          text: 'Assign PC',
          icon: { customIcon: 'pcAssignment' },
          link: '/video/pc/unassigned_matches',
          newTab: false,
          allowedPermissions:
            ROUTE_ROLE_PERMISSIONS['/video/pc/unassigned_matches'],
          isActive: includes(currentRoute, [
            '/video/pc/unassigned_matches',
            '/video/pc/assigned_matches',
          ]),
        },
      ],
    },
    {
      order: 3,
      text: 'Team Management',
      links: [
        {
          order: 2,
          text: 'Shifts Schedule',
          icon: { customIcon: 'calenderView' },
          link: '/shift-schedule',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/shift-schedule'],
          isActive: includes(currentRoute, ['/shift-schedule']),
        },
      ],
    },
    {
      order: 4,
      text: 'User Management',
      links: [
        {
          order: 1,
          text: 'Users',
          icon: { customIcon: 'user' },
          link: '/user-management',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/user-management'],
          isActive: includes(currentRoute, ['/user-management']),
        },
        {
          order: 2,
          text: 'Squads',
          icon: { customIcon: 'groups' },
          link: '/squads',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/squads'],
          isActive: includes(currentRoute, ['/squads']),
        },
      ],
    },
    {
      order: 5,
      text: 'Video Data',
      links: [
        {
          order: 1,
          text: 'PCs',
          icon: { icon: 'desktop' },
          link: '/video/pcs',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/video/pcs'],
          isActive: includes(currentRoute, ['/video/pcs']),
        },
        {
          order: 3,
          text: 'Providers',
          icon: { icon: 'antenna' },
          link: '/video/providers',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/video/providers'],
          isActive: includes(currentRoute, ['/video/providers']),
        },
        {
          order: 2,
          text: 'Leagues',
          icon: { icon: 'globe' },
          link: '/video/leagues',
          newTab: false,
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/video/leagues'],
          isActive: includes(currentRoute, ['/video/leagues']),
        },
      ],
    },
    {
      order: 6,
      text: '',
      links: [
        {
          order: 2,
          text: 'Sign Out',
          icon: { customIcon: 'out' },
          link: '/logout',
          newTab: false,
          onClick: 'SIGN_OUT',
          allowedPermissions: ROUTE_ROLE_PERMISSIONS['/logout'],
        },
      ],
    },
  ];
  return (
    <div className={classNames('side-nav-container', { expanded: !collapsed })}>
      <RenderIf condition={!collapsed}>
        <Button
          minimal
          icon="minimize"
          className="shrink"
          onClick={() => setCollapsed(!collapsed)}
        />
      </RenderIf>
      <RenderIf condition={collapsed}>
        <Button
          minimal
          icon="menu"
          className="expand-side mgt-10"
          onClick={() => setCollapsed(!collapsed)}
        />
      </RenderIf>
      <ul className="side-nav-sections">
        {sortBy(prop('order'))(SIDE_NAV_ITEMS).map((item) => {
          const allLinks = item.links;
          const allowedLinks = filter(
            ({ allowedPermissions }) =>
              hasAccess({ allowedPermissions, profile }),
            allLinks
          );
          const sortedAllowedLinks = sortBy(prop('order'), allowedLinks);
          return (
            // eslint-disable-next-line
            <RenderIf
              condition={sortedAllowedLinks && !isEmpty(sortedAllowedLinks)}
            >
              <li key={`main-left-nav-${title(item.text)}-${item.order}`}>
                <RenderIf condition={!collapsed}>
                  <div className="nav-header">{item.text}</div>
                </RenderIf>
                <TabGroup
                  isVertical
                  small
                  items={sortedAllowedLinks}
                  isCollapsed={collapsed}
                />
              </li>
            </RenderIf>
          );
        })}
      </ul>
    </div>
  );
};

export default SideNav;
SideNav.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};
