import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'gql';

export default gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
