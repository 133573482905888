import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentRoute } from 'react-navi';
import { Spinner } from '@blueprintjs/core';
import { map, sum, pluck } from 'ramda';
import slugify from 'slugify';
import ButtonLink from './ButtonLink';
import Scrollable from '../Scrollable';

const ButtonLinkGroup = ({
  items = [],
  small,
  route,
  type,
  loading,
  getPrioritiesFromCategories,
}) => {
  const activeId = useCurrentRoute()?.lastChunk?.request.params?.id;
  return loading ? (
    <Spinner />
  ) : (
    <Scrollable>
      {map((item) => {
        const count = sum(pluck(type, pluck(`totalCount`, item?.priorities)));
        return (
          <ButtonLink
            {...item}
            small={small}
            key={item.id}
            text={item.value}
            activeId={activeId}
            // REFACTOR NEEDED this needs to mmove out of display layer
            link={`${route}/${slugify(item.value, '_').toLowerCase()}/${
              item.id
            }/${getPrioritiesFromCategories(items, item.id)[0]?.id || ''}`}
            // along with this
            count={count || 0}
          />
        );
      }, items)}
    </Scrollable>
  );
};
export default ButtonLinkGroup;
ButtonLinkGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
};
